import { REACT_QUERY_CLIENTS } from "../constants/reactQuery.constants";
import { getClients } from "../apis";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useClients = (filters?: any) => {
  const { data, error, isPending, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      REACT_QUERY_CLIENTS,
      filters?.status,
      filters?.user_type_id,
      filters?.user_type_parent_id,
      filters?.search_text,
      filters?.cat_id,
      filters?.rate,
      filters?.bid,
    ],
    queryFn: getClients,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.data.length < 10) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return {
    data,
    error,
    isPending,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    ref,
  };
};
