import { styled } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};
const StyledContainer = styled("div")({
  display: "flex",
  gap: "1rem",
  flexDirection: "column",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 1px 10px rgba(52, 180, 74, 0.25)",
  padding: "2rem 1.5rem 1.5rem 1.5rem",
});

const MainContainer = (props: Props) => {
  return <StyledContainer>{props.children}</StyledContainer>;
};

export default MainContainer;
