import { Divider, Stack, styled } from "@mui/material";
import SettingsForm from "../../components/Forms/SettingsForm";
import Header from "../../components/Header/Header";

type Props = {};

const StyledMainContainer = styled("div")({
  background: "#FFFFFF",
  boxShadow: "0px 1px 10px rgba(52, 180, 74, 0.25)",
  borderRadius: "8px",
  padding: "3rem .5rem",
});

const Settings = (props: Props) => {
  return (
    <main>
      <Header title="الاعدادات" />
      <StyledMainContainer>
        <Stack
          spacing={5}
          divider={
            <Divider
              sx={{
                borderColor: "rgba(0, 0, 0, 0.08)",
              }}
            />
          }
        >
          <SettingsForm />
        </Stack>
      </StyledMainContainer>
      {/* <ChangePasswordModal open={open} handleClose={handleClose} /> */}
    </main>
  );
};

export default Settings;
