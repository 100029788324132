import axios from "axios";
import { WhyMarkoonItem } from "../types";

export const getWhyMarkoon = async () => {
  try {
    const response = await axios.get(`why_markoon/get`);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateWhyMarkoon = async (values: WhyMarkoonItem) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (key === "icon" && typeof values[key] === "string") {
        continue;
      }
      if (key === "id") {
        formData.append("whyMarkoon_id", String(values[key]));
        continue;
      }
      formData.append(key, values[key]);
    }
    const response = await axios.post(`why_markoon/update`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const createWhyMarkoon = async (values: WhyMarkoonItem) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    const response = await axios.post(`why_markoon/create`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};
