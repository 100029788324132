import { Form, Formik } from "formik";
import { BlogForm, CommonModalProps } from "../../types";
import { CommonModal } from "./commonModal";
import { Box, Button, FormLabel, Stack } from "@mui/material";
import { BiPlus } from "react-icons/bi";
import CustomTextField from "../CustomTextField/CustomTextField";
import { ChangeEventHandler, useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface BlogModalProps extends CommonModalProps<BlogForm> {
  blog: BlogForm | null;
  isEdit?: boolean;
}

export const BlogModal: React.FC<BlogModalProps> = ({
  open,
  handleClose,
  title,
  blog,
  handleConfirm,
  isLoading,
  isEdit,
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(blog?.image ?? undefined);
  const handleImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    if (blog?.image) {
      setImageUrl(blog.image);
    }
  }, [blog]);
  return (
    <Formik
      initialValues={{
        id: blog?.id || null,
        title_ar: blog?.title_ar ?? "",
        title_en: blog?.title_en ?? "",
        description_ar: blog?.description_ar ?? "",
        description_en: blog?.description_en ?? "",
        image: blog?.image ?? "",
        date: blog?.date ?? "",
      }}
      onSubmit={async (v) => {
        await handleConfirm(v);
      }}
      enableReinitialize={true}
    >
      {({ setFieldValue, handleSubmit, resetForm, errors, touched, values }) => (
        <CommonModal
          open={open}
          handleClose={() => {
            setImageUrl(undefined);
            resetForm();
            handleClose();
          }}
          title={title}
          handleConfirm={() => {
            //@ts-ignore
            return handleSubmit()?.then(() => {
              resetForm();
              setImageUrl(undefined);
            });
            // resetForm();
          }}
          isLoading={isLoading}
          width={"80%"}
          confirmButtonText={isEdit ? "تعديل" : undefined}
        >
          <Form>
            <Stack spacing={4}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <CustomTextField name="title_ar" label="عنوان المقال بالعربي" multiLine={1} />
                <CustomTextField name="title_en" label="عنوان المقال بالانجليزي" multiLine={1} />

                <Stack spacing={1}>
                  <FormLabel
                    sx={{
                      fontSize: "1rem",
                    }}
                  >
                    وصف المقال باللغه العربيه
                  </FormLabel>
                  <CKEditor
                    config={{
                      language: "ar",
                      toolbar: {
                        items: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",

                          "numberedList",
                          "blockQuote",
                          "undo",
                          "redo",
                        ],
                      },
                    }}
                    editor={ClassicEditor}
                    data={values?.description_ar}
                    onChange={(_, editor) => {
                      const data = editor.getData();

                      setFieldValue("description_ar", data);
                    }}
                  />
                </Stack>
                <Stack spacing={1}>
                  <FormLabel
                    sx={{
                      fontSize: "1rem",
                    }}
                  >
                    وصف المقال باللغه الانجليزيه
                  </FormLabel>
                  <CKEditor
                    config={{
                      language: "en",
                      toolbar: {
                        items: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",

                          "numberedList",
                          "blockQuote",
                          "undo",
                          "redo",
                        ],
                      },
                    }}
                    editor={ClassicEditor}
                    data={values?.description_en}
                    onChange={(_, editor) => {
                      const data = editor.getData();

                      setFieldValue("description_en", data);
                    }}
                  />
                </Stack>
                {/* Date Picker */}
                <DatePicker
                  disablePast
                  label={"تاريخ المقال"}
                  onChange={(e: Date | null) => {
                    if (e) {
                      setFieldValue("date", format(e, "yyyy-MM-dd"));
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EAEAEA",
                      },
                      borderColor: "#EAEAEA",
                    },
                    "& .MuiInputLabel-root": {
                      color: "primary.main",
                      "&.Mui-focused": {
                        color: "primary.main",
                      },
                    },
                  }}
                />
              </Box>
              {/* Image and icom */}
              <Stack gap={"1rem"}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <label htmlFor="image">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        height: "45px",
                        width: "200px",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      component="span"
                      disabled={isLoading}
                      startIcon={<BiPlus size={24} />}
                    >
                      إضافة صورة المقال
                    </Button>
                  </label>
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    name="image"
                    disabled={isLoading}
                    onChange={(e) => {
                      handleImageChange(e);
                      setFieldValue("image", e.target.files?.[0]);
                    }}
                  />
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Selected"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Form>
        </CommonModal>
      )}
    </Formik>
  );
};
