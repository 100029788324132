import { Box, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import AddressDetails from "../../components/AddressDetails/AddressDetails";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";
import Map from "../../components/Map/Map";
import SecondaryTitle from "../../components/StyledComponents/SecondaryTitle";
import AddressModal from "../../components/Modals/AddressModal";
import { useParams } from "react-router-dom";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
// add icon
import { AiOutlinePlus } from "react-icons/ai";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import MainLoader from "../../components/Loaders/MainLoader";
import { useDispatch, useSelector } from "react-redux";
import { addressActions } from "../../store/address-slice";

type Props = {};

const ClientAddress = (props: Props) => {
  const [selected, setSelected] = useState(0);
  const [mapAddress, setMapAddress] = useState("");
  const { id } = useParams<{ id: string }>();
  const apiUrl = useMemo(() => `address/all?user_id=${id}`, []);
  const mainState = useSelector((state: any) => state.main);
  const dependencies = [mainState.refreshApi];
  const { data, loading, error } = useGetSingleApi(apiUrl, dependencies);

  const dispatch = useDispatch();
  return (
    <div>
      <Header title="العملاء" />
      {loading ? (
        <MainLoader />
      ) : (
        <MainContainer>
          <Stack direction="row" justifyContent="space-between">
            <SecondaryTitle>تفاصيل عناوين العميل</SecondaryTitle>
            <OutlinedButton
              sx={{
                width: "200px",
              }}
              onClick={() => {
                dispatch(
                  addressActions.openModal({
                    type: "add",
                    apiUrl: `address/create`,
                    initialValues: {
                      lat: "",
                      lng: "",
                      address: "",
                      user_id: id,
                      city_id: "",
                    },
                  })
                );
              }}
              endIcon={<AiOutlinePlus />}
            >
              إضافة عنوان جديد
            </OutlinedButton>
          </Stack>
          <Box
            sx={{
              margin: "1rem 0",
            }}
          >
            <Map url={mapAddress} />
          </Box>
          <Stack spacing={1}>
            {data?.data?.data?.map((address: any) => {
              return (
                <AddressDetails
                  setSelected={setSelected}
                  setMapAddress={setMapAddress}
                  selected={selected}
                  key={address.id}
                  {...address}
                />
              );
            })}
          </Stack>
        </MainContainer>
      )}
      <AddressModal />
    </div>
  );
};

export default ClientAddress;
