import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";

type Props = {
  id: string;
};

export const useClientFastLogin = ({ id }: Props) => {
  const [loading, setLoading] = useState(false);
  const loginHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", id);
    try {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}user/generate_admin_token`,
          formData
        )
        .then((res) => {
          if (res.status === 200 && res.data.status === 1) {
            window.open(
              `${process.env.REACT_APP_CLIENT_URL}admin-login?token=${res.data.data.admin_login_token}&name=${res.data.data.name}`,
              "_blank"
            );
          } else if (res.status === 200 && res.data.status === 0) {
            toast.error(res.data.message, {
              toastId: "LoginForm",
            });
          } else {
          }
        });
    } catch (error) {
      toast.error(`Something Went wrong`, {
        toastId: "LoginForm",
      });
    } finally {
      setLoading(false);
    }
  };
  return { loginHandler, loading };
};
