import React, { useEffect, useMemo } from "react";
import { StyledSecondaryContainer } from "../StyledComponents/AddClientFormComponents";
import { Box, Button, CircularProgress, Grid, IconButton } from "@mui/material";
import CustomTextField from "../CustomTextField/CustomTextField";
import { Formik, Form } from "formik";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import MainLoader from "../Loaders/MainLoader";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import * as yup from "yup";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Cookies from "universal-cookie";

type Props = {};

const ProfileForm = (props: Props) => {
  const cookies = new Cookies();
  const apiUrl = useMemo(() => "auth/my_info", []);
  const { data, loading, error } = useGetSingleApi(apiUrl, []);
  const handleEditProfile = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);
      let newValues = values;
      if (typeof values?.image === "string") {
        delete newValues.image;
      }
      await postAxios("auth/edit_profile", newValues).then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          toast.success(`تم تعديل الملف الشخصي بنجاح`, {
            toastId: "EditProfileForm",
          });
          cookies.set("admin-image", res?.data?.data?.image, { path: "/" });
        } else {
          toast.error(`Something Went wrong`, {
            toastId: "EditProfileForm",
          });
        }
      });
    } catch (error) {
      toast.error(`Something Went wrong`, {
        toastId: "EditProfileForm",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };
  const validationSchema = yup.object({
    name: yup.string().required(" الاسم مطلوب").min(3),
    phone: yup.string().required(" رقم الهاتف مطلوب").min(9),
    email: yup
      .string()
      .required(" البريد الالكتروني مطلوب")
      .email("من فضلك ادخل ايميل صحيح"),
  });
  const [profileData, setProfileData] = React.useState<any>({
    name: "",
    companyName: "",
    phone: "",
    email: "",
    image: "",
  });
  useEffect(() => {
    if (data) {
      setProfileData({
        name: data?.data?.name || "",
        // companyName: data?.company_name,
        phone: data?.data?.phone || "",
        email: data?.data?.email || "",
        image: data?.data?.image,
      });
    }
  }, [data]);
  if (loading) return <MainLoader />;
  return (
    <Formik
      enableReinitialize
      initialValues={profileData}
      onSubmit={handleEditProfile}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, errors, touched, setFieldValue, values }) => (
        <Form>
          <StyledSecondaryContainer>
            <Grid container rowSpacing={5} columnSpacing={2.5}>
              <Grid item xs={12} md={6}>
                <CustomTextField name="name" label="الاسم" required />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomTextField name="phone" label="رقم الهاتف" required />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  name="email"
                  label="البريد الالكتروني"
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  endIcon={<AiOutlineCloudUpload />}
                  component="label"
                  sx={{
                    height: "60px",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => {
                      setFieldValue("image", e.target.files![0]);
                    }}
                  />
                  تحميل صورة
                </Button>
                {/* image preview */}
                <Box
                  component={"img"}
                  alt="profile-image"
                  src={
                    typeof values?.image === "object"
                      ? URL.createObjectURL(values?.image)
                      : profileData?.image
                  }
                  sx={{
                    width: "auto",
                    height: "60px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    borderRadius: "8px",
                    width: "250px",
                    height: "60px",
                  }}
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  تعديل البيانات
                </Button>
              </Grid>
            </Grid>
          </StyledSecondaryContainer>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
