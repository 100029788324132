import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { REACT_QUERY_ABOUT } from "../../constants/reactQuery.constants";
import { updateAbout } from "../../apis/about.api";

export const useUpdateAbout = (fn?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAbout,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [REACT_QUERY_ABOUT] });
      if (data?.status === 1) {
        toast.success("تم التحديث  بنجاح");
      }
      fn && fn();
    },
    onError: () => {
      toast.error("حدث خطأ ما");
    },
  });
};
