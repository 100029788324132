import { Stack, Typography } from "@mui/material";
import queryString from "query-string";
import { useMemo, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import MainContainer from "../../components/Containers/MainContainer";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import Header from "../../components/Header/Header";
import ImageOrVideoDialog from "../../components/ImageOrVideoDialog/ImageOrVideoDialog";
import TableLoader from "../../components/Loaders/TableLoader";
import SliderTable from "../../components/Tables/SliderTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { sliderActions } from "../../store/slider-slice";
import { Formik, Form } from "formik";
import { RiDeleteBin6Line } from "react-icons/ri";
import { postAxios } from "../../Services/axios-services";
import { toast } from "react-toastify";
import { mainSliceActions } from "../../store/main-slice";
import ConfirmActionDialog from "../../components/ConfirmActionDialog/ConfirmActionDialog";

type Props = {};

const Slider = (props: Props) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const mainState = useSelector((state: any) => state.main);
  const dispatch = useDispatch();
  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  const sliderApiUrl = useMemo(() => `slider/get?page=${query.page || "1"}`, []);
  const { data, loading, error } = useGetSingleApi(sliderApiUrl, dependencies);

  const handleOpenDialog = () => {
    dispatch(sliderActions.openModal());
  };
  const handleCloseDialog = () => {
    dispatch(sliderActions.closeModal());
  };

  //delete multiple logic
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);

  const handleDelete = async (values: any) => {
    try {
      let deleteApiUrl = `slider/delete?slider_id=`;
      let deleteIds = "";
      values.checked.map((value: any, index: number) => {
        if (index === 0) {
          deleteIds = `${value}`;
        } else {
          deleteIds = `${deleteIds},${value}`;
        }
      });
      deleteApiUrl = deleteApiUrl + deleteIds;

      setDeleting(true);

      await postAxios(deleteApiUrl).then((data) => {
        if (data.data.status !== 1) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
          dispatch(mainSliceActions.toggleRefreshApi());
        }
      });
    } catch (err) {
    } finally {
      setDeleting(false);
      setDeleteMultiple(false);
      setDeleteAction(null);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{ checked: [] }}
        onSubmit={(values) => {
          setDeleteAction(() => () => handleDelete(values));
          setDeleteMultiple(true);
        }}
      >
        {({ values, handleSubmit }) => (
          <Form>
            <Header title="السلايدر" />
            <MainContainer>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="h6"
                  sx={{
                    color: "primary.main",
                    fontWeight: "700",
                    fontSize: "1.375rem",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: "8px",
                      left: "0",
                      width: "90px",
                      height: "2px",
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  صور و فيديوهات الموقع
                </Typography>
                <Stack direction="row" spacing={2}>
                  <OutlinedButton
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant="outlined"
                    endIcon={<RiDeleteBin6Line />}
                    disabled={values.checked.length === 0}
                  >
                    حذف الملفات
                  </OutlinedButton>
                  <OutlinedButton
                    onClick={() => {
                      handleOpenDialog();
                    }}
                    endIcon={<IoMdAdd />}
                    sx={{
                      width: "200px",
                    }}
                  >
                    اضافه ملف
                  </OutlinedButton>
                </Stack>
              </Stack>
              {loading ? (
                <TableLoader />
              ) : error ? (
                <></>
              ) : (
                data?.data?.data.length > 0 && <SliderTable data={data.data.data} />
              )}
              {data?.data?.pagination?.total_pages > 1 && (
                <CustomPagination totalPages={data.data.pagination.total_pages} />
              )}
            </MainContainer>
          </Form>
        )}
      </Formik>
      <ImageOrVideoDialog handleClose={handleCloseDialog} />
      <ConfirmActionDialog
        title={"هل أنت متأكد من حذف الملفات ؟"}
        action={deleteAction}
        open={deleteMultiple}
        handleClose={() => {
          setDeleteMultiple(false);
        }}
        loading={deleting}
      />
    </div>
  );
};

export default Slider;
