import axios from "axios";

export const getProductStatus = async (props: {
  pageParam: number | false;
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(`status/get?page=${props.pageParam || ""}`);
  return response?.data?.data?.data;
};

export const getSingleStatus = async (props: { queryKey: (string | null | undefined | number)[] }) => {
  const response = await axios.get(`status/single?status_id=${props.queryKey[1]}`);
  return response?.data?.data;
};

export const createProductStatus = async (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  const response = await axios.post("status/create", formData);
  return response?.data;
};
export const updateStatus = async (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  const response = await axios.post("status/update", formData);
  return response?.data;
};
