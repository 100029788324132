import { Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import bg1 from "../../assets/images/forget-password-bg1.svg";
import bg2 from "../../assets/images/forget-password-bg2.svg";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import LoginFormContainer from "../../components/LoginFormContainer/LoginFormContainer";
import styles from "./ForgetPassword.module.scss";
import Cookies from "universal-cookie";
import { otpValidationSchema, newPasswordValidationSchema } from "../../validation/Validation";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";

type Props = {};

const ForgetPassword = (props: Props) => {
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();
  const cookies = new Cookies();
  //step one
  const resetPasswordStepEmail = async (values: any, actions: any) => {
    try {
      await postAxios("auth/forget_password", values).then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          setEmail(values.email);
          setStep(2);
        } else {
        }
      });
    } catch (error) {
    } finally {
      actions.setSubmitting(false);
    }
  };
  const emailInitialValues = {
    email: "",
  };

  //second step
  const resetPasswordStepOTP = async (values: any, actions: any) => {
    try {
      await postAxios("auth/check_password_code", values).then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          //save token
          cookies.set("admin-token", res.data.data.token, {
            //expires after 10 minutes
            expires: new Date(Date.now() + 10 * 60 * 1000),
          });
          setStep(3);
        } else {
          toast.error(res.data.message, {
            toastId: "error",
          });
        }
      });
    } catch (error) {
    } finally {
      actions.setSubmitting(false);
    }
  };
  const otpInitialValues = {
    code: "",
    email: email,
  };

  //last step
  const resetPasswordStepConfirmPasswordInitialValues = {
    password: "",
  };
  const resetPasswordStepNewPassword = async (values: any, actions: any) => {
    try {
      await postAxios("auth/reset_password", values).then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          navigate("/login");
        } else {
          toast.error(res.data.message, {
            toastId: "error",
          });
        }
      });
    } catch (error) {
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={styles.forgetPassword}>
      <div className={styles.mainStep}>
        {step === 1 && (
          <>
            <div className={styles.image}>
              <img src={bg1} alt="bg" />
            </div>
            <div className={styles.form}>
              <h1>
                ادخل الايميل و سوف نرسل لك <span>لك</span> رمز التحقق
              </h1>
              <Formik initialValues={emailInitialValues} onSubmit={resetPasswordStepEmail}>
                {({ isSubmitting }) => (
                  <Form>
                    <LoginFormContainer>
                      <h3>اعادة تعيين كلمة المرور </h3>
                      <div className={styles.formContainer}>
                        <CustomTextField label="البريد الالكتروني" name="email" required />
                      </div>
                      <div className={styles.actions}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            width: "100%",
                            color: "white",
                            borderRadius: "10px",
                            height: "60px",
                            fontSize: "18px",
                            fontWeight: "800",
                          }}
                          type="submit"
                          disabled={isSubmitting}
                          endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                        >
                          ارسال الرمز{" "}
                        </Button>
                      </div>
                    </LoginFormContainer>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className={styles.image}>
              <img src={bg2} alt="bg" />
            </div>
            <div className={styles.form}>
              <h1>
                ادخل الايميل و سوف نرسل لك <span>لك</span> رمز التحقق
              </h1>
              <Formik
                initialValues={otpInitialValues}
                onSubmit={resetPasswordStepOTP}
                validationSchema={otpValidationSchema}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <LoginFormContainer>
                      <h3>اعادة تعيين كلمة المرور </h3>
                      <div className={styles.formContainer}>
                        <CustomTextField label="OTP" name="code" required />
                      </div>
                      <div className={styles.actions}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            width: "100%",
                            color: "white",
                            borderRadius: "10px",
                            height: "60px",
                            fontSize: "18px",
                            fontWeight: "800",
                          }}
                          type="submit"
                          disabled={isSubmitting}
                          endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                        >
                          حفظ التعديل
                        </Button>
                      </div>
                    </LoginFormContainer>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className={styles.image}>
              <img src={bg2} alt="bg" />
            </div>
            <div className={styles.form}>
              <h1>
                ادخل الايميل و سوف نرسل لك <span>لك</span> رمز التحقق
              </h1>
              <Formik
                initialValues={resetPasswordStepConfirmPasswordInitialValues}
                onSubmit={resetPasswordStepNewPassword}
                validationSchema={newPasswordValidationSchema}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <LoginFormContainer>
                      <h3>اعادة تعيين كلمة المرور </h3>
                      <div className={styles.formContainer}>
                        <CustomTextField name="password" label="كلمة المرور الجديدة" required type="password" />
                      </div>
                      <div className={styles.actions}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            width: "100%",
                            color: "white",
                            borderRadius: "10px",
                            height: "60px",
                            fontSize: "18px",
                            fontWeight: "800",
                          }}
                          type="submit"
                          disabled={isSubmitting}
                          endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                        >
                          حفظ التعديل
                        </Button>
                      </div>
                    </LoginFormContainer>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;
