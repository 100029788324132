import { Autocomplete, CircularProgress, Stack, TextField } from "@mui/material";

import { IUser } from "../../types";
import { getAdminsForCombobox } from "../../apis";
import { useDebounce } from "@uidotdev/usehooks";
import { useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

type ComboboxProps = {};
export const AdminComboBox = (props: ComboboxProps) => {
  const [searchText, setSearchText] = useState("");
  const value = useDebounce(searchText, 500);
  const { data, isPending } = useQuery({
    queryKey: ["adminsComboBox", value],
    queryFn: getAdminsForCombobox,
  });
  const { setFieldValue } = useFormikContext();
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      clearOnBlur
      clearOnEscape
      options={data || []}
      getOptionLabel={(option: IUser) => option.name}
      filterOptions={(x) => x}
      size="small"
      // disable auto complete filter

      sx={{ width: 300 }}
      onChange={(_e, newValue) => {
        setFieldValue("admin_id", newValue?.id);
      }}
      loading={isPending}
      loadingText={
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={24} />
        </Stack>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="الموظف المسؤول"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          sx={{
            borderRadius: "1rem",
          }}
        />
      )}
    />
  );
};
