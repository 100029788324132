import { toast } from "react-toastify";
import { updateStatus } from "../../../apis";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const useUpdateStatus = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: updateStatus,
    onSuccess: (data) => {
      if (data?.status === 1) {
        toast.success("تم تعديل الجالة بنجاح");
        navigate("/product-status");
      }
    },
    onError: (e: any) => {
      console.log(e);
      toast.error(e?.response?.data?.message || "حدث خطأ ما");
    },
  });
};
