import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import { IoMdAdd } from "react-icons/io";
import MainContainer from "../../../components/Containers/MainContainer";
import OutlinedButton from "../../../components/Buttons/OutlinedButton";
import { ProductStatusTable } from "./productStatusTable";
import TableLoader from "../../../components/Loaders/TableLoader";
import { useGetStatus } from "../hooks";
import { useNavigate } from "react-router-dom";

const ProductStatus = () => {
  const { data, isPending, ref, hasNextPage, isRefetching } = useGetStatus();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        mt: "1rem",
      }}
    >
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            حالات المنتج
          </Typography>
          <OutlinedButton
            endIcon={<IoMdAdd />}
            onClick={() => {
              navigate("/product-status/create");
            }}
          >
            إضافة حالة
          </OutlinedButton>
        </Stack>

        {isPending || isRefetching ? (
          <TableLoader />
        ) : (
          data && (
            <>
              <ProductStatusTable rows={data?.pages.map((page) => page).flat()} />

              {hasNextPage && (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={24} ref={ref} />
                </Box>
              )}
            </>
          )
        )}
      </MainContainer>
    </Box>
  );
};

export default ProductStatus;
