import { Form, Formik } from "formik";
import { CommonModalProps, WhyMarkoonItem } from "../../types";
import { CommonModal } from "./commonModal";
import { Box, Button, Stack } from "@mui/material";
import { BiPlus } from "react-icons/bi";
import CustomTextField from "../CustomTextField/CustomTextField";
import { ChangeEventHandler, useEffect, useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";
import { pageTypes } from "../../constants/pageTypes.constant";

interface WhyMarkoonProps extends CommonModalProps<WhyMarkoonItem> {
  item: WhyMarkoonItem | null;
  isEdit?: boolean;
}

export const WhyMarkoonModal: React.FC<WhyMarkoonProps> = ({
  open,
  handleClose,
  title,
  item,
  handleConfirm,
  isLoading,
  isEdit,
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(
    item?.icon ?? undefined
  );
  const handleImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    if (item?.icon) {
      setImageUrl(item.icon);
    }
  }, [item]);
  return (
    <Formik
      initialValues={{
        id: item?.id || null,
        title_ar: item?.title_ar ?? "",
        title_en: item?.title_en ?? "",
        description_ar: item?.description_ar ?? "",
        description_en: item?.description_en ?? "",
        icon: item?.icon ?? "",
        type: item?.type ?? 1,
        page: item?.page ?? 1,
      }}
      onSubmit={async (v) => {
        await handleConfirm(v);
      }}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        handleSubmit,
        resetForm,
        touched,
        errors,
        values,
      }) => (
        <CommonModal
          open={open}
          handleClose={() => {
            setImageUrl(undefined);
            resetForm();
            handleClose();
          }}
          title={title}
          handleConfirm={() => {
            //@ts-ignore
            return handleSubmit().then(() => {
              resetForm();
              setImageUrl(undefined);
            });
          }}
          isLoading={isLoading}
          width={"80%"}
          confirmButtonText={isEdit ? "تعديل" : undefined}
        >
          <Form>
            <Stack spacing={4}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <CustomTextField
                  name="title_ar"
                  label="عنوان العنصر بالعربي"
                  multiLine={1}
                />
                <CustomTextField
                  name="title_en"
                  label="عنوان العنصر بالانجليزي"
                  multiLine={1}
                />
                <CustomTextField
                  name="description_ar"
                  label="وصف العنصر بالعربي"
                  multiLine={3}
                />
                <CustomTextField
                  name="description_en"
                  label="وصف العنصر بالانجليزي"
                  multiLine={3}
                />
                <CustomSelect
                  touched={touched}
                  errors={errors}
                  title="نوع المستخدم"
                  placeholder="اختر نوع المستخدم"
                  name="type"
                  setFieldValue={setFieldValue}
                  optionsProp={[
                    { value: 1, label: "بائع" },
                    { value: 2, label: "مشتري" },
                  ]}
                  values={values}
                  value={values.type}
                />
                <CustomSelect
                  touched={touched}
                  errors={errors}
                  title="الصفحة"
                  placeholder="اختر نوع الصفحة"
                  name="page"
                  setFieldValue={setFieldValue}
                  optionsProp={[
                    { value: pageTypes.HOME, label: "الرئيسية" },
                    { value: pageTypes.ABOUT, label: "عن مركون" },
                  ]}
                  values={values}
                  value={values.page}
                />
              </Box>
              {/* Image and icom */}
              <Stack gap={"1rem"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <label htmlFor="icon">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        height: "45px",
                        width: "200px",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      component="span"
                      disabled={isLoading}
                      startIcon={<BiPlus size={24} />}
                    >
                      إضافة أيقونة العنصر
                    </Button>
                  </label>
                  <input
                    id="icon"
                    type="file"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    name="icon"
                    disabled={isLoading}
                    onChange={(e) => {
                      handleImageChange(e);
                      setFieldValue("icon", e.target.files?.[0]);
                    }}
                  />
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Selected"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Form>
        </CommonModal>
      )}
    </Formik>
  );
};
