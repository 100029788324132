import { InfoDto } from "../../types";
import { Formik, Form } from "formik";
import { Box, Button, CircularProgress } from "@mui/material";
import CustomTextField from "../CustomTextField/CustomTextField";
import { useUpdateInfo } from "../../hooks/information/useUpdateinfo";
import { BsFillPlayFill } from "react-icons/bs";
import OutlinedButton from "../Buttons/OutlinedButton";
import { useRef } from "react";

export const InformationForm: React.FC<{
  initialValues?: InfoDto;
}> = ({ initialValues }) => {
  const { mutate, isPending } = useUpdateInfo();
  const videoInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    videoInputRef?.current?.click();
  };

  //   const [imageUrl, setImageUrl] = useState<string | undefined>(initialValues?.always_image ?? undefined);
  //   const handleImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
  //     const file = event?.target?.files?.[0];
  //     if (file) {
  //       setImageUrl(URL.createObjectURL(file));
  //     }
  //   };
  //   useEffect(() => {
  //     if (initialValues?.always_image) {
  //       setImageUrl(initialValues.always_image);
  //     }
  //   }, [initialValues]);
  return (
    <Formik
      //   enableReinitialize={true}
      initialValues={{
        name_ar: initialValues?.name_ar ?? "",
        name_en: initialValues?.name_en ?? "",
        whyMrkoon_title_ar: initialValues?.whyMrkoon_title_ar ?? "",
        whyMrkoon_title_en: initialValues?.whyMrkoon_title_en ?? "",
        ourNumbers_title_ar: initialValues?.ourNumbers_title_ar ?? "",
        ourNumbers_title_en: initialValues?.ourNumbers_title_en ?? "",
        ourNumbers_desc_ar: initialValues?.ourNumbers_desc_ar ?? "",
        ourNumbers_desc_en: initialValues?.ourNumbers_desc_en ?? "",
        testimonial_title_ar: initialValues?.testimonial_title_ar ?? "",
        testimonial_title_en: initialValues?.testimonial_title_en ?? "",
        blog_title_ar: initialValues?.blog_title_ar ?? "",
        blog_title_en: initialValues?.blog_title_en ?? "",
        join_title_ar: initialValues?.join_title_ar ?? "",
        join_title_en: initialValues?.join_title_en ?? "",
        join_desc_ar: initialValues?.join_desc_ar ?? "",
        join_desc_en: initialValues?.join_desc_en ?? "",
        footer_ar: initialValues?.footer_ar ?? "",
        footer_en: initialValues?.footer_en ?? "",
        file: initialValues?.file ?? "",
      }}
      onSubmit={async (v) => {
        await mutate(v);
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <CustomTextField name="name_ar" label="الاسم بالعربي" />
            <CustomTextField name="name_en" label="الاسم بالانجليزي" />
            <CustomTextField name="whyMrkoon_title_ar" label="عنوان السبب" />
            <CustomTextField name="whyMrkoon_title_en" label="عنوان السبب بالانجليزي" />
            <CustomTextField name="ourNumbers_title_ar" label="عنوان الارقام" />
            <CustomTextField name="ourNumbers_title_en" label="عنوان الارقام بالانجليزي" />
            <CustomTextField name="ourNumbers_desc_ar" label="وصف الارقام" multiLine={2} />
            <CustomTextField name="ourNumbers_desc_en" label="وصف الارقام بالانجليزي" multiLine={2} />
            <CustomTextField name="testimonial_title_ar" label="عنوان الشهادات" />
            <CustomTextField name="testimonial_title_en" label="عنوان الشهادات بالانجليزي" />
            <CustomTextField name="blog_title_ar" label="عنوان المدونة" />
            <CustomTextField name="blog_title_en" label="عنوان المدونة بالانجليزي" />
            <CustomTextField name="join_title_ar" label="عنوان الانضمام" />
            <CustomTextField name="join_title_en" label="عنوان الانضمام بالانجليزي" />
            <CustomTextField name="join_desc_ar" label="وصف الانضمام" multiLine={2} />
            <CustomTextField name="join_desc_en" label="وصف الانضمام بالانجليزي" multiLine={2} />
            <CustomTextField name="footer_ar" label="الفوتر بالعربي" multiLine={3} />
            <CustomTextField name="footer_en" label="الفوتر بالانجليزي" multiLine={3} />

            <OutlinedButton
              onClick={() => {
                handleButtonClick();
                setFieldValue("file", "");
              }}
              endIcon={<BsFillPlayFill />}
            >
              فيديو{" "}
              <input
                ref={videoInputRef}
                type="file"
                accept="video/*"
                onChange={(e) => {
                  if (e.target.files) {
                    setFieldValue("file", e.target.files[0]);
                  }
                }}
                style={{ display: "none" }}
              />
            </OutlinedButton>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "1rem",
                fontWeight: 700,
                height: "45px",
                width: "200px",
                color: "#FFFFFF",
                borderRadius: "6px",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              disabled={isPending}
              type="submit"
              endIcon={isPending ? <CircularProgress size={20} color="primary" /> : null}
            >
              تعديل
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
