import { Box, Button, Checkbox, CircularProgress, Divider, IconButton, Stack, Typography, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import React, { useMemo } from "react";
import { BsImages } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { postAxios } from "../../Services/axios-services";
import AddFileIcon from "../../assets/images/icons/addFileIcon.svg";
import MainContainer from "../../components/Containers/MainContainer";
import CustomInputWithBtns from "../../components/CustomInputWithBtns/CustomInputWithBtns";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import Dropzone from "../../components/Dropzone/Dropzone";
import Header from "../../components/Header/Header";
import AddAdditionalClientInfo from "../../components/Modals/AddAdditionalClientInfoModal";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};
const StyledSubContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  padding: "23px 27px",
  gap: "30px",
});
const StyledTitle = styled("h4")({
  fontSize: "1.25rem",
  fontWeight: "700",
});

const AddConstructionMaterials = (props: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const unitsApiUrl = useMemo(() => "unit/get", []);
  const catApiUrl = useMemo(() => "categories/get?type=3", []);
  const addressApiUrl = useMemo(() => `address/all?user_id=${userId}`, [userId]);
  const { data: addressData, loading: addressLoading, error: addressError } = useGetSingleApi(addressApiUrl, [userId]);
  const { data: unitsData, loading: unitsLoading, error } = useGetSingleApi(unitsApiUrl, []);
  const { data: catData, loading: catLoading, error: catError } = useGetSingleApi(catApiUrl, []);
  const usersApiUrl = useMemo(() => "user/get?status=1", []);
  const { data: usersData, loading: usersLoading, error: usersError } = useGetSingleApi(usersApiUrl, []);

  const handleSubmit = async (values: any, actions: any) => {
    let newValues = values;
    //check if pdf is file or string
    if (newValues.price_type === "1") {
      //remove mazad_type and end_date if price_type is 1
      delete newValues.mazad_type;
      delete newValues.end_date;
    }
    if (newValues.price_type === "2") {
      //remove real_price if price_type is 2
      delete newValues.real_price;
      delete newValues.price;
    }
    //check if adds has an empty key or value
    newValues.adds = newValues.adds.filter((add: any) => add.key !== "" && add.value !== "");

    if (newValues.sub_cat_id) {
      newValues.cat_id = values.sub_cat_id;
      delete newValues.sub_cat_id;
    }

    try {
      actions.setSubmitting(true);
      await postAxios("products/create", newValues).then((res) => {
        if (res.data.status === 1) {
          navigate("/construction-materials/all");
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };
  const [initialValues, setInitialValues] = React.useState({
    name: "",
    description: "",
    logo: "" as any,
    pdf: "" as any,
    quantity: "",
    unit_id: "",
    type: 3,
    price_type: "",
    price: "",
    real_price: "",
    mazad_type: "",
    end_date: "",
    cat_id: "",
    sub_cat_id: "",
    masafa_price: "",
    status_id: "",
    user_id: "",
    less_quantity: "",
    show_company: 1,
    images: [],

    adds: [
      {
        key: "",
        value: "",
      },
    ],
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("اسم المنتج مطلوب"),
    unit_id: Yup.string().required("وحده القياس مطلوبه"),
    cat_id: Yup.string().required("التصنيف مطلوب"),
    status_id: Yup.string().required("حاله المنتج مطلوبه"),
    user_id: Yup.string().required("التاجر مطلوب"),
    quantity: Yup.number().required("الكميه مطلوبه"),
    less_quantity: Yup.number().required("ادخل اقل كميه للطلب"),
    price_type: Yup.string().required("تسعير المنتج مطلوب"),
    price: Yup.string().test("required-if-price-type-2", "سعر المنتج بعد الخصم مطلوب", function (value) {
      const priceType = this.resolve(Yup.ref("price_type"));

      if (priceType === "1") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
    mazad_type: Yup.string().test("required-if-price-type-2", "نوع المزاد مطلوب", function (value) {
      const priceType = this.resolve(Yup.ref("price_type"));
      if (priceType === "2") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
    end_date: Yup.string().test("required-if-mazad-type-1", "التاريخ  مطلوب", function (value) {
      const mazadType = this.resolve(Yup.ref("mazad_type"));
      if (mazadType === "1") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
  });

  return (
    <div>
      <Header title="مواد بناء" />
      <MainContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {({ values, setFieldValue, errors, touched, isSubmitting }) => (
            <Form>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  columnGap: "1.25rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.25rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "primary.main",
                      fontWeight: "700",
                      fontSize: "1.375rem",
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: "-4px",
                        left: "0",
                        width: "90px",
                        height: "2px",
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    {"إضافة مواد بناء جديدة"}
                  </Typography>
                  <StyledSubContainer>
                    <StyledTitle>صور المنتج</StyledTitle>
                    <Divider
                      sx={{
                        width: "calc(100% + 54px)",
                        marginLeft: "-27px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          marginBottom: "1.5rem",
                        }}
                      >
                        إضافة صورة
                      </Typography>
                      <Dropzone setFieldValue={setFieldValue} />
                    </Box>
                    <Divider
                      sx={{
                        width: "calc(100% + 54px)",
                        marginLeft: "-27px",
                      }}
                    />
                    <Stack spacing={3}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          width: "400px",
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "400",
                            }}
                          >
                            إضافة ملف pdf للمنتج (المواصفات)
                          </Typography>
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            sx={{
                              width: "100px",
                              height: "80px",
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                              borderRadius: "6px",
                            }}
                          >
                            <input
                              hidden
                              accept="application/pdf"
                              type="file"
                              onChange={(e) => {
                                const files = e.target ? e.target.files : null;
                                if (files) {
                                  setFieldValue("pdf", files[0]);
                                }
                              }}
                            />
                            <img src={AddFileIcon} alt="add file icon" />
                          </IconButton>
                        </Stack>

                        {values.pdf instanceof File && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "20px",
                                cursor: "pointer",
                                fontWeight: "700",
                              }}
                              onClick={() => {
                                const url = URL.createObjectURL(values.pdf);
                                window.open(url, "_blank");
                              }}
                            >
                              {values.pdf.name.split(".")[0] || "الملف"}
                            </Typography>
                            <Box
                              onClick={() => {
                                setFieldValue("pdf", "");
                              }}
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                "& svg": {
                                  fontSize: "1.5rem",
                                  fill: "red",
                                },
                              }}
                            >
                              <TiDeleteOutline />
                            </Box>
                          </Box>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        sx={{
                          width: "400px",
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>
                            إضافة صورة المنتج الرئيسية
                          </Typography>
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            sx={{
                              width: "100px",
                              height: "80px",
                              border: "1px solid rgba(0, 0, 0, 0.08)",
                              borderRadius: "6px",
                              fontSize: "3rem",
                            }}
                          >
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) => {
                                const files = e.target ? e.target.files : null;
                                if (files) {
                                  setFieldValue("logo", files[0]);
                                }
                              }}
                            />
                            <BsImages />{" "}
                          </IconButton>
                        </Stack>

                        {values.logo instanceof File && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <Box
                              component={"img"}
                              sx={{
                                width: "100px",
                                height: "80px",
                                cursor: "pointer",
                              }}
                              src={URL.createObjectURL(values?.logo)}
                              onClick={() => {
                                const url = URL.createObjectURL(values?.logo);
                                window.open(url, "_blank");
                              }}
                            />

                            <Box
                              onClick={() => {
                                setFieldValue("logo", "");
                              }}
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                "& svg": {
                                  fontSize: "1.5rem",
                                  fill: "red",
                                },
                              }}
                            >
                              <TiDeleteOutline />
                            </Box>
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                  </StyledSubContainer>
                </Box>

                <StyledSubContainer>
                  <StyledTitle>بيانات المنتج :</StyledTitle>
                  <Divider
                    sx={{
                      width: "calc(100% + 54px)",
                      marginLeft: "-27px",
                    }}
                  />
                  <Stack
                    justifyContent="space-between"
                    spacing={3}
                    sx={{
                      margin: "1rem 0",
                      height: "100%",
                    }}
                  >
                    <CustomTextField label="اسم المنتج" name="name" />
                    <Stack direction="row" spacing={2}>
                      <CustomTextField label="نسبة مسافه" name="masafa_price" />
                      <CustomSelect
                        placeholder="اختر من القائمه"
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        name="status_id"
                        title="حاله المنتج"
                        optionsProp={[
                          { value: 1, label: "جديد" },
                          { value: 2, label: "مفعل" },
                          { value: 3, label: "غير مفعل" },
                        ]}
                        values={values}
                      />
                    </Stack>
                    <CustomSelect
                      values={values}
                      placeholder="اختر من القائمه"
                      title="تسعير المنتج"
                      optionsProp={[
                        { value: 1, label: "سعر ثابت" },
                        { value: 2, label: "اعلي سعر" },
                      ]}
                      name="price_type"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      required
                    />
                    {String(values.price_type) === "1" && (
                      <Stack direction="row" spacing={2}>
                        <CustomTextField label="سعر المنتج الرئيسي" name="real_price" />
                        <CustomTextField label="سعر المنتج بعد الخصم" name="price" />
                      </Stack>
                    )}
                    {String(values.price_type) === "2" && (
                      <Stack direction="row" spacing={2}>
                        <Box
                          sx={{
                            flexGrow: 1,
                            width: "100%",
                          }}
                        >
                          <CustomSelect
                            placeholder="اختر من القائمه"
                            title="نوع المزاد"
                            optionsProp={[
                              { value: 1, label: "له تاريخ نهايه" },
                              { value: 2, label: "بدون تاريخ" },
                            ]}
                            name="mazad_type"
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            width: "100%",
                          }}
                        >
                          {String(values.mazad_type) === "1" && (
                            <DatePicker
                              disablePast
                              label={errors.end_date && touched.end_date ? " التاريخ مطلوب" : "تاريخ النهايه"}
                              onChange={(e: Date | null) => {
                                if (e) {
                                  setFieldValue("end_date", format(e, "yyyy-MM-dd"));
                                }
                              }}
                              sx={{
                                "& .MuiInputBase-root": {
                                  borderRadius: "10px",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: errors.end_date && touched.end_date ? "red" : "#EAEAEA",
                                  },
                                  borderColor: errors.end_date && touched.end_date ? "red" : "#EAEAEA",
                                },
                                "& .MuiInputLabel-root": {
                                  color: errors.end_date && touched.end_date ? "red" : "primary.main",
                                  "&.Mui-focused": {
                                    color: errors.end_date && touched.end_date ? "red" : "primary.main",
                                  },
                                },
                              }}
                            />
                          )}
                        </Box>
                      </Stack>
                    )}
                    <Stack direction="row" alignItems="center" spacing={5}>
                      <CustomInputWithBtns title="الكمية المتاحة" name="quantity" setFieldValue={setFieldValue} />
                      {unitsData && (
                        <CustomSelect
                          placeholder="اختر من القائمه"
                          title="وحده القياس"
                          name="unit_id"
                          optionsProp={unitsData.data.data.map((unit: any) => {
                            return {
                              value: unit.id,

                              label: unit.name_ar,
                            };
                          })}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      )}
                    </Stack>{" "}
                    <CustomTextField label="اقل كميه للطلب" name="less_quantity" type="number" />
                    <CustomTextField label="وصف المنتج" multiLine={4} name="description" />
                    {catData && (
                      <Stack direction="row" spacing={2}>
                        <CustomSelect
                          placeholder="اختر من القائمه"
                          title="التصنيف"
                          name="cat_id"
                          optionsProp={catData.data.data.map((item: any) => {
                            if (item.cats.length > 0) {
                              return {
                                value: item.id,
                                label: item.name_ar,
                                sub: item.cats.map((cat: any) => {
                                  return {
                                    value: cat.id,
                                    label: cat.name_ar,
                                  };
                                }),
                              };
                            } else {
                              return {
                                value: item.id,
                                label: item.name_ar,
                              };
                            }
                          })}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                        {values.cat_id &&
                          catData.data.data.find((item: any) => item.id === values.cat_id)?.cats.length > 0 && (
                            <CustomSelect
                              placeholder="اختر من القائمه"
                              title="التصنيف الفرعي"
                              name="sub_cat_id"
                              optionsProp={catData.data.data
                                .find((item: any) => item.id === values.cat_id)
                                ?.cats.map((cat: any) => {
                                  return {
                                    value: cat.id,
                                    label: cat.name_ar,
                                  };
                                })}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          )}
                      </Stack>
                    )}
                    {usersData && (
                      <Stack direction="row" spacing={2}>
                        <CustomSelect
                          placeholder="اختر من القائمه"
                          title="التاجر"
                          name="user_id"
                          optionsProp={usersData.data.data.map((user: any) => {
                            return {
                              value: user.id,
                              label: `${user.name} - ${user.company_name}`,
                            };
                          })}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setState={setUserId}
                          values={values}
                        />
                        {addressData && (
                          <CustomSelect
                            placeholder="اختر من القائمه"
                            title="العنوان"
                            name="address_id"
                            optionsProp={addressData.data.data.map((address: any) => {
                              return {
                                value: address.id,
                                label: address.address,
                              };
                            })}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        )}
                      </Stack>
                    )}
                    {/* add checkbox for show company field */}
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Checkbox
                        checked={values.show_company === 1}
                        onChange={(e) => {
                          setFieldValue("show_company", e.target.checked ? 1 : 0);
                        }}
                        color="primary"
                      />
                      <Typography>إظهار معلومات الشركه</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{
                        gap: "24px",
                        padding: "0 50px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={isSubmitting && <CircularProgress size={20} />}
                      >
                        إضافة المنتج
                      </Button>
                      <Button variant="outlined" color="primary" fullWidth onClick={handleOpen}>
                        إضافة البيانات
                      </Button>
                    </Stack>
                  </Stack>
                </StyledSubContainer>
              </Box>
              <AddAdditionalClientInfo type="product" open={open} handleClose={handleClose} values={values} />
            </Form>
          )}
        </Formik>
      </MainContainer>
    </div>
  );
};

export default AddConstructionMaterials;
