export const PAY_REQUEST_STATUS = {
  NEW: 1,
  PAID: 2,
  UNPAID: 3,
  REFUND: 4,
};
// status (1=>new request ,2=>paid ,3=>un paid)

export const PAY_REQUEST_STATUS_OPTIONS = {
  PAID: "2",
  UNPAID: "3",
  REFUND: "4",
  EXCLUDED: "5",
};

export const INSURANCE_STATUS_OPTIONS = {
  NEW: "1",
  PAID: "2",
  UNPAID: "3",
  REFUND: "4",
  EXCLUDED: "5",
};
