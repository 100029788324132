import { Badge, IconButton } from "@mui/material";
import * as React from "react";
import { IoNotificationsSharp } from "react-icons/io5";

function NotificationMenu() {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const apiUrl = useMemo(() => "auth/my_notifications", []);
  // const mainState = useSelector((state: any) => state.main);

  // const { data, loading, error } = useGetSingleApi(apiUrl, [
  //   mainState?.refreshNotification,
  // ]);

  return (
    <div>
      <IconButton aria-label="notification" onClick={() => {}}>
        <Badge
          badgeContent={0}
          color="error"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <IoNotificationsSharp />
        </Badge>
      </IconButton>
      {/* {data?.data?.notifications?.data && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: "290px",
              height: "400px",
              borderRadius: "15px",
              overflowY: "scroll",
              overflowX: "hidden",
              padding: "0 1rem",
              backgroundImage:
                "linear-gradient(to bottom left, white, #c3ffba59)",
              boxShadow: "0px 0px 10px 0px #0000001a",
              "&::-webkit-scrollbar": {
                width: "5px",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "#BDBDBD",
              },
            },
          }}
        >
          <NotificationContainer data={data?.data?.notifications?.data} />
        </Menu>
      )} */}
    </div>
  );
}

export default React.memo(NotificationMenu);
