import "./CustomSelect.scss";

import { Box, Typography } from "@mui/material";
import Select, { ControlProps, GroupProps, MultiValue, OptionProps, StylesConfig, components } from "react-select";

import { Field } from "formik";
import { memo } from "react";

interface OptionType {
  value: string | number;
  label: string;
  sub?: OptionType[];
}

interface Props {
  placeholder: string;
  width?: string;
  title: string;
  required?: boolean;
  optionsProp: OptionType[] | [];
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  value?: any;
  errors?: any;
  touched?: any;
  setState?: any;
  values?: any;
  loading?: boolean;
  disabled?: boolean;
}

const SendMessageMultiSelect = ({
  title,
  placeholder,
  optionsProp,
  width,
  required,
  name,
  setFieldValue,
  value,
  setState,
  loading,
  disabled = false,
}: Props) => {
  const Control = ({ children, ...props }: ControlProps<OptionType, true>) => {
    return (
      <components.Control {...props}>
        <Box
          sx={{
            position: "absolute",
            top: "-11px",
            left: "9px",
            background: "white",
            padding: "0 5px",
            "& span": {
              position: "absolute",
              right: "-3px",
              top: "-2px",
              color: "red",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "black",
              fontWeight: "700",
              fontSize: ".8rem",
            }}
          >
            {title}
          </Typography>
          {required && <span>*</span>}
        </Box>
        {children}
      </components.Control>
    );
  };

  const Option = (props: OptionProps<OptionType, true>) => {
    return <components.Option {...props} />;
  };

  const Group = (props: GroupProps<OptionType, true>) => {
    return <components.Group {...props} />;
  };

  const customStyles: StylesConfig<OptionType, true> = {
    control: (baseStyles) => ({
      ...baseStyles,
      width: width ? width : "auto",
      boxShadow: "none",
      cursor: "pointer",
      borderRadius: "8px",
      padding: "9px 14px",
      position: "relative",
      "&:hover": {
        border: "1px solid #34B44A",
      },
    }),
    option: (baseStyles) => ({
      ...baseStyles,
      paddingLeft: "16px",
    }),
    group: (baseStyles) => ({
      ...baseStyles,
      paddingBottom: "4px",
      marginBottom: "4px",
    }),
  };

  return (
    <Field name={name}>
      {({ field, meta, form }: any) => (
        <Select
          className={meta?.error && meta?.touched ? "react-select-container error" : "react-select-container"}
          classNamePrefix="react-select"
          isLoading={loading}
          isDisabled={disabled}
          placeholder={
            field.value
              ? optionsProp.find((item) => item.value === field.value)?.label
              : value
              ? optionsProp.find((item) => item.value === value)?.label
              : placeholder
          }
          isOptionSelected={(option) => {
            return field.value && field.value.includes(option.value);
          }}
          options={optionsProp}
          isMulti
          components={{ Control, Option, Group }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#DEF7E5",
              primary: "#1BBC39",
            },
          })}
          styles={customStyles}
          name={name}
          onChange={(selectedOptions: MultiValue<OptionType>) => {
            let selectedValues = selectedOptions?.join(",");
            setFieldValue(name, selectedValues || "");
          }}
        />
      )}
    </Field>
  );
};

export default memo(SendMessageMultiSelect);
