import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";

import AuctionsTable from "../../../components/Tables/AuctionsTable";
import CustomTextFieldSmall from "../../../components/CustomTextFieldSmall/CustomTextFieldSmall";
import Header from "../../../components/Header/Header";
import MainContainer from "../../../components/Containers/MainContainer";
import TableLoader from "../../../components/Loaders/TableLoader";
import useGetSingleApi from "../../../hooks/useGetSingleApi";
import { useProducts } from "../../../hooks/useProducts";

const OngoingAuctions = () => {
  const [filters, setFilters] = useState({
    status_id: "2",
    search_text: "",
    watchers: [],
    status: [],
    type: "",
    active_bid: "1",
  });
  const { isPending, data, hasNextPage, ref } = useProducts(filters);

  const filterStatusApi = useMemo(() => `helper/get_status?model_name=product`, []);
  const { data: filterData } = useGetSingleApi(filterStatusApi, []);
  return (
    <div>
      <Header title="المزادات" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              minHeight: "50px",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            مزادات سارية
          </Typography>
        </Stack>

        <CustomTextFieldSmall setFilters={setFilters} filters={filters} />

        {isPending ? (
          <TableLoader />
        ) : data ? (
          <>
            <AuctionsTable
              rows={data?.pages.map((page) => page).flat()}
              status="new"
              statusOptions={filterData?.data?.map((item: any) => {
                return {
                  name: item.name_ar,
                  id: item.id,
                  class: item.class,
                };
              })}
              realTime={true}
            />
          </>
        ) : null}
        {hasNextPage && (
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={24} ref={ref} />
          </Box>
        )}
      </MainContainer>
    </div>
  );
};

export default OngoingAuctions;
