import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { AiOutlineSetting } from "react-icons/ai";
import { MarkoonNumberItem, WhyMarkoonItem } from "../../types";
import { useState } from "react";
import { MarkoonNumberModal } from "../Modals/MarkoonNumberModal";
import { useUpdateMarkoonNumber } from "../../hooks/markoonNumbers/useUpdateMarkoonNumber.hook";
import { pageTypes } from "../../constants/pageTypes.constant";

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  //   color: "#34B44A",
  fontWeight: "500",
  textAlign: "center",
});

interface CustomTableRowProps {
  id: number;
  index: number;
  comment: string;
  comment_en: string;
  comment_ar: string;
  number: number;
  page: number;
  unit: string;
  unit_ar: string;
  unit_en: string;

  openEditBlogModal: (item: MarkoonNumberItem) => void;
}

const CustomTableRow: React.FC<CustomTableRowProps> = ({
  id,
  index,
  comment,
  comment_ar,
  comment_en,
  number,
  page,
  openEditBlogModal,
  unit,
  unit_ar,
  unit_en,
}) => {
  return (
    <StyledTableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
        border: "unset",
      }}
    >
      <StyledMainCell>
        <Typography variant="body2">{comment}</Typography>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">{number}</Typography>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">{unit || "----"}</Typography>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">{page && page === pageTypes.HOME ? "الرئيسية" : "عن مركون"}</Typography>
      </StyledMainCell>
      <Stack direction="row" spacing={1.5} alignItems="center" justifyContent={"center"}>
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& a": {
              color: "#34B44A",
            },
            "& svg": {
              fill: "gray",
            },
          }}
          onClick={() =>
            openEditBlogModal({
              id,

              comment_ar,
              comment_en,
              comment,
              unit_ar,
              unit_en,
              number,
              page,
            })
          }
        >
          <AiOutlineSetting />
        </IconButton>
        <DeleteIcon
          id={Number(id)}
          apiUrl="number/delete"
          payload={{
            number_id: id,
          }}
          refresh="markoon_numbers"
        />
      </Stack>
    </StyledTableRow>
  );
};

interface MarkoonNumberTableProps {
  data: any;
}

export const MarkoonNumberTable: React.FC<MarkoonNumberTableProps> = ({ data }) => {
  const [numberItem, setNumberItem] = useState<MarkoonNumberItem | null>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { mutate: updateMarkoonNumberHandler, isPending: updateLoading } = useUpdateMarkoonNumber(() =>
    setEditModalOpen(false)
  );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader>
          <h6>عنوان العنصر</h6>
          <h6> الرقم</h6>
          <h6> الوحده</h6>
          <h6>الصفحة</h6>
          <h6></h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {data.map((row: any, index: number) => {
            return (
              <CustomTableRow
                {...row}
                id={row.id}
                key={row.id}
                openEditBlogModal={(numberItem) => {
                  setNumberItem(numberItem);
                  setEditModalOpen(true);
                }}
              />
            );
          })}
        </Stack>
        <MarkoonNumberModal
          handleClose={() => {
            setEditModalOpen(false);
            setNumberItem(null);
          }}
          handleConfirm={updateMarkoonNumberHandler}
          isLoading={updateLoading}
          open={editModalOpen}
          title="تعديل العنصر"
          item={numberItem ?? null}
          isEdit
        />
      </Box>
    </>
  );
};
