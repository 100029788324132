import { Stack, Typography, styled } from "@mui/material";
import { useMemo, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";
import TableLoader from "../../components/Loaders/TableLoader";
import SubCategoryTable from "../../components/Tables/SubCategoryTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { clientsActions } from "../../store/clients-slice";
import { modalActions } from "../../store/modal-slice";
import SubClientsTypesTable from "../../components/Tables/SubClientsTypesTable";

type Props = {
  new?: boolean;
};

const ClientsSubTypes = (props: Props) => {
  const { id } = useParams();

  const subApiUrl = useMemo(() => `userTypes/single?type_id=${id}`, []);
  const mainState = useSelector((state: any) => state.main);
  const dependencies = useMemo(
    () => [mainState.refreshApi],
    [mainState.refreshApi]
  );
  const dispatch = useDispatch();

  const { data, loading, error } = useGetSingleApi(subApiUrl, dependencies);

  return (
    <div>
      <Header title="أنواع العملاء" />

      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <StyledTypography variant="h6">
            {"أنواع العملاء الفرعية"}
          </StyledTypography>
          <OutlinedButton
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  type: "add",
                  title: "clients",
                  apiUrl: "userTypes/create",
                  initialValues: {
                    title_ar: "",
                    title_en: "",
                    parent_id: Number(id),
                  },
                })
              );
            }}
            endIcon={<IoMdAdd />}
          >
            اضافة فئة
          </OutlinedButton>
        </Stack>
        {loading ? (
          <TableLoader />
        ) : data ? (
          <>
            <SubClientsTypesTable data={data.data.subTypes} />
          </>
        ) : null}{" "}
      </MainContainer>
    </div>
  );
};

export default ClientsSubTypes;

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "700",
  fontSize: "1.375rem",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "8px",
    left: "0",
    width: "90px",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
  },
}));
