import { Divider, Grid, Rating, Stack, Typography, styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import ClientTypesFilter from "../../../components/ClientTypesFilter/ClientTypesFilter";
import FilterCatsModal from "../../../components/FilterCatsModal/FilterCatsModal";
import { ClientFilterItem } from "../common";

type Props = {
  open: boolean;
  handleClose: () => void;
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>> | undefined;
  new?: boolean;
  trusted?: 1 | 2;
};

export const ClientsFilterDrawer: React.FC<Props> = React.memo(
  ({ open, handleClose, filters, setFilters, trusted = 2 }) => {
    return (
      <Drawer
        keepMounted
        anchor="bottom"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack
          spacing={1}
          alignItems={"flex-start"}
          sx={{
            padding: "1rem",
          }}
        >
          <Typography variant="h2" color="primary.main">
            فلتر العملاء
            <StyledDivider />
          </Typography>
          <Grid container spacing={2} columns={100}>
            {trusted !== 1 && (
              <Grid item xs={20}>
                <ClientFilterItem
                  filters={filters}
                  setFilters={setFilters}
                  name="status"
                  title="حالة العميل"
                  options={[
                    { value: "1", label: "مفعل" },
                    { value: "2", label: "غير مفعل" },
                    { value: "3", label: "جديد" },
                    { value: "4", label: "لم يتم الرد" },
                  ]}
                  multi
                />
              </Grid>
            )}

            <Grid item xs={20}>
              <ClientFilterItem
                filters={filters}
                setFilters={setFilters}
                name="bid"
                title="حسب المزاد"
                options={[
                  { value: "1", label: " المشتريكين بالمزادات" },
                  { value: "2", label: "المقبولين بالمزادات" },
                  { value: "3", label: "الغير مشتريكين بالمزادات" },
                ]}
                multi
              />
            </Grid>
            <Grid item xs={40}>
              <ClientTypesFilter setFilters={setFilters} filters={filters} />
            </Grid>
            <Grid item xs={20}>
              <Stack>
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" color="primary.main">
                    تقييم العميل
                  </Typography>
                  <Rating
                    name="filter-rating"
                    precision={1}
                    size="small"
                    onChange={(e__, v) => {
                      setFilters && setFilters({ filters, rate: v });
                    }}
                  />
                </Stack>
                <FilterCatsModal filters={filters} setFilters={setFilters} />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Drawer>
    );
  }
);

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.primary.main,
}));
