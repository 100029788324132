import React, { ChangeEvent, useRef, useState } from "react";
import { Box, Button, CircularProgress, Dialog, Stack, Typography } from "@mui/material";
import OutlinedButton from "../Buttons/OutlinedButton";
import { BiImage } from "react-icons/bi";
import { BsFillPlayFill } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";
import { mainSliceActions } from "../../store/main-slice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import CustomTextField from "../CustomTextField/CustomTextField";
import CustomSelect from "../CustomSelect/CustomSelect";
import { pageTypes } from "../../constants/pageTypes.constant";

type Props = {
  handleClose: () => void;
};

const ImageOrVideoDialog = ({ handleClose }: Props) => {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleButtonClick = (type: "image" | "video") => {
    if (imageInputRef.current && type === "image") {
      imageInputRef.current.click();
    }
    if (videoInputRef.current && type === "video") {
      videoInputRef.current.click();
    }
  };
  const handleUploadSliderItem = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);
      const formData = new FormData();
      formData.append("type", values.type);
      formData.append("title_ar", values.title_ar);
      formData.append("title_en", values.title_en);
      formData.append("desc_ar", values.desc_ar);
      formData.append("desc_en", values.desc_en);
      formData.append("button_ar", values.button_ar);
      formData.append("button_en", values.button_en);
      formData.append("button_link", values.button_link);
      formData.append("sort", values.sort);
      formData.append("page", values.page);
      if (typeof values.image === "string") {
        console.log(values.image);
        delete values.image;
      } else {
        formData.append("image", values.image);
      }
      if (values.slider_id) {
        formData.append("slider_id", values.slider_id);
      }

      await axios
        .post(`${process.env.REACT_APP_API_URL}slider/${values.slider_id ? "update" : "create"}`, formData)
        .then((res) => {
          console.log("res", res);
          if (res.data.status === 1) {
            toast.success("تم اضافه الملف بنجاح");
            dispatch(mainSliceActions.toggleRefreshApi());
            handleClose();
          } else {
            toast.error(res.data.message);
          }
        });
    } catch (error: any) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const newSlideState = useSelector((state: any) => state.slider);
  return (
    <Dialog
      open={newSlideState.sliderModal}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "80vw",
          padding: "20px",
          borderRadius: "10px",
          maxWidth: "unset",
        },
      }}
    >
      <Formik initialValues={newSlideState.newSliceValues} onSubmit={handleUploadSliderItem}>
        {({ setFieldValue, isSubmitting, values, touched, errors }) => (
          <Form>
            <Stack spacing={4}>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{
                  fontWeight: "700",
                  fontSize: "1.375rem",
                }}
              >
                اختر نوع الملف
              </Typography>
              <Stack
                direction="row"
                justifyContent={"center"}
                spacing={2}
                sx={{
                  width: "100%",
                }}
              >
                <OutlinedButton
                  endIcon={<BiImage />}
                  onClick={() => {
                    setFieldValue("type", "1");
                    handleButtonClick("image");
                  }}
                >
                  صورة{" "}
                  <input
                    ref={imageInputRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files) {
                        setFieldValue("image", e.target.files[0]);
                      }
                    }}
                    style={{ display: "none" }}
                  />
                </OutlinedButton>
                <OutlinedButton
                  onClick={() => {
                    setFieldValue("type", "2");
                    handleButtonClick("video");
                  }}
                  endIcon={<BsFillPlayFill />}
                >
                  فيديو{" "}
                  <input
                    ref={videoInputRef}
                    type="file"
                    accept="video/*"
                    onChange={(e) => {
                      if (e.target.files) {
                        setFieldValue("image", e.target.files[0]);
                      }
                    }}
                    style={{ display: "none" }}
                  />
                </OutlinedButton>
              </Stack>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <CustomTextField name="title_ar" label="العنوان بالعربي" />
                <CustomTextField name="title_en" label="العنوان بالانجليزي" />
                <CustomTextField name="desc_ar" label="الوصف بالعربي" multiLine={2} />
                <CustomTextField name="desc_en" label="الوصف بالانجليزي" multiLine={2} />
                <CustomTextField name="button_ar" label="اسم الزرار بالعربي" />
                <CustomTextField name="button_en" label="اسم الزرار بالانجليزي" />
                <Box
                  sx={{
                    gridColumn: "span 2",
                  }}
                >
                  <CustomTextField name="button_link" label="رابط الزرار" />
                </Box>
                <CustomTextField name="sort" label="الترتيب" />
                <CustomSelect
                  touched={touched}
                  errors={errors}
                  title="الصفحة"
                  placeholder="اختر نوع الصفحة"
                  name="page"
                  setFieldValue={setFieldValue}
                  optionsProp={[
                    { value: pageTypes.HOME, label: "الرئيسية" },
                    { value: pageTypes.ABOUT, label: "عن مركون" },
                  ]}
                  values={values}
                  value={values.page}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  رفع الملف
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ImageOrVideoDialog;
