import { Navigate, Outlet } from "react-router-dom";

import { RootState } from "../../store";
import axios from "axios";
import { useSelector } from "react-redux";

// import Cookies from "universal-cookie";

type Props = {};

const PrivateRoutes = (props: Props) => {
  // const cookies = new Cookies();
  // const token = cookies.get("admin-token");

  const token = useSelector((state: RootState) => state.user.token);
  // axios.interceptors.request.use((config: any) => {
  //   config.headers.Authorization = `Bearer ${token}`;
  //   return config;
  // });
  return token ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
