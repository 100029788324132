import axios from "axios";
import { MarkoonNumberItem } from "../types";

export const getMarkoonNumber = async () => {
  try {
    const response = await axios.get(`number/get`);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateMarkoonNumber = async (values: MarkoonNumberItem) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (key === "id") {
        formData.append("number_id", String(values[key]));
        continue;
      }
      formData.append(key, values[key]);
    }
    const response = await axios.post(`number/update`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const createMarkoonNumber = async (values: MarkoonNumberItem) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    const response = await axios.post(`number/create`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};
