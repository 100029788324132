import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { postAxios } from "../../Services/axios-services";
import { genericMessageModalActions } from "../../store/generic-message-slice";
import CustomTextField from "../CustomTextField/CustomTextField";
import CustomSelectSmall from "../CustomSelect/CustomSelectSmall";
import { useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 4.75rem",
  width: "670px",
};

const GenericSendMessageModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: any) => state.genericMessageModal);
  const [filters, setFilters] = useState(1);

  const handleClose = () => {
    dispatch(genericMessageModalActions.closeModal());


    // 
  };

  const submitApiCall = async (values: { msg: string; user_id: string; product_id: string }, actions: any) => {
    // console.log("users",values)
    try {
      const data = await postAxios("notification/send_msg_interests", {
        ...values,
        type: filters,
      });
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success("تم ارسال الرسالة !");
        handleClose();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    msg: Yup.string().required("هذا الحقل مطلوب"),
  });

  return (
    <>
      <Modal open={modalState.isOpen} onClose={handleClose} aria-labelledby="modal-add" aria-describedby="modal-add">
        <>
          <Formik
            initialValues={{
              msg: "",
              user_id: modalState.userId,
              product_id: modalState.productId,
            }}
            onSubmit={(values, actions) => {
              submitApiCall(values, actions);
            }}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                      <Typography
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          color: "#34B44A",
                          textAlign: "center",
                        }}
                      >
                        {`أرسل رسالة`}
                      </Typography>
                      <CustomSelectSmall
                        optionsProp={[
                          // { value: 1, label: "كل المهمتمين" },
                          { value: 1, label: "المزايدين" },
                          { value: 2, label: "غير المزايدين" },
                        ]}
                        placeholder="اختيار نوع المزايدين"
                        type="bid"
                        setFilters={setFilters}
                      />
                    </Stack>
                    <Box
                      sx={{
                        margin: "1rem 0",
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      <CustomTextField name="msg" label="الرسالة" type="text" multiLine={5} />
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                          color: "#FFFFFF",
                          borderRadius: "6px",
                        }}
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                      >
                        {"أرسل"}
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "6px",
                          border: "2px solid #34B44A",
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        إلغاء
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default GenericSendMessageModal;
