import AddAdditionalClientInfo from "../../components/Modals/AddAdditionalClientInfoModal";
import { Button } from "@mui/material";
import { MdAdd } from "react-icons/md";
import { useState } from "react";

interface IAddProductionAdditionalInfo {
  values: any;
}
export const AddProductAdditionalInfo: React.FC<IAddProductionAdditionalInfo> = ({ values }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button
        sx={{
          borderRadius: "1rem",
          maxWidth: "350px",
          height: "60px",
        }}
        variant="text"
        color="primary"
        fullWidth
        onClick={handleOpen}
        startIcon={<MdAdd />}
      >
        بيانات اضافية
      </Button>
      <AddAdditionalClientInfo type="product" open={open} handleClose={handleClose} values={values} />
    </>
  );
};
