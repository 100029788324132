import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useMemo, useState } from "react";

import { AuctionsFilterDrawer } from "../../../features";
import AuctionsTable from "../../../components/Tables/AuctionsTable";
import CustomTextFieldSmall from "../../../components/CustomTextFieldSmall/CustomTextFieldSmall";
import Header from "../../../components/Header/Header";
import MainContainer from "../../../components/Containers/MainContainer";
import TableLoader from "../../../components/Loaders/TableLoader";
import { useAuctions } from "../../../hooks/useAuctions";
import useGetSingleApi from "../../../hooks/useGetSingleApi";

type Props = {};

const NewAuctions = (props: Props) => {
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const handleChange = () => {
    setFilterExpanded((prev) => !prev);
  };
  const [filters, setFilters] = useState({
    status_id: null,
    // price_type: null,
    search_text: "",
    watchers: [],
    status: [],
  });
  const { isPending, data, hasNextPage, ref } = useAuctions(filters);
  // const auctionsApi = useMemo(
  //   () =>
  //     `auction/get_products?page=${query.page || 1}&search_text=${
  //       query.search_text || ""
  //     }`,
  //   [query.page, query.search_text]
  // );
  // const mainState = useSelector((state: any) => state.main);

  // const dependencies = useMemo(
  //   () => [mainState.refreshApi],
  //   [mainState.refreshApi]
  // );
  // const handleSubmitFilters = () => {
  //   //take the state and construct it to a url to use a query string
  //   const urlToPush = `?search_text=${filters.search_text || ""}`;
  //   navigate(urlToPush);
  // };

  // const { data, loading } = useGetSingleApi(auctionsApi, dependencies);
  const filterStatusApi = useMemo(
    () => `helper/get_status?model_name=product`,
    []
  );
  const { data: filterData } = useGetSingleApi(filterStatusApi, []);
  return (
    <div>
      <Header title="المزادات" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              minHeight: "50px",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            مزاداتي
          </Typography>
          <Button
            sx={{
              color: "#F7F7FC",
              borderRadius: "8px",
              width: "190px",
              height: "50px",
              fontSize: "1a.125rem",
              fontWeight: "800",
            }}
            onClick={handleChange}
            variant="contained"
            color="primary"
            endIcon={
              filterExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />
            }
          >
            تصفية المزادات
          </Button>
        </Stack>

        {/* <FiltersContainer
          filterExpanded={filterExpanded}
          handleSubmitFilters={handleSubmitFilters}
        >
          <DatePicker />
          <CustomTextFieldSmall setFilters={setFilters} filters={filters} />
        </FiltersContainer> */}
        <CustomTextFieldSmall setFilters={setFilters} filters={filters} />

        {isPending ? (
          <TableLoader />
        ) : data ? (
          <>
            <AuctionsTable
              rows={data?.pages.map((page) => page).flat()}
              status="new"
              statusOptions={filterData?.data?.map((item: any) => {
                return {
                  name: item.name_ar,
                  id: item.id,
                  class: item.class,
                };
              })}
            />
          </>
        ) : null}
        {hasNextPage && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={24} ref={ref} />
          </Box>
        )}
      </MainContainer>
      <AuctionsFilterDrawer
        open={filterExpanded}
        filters={filters}
        setFilters={setFilters}
        handleClose={() => {
          setFilterExpanded(false);
        }}
        statusOptions={filterData?.data?.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        })}
      />
    </div>
  );
};

export default NewAuctions;
