import * as React from "react";

import { Divider, Grid, Stack, Typography, styled } from "@mui/material";

import { ClientFilterItem } from "../../clients";
import Drawer from "@mui/material/Drawer";

type Props = {
  open: boolean;
  handleClose: () => void;
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>> | undefined;
  statusOptions?: {
    value: string;
    label: string;
  }[];
};

export const AuctionsFilterDrawer: React.FC<Props> = React.memo(
  ({ open, handleClose, filters, setFilters, statusOptions }) => {
    return (
      <Drawer
        keepMounted
        anchor="bottom"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack
          spacing={1}
          alignItems={"flex-start"}
          sx={{
            padding: "1rem",
          }}
        >
          <Typography variant="h2" color="primary.main">
            فلتر المزادات
            <StyledDivider />
          </Typography>
          <Grid container spacing={2} columns={100}>
            <Grid item xs={20}>
              <ClientFilterItem
                filters={filters}
                setFilters={setFilters}
                name="watchers"
                title="عدد المشاهدين"
                options={[
                  { value: "1", label: "لا يوجد مشاهدات" },
                  { value: "2", label: "1 - 10 مشاهدات" },
                  { value: "3", label: "أكثر من 10 مشاهدات" },
                ]}
                multi
              />
            </Grid>
            {statusOptions && (
              <Grid item xs={40}>
                <ClientFilterItem
                  filters={filters}
                  setFilters={setFilters}
                  name="status"
                  title="الحالة"
                  options={statusOptions}
                  multi
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      </Drawer>
    );
  }
);

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.primary.main,
}));
