import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  refresh: boolean;

  subCategoryModal: boolean;
  addNewCategoryValues: {
    name_ar: string;
    name_en: string;
    parent_id: string;
    cat_id?: string;
    type: number | null;
  };
}

const initialState: initialStateType = {
  refresh: false,
  subCategoryModal: false,
  addNewCategoryValues: {
    name_ar: "",
    name_en: "",
    parent_id: "",
    type: 1,
    cat_id: "",
  },
};

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    toggleRefresh(state) {
      state.refresh = !state.refresh;
    },
    openModal(state) {
      state.subCategoryModal = true;
    },
    closeModal(state) {
      state.subCategoryModal = false;
      state.addNewCategoryValues = {
        name_ar: "",
        name_en: "",
        parent_id: "",
        type: 1,
      };
    },
    edit(state, action) {
      state.addNewCategoryValues = action.payload;
    },
    addNew(state, action) {
      state.addNewCategoryValues = {
        name_ar: "",
        name_en: "",
        parent_id: action.payload.parent_id,
        type: 1,
      };
    },
  },
});

export const subCategoryActions = subCategorySlice.actions;
export default subCategorySlice;
