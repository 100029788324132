import * as React from "react";

import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { AiOutlineEye } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import DeleteIcon from "../../../components/DeleteIcon/DeleteIcon";
import { FaFilePdf } from "react-icons/fa";
import Fade from "@mui/material/Fade";
import { IoMdMore } from "react-icons/io";
import { MdLogin } from "react-icons/md";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SendMessageToClient } from "../common";
import { getAxios } from "../../../Services/axios-services";
import { useClientFastLogin } from "../../../hooks";

type Props = {
  id: number;
  name: string;
  type: 1 | 2;
};

export const ClientsTableActionMenu = ({ id, type, name }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleClientDetails = () => {
    navigate(`/clients/all/${id}`);
  };
  const handleClientEdit = () => {
    navigate(`/clients/all/${id}/edit`);
  };

  const [loading, setLoading] = React.useState(false);
  const handleGetClientReport = async () => {
    setLoading(true);
    try {
      console.log(loading);
      getAxios(`user/user_report_link?user_id=${id}`)
        .then((response) => {
          console.log(response);
          if (response?.data?.status === 1) {
            window.open(response?.data?.data, "_blank");
          }
        })
        .then(() => {
          setLoading(false);
          handleClose();
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const { loading: clientLoginLoading, loginHandler } = useClientFastLogin({
    id: String(id),
  });
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          padding: "0px",
        }}
      >
        <IoMdMore />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          "& .MuiMenu-paper": {
            borderRadius: "1rem",
            boxShadow: "0px 0px 10px 0px #0000001f",
          },
        }}
      >
        {type !== 1 && (
          <MenuItem>
            <SendMessageToClient id={id} name={name} label="ارسال رسالة" />
          </MenuItem>
        )}
        <MenuItem onClick={handleClientDetails}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            width="100%"
            spacing={1}
          >
            <IconButton
              size="small"
              sx={{
                "& .MuiIconButton-root": {
                  padding: "0px",
                },
                width: "20px",
                height: "20px",
                "& a": {
                  color: "#34B44A",
                },
              }}
            >
              <Link
                to={`/clients/all/${id}`}
                style={{
                  padding: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiOutlineEye />
              </Link>
            </IconButton>
            <Typography variant="caption" color="primary.main">
              تفاصيل العميل
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleClientEdit}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            width="100%"
            justifyContent="flex-start"
          >
            <IconButton
              size="small"
              sx={{
                padding: "0px",

                "& a": {
                  color: "#34B44A",
                },
                "& svg": {
                  fill: "gray",
                  // width: "24px",
                  // height: "24px",
                },
              }}
            >
              {/* <Link to={`/clients/all/${id}/edit`}> */}
              <BiEditAlt />
              {/* </Link> */}
            </IconButton>
            <Typography variant="caption" color="gray">
              تعديل العميل
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleGetClientReport} disabled={loading}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            width="100%"
            justifyContent="flex-start"
          >
            <IconButton
              size="small"
              sx={{
                padding: "0px",

                "& a": {
                  color: "#34B44A",
                },
                "& svg": {
                  fill: "#9b666e",
                  // width: "24px",
                  // height: "24px",
                },
              }}
            >
              {loading ? <CircularProgress size={16} /> : <FaFilePdf />}
            </IconButton>
            <Typography variant="caption" color="#9b666e">
              تقرير العميل
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={loginHandler} disabled={clientLoginLoading}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            width="100%"
            justifyContent="flex-start"
          >
            <IconButton
              size="small"
              sx={{
                padding: "0px",

                "& a": {
                  color: "#34B44A",
                },
                "& svg": {
                  fill: "#34B44A",
                  // width: "24px",
                  // height: "24px",
                },
              }}
            >
              {loading ? <CircularProgress size={16} /> : <MdLogin />}
            </IconButton>
            <Typography variant="caption" color="#9b666e">
              تسجيل دخول{" "}
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem>
          <DeleteIcon
            id={Number(id)}
            label="حذف العميل"
            apiUrl="user/delete"
            payload={{
              user_id: id,
            }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
