import * as React from "react";

import { IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { MdAddChart, MdOutlinePersonSearch } from "react-icons/md";

import { AiOutlineEye } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import DeleteIcon from "../../components/DeleteIcon/DeleteIcon";
import Fade from "@mui/material/Fade";
import { IoMdMore } from "react-icons/io";
import Menu from "@mui/material/Menu";
import { REACT_QUERY_PRODUCTS } from "../../constants/reactQuery.constants";
import { genericMessageModalActions } from "../../store/generic-message-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type Props = {
  id: number;
  tableType?: string;
  handleOpenInterestedClients?: (id: number) => void;
  product_name?: string;
  lot: 1 | 0;
};

export const ProductTableMenu = ({ id, tableType, handleOpenInterestedClients, product_name, lot }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleProductDetails = () => {
    if (tableType === "khorda") {
      navigate(`/khorda/all/${id}`);
    } else {
      navigate(`/rawakd/all/${id}`);
    }
  };
  const handleEditProduct = () => {
    if (tableType === "khorda") {
      navigate(`/khorda/all/${id}/edit`);
    } else {
      navigate(`/rawakd/all/${id}/edit`);
    }
  };
  const dispatch = useDispatch();
  const handleNavigateToTermsOfConditions = () => {
    navigate(`/pay-requests/${id}?product_name=${product_name}&product_type=${tableType}`);
  };
  const handleNavigateToSubProducts = () => {
    navigate(`/products/view/${id}?type=${tableType}`);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          padding: "0px",
        }}
      >
        <IoMdMore />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          "& .MuiMenu-paper": {
            borderRadius: "1rem",
            boxShadow: "0px 0px 10px 0px #0000001f",
          },
        }}
      >
        <MenuItem onClick={handleProductDetails}>
          <Stack direction="row" justifyContent="flex-start" width="100%" spacing={1}>
            <IconButton
              size="small"
              sx={{
                padding: "0px",
                "& svg": {
                  fill: "#34B44A",
                },
              }}
            >
              <AiOutlineEye />
            </IconButton>
            <Typography variant="caption" color="primary.main">
              تفاصيل المنتج
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleEditProduct}>
          <Stack direction="row" justifyContent="flex-start" width="100%" spacing={1}>
            <IconButton
              size="small"
              sx={{
                padding: "0px",

                "& svg": {
                  fill: "#9b666e",
                },
              }}
            >
              <BiEditAlt />
            </IconButton>
            <Typography variant="caption" color="#9b666e">
              تعديل المنتج
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenInterestedClients && handleOpenInterestedClients(id);
            dispatch(genericMessageModalActions.setProductId(String(id)));
          }}
        >
          <Stack direction="row" justifyContent="flex-start" width="100%" spacing={1}>
            <IconButton
              size="small"
              sx={{
                padding: "0px",
                "& svg": {
                  fill: "grey",
                },
              }}
            >
              <MdOutlinePersonSearch />
            </IconButton>
            <Typography variant="caption" color="grey">
              العملاء المهتمين
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleNavigateToTermsOfConditions}>
          <Stack direction="row" justifyContent="flex-start" width="100%" spacing={1}>
            <IconButton
              size="small"
              sx={{
                padding: "0px",
                "& svg": {
                  fill: "grey",
                },
              }}
            >
              <MdOutlinePersonSearch />
            </IconButton>
            <Typography variant="caption" color="grey">
              طلبات كراسة الشروط
            </Typography>
          </Stack>
        </MenuItem>
        {lot ? (
          <MenuItem onClick={handleNavigateToSubProducts}>
            <Stack direction="row" justifyContent="flex-start" width="100%" spacing={1}>
              <IconButton
                size="small"
                sx={{
                  padding: "0px",
                  "& svg": {
                    fill: "grey",
                  },
                }}
              >
                <MdAddChart />
              </IconButton>
              <Typography variant="caption" color="grey">
                منتجات اللوط{" "}
              </Typography>
            </Stack>
          </MenuItem>
        ) : (
          <></>
        )}
        <MenuItem>
          <DeleteIcon
            id={Number(id)}
            apiUrl="products/delete"
            label="حذف المنتج"
            payload={{
              product_id: id,
            }}
            refresh={REACT_QUERY_PRODUCTS}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
