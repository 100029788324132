import { Box, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";

import AddFileIcon from "../../assets/images/icons/addFileIcon.svg";
import { BsImages } from "react-icons/bs";
import Dropzone from "../../components/Dropzone/Dropzone";
import { StyledSubContainer } from "../styles";
import { TiDeleteOutline } from "react-icons/ti";
import { useFormikContext } from "formik";

type Props = {
  images?: any;
};

export const AddProductFirstStep = ({ images }: Props) => {
  const { setFieldValue, values } = useFormikContext<any>();
  return (
    <StyledSubContainer>
      {/* <StyledTitle>صور المنتج</StyledTitle> */}
      {/* <Divider
        sx={{
          width: "calc(100% + 54px)",
          marginLeft: "-27px",
        }}
      /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "700",
            marginBottom: "1.5rem",
          }}
        >
          إضافة صورة
        </Typography>
        <Dropzone setFieldValue={setFieldValue} images={images} />
      </Box>
      <Divider
        sx={{
          width: "calc(100% + 54px)",
          marginLeft: "-27px",
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "400",
                }}
              >
                إضافة ملف pdf للمنتج (المواصفات)
              </Typography>
              <IconButton
                disableFocusRipple
                disableRipple
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{
                  width: "100px",
                  height: "80px",
                  border: "1px solid rgba(0, 0, 0, 0.08)",
                  borderRadius: "6px",
                }}
              >
                <input
                  hidden
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => {
                    const files = e.target ? e.target.files : null;
                    if (files) {
                      setFieldValue("pdf", files[0]);
                    }
                  }}
                />
                <img src={AddFileIcon} alt="add file icon" />
              </IconButton>
            </Stack>

            {values.pdf instanceof File && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    cursor: "pointer",
                    fontWeight: "700",
                  }}
                  onClick={() => {
                    const url = URL.createObjectURL(values.pdf);
                    window.open(url, "_blank");
                  }}
                >
                  {values.pdf.name.split(".")[0] || "الملف"}
                </Typography>
                <Box
                  onClick={() => {
                    setFieldValue("pdf", "");
                  }}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    "& svg": {
                      fontSize: "1.5rem",
                      fill: "red",
                    },
                  }}
                >
                  <TiDeleteOutline />
                </Box>
              </Box>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>إضافة صورة المنتج الرئيسية</Typography>
              <IconButton
                disableFocusRipple
                disableRipple
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{
                  width: "100px",
                  height: "80px",
                  border: "1px solid rgba(0, 0, 0, 0.08)",
                  borderRadius: "6px",
                  fontSize: "3rem",
                }}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    const files = e.target ? e.target.files : null;
                    if (files) {
                      setFieldValue("logo", files[0]);
                    }
                  }}
                />
                <BsImages />{" "}
              </IconButton>
            </Stack>

            {values.logo instanceof File && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  position: "relative",
                }}
              >
                <Box
                  component={"img"}
                  sx={{
                    width: "250px",
                    height: "auto",
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                  src={URL.createObjectURL(values?.logo)}
                  onClick={() => {
                    const url = URL.createObjectURL(values?.logo);
                    window.open(url, "_blank");
                  }}
                />

                <Box
                  onClick={() => {
                    setFieldValue("logo", "");
                  }}
                  sx={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    "& svg": {
                      fontSize: "1.5rem",
                      fill: "red",
                    },
                  }}
                >
                  <TiDeleteOutline />
                </Box>
              </Box>
            )}
            {values?.logo && typeof values?.logo === "string" && (
              <Box
                component={"img"}
                sx={{
                  width: "175px",
                  height: "280px",
                  cursor: "pointer",
                  objectFit: "contain",
                }}
                src={values?.logo}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "400",
                }}
              >
                كراسة الشروط
              </Typography>
              <IconButton
                disableFocusRipple
                disableRipple
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{
                  width: "100px",
                  height: "80px",
                  border: "1px solid rgba(0, 0, 0, 0.08)",
                  borderRadius: "6px",
                }}
              >
                <input
                  hidden
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => {
                    const files = e.target ? e.target.files : null;
                    if (files) {
                      setFieldValue("conditions_document", files[0]);
                    }
                  }}
                />
                <img src={AddFileIcon} alt="add file icon" />
              </IconButton>
            </Stack>

            {values.conditions_document instanceof File && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    cursor: "pointer",
                    fontWeight: "700",
                  }}
                  onClick={() => {
                    const url = URL.createObjectURL(values.conditions_document);
                    window.open(url, "_blank");
                  }}
                >
                  {values.conditions_document.name.split(".")[0] || "الملف"}
                </Typography>
                <Box
                  onClick={() => {
                    setFieldValue("conditions_document", "");
                  }}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    "& svg": {
                      fontSize: "1.5rem",
                      fill: "red",
                    },
                  }}
                >
                  <TiDeleteOutline />
                </Box>
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
    </StyledSubContainer>
  );
};
