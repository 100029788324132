import { Button, Checkbox, FormControlLabel, FormLabel, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import AddClientToProductModal from "../../components/Modals/AddClientToProductModal";
import { AdminComboBox } from "../../components/adminComboBox";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import MainContainer from "../../components/Containers/MainContainer";
import { MdAdd } from "react-icons/md";
import SelectWithApi from "../../components/CustomSelect/SelectWithApi";
import SendMessageMultiSelect from "../../components/CustomSelect/SendMessageMultiSelect";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

interface IAddProductSecondStep {
  catData: any;
}

export const AddProductSecondStep: React.FC<IAddProductSecondStep> = ({ catData }) => {
  const navigate = useNavigate();
  const { values, setFieldValue, errors, touched } = useFormikContext<any>();
  const [openClientModal, setOpenClientModal] = useState(false);
  const handleOpenClientModal = () => setOpenClientModal(true);
  const handleCloseClientModal = () => setOpenClientModal(false);
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const getCatDate = (catData: any) => {
    let catsArray: any = [];
    if (catData.data.data) {
      catsArray = catData.data.data.map((item: any) => {
        if (item.cats.length > 0) {
          return {
            value: item.id,
            label: item.name_ar,
            sub: item.cats.map((cat: any) => {
              return {
                value: cat.id,
                label: cat.name_ar,
              };
            }),
          };
        } else {
          return {
            value: item.id,
            label: item.name_ar,
          };
        }
      });
    }
    catsArray.unshift({ value: "all", label: "الكل" });
    return catsArray;
  };

  return (
    <MainContainer>
      <Grid container spacing={2} justifyItems="center">
        <Grid item xs={12} md={6}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="text"
              startIcon={<MdAdd />}
              color={errors?.user_id && touched?.user_id ? "error" : "primary"}
              fullWidth
              onClick={handleOpenClientModal}
              sx={{
                borderRadius: "1rem",
                maxWidth: "350px",
                height: "60px",
              }}
            >
              إضافة عميل
            </Button>
            {values?.user && (
              <Typography
                sx={{
                  width: "200px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/clients/all/${values?.user?.id}`);
                }}
              >
                {values?.user?.name}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          {values?.user_id && (
            <SelectWithApi
              placeholder="اختر من القائمه"
              title="العنوان"
              name="address_id"
              errors={errors}
              touched={touched}
              apiUrl={`address/all?user_id=${values?.user_id}`}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6}>
          <Button
            sx={{
              borderRadius: "1rem",
              maxWidth: "350px",
              height: "60px",
            }}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleOpen}
          >
            بيانات اضافية
          </Button>
          <AddAdditionalClientInfo type="product" open={open} handleClose={handleClose} values={values} />
        </Grid> */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AdminComboBox />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack spacing={2} width="100%">
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                color: "primary.main",
              }}
            >
              ارسال رسالة
            </Typography>
            <SendMessageMultiSelect
              placeholder="اختر من القائمه"
              title="التصنيف"
              name="cats_messages"
              optionsProp={getCatDate(catData)}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              values={values}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Checkbox
              checked={values.show_company === 1}
              onChange={(e) => {
                setFieldValue("show_company", e.target.checked ? 1 : 0);
              }}
              color="primary"
            />
            <Typography>إظهار معلومات الشركه</Typography>
          </Stack>
        </Grid>
      </Grid>
      <AddClientToProductModal open={openClientModal} onClose={handleCloseClientModal} />;
    </MainContainer>
  );
};
