import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { createWhyMarkoon } from "../../apis/whyMarkoon_apis";

export const useCreateWhyItem = (fn?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createWhyMarkoon,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["why_markoon"] });
      if (data?.status === 1) {
        toast.success("تم الإضافة بنجاح");
        fn && fn();
      }
      if (data?.status === 0) {
        toast.error(data?.message ?? "حدث خطأ ما");
      }
    },
    onError: () => {
      toast.error("حدث خطأ ما");
    },
  });
};
