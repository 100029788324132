import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import { MdOutlineExpandMore } from "react-icons/md";
import AddressTable from "../Tables/TaxInfoTable";
import { useState } from "react";

type Props = {
  children?: React.ReactNode;
  title: string;
};

const BasicAccordionContainer = (props: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const handleChange = () => {
    setExpanded((prev) => !prev);
  };
  return (
    <Accordion
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      sx={{
        padding: "5px 0",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        boxShadow: "0px 4px 10px rgba(181, 181, 181, 0.25)",
        borderRadius: "8px !important",
        height: "fit-content",

        "&::before": {
          display: "none",
        },
        "&.Mui-expanded": {
          margin: "0 !important",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          padding: "0 2.5rem",
          position: "relative",
          "& h4": {
            fontWeight: "700",
            fontSize: "1.25rem",
            color: "black",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            color: "black",
            fontSize: "1.5rem",
          },
        }}
        expandIcon={<MdOutlineExpandMore />}
      >
        <h4>{props.title} :</h4>
        <Divider
          sx={{
            width: "calc(100%)",
            marginLeft: "-2.5rem",
            position: "absolute",
            bottom: "-5px",
          }}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "1.5rem 2.5rem !important",
          height: "fit-content",
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

export default BasicAccordionContainer;
