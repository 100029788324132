import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_ABOUT } from "../../constants/reactQuery.constants";
import { getAboutMrkoon } from "../../apis/about.api";

export const useGetAbout = () => {
  return useQuery({
    queryKey: [REACT_QUERY_ABOUT],
    queryFn: getAboutMrkoon,
  });
};
