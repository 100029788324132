import { Button, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { IPaymentMethod, IProduct } from "../../../types";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuctionMessagesModal from "../../../components/AuctionMessagesModal/AuctionMessagesModal";
import { CreatePayRequestContent } from "./createPayRequestContent";
import CustomTextFieldSmall from "../../../components/CustomTextFieldSmall/CustomTextFieldSmall";
import Header from "../../../components/Header/Header";
import MainContainer from "../../../components/Containers/MainContainer";
import { PayRequestFilterDrawer } from "./payRequestFilterDrawer";
import { PayRequestFormModal } from "./payRequestFormModal";
import { PayRequestsTable } from "./payRequestsTable";
import { StyledTitle } from "../styles";
import TableLoader from "../../../components/Loaders/TableLoader";
import { useSendMessage } from "../../../hooks";
import { useState } from "react";

type Props = {
  filters: any;
  setFilters: any;
  loading?: boolean;
  data?: any;
  paymentMethodFilters?: IPaymentMethod[];
  product_id?: string;
  total?: number;
};

export const PayRequestsContent: React.FC<Props> = ({
  filters,
  setFilters,
  loading,
  data,
  paymentMethodFilters,
  product_id,
  total,
}) => {
  const [filterExpanded, setFilterExpanded] = useState(false);
  const handleChange = () => {
    setFilterExpanded(!filterExpanded);
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setInitialValues({
      payRequest_id: "",
      status: "",
      payment_method_id: "",
      pay_receipt: "",
      note: "",
    } as any);
  };
  const { mutation, handleCloseMessage, handleOpenMessage, openMessage } = useSendMessage();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleNavigateToSingle = (id: string) => {
    if (searchParams.get("product_type") === "khorda") {
      navigate(`/khorda/all/${id}`);
    } else {
      navigate(`/rawakd/all/${id}`);
    }
  };
  const [initialValues, setInitialValues] = useState({
    payRequest_id: "",
    status: "",
    payment_method_id: "",
    pay_receipt: "",
    note: "",
    insurance_status: "",
  });
  const handleOpen = (
    id: number,
    status: string,
    payment_method_id: string,
    note: string,
    insurance_status: string
  ) => {
    setOpen(true);
    // setPayRequestId(id);
    setInitialValues({
      payRequest_id: id.toString(),
      status,
      payment_method_id,
      pay_receipt: "",
      note: note || "",
      insurance_status,
    });
  };
  return (
    <>
      <Header title="طلبات الدفع" />
      <Formik
        initialValues={{
          checked: [],
        }}
        onSubmit={(values) => {
          mutation.mutate(values);
        }}
      >
        {({ values }) => (
          <Form>
            <MainContainer>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={0.25}>
                  {searchParams.get("product_name") ? (
                    <StyledTitle
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        product_id && handleNavigateToSingle(product_id);
                      }}
                    >{`(${searchParams.get("product_name")}) (${total || "0"}) ${
                      data?.length > 0 && !data[0]?.product.has_conditions_document ? "(مجاني)" : ""
                    }`}</StyledTitle>
                  ) : (
                    <StyledTitle variant="h6">{`طلبات الدفع (${total || ""})`}</StyledTitle>
                  )}
                </Stack>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  {product_id && paymentMethodFilters && (
                    <CreatePayRequestContent product_id={product_id} paymentMethods={paymentMethodFilters} />
                  )}
                  <AuctionMessagesModal
                    open={openMessage}
                    handleClose={handleCloseMessage}
                    handleOpen={handleOpenMessage}
                    loading={mutation.isPending}
                  />
                  <Button
                    sx={filterButtonStyles}
                    onClick={handleChange}
                    variant="contained"
                    color="primary"
                    endIcon={filterExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                  >
                    تصفية طلبات الدفع
                  </Button>
                </Stack>
              </Stack>
              <CustomTextFieldSmall setFilters={setFilters} filters={filters} />
              {loading ? <TableLoader /> : data && <PayRequestsTable rows={data} handlePay={handleOpen} />}
            </MainContainer>
            <PayRequestFilterDrawer
              open={filterExpanded}
              setFilters={setFilters}
              filters={filters}
              handleClose={handleChange}
              paymentMethodFilters={paymentMethodFilters}
            />
            {initialValues.payRequest_id && paymentMethodFilters && (
              <PayRequestFormModal
                initialValues={initialValues}
                payRequest_id={initialValues.payRequest_id}
                open={open}
                handleClose={handleClose}
                paymentMethods={paymentMethodFilters}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

const filterButtonStyles = {
  color: "#F7F7FC",
  borderRadius: "8px",
  height: "50px",
  fontSize: "1.125rem",
  fontWeight: "800",
};
