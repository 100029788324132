import { Typography, styled } from "@mui/material";

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "700",
  fontSize: "1.375rem",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "8px",
    left: "0",
    width: "90px",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
  },
}));
