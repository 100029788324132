import { Box } from "@mui/material";
import { useMemo } from "react";
import firstStatus from "../../assets/images/first-status.png";
import fourthStatus from "../../assets/images/fourth-status.png";
import secondStatus from "../../assets/images/second-status.png";
import thirdStatus from "../../assets/images/third-status.png";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import StatusCard from "../StatusCard/StatusCard";

const StaticsContainer = () => {
  const apiUrl = useMemo(() => "statistics/home", []);
  const { data, loading, error } = useGetSingleApi(apiUrl, []);
  if (loading || error) return <></>;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: "1rem",
        justifyContent: "center",
      }}
    >
      <StatusCard
        icon={<img src={firstStatus} alt="first-icon" />}
        color="#E07400"
        title={"عدد الرواكد"}
        count={data?.data?.rawakedCount}
      />
      <StatusCard
        icon={<img src={secondStatus} alt="second-icon" />}
        color="#4282AA"
        title={"عدد الخرده"}
        count={data?.data?.kordaCount}
      />

      {data?.data?.usersTypes?.data?.map((item: any) => {
        return (
          <StatusCard
            key={item?.id}
            icon={
              <img
                src={item?.id % 2 === 0 ? fourthStatus : thirdStatus}
                alt="third-icon"
              />
            }
            color={item?.id % 2 === 0 ? "#E07400" : "#4282AA"}
            title={`عدد العملاء ${item?.title}`}
            count={item?.user_count}
          />
        );
      })}
    </Box>
  );
};

export default StaticsContainer;
