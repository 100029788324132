import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTestimonial } from "../../apis/testimonials_apis";
import { toast } from "react-toastify";

export const useUpdateTestimonial = (fn?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateTestimonial,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["testimonials"] });
      if (data?.status === 1) {
        toast.success("تم تحديث البيانات بنجاح");
      }
      fn && fn();
    },
    onError: () => {
      toast.error("حدث خطأ ما");
    },
  });
};
