import * as Yup from "yup";

import { Box, Button, Checkbox, CircularProgress, Divider, IconButton, Stack, Typography, styled } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { endOfYesterday, format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

import AddAdditionalClientInfo from "../../Modals/AddAdditionalClientInfoModal";
import AddClientToProductModal from "../../Modals/AddClientToProductModal";
import AddFileIcon from "../../../assets/images/icons/addFileIcon.svg";
import { BsImages } from "react-icons/bs";
import CustomInputWithBtns from "../../CustomInputWithBtns/CustomInputWithBtns";
import CustomSelect from "../../CustomSelect/CustomSelect";
import CustomTextField from "../../CustomTextField/CustomTextField";
import Dropzone from "../../Dropzone/Dropzone";
import Header from "../../Header/Header";
import MainContainer from "../../Containers/MainContainer";
import MainLoader from "../../Loaders/MainLoader";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import MultiSelectTags from "../../MultiSelectTags/MultiSelectTags";
import SelectAddress from "../../CustomSelect/SelectAddress";
import SelectWithApi from "../../CustomSelect/SelectWithApi";
import { TiDeleteOutline } from "react-icons/ti";
import axios from "axios";
import { postAxios } from "../../../Services/axios-services";
import { toast } from "react-toastify";
import useGetSingleApi from "../../../hooks/useGetSingleApi";
import { useSelector } from "react-redux";

type Props = {
  type: string;
};
const StyledSubContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  padding: "23px 27px",
  gap: "30px",
});
const StyledTitle = styled("h4")({
  fontSize: "1.25rem",
  fontWeight: "700",
});

const EditProduct = ({ type }: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const catApiUrl = useMemo(() => `categories/get?type=${type || ""}`, []);
  const navigate = useNavigate();
  const { data: catData } = useGetSingleApi(catApiUrl, []);

  const handleSubmit = async (values: any, actions: any) => {
    console.log(values);
    let newValues = values;
    if (newValues.price_type === "1") {
      delete newValues.mazad_type;
      delete newValues.end_date;
    }
    if (newValues.price_type === "2") {
      delete newValues.real_price;
      delete newValues.price;
    }
    // send only cat_id and if sub_cat_id is not empty send it but in the same object
    if (newValues.sub_cat_id) {
      newValues.cat_id = values.sub_cat_id;
      delete newValues.sub_cat_id;
    }
    //delete logo if it is not a file
    if (!(newValues.logo instanceof File)) {
      delete newValues.logo;
    }
    //check if adds has an empty key or value
    newValues.adds = newValues.adds.filter((add: any) => add.key !== "" && add.value !== "");

    try {
      actions.setSubmitting(true);
      await postAxios("products/update", newValues).then((res) => {
        if (res.data.status === 1) {
          toast.success("تم تعديل المنتج بنجاح");
          // dispatch(mainSliceActions.toggleRefreshApi());
          console.log(values);

          setTimeout(() => {
            if(values.parent_id){
              navigate(`/products/view/${values.parent_id}`);
            }
           else if (type === "2") {
              navigate("/khorda/all");
            } else {
              navigate("/rawakd/all");
            }
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };
  const { id } = useParams<{ id: string }>();

  const [initialValues, setInitialValues] = React.useState<any>({
    name: "",
    description: "",
    logo: "",
    pdf: "",
    quantity: "",
    less_quantity: "",
    unit_id: "",
    type: Number(type),
    price_type: "",
    price: "",
    real_price: "",
    mazad_type: "",
    end_date: "",
    cat_id: "",
    sub_cat_id: "",
    masafa_price: "",
    status_id: "",
    user: "" as any,
    user_id: "",
    show_company: 1,
    images: [],
    tags: [],
    step_price: "",
    lot: "",
    parent_id: "",
    adds: [
      {
        key: "",
        value: "",
      },
    ],
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("اسم المنتج مطلوب"),
    unit_id: Yup.string().required("وحده القياس مطلوبه"),
    parent_id: Yup.string().notRequired(),  
    cat_id: Yup.string().required("التصنيف مطلوب"),
    status_id: Yup.string().required("حاله المنتج مطلوبه"),
    user_id: Yup.string().required("التاجر مطلوب"),
    quantity: Yup.number().required("الكميه مطلوبه"),
    less_quantity: Yup.number().required("اقل كميه مطلوبه"),
    price_type: Yup.string().required("تسعير المنتج مطلوب"),
    price: Yup.string().test("required-if-price-type-2", "سعر المنتج بعد الخصم مطلوب", function (value) {
      const priceType = this.resolve(Yup.ref("price_type"));

      if (priceType === "1") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
    step_price: Yup.string().notRequired().matches(/^\d+$/, "أقل سعر للمزايدة يجب ان يكون رقم"),
    mazad_type: Yup.string().test("required-if-price-type-2", "نوع المزاد مطلوب", function (value) {
      const priceType = this.resolve(Yup.ref("price_type"));
      if (priceType === "2") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
    end_date: Yup.string().test("required-if-mazad-type-1", "التاريخ  مطلوب", function (value) {
      const mazadType = this.resolve(Yup.ref("mazad_type"));
      if (mazadType === "1") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
  });

  const [loading, setLoading] = React.useState(false);
  const getSingleProduct = async () => {
    try {
      setLoading(true);
      await axios.get(`${process.env.REACT_APP_API_URL}products/single?product_id=${id}`).then((data) => {
        let tags = data?.data?.data?.tags.map((tag: any) => tag.id);
        console.log(data?.data?.data?.parent_id);
        setInitialValues({
          name: data?.data?.data?.name || "",
          description: data?.data?.data?.description || "",
          logo: data?.data?.data?.logo || "",
          pdf: data?.data?.data?.pdf || "",
          quantity: data?.data?.data?.quantity || "",
          unit_id: data?.data?.data?.unit?.id || "",
          type: data?.data?.data?.type || "",
          price_type: data?.data?.data?.price_type || "",
          start_price: data?.data?.data?.start_price || "",
          price: data?.data?.data?.price || "",
          real_price: data?.data?.data?.real_price || "",
          mazad_type: data?.data?.data?.mazad_type || "",
          end_date: data?.data?.data?.end_date || "",
          cat_id: data?.data?.data?.category?.id || "",
          address_id: data?.data?.data?.address?.id || "",
          masafa_price: data?.data?.data?.masafa_price,
          less_quantity: data?.data?.data?.less_quantity || "",
          status_id: data?.data?.data?.status?.id || "",
          user_id: data?.data?.data?.user?.id || "",
          user: data?.data?.data?.user || "",
          images: data?.data?.data?.images || "",
          tags: tags || [],
          product_id: id,
          show_company: data?.data?.data?.show_company || 1,
          step_price: data?.data?.data?.step_price || "",
          lot: data?.data?.data?.lot || "",
          parent_id: data?.data?.data?.parent_id || "",
          adds: (data?.data?.data?.adds.length > 0 && data?.data?.data?.adds) || [
            {
              key: "",
              value: "",
            },
          ],
        });
      });
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };
  const mainState = useSelector((state: any) => state.main);
  React.useEffect(() => {
    getSingleProduct();
  }, [id, mainState.refreshApi]);

  // add Client Modal
  const [openClientModal, setOpenClientModal] = useState(false);
  const handleOpenClientModal = () => setOpenClientModal(true);
  const handleCloseClientModal = () => setOpenClientModal(false);
  return (
    <div>
      <Header title={type === "1" ? "تعديل راكد" : type === "2" ? "تعديل خرده" : "تعديل مواد بناء"} />
      {loading ? (
        <MainLoader />
      ) : (
        <MainContainer>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, touched, isSubmitting }) => (
              <Form>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    columnGap: "1.25rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.25rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "primary.main",
                        fontWeight: "700",
                        fontSize: "1.375rem",
                        position: "relative",
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          bottom: "-4px",
                          left: "0",
                          width: "90px",
                          height: "2px",
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      تعديل المنتج
                    </Typography>
                    <StyledSubContainer>
                      <StyledTitle>صور المنتج</StyledTitle>
                      <Divider
                        sx={{
                          width: "calc(100% + 54px)",
                          marginLeft: "-27px",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "1.5rem",
                          }}
                        >
                          إضافة صورة
                        </Typography>
                        <Dropzone setFieldValue={setFieldValue} images={initialValues.images} />
                      </Box>
                      <Divider
                        sx={{
                          width: "calc(100% + 54px)",
                          marginLeft: "-27px",
                        }}
                      />
                      <Stack spacing={3}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            width: "400px",
                          }}
                        >
                          <Stack spacing={1}>
                            <Typography
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "400",
                              }}
                            >
                              إضافة ملف pdf للمنتج (المواصفات)
                            </Typography>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                              sx={{
                                width: "100px",
                                height: "80px",
                                border: "1px solid rgba(0, 0, 0, 0.08)",
                                borderRadius: "6px",
                              }}
                            >
                              <input
                                hidden
                                accept="application/pdf"
                                type="file"
                                onChange={(e) => {
                                  const files = e.target ? e.target.files : null;
                                  if (files) {
                                    setFieldValue("pdf", files[0]);
                                  }
                                }}
                              />
                              <img src={AddFileIcon} alt="add file icon" />
                            </IconButton>
                          </Stack>

                          {values.pdf instanceof File ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "24px",
                                  cursor: "pointer",
                                  fontWeight: "700",
                                }}
                                onClick={() => {
                                  const url = URL.createObjectURL(values.pdf);
                                  window.open(url, "_blank");
                                }}
                              >
                                {values?.pdf?.name?.split(".")[0] || "الملف"}
                              </Typography>
                              <Box
                                onClick={() => {
                                  setFieldValue("pdf", "");
                                }}
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  "& svg": {
                                    fontSize: "1.5rem",
                                    fill: "red",
                                  },
                                }}
                              >
                                <TiDeleteOutline />
                              </Box>
                            </Box>
                          ) : values?.pdf ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "24px",
                                  cursor: "pointer",
                                  fontWeight: "700",
                                }}
                                onClick={() => {
                                  window.open(values?.pdf, "_blank");
                                }}
                              >
                                {values?.pdf?.name?.split(".")[0] || "الملف"}
                              </Typography>
                              <Box
                                onClick={() => {
                                  setFieldValue("pdf", "");
                                }}
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  "& svg": {
                                    fontSize: "1.5rem",
                                    fill: "red",
                                  },
                                }}
                              >
                                <TiDeleteOutline />
                              </Box>
                            </Box>
                          ) : null}
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="flex-end"
                          justifyContent="space-between"
                          sx={{
                            width: "400px",
                          }}
                        >
                          <Stack spacing={1}>
                            <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>
                              إضافة صورة المنتج الرئيسية
                            </Typography>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                              sx={{
                                width: "100px",
                                height: "80px",
                                border: "1px solid rgba(0, 0, 0, 0.08)",
                                borderRadius: "6px",
                                fontSize: "3rem",
                              }}
                            >
                              <input
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={(e) => {
                                  const files = e.target ? e.target.files : null;
                                  if (files) {
                                    setFieldValue("logo", files[0]);
                                  }
                                }}
                              />
                              <BsImages />{" "}
                            </IconButton>
                          </Stack>

                          {values.logo instanceof File ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <Box
                                component={"img"}
                                sx={{
                                  width: "100px",
                                  height: "80px",
                                  cursor: "pointer",
                                }}
                                src={URL.createObjectURL(values?.logo)}
                                onClick={() => {
                                  const url = URL.createObjectURL(values?.logo);
                                  window.open(url, "_blank");
                                }}
                              />

                              <Box
                                onClick={() => {
                                  setFieldValue("logo", "");
                                }}
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  "& svg": {
                                    fontSize: "1.5rem",
                                    fill: "red",
                                  },
                                }}
                              >
                                <TiDeleteOutline />
                              </Box>
                            </Box>
                          ) : values?.logo ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <Box
                                component={"img"}
                                sx={{
                                  width: "100px",
                                  height: "80px",
                                  cursor: "pointer",
                                }}
                                src={values?.logo}
                                onClick={() => {
                                  window.open(values?.logo, "_blank");
                                }}
                              />

                              <Box
                                onClick={() => {
                                  setFieldValue("logo", "");
                                }}
                                sx={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  "& svg": {
                                    fontSize: "1.5rem",
                                    fill: "red",
                                  },
                                }}
                              >
                                <TiDeleteOutline />
                              </Box>
                            </Box>
                          ) : null}
                        </Stack>
                      </Stack>
                    </StyledSubContainer>
                  </Box>

                  <StyledSubContainer>
                    <StyledTitle>بينات المنتج :</StyledTitle>
                    <Divider
                      sx={{
                        width: "calc(100% + 54px)",
                        marginLeft: "-27px",
                      }}
                    />
                    <Stack
                      justifyContent="space-between"
                      spacing={3}
                      sx={{
                        margin: "1rem 0",
                        height: "100%",
                      }}
                    >
                      <CustomTextField label="اسم المنتج" name="name" />
                      <Stack direction="row" spacing={2}>
                        <CustomTextField label="نسبة مسافه" name="masafa_price" />

                        <SelectWithApi
                          placeholder="اختر من القائمه"
                          title="حاله المنتج"
                          name="status_id"
                          apiUrl="helper/get_status?model_name=product"
                          selectedValue={values?.status_id}
                          touched={touched}
                          errors={errors}
                        />
                      </Stack>
                      <CustomSelect
                        value={values?.price_type}
                        values={values}
                        placeholder="اختر من القائمه"
                        title="تسعير المنتج"
                        optionsProp={[
                          { value: 1, label: "سعر ثابت" },
                          { value: 2, label: "اعلي سعر" },
                        ]}
                        name="price_type"
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                      {String(values.price_type) === "1" && (
                        <Stack direction="row" spacing={2}>
                          <CustomTextField label="سعر المنتج الرئيسي" name="real_price" />
                          <CustomTextField label="سعر المنتج بعد الخصم" name="price" />
                        </Stack>
                      )}
                      {String(values.price_type) === "2" && (
                        <Stack direction="row" spacing={2}>
                          <Box
                            sx={{
                              flexGrow: 1,
                              width: "100%",
                            }}
                          >
                            <CustomSelect
                              placeholder="اختر من القائمه"
                              title="نوع المزاد"
                              optionsProp={[
                                { value: 1, label: "له تاريخ نهايه" },
                                { value: 2, label: "بدون تاريخ" },
                              ]}
                              name="mazad_type"
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              required
                              value={values?.mazad_type}
                            />
                          </Box>
                          <Stack direction="row" spacing={2}>
                            <CustomTextField label="أقل سعر للمزاد" name="start_price" />
                            <CustomTextField label="مقدار المزايدة" name="step_price" />
                          </Stack>{" "}
                          {String(values?.mazad_type) === "1" && (
                            <Box
                              sx={{
                                flexGrow: 1,
                                width: "100%",
                              }}
                            >
                              <MobileDateTimePicker
                                views={["month", "day", "hours", "minutes"]}
                                minDate={endOfYesterday()}
                                label={errors.end_date && touched.end_date ? " التاريخ مطلوب" : "تاريخ النهايه"}
                                onChange={(e: Date | null) => {
                                  if (e) {
                                    console.log(format(e, "yyyy-MM-dd HH:mm:ss"));
                                    setFieldValue("end_date", format(e, "yyyy-MM-dd HH:mm:ss"));
                                  }
                                }}
                                defaultValue={values?.end_date && new Date(values?.end_date)}
                                sx={{
                                  "& .MuiInputBase-root": {
                                    borderRadius: "10px",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: errors.end_date && touched.end_date ? "red" : "#EAEAEA",
                                    },
                                    borderColor: errors.end_date && touched.end_date ? "red" : "#EAEAEA",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: errors.end_date && touched.end_date ? "red" : "primary.main",
                                    "&.Mui-focused": {
                                      color: errors.end_date && touched.end_date ? "red" : "primary.main",
                                    },
                                  },
                                }}
                              />
                              {/* <DatePicker
                                disablePast
                                label="تاريخ النهايه"
                                value={values?.end_date && new Date(values?.end_date)}
                                onChange={(e: Date | null) => {
                                  if (e) {
                                    setFieldValue("end_date", format(e, "yyyy-MM-dd"));
                                  }
                                }}
                                sx={{
                                  "& .MuiInputBase-root": {
                                    borderRadius: "10px",
                                  },
                                }}
                              /> */}
                            </Box>
                          )}
                        </Stack>
                      )}
                      <Stack direction="row" alignItems="center" spacing={5}>
                        <CustomInputWithBtns title="الكمية المتاحة" name="quantity" setFieldValue={setFieldValue} />

                        <SelectWithApi
                          placeholder="اختر من القائمه"
                          title="وحده القياس"
                          name="unit_id"
                          apiUrl="unit/get"
                          selectedValue={values?.unit_id}
                          errors={errors}
                          touched={touched}
                        />
                      </Stack>{" "}
                      <CustomTextField label="اقل كميه للطلب" name="less_quantity" type="number" />
                      <CustomTextField label="وصف المنتج" multiLine={4} name="description" />
                      {catData && (
                        <Stack direction="row" spacing={2}>
                          <CustomSelect
                            placeholder="اختر من القائمه"
                            title="التصنيف"
                            name="cat_id"
                            optionsProp={catData.data.data.map((item: any) => {
                              return {
                                value: item.id,
                                label: item.name_ar,
                              };
                            })}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            value={values?.cat_id}
                          />
                          {values.cat_id &&
                            catData.data.data.find((item: any) => item.id === values.cat_id)?.cats.length > 0 && (
                              <CustomSelect
                                placeholder="اختر من القائمه"
                                title="التصنيف الفرعي"
                                name="sub_cat_id"
                                optionsProp={catData.data.data
                                  .find((item: any) => item.id === values.cat_id)
                                  ?.cats.map((cat: any) => {
                                    return {
                                      value: cat.id,
                                      label: cat.name_ar,
                                    };
                                  })}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                                value={values?.sub_cat_id}
                              />
                            )}
                        </Stack>
                      )}
                      <MultiSelectTags />
                      <Stack direction="row" spacing={4} alignItems="center">
                        <Button variant="outlined" fullWidth color="primary" onClick={handleOpenClientModal}>
                          تغيير عميل
                        </Button>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "400",
                            textDecoration: "underline",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            navigate(`/clients/all/${values?.user_id}`);
                          }}
                        >
                          {values?.user?.name}
                        </Typography>
                      </Stack>
                      <SelectAddress
                        name="address_id"
                        title="العنوان"
                        selectedValue={values?.address_id}
                        placeholder="اختر من القائمه"
                        errors={errors}
                        touched={touched}
                        userId={values?.user_id}
                      />
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Checkbox
                          checked={values.show_company === 1}
                          onChange={(e) => {
                            setFieldValue("show_company", e.target.checked ? 1 : 0);
                          }}
                          color="primary"
                        />
                        <Typography>إظهار معلومات الشركه</Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        sx={{
                          gap: "24px",
                          padding: "0 50px",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          type="submit"
                          disabled={isSubmitting}
                          endIcon={isSubmitting && <CircularProgress size={20} />}
                        >
                          تعديل المنتج
                        </Button>
                        <Button variant="outlined" color="primary" fullWidth onClick={handleOpen}>
                          إضافة بينات جديدة
                        </Button>
                      </Stack>
                    </Stack>
                  </StyledSubContainer>
                </Box>
                <AddClientToProductModal open={openClientModal} onClose={handleCloseClientModal} />
                <AddAdditionalClientInfo type="product" open={open} handleClose={handleClose} values={values} />
              </Form>
            )}
          </Formik>
        </MainContainer>
      )}
    </div>
  );
};

export default EditProduct;
