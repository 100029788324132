import { Form, Formik } from "formik";
import { CommonModalProps, MarkoonNumberItem } from "../../types";
import { CommonModal } from "./commonModal";
import { Box, Stack } from "@mui/material";
import CustomTextField from "../CustomTextField/CustomTextField";
import CustomSelect from "../CustomSelect/CustomSelect";
import { pageTypes } from "../../constants/pageTypes.constant";
import * as Yup from "yup";
interface MarkoonNumberModalProps extends CommonModalProps<MarkoonNumberItem> {
  item: MarkoonNumberItem | null;
  isEdit?: boolean;
}

export const MarkoonNumberModal: React.FC<MarkoonNumberModalProps> = ({
  open,
  handleClose,
  title,
  item,
  handleConfirm,
  isLoading,
  isEdit,
}) => {
  return (
    <Formik
      initialValues={{
        id: item?.id || null,
        comment_ar: item?.comment_ar ?? "",
        comment_en: item?.comment_en ?? "",
        number: item?.number ?? null,
        page: item?.page ?? 0,
        unit_ar: item?.unit_ar ?? "",
        unit_en: item?.unit_en ?? "",
      }}
      onSubmit={async (v) => {
        // @ts-ignore
        await handleConfirm(v);
      }}
      enableReinitialize={true}
    >
      {({ handleSubmit, resetForm, touched, errors, values, setFieldValue }) => (
        <CommonModal
          open={open}
          handleClose={() => {
            resetForm();
            handleClose();
          }}
          title={title}
          handleConfirm={() => {
            //@ts-ignore
            return handleSubmit()?.then(() => {
              resetForm();
            });
          }}
          isLoading={isLoading}
          width={"80%"}
          confirmButtonText={isEdit ? "تعديل" : undefined}
        >
          <Form>
            <Stack spacing={4}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <CustomTextField name="comment_ar" label="عنوان بالعربي " multiLine={1} />
                <CustomTextField name="comment_en" label="عنوان بالانجليزي " multiLine={1} />
                <CustomTextField name="unit_ar" label="الوحده بالعربي" multiLine={1} type="number" />
                <CustomTextField name="unit_en" label="الوحده بالانجليزيه" multiLine={1} type="number" />
                <CustomTextField name="number" label="الرقم" multiLine={1} type="number" />

                <CustomSelect
                  touched={touched}
                  errors={errors}
                  title="الصفحة"
                  placeholder="اختر نوع الصفحة"
                  name="page"
                  setFieldValue={setFieldValue}
                  optionsProp={[
                    { value: pageTypes.HOME, label: "الرئيسية" },
                    { value: pageTypes.ABOUT, label: "عن مركون" },
                  ]}
                  required
                  values={values}
                  value={values.page}
                />
              </Box>
            </Stack>
          </Form>
        </CommonModal>
      )}
    </Formik>
  );
};
