import { Box, IconButton, Stack, Switch, Typography } from "@mui/material";

import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

//  row component
interface CustomTableRowProps {
  id: number;
  name: string;
  name_ar: string;
  name_en: string;
  show_status: 0 | 1;
  bid: 0 | 1;
  show_name: 0 | 1;
  show_price: 0 | 1;
  show_countdown: 0 | 1;
  class: string;
  color: string;
  index: number;
}

const CustomTableRow = ({ id, index, name, color, show_price, show_name, show_countdown }: CustomTableRowProps) => {
  return (
    <StyledTableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
      }}
    >
      <StyledMainCell>{id}</StyledMainCell>
      <StyledMainCell>{name}</StyledMainCell>
      <StyledSubCell>{show_price === 1 ? <StyledSwitch disabled checked /> : <StyledSwitch disabled />}</StyledSubCell>
      <StyledSubCell>{show_name === 1 ? <StyledSwitch disabled checked /> : <StyledSwitch disabled />}</StyledSubCell>
      <StyledSubCell>
        {show_countdown === 1 ? <StyledSwitch disabled checked /> : <StyledSwitch disabled />}
      </StyledSubCell>
        <IconButton
          size="small"
          sx={{
            "& .MuiIconButton-root": {
              padding: "0px",
            },
            width: "20px",
            height: "20px",
            "& a": {
              color: "#34B44A",
            },
          }}
        >
          <Link
            to={`/product-status/${id}`}
            style={{
              padding: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineEye />
          </Link>
        </IconButton>
    </StyledTableRow>
  );
};

const StyledSwitch = styled(Switch)({
  "& .Mui-checked": {
    color: "#34B44A !important",
    "&.Mui-disabled": {
      color: "#34B44A !important",
    },
  },
  "& .Mui-disabled": {
    color: "red !important",
  },
});

// main component

interface adminTableProps {
  rows: {
    id: number;
    name: string;
    email: string;
    phone: string;
    status: any;
  }[];
}

export const ProductStatusTable = ({ rows }: adminTableProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader
          sx={{
            height: "63px",
            "& h6": {
              fontSize: "1.5rem",
              color: "#34B44A",
              fontWeight: "400",
              textAlign: "center",
            },
          }}
        >
          <h6>#</h6>
          <h6>اسم الحالة</h6>
          <h6>اظهار السعر</h6>
          <h6>اظهار اسم الحالة</h6>
          <h6>اظهار العد التنازلي</h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {rows?.map((row: any, index: number) => {
            return <CustomTableRow key={index} id={row.id} index={index} {...row} />;
          })}
        </Stack>
      </Box>
    </>
  );
};

//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: ".5fr repeat(4,1fr) .25fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: ".5fr repeat(4,1fr) .25fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledSubCell = styled(Typography)({
  color: "#000000",
  textAlign: "center",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});
