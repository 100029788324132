import { createProductStatus } from "../../../apis";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const useCreateStatus = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: createProductStatus,
    onSuccess: (data: any) => {
      if (data?.status === 1) {
        toast.success("تم إنشاء الحالة بنجاح");
        navigate("/product-status");
      }
    },
    onError: (e: any) => {
      console.log(e);
      toast.error(e?.response?.data?.message || "حدث خطأ ما");
    },
  });
};
