import { useMemo } from "react";
import AuctionsTable from "../../../components/Tables/AuctionsTable";
import useGetSingleApi from "../../../hooks/useGetSingleApi";
import { Stack, Typography, styled } from "@mui/material";

export const HotAuctions = () => {
  const auctionsApi = useMemo(() => `auction/get_products?hot=1&limit=7`, []);
  const { data, loading, error } = useGetSingleApi(auctionsApi, []);
  if (loading || error) return <></>;
  return (
    <StyledContainer
      sx={{
        gridColumn: "span 2",
      }}
    >
      <Stack spacing={2}>
        <Typography
          sx={{
            color: "primary.main",
            fontWeight: "700",
            fontSize: "1.25rem",
          }}
        >
          مزادات 🔥
        </Typography>
        {data?.data?.data && <AuctionsTable rows={data?.data?.data} />}
      </Stack>
    </StyledContainer>
  );
};

const StyledContainer = styled("div")({
  backgroundColor: "#fff",
  padding: "1.25rem 1.5rem",
  boxShadow: " 0px 4px 15px rgba(156, 156, 156, 0.25)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  flex: "1",
});
