import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import { deliveryActions } from "../../store/delivery-slice";
import { mainSliceActions } from "../../store/main-slice";
import CustomTextField from "../CustomTextField/CustomTextField";
type Props = {};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 4.75rem",
  width: "670px",
};

const DeliveryModal = ({}: Props) => {
  const dispatch = useDispatch();
  const deliveryState = useSelector((state: any) => state.delivery);

  const handleClose = () => {
    dispatch(deliveryActions.closeModal());
  };

  const submitApiCall = async (
    values: { order_id: string; delivery_price: string },
    actions: any
  ) => {
    try {
      const data = await postAxios("orders/change_delivery_price", values);
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success("تم وضع سعر التوصيل بنجاح");
        handleClose();
        dispatch(mainSliceActions.toggleRefreshApi());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={deliveryState.open}
        onClose={handleClose}
        aria-labelledby="modal-add"
        aria-describedby="modal-add"
      >
        <>
          <Formik
            initialValues={deliveryState.initialValues}
            onSubmit={(values, actions) => {
              submitApiCall(values, actions);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "2rem",
                        fontWeight: 700,
                        color: "#34B44A",
                        textAlign: "center",
                      }}
                    >
                      اضافه سعر التوصيل{" "}
                    </Typography>
                    <Box
                      sx={{
                        margin: "1rem 0",
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      <CustomTextField
                        label="سعر التوصيل"
                        required
                        name="delivery_price"
                        type="number"
                      />
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                          color: "#FFFFFF",
                          borderRadius: "6px",
                        }}
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={
                          isSubmitting ? <CircularProgress size={20} /> : null
                        }
                      >
                        حفظ{" "}
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "6px",
                          border: "2px solid #34B44A",
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        إلغاء
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default DeliveryModal;
