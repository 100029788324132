import { Divider, styled } from "@mui/material";

export const StyledSecondaryContainer = styled("div")({
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 10px rgba(181, 181, 181, 0.25)",
  borderRadius: "8px",
  padding: "1.25rem 1.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
});
export const StyledInfo = styled("h6")({
  fontSize: "1.25rem",
  fontWeight: "700",
  color: "#000000",
});
export const StyledDivider = styled(Divider)({
  width: "calc(100% + 3rem)",
  marginLeft: "-1.5rem",
});
