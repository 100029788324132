import { Box, Button, Checkbox, FormControlLabel, Modal, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  handleClose: any;
  updateCats?: boolean;
  allCats: any;
  myCats?: any;
  filter?: boolean;
  handleSubmitFilter?: (values: number[]) => void;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: {
    xs: "8px",
    md: "16px",
    lg: "24px",
  },
  boxShadow: 24,
  p: 6,
  minWidth: {
    xs: "90vw",
    sm: "80vw",
    md: "70vw",
  },
  overflow: "auto",
  maxHeight: "90vh",
  maxWidth: "90vw",
};

const ClientInterestsModal = ({ open, handleClose, allCats, myCats, filter = false, handleSubmitFilter }: Props) => {
  const { setFieldValue } = useFormikContext<any>();

  // Initialize `selectedCats` state
  const [selectedCats, setSelectedCats] = useState<any[]>([]);

  // Ensure data is not undefined
  const categories = allCats ?? [];

  // Check if all categories are selected
  const isAllSelected = useMemo(() => {
    return categories?.length > 0 && selectedCats?.length === categories.length;
  }, [categories, selectedCats]); // Check if all categories are selected

  // // Function to handle selecting all categories
  // const handleSelectAll = (selectAll: boolean) => {
  //   if (selectAll) {
  //     setSelectedCats(allCats?.map((item: any) => item.id));
  //   } else {
  //     setSelectedCats([]);
  //   }
  // };

  useEffect(() => {
    if (myCats && myCats.length > 0) {
      setSelectedCats(myCats);
    }
  }, [myCats]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        "& .MuiPaper-root": {
          overflow: "scroll",
        },
      }}
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-select-cats"
          variant="h6"
          component="h2"
          sx={{
            textAlign: "center",
            textDecoration: "underline",
            fontWeight: "700",
            fontSize: {
              xs: "1.5rem",
              md: "2rem",
            },
            color: "primary.main",
          }}
        >
          اهتمامات العميل
        </Typography>

        <Stack
          direction="column"
          gap="1.5rem"
          sx={{
            padding: {
              xs: ".5rem 0",
              md: "2rem 0rem",
            },
            position: "relative",
          }}
        >
          {/* <FormControlLabel
            sx={{
              position: "absolute",
              top: {
                xs: ".5rem",
                md: "2rem",
              },
              right: "0",
              fontSize: {
                xs: ".75rem",
                md: "1rem",
              },
            }}
            control={<Checkbox checked={isAllSelected || false} onChange={(e) => handleSelectAll(e.target.checked)} />}
            label={"اختيار الكل"}
          /> */}
          {/* three categories */}
          <SingleCategoryContainer
            data={allCats?.filter((item: any) => item.type === 1)}
            type="1"
            setSelectedCats={setSelectedCats}
            selectedCats={selectedCats}
          />
          <SingleCategoryContainer
            data={allCats?.filter((item: any) => item.type === 2)}
            type="2"
            setSelectedCats={setSelectedCats}
            selectedCats={selectedCats}
          />
          {/* {mainState?.webSettings?.construction_materials === 1 && (
            <SingleCategoryContainer
              data={allCats?.data?.data?.data?.filter(
                (item: any) => item.type === 3
              )}
              type="3"
              setSelectedCats={setSelectedCats}
              selectedCats={selectedCats}
            />
          )} */}
        </Stack>
        {/* )} */}
        <Stack
          direction="row"
          gap="1rem"
          sx={{
            width: "100%",
            justifyContent: "center",
            button: {
              minWidth: {
                xs: "100px",
                sm: "150px",
                md: "200px",
              },
            },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (filter && handleSubmitFilter) {
                handleSubmitFilter(selectedCats);
                handleClose();
                return;
              }
              setFieldValue("cats", selectedCats);
              handleClose();
            }}
            sx={{
              color: "white",
            }}
          >
            تأكيد
          </Button>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            الغاء{" "}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default React.memo(ClientInterestsModal);

type SingleCategoryContainerProps = {
  data: any;
  type: "1" | "2" | "3";
  selectedCats: any[];
  setSelectedCats: any;
};

const SingleCategoryContainer = ({ data, type, setSelectedCats, selectedCats }: SingleCategoryContainerProps) => {
  const { t } = useTranslation();
  //   const mainState = useSelector((state: any) => state.main);

  //   if (mainState.websiteSettings.construction_materials === 0 && type === "3")
  //     return null;
  return (
    <Stack>
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "1.625rem",
          color: "primary.main",
        }}
      >
        {type === "1" ? "رواكد" : "خردة"}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          },
          gap: {
            xs: ".5rem",
            md: "1rem",
          },
          position: "relative",
          padding: "0 1rem",
        }}
      >
        {data?.map((item: any) => (
          <SingleCategory key={item.id} {...item} setSelectedCats={setSelectedCats} selectedCats={selectedCats} />
        ))}
      </Box>
    </Stack>
  );
};

const SingleCategory = ({
  name,
  id,
  setSelectedCats,
  selectedCats,
}: {
  name: string;
  id: number;
  setSelectedCats: any;
  selectedCats: any[];
}) => {
  // Function to toggle an individual category
  const toggleCategory = (id: number) => {
    setSelectedCats((prev: any) => (prev.includes(id) ? prev.filter((item: any) => item !== id) : [...prev, id]));
  };
  return (
    <Stack direction="row" gap="1rem" alignItems="center">
      <FormControlLabel
        control={<Checkbox checked={selectedCats.includes(id)} onChange={() => toggleCategory(id)} />}
        label={name}
      />
    </Stack>
  );
};
