import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";
import TableLoader from "../../components/Loaders/TableLoader";
import ClientsTypesTable from "../../components/Tables/ClientsTypesTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { modalActions } from "../../store/modal-slice";

type Props = {};

const ClientsTypes = (props: Props) => {
  const mainState = useSelector((state: any) => state.main);
  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  const dispatch = useDispatch();
  const usersTypesApiUrl = useMemo(() => "userTypes/get", []);
  const { data, loading, error } = useGetSingleApi(usersTypesApiUrl, dependencies);

  return (
    <div>
      <Header title="أنواع العملاء" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            أنواع العملاء الرئيسية
          </Typography>
          <OutlinedButton
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  title: "clients",
                  type: "add",
                  apiUrl: "userTypes/create",
                  initialValues: {
                    title_ar: "",
                    title_en: "",
                    show_price: "",
                  },
                })
              );
            }}
            endIcon={<IoMdAdd />}
            sx={{
              width: "200px",
            }}
          >
            اضافة نوع جديد
          </OutlinedButton>
        </Stack>
        {loading ? (
          <TableLoader />
        ) : error ? (
          <></>
        ) : (
          data?.data?.data.length > 0 && <ClientsTypesTable data={data.data.data} />
        )}
      </MainContainer>
    </div>
  );
};

export default ClientsTypes;
