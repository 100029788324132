import { createAdmin } from "../../../apis";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const useCreateAdmin = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: createAdmin,
    onSuccess: (data) => {
      if (data?.status === 1) {
        toast.success("تم إنشاء المستخدم بنجاح");
        navigate("/admins");
      }
    },
    onError: (e: any) => {
      console.log(e);
      toast.error(e?.response?.data?.message || "حدث خطأ ما");
    },
  });
};
