import axios from "axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

export const useSendMessage = () => {
  const [openMessage, setOpenMessage] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);
  const handleOpenMessage = () => setOpenMessage(true);
  const sendMessage = async (
    values: any
    // setSubmitting: any,
    // resetForm: any
  ) => {
    const formData = new FormData();

    const ids = values.checked.join(",");

    formData.append("user_id", ids);
    formData.append("msg", values?.message || "");
    // setSubmitting(true);
    const data = await axios.post("user/send_msg", formData);

    return data?.data?.data;

    // try {
    //   if (data.data.status !== 1) {
    //     toast.error(data.data.message);
    //   } else {
    //     toast.success(data.data.message);
    //     // resetForm();
    //     handleCloseMessage();
    //   }
    // } catch (error: any) {
    //   toast.error(error?.response?.data?.message);
    // } finally {
    //   //   setSubmitting(false);
    // }
  };

  const mutation = useMutation({
    mutationFn: sendMessage,
    onSuccess: (data) => {
      if (data.data.status !== 1) {
        toast.error(data.data.message);
        handleCloseMessage();
      } else {
        toast.success(data.data.message);
        // resetForm();
        handleCloseMessage();
      }
    },
    onError: (e: any) => {
      toast.error(e?.response?.data?.message);
      handleCloseMessage();
    },
  });

  return { mutation, openMessage, handleOpenMessage, handleCloseMessage };
};
