import { useEffect, useState } from "react";

import MainLoader from "../../../components/Loaders/MainLoader";
import { ProductStatusForm } from "./productStatusForm";
import { getSingleStatus } from "../../../apis";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useUpdateStatus } from "../hooks";

export const EditProductStatus = () => {
  const { mutate, isPending } = useUpdateStatus();
  const { id } = useParams();
  const { data, isPending: isPendingAdmin } = useQuery({
    queryKey: ["product-status-single", id],
    queryFn: getSingleStatus,
  });
  const [initialValues, setInitialValues] = useState({
    name_ar: "",
    name_en: "",
    show_status: "1",
    bid: "0",
    show_name: "0",
    show_price: "0",
    show_countdown: "0",
    show_price_list: "0",
    show_price_for_seller: "0",
    send_message: "0",
    class: "",
    color: "",
    status_id: id,
    button_ar: "",
    button_en: "",
    msg_all_less_price_when_change: "0",
    is_pay_conditions_document: "0",
  });

  useEffect(() => {
    if (data) {
      setInitialValues({
        name_ar: data?.name_ar,
        name_en: data?.name_en,
        show_status: data?.show_status,
        bid: data?.bid,
        show_name: data?.show_name,
        show_price: data?.show_price,
        show_countdown: data?.show_countdown,
        class: data?.class,
        color: data?.color || "",
        status_id: id,
        button_ar: data?.button_ar || "",
        button_en: data?.button_en || "",
        show_price_list: data?.show_price_list,
        show_price_for_seller: data?.show_price_for_seller,
        send_message: data?.send_message,
        msg_all_less_price_when_change: data?.msg_all_less_price_when_change,
        is_pay_conditions_document: data?.is_pay_conditions_document,
      });
    }
  }, [data, id]);
  if (isPendingAdmin) return <MainLoader />;
  return (
    <ProductStatusForm
      initialValues={initialValues}
      loading={isPending}
      handleSubmit={mutate}
      edit
    />
  );
};
