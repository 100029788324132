import { Stack, Typography } from "@mui/material";

import ContactUsTable from "../../components/Tables/ContactUsTable";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import Header from "../../components/Header/Header";
import MainContainer from "../../components/Containers/MainContainer";
import TableLoader from "../../components/Loaders/TableLoader";
import queryString from "query-string";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";

type Props = {};

const ContactUs = (props: Props) => {
  const mainState = useSelector((state: any) => state.main);
  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  const location = useLocation();
  const query = queryString.parse(location.search);
  const contactApiUrl: string = useMemo(() => `contact/get?page=${query.page || "1"}`, [query.page]);
  const { data, loading, error } = useGetSingleApi(contactApiUrl, dependencies);

  return (
    <div>
      <Header title="الرسائل" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            رسائل الزوار
          </Typography>
        </Stack>
        {loading ? (
          <TableLoader />
        ) : error ? (
          <></>
        ) : (
          data?.data?.data.length > 0 && <ContactUsTable rows={data?.data?.data} />
        )}
        {data?.data?.pagination?.total_pages > 1 && <CustomPagination totalPages={data.data.pagination.total_pages} />}
      </MainContainer>
    </div>
  );
};

export default ContactUs;
