import { Divider, Stack } from "@mui/material";
import React from "react";
import messagesIcon from "../../assets/images/icons/messagesIcon.svg";
import NotificationMenu from "../Notifications/NotificationMenu";
import ProfileMenu from "../ProfileMenu/ProfileMenu";

type Props = {};

const HeaderActions = (props: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={4}
      divider={<Divider orientation="vertical" />}
      sx={{
        direction: "rtl",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={4}
        sx={{
          "& img": {
            cursor: "pointer",
          },
        }}
      >
        <ProfileMenu />

        <img src={messagesIcon} alt="Messages" />
      </Stack>

      <NotificationMenu />
    </Stack>
  );
};

export default React.memo(HeaderActions);
