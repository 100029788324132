import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Drawer from "../Drawer/Drawer";
import styles from "./Layout.module.scss";

type Props = {
  children?: React.ReactNode;
};

const Layout = (props: Props) => {
  return (
    <div className={styles.layout}>
      <Drawer />

      <main className={styles.main}>
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "95%",
          }}
        >
          <Outlet />{" "}
        </Container>
      </main>
    </div>
  );
};

export default Layout;
