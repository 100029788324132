import axios from "axios";
import { AboutDto } from "../types";

export const getAboutMrkoon = async () => {
  try {
    const response = await axios.get(`about/get`);
    return response?.data as {
      data: AboutDto;
    };
  } catch (error) {
    console.log("error", error);
  }
};

export const updateAbout = async (values: AboutDto) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      //   if (key === "always_image" && typeof values[key] === "string") {
      //     continue;
      //   }

      //   @ts-ignore
      formData.append(key, values[key]);
    }
    const response = await axios.post(`about/update`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};
