import { useEffect, useState } from "react";

import { ADMINS_ROLES } from "../../../constants/admins.constants";
import { AdminsForm } from "./adminsForm";
import MainLoader from "../../../components/Loaders/MainLoader";
import { getAdmin } from "../../../apis";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useUpdateAdmin } from "../hooks";

export const EditAdmin = () => {
  const { mutate, isPending } = useUpdateAdmin();
  const { id } = useParams();
  const { data, isPending: isPendingAdmin } = useQuery({
    queryKey: ["admin-single", id],
    queryFn: getAdmin,
  });
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    status: "",
    admin_id: id,
    admins_control: "0",
    website_settings: "0",
    visitors_messages: "0",
    clients: "0",
    products: "0",
    auctions: "0",
  });

  useEffect(() => {
    if (data) {
      setInitialValues({
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        password: "",
        status: data?.status,
        admin_id: id,
        admins_control: data?.roles?.includes(String(ADMINS_ROLES.ADMIN_CONTROL)) ? "1" : "0",
        website_settings: data?.roles?.includes(String(ADMINS_ROLES.WEBSITE_SETTINGS)) ? "1" : "0",
        visitors_messages: data?.roles?.includes(String(ADMINS_ROLES.VISITORS_MESSAGES)) ? "1" : "0",
        clients: data?.roles?.includes(String(ADMINS_ROLES.CLIENTS)) ? "1" : "0",
        products: data?.roles?.includes(String(ADMINS_ROLES.PRODUCTS)) ? "1" : "0",
        auctions: data?.roles?.includes(String(ADMINS_ROLES.AUCTIONS)) ? "1" : "0",
      });
    }
  }, [data, id]);
  if (isPendingAdmin) return <MainLoader />;
  return <AdminsForm initialValues={initialValues} loading={isPending} handleSubmit={mutate} edit />;
};
