import { Button, Stack } from "@mui/material";
import { CommonModalProps } from "../../types";
import { CommonModal } from "./commonModal";
import { BiPlus } from "react-icons/bi";
import { ChangeEventHandler, useState } from "react";

interface AddPartnerModalProps extends CommonModalProps<File> {}

export const AddPartnerModal: React.FC<AddPartnerModalProps> = ({
  open,
  handleClose,
  handleConfirm,
  title,
  isLoading,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const handleImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.target?.files?.[0];
    setSelectedFile(file);
    if (file) {
      setImageUrl(URL.createObjectURL(file));
    }
  };
  return (
    <CommonModal
      handleClose={() => {
        setSelectedFile(undefined);
        setImageUrl(undefined);
        handleClose();
      }}
      open={open}
      handleConfirm={() => {
        handleConfirm(selectedFile!);
        setSelectedFile(undefined);
        setImageUrl(undefined);
      }}
      title={title}
      isLoading={isLoading}
    >
      <Stack
        gap={"1rem"}
        alignItems={"center"}
        justifyContent={"center"}
        mb={"1rem"}
      >
        <label htmlFor="fileInput">
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              height: "45px",
              width: "200px",
              color: "#FFFFFF",
              borderRadius: "6px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            component="span"
            disabled={isLoading}
            startIcon={<BiPlus size={24} />}
          >
            إضافة صورة
          </Button>
        </label>
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          style={{
            display: "none",
          }}
          disabled={isLoading}
          onChange={handleImageChange}
        />
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Selected"
            style={{
              maxWidth: "100%",
              maxHeight: "200px",
              marginTop: "1rem",
            }}
          />
        )}
      </Stack>
    </CommonModal>
  );
};
