import axios from "axios";

export const getInterestedClients = async (props: {
  pageParam: number | false;
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(
    `products/getMatchMakingUsers?product_id=${props.queryKey[1]}&search_text=${props.queryKey[2]}&bid=${props.queryKey[3]}&page=${props.pageParam}`
  );
  return response?.data?.data?.data;
};
export const getClients = async (props: {
  pageParam: number | false;
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(
    `user/get?status=${props.queryKey[1] || ""}&user_type_id=${props.queryKey[2] || ""}&user_type_parent_id=${
      props.queryKey[3] || ""
    }&search_text=${props.queryKey[4] || ""}&cat_id=${props.queryKey[5] || ""}&rate=${props.queryKey[6] || ""}&bid=${
      props.queryKey[7] || ""
    }&page=${props.pageParam || ""}`
  );
  return response?.data?.data as {
    data: any[];
    pagination: {
      total: number;
    };
  };
};

export const getClientsTypes = async () => {
  const response = await axios.get("userTypes/get");
  return response?.data?.data;
};
