import * as Yup from "yup";

//auth validation

export const loginValidationSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email("Please Enter a valid name")
  //   .required("Please Enter Your Email"),
  phone: Yup.string().required("Please Enter Your Phone"),
  password: Yup.string().required("Please Enter Your Password"),
});

export const otpValidationSchema = Yup.object().shape({
  code: Yup.string().required("Please Enter Your OTP"),
});

export const newPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required("Please Enter Your Password").min(8, "Password must be at least 8 characters"),
});

//main info validation

export const cityValidationSchema = Yup.object().shape({
  //only in arabic
  name_ar: Yup.string().required("Name in Arabic"),
  name_en: Yup.string().required("Name in English"),
});
export const unitValidationSchema = Yup.object().shape({
  name_ar: Yup.string().required("Unit Name in Arabic"),
  name_en: Yup.string().required("Unit Name in English"),
});

// client validation

export const clientValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please Enter Your Name"),
  phone: Yup.string().required("Please Enter Your Phone"),
  // company_name: Yup.string().required("Please Enter Your Company Name"),
  password: Yup.string().required("Please Enter Your Password").min(8, "Password must be at least 8 characters"),
});
export const editClientValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please Enter Your Name"),
  phone: Yup.string().required("Please Enter Your Phone"),
  // company_name: Yup.string().required("Please Enter Your Company Name"),
});

export const clientTypeValidationSchema = Yup.object().shape({
  //only in arabic
  title_ar: Yup.string().required("title in Arabic"),
  title_en: Yup.string().required("Name in English"),
});

// product percentage validation

export const productPercentageValidationSchema = Yup.object().shape({
  masafa_price: Yup.number().required("Please Enter Your Masafa Percentage"),
});
