import { IconButton, Stack, Typography } from "@mui/material";
import { MdEditNotifications } from "react-icons/md";
import { useDispatch } from "react-redux";
import { messageModalActions } from "../../../store/message-modal-slice";

type Props = {
  id: number;
  name: string;
  label?: string;
};

export const SendMessageToClient: React.FC<Props> = ({ id, name, label }) => {
  const dispatch = useDispatch();
  return (
    <Stack
      justifyContent="flex-start"
      width={label ? "100%" : "auto"}
      direction="row"
      alignItems="center"
      spacing={1}
      onClick={() => {
        dispatch(
          messageModalActions.openModal({
            userId: String(id),
            userName: name,
          })
        );
      }}
    >
      <IconButton
        size="small"
        sx={{
          padding: "0px",
          "& svg": {
            fill: "gray",
            // width: "24px",
            // height: "24px",
          },
        }}
      >
        <MdEditNotifications />
      </IconButton>
      {label && <Typography variant="caption">{label}</Typography>}
    </Stack>
  );
};
