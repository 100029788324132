import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from "date-fns";
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";

import { IRealTimeAuction } from "../types";
import auctionVoice from "../assets/auction_voice.wav";
import { db } from "../Services/firebase";

export const useRealTimeAuctions = (productId: string) => {
  const [auctions, setAuctions] = useState<IRealTimeAuction[]>([]);

  const [highestBid, setHighestBid] = useState<Number>(0);

  //   real time database
  const auctionsRef = ref(db, `auctions/product_${productId}`);

  const playSound = () => {
    const audio = new Audio(auctionVoice);
    audio.play();
  };

  useEffect(() => {
    onValue(auctionsRef, (snapshot) => {
      playSound();
      // sort by price

      const data = snapshot.val();
      if (data) {
        // playSound();
        const newAuctions: IRealTimeAuction[] = [];

        Object.keys(data).forEach((key) => {
          const auction = data[key];

          const newAuction = {
            buyer_name: auction.buyer_name,
            buyer_phone: auction.buyer_phone,
            buyer_price: auction.buyer_price,
            seller_price: auction.seller_price,
            created_at: formatTimeForAuction(auction.created_at),
            id: auction.id,
            buyer_id: auction.buyer_id,
            quantity: auction.quantity,
          };
          newAuctions.push(newAuction);
        });

        setAuctions(newAuctions.sort((a, b) => Number(b.buyer_price) - Number(a.buyer_price)));
        setHighestBid(Number(newAuctions[0].buyer_price));
      }
    });
  }, []);

  return { auctions, highestBid };
};

const formatTimeForAuction = (date: string): string => {
  let formattedTime = "الأن";

  const timeDifferenceInHours = differenceInHours(Date.now(), new Date(date));
  const timeDifferenceInMinutes = differenceInMinutes(Date.now(), new Date(date));
  const timeDifferenceInSeconds = differenceInSeconds(Date.now(), new Date(date));
  const timeDifferenceInDays = differenceInDays(Date.now(), new Date(date));

  if (timeDifferenceInDays > 1) {
    formattedTime = `منذ ${timeDifferenceInDays} يوم`;
    return formattedTime;
  }

  if (timeDifferenceInHours > 1) {
    formattedTime = `منذ ${timeDifferenceInHours} ساعة`;
    return formattedTime;
  }
  if (timeDifferenceInMinutes && timeDifferenceInMinutes > 1) {
    formattedTime = `منذ ${timeDifferenceInMinutes} دقيقة`;
    return formattedTime;
  }
  if (timeDifferenceInSeconds && timeDifferenceInSeconds > 1) {
    formattedTime = `منذ ${timeDifferenceInSeconds} ثانيه`;
    return formattedTime;
  }

  return formattedTime;
};
