import { Stack, Typography } from "@mui/material";
import MainContainer from "../Containers/MainContainer";
import Header from "../Header/Header";
import MainLoader from "../Loaders/MainLoader";
import { InformationForm } from "./informationForm.component";
import { useGetInfo } from "../../hooks/information/useGetInfo";

export const InformationContent: React.FC = () => {
  const { data, isPending } = useGetInfo();
  return (
    <div>
      <Header title="معلومات الموقع" />
      <MainContainer>
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: "700",
            fontSize: "1.375rem",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "2px",
              left: "0",
              width: "90px",
              height: "2px",
              backgroundColor: "primary.main",
            },
          }}
        >
          معلومات الموقع
        </Typography>
        <Stack>{isPending ? <MainLoader /> : <InformationForm initialValues={data?.data} />}</Stack>
      </MainContainer>
    </div>
  );
};
