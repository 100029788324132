import { createSlice } from "@reduxjs/toolkit";

interface unitType {
  id: string;
  name_ar: string;
  name_en: string;
}

interface initialStateType {
  cities: unitType[];
  refresh: boolean;
  unitModal: boolean;
  addNewUnitValues: {
    name_ar: string;
    name_en: string;
    unit_id?: string;
  };
}

const initialState: initialStateType = {
  cities: [],
  refresh: false,
  unitModal: false,
  addNewUnitValues: {
    name_ar: "",
    name_en: "",
  },
};

const unitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    addUnit(state, action) {
      state.cities = action.payload;
    },
    toggleRefresh(state) {
      state.refresh = !state.refresh;
    },
    openUnitModal(state) {
      state.unitModal = true;
    },
    closeUnitModal(state) {
      state.unitModal = false;
    },
    editUnit(state, action) {
      state.addNewUnitValues = action.payload;
    },
    addNewUnit(state) {
      state.addNewUnitValues = {
        name_ar: "",
        name_en: "",
      };
    },
  },
});

export const unitActions = unitSlice.actions;
export default unitSlice;
