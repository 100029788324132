import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { postAxios } from "../../Services/axios-services";
import { messageModalActions } from "../../store/message-modal-slice";
import CustomTextField from "../CustomTextField/CustomTextField";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 4.75rem",
  width: "670px",
};

const MessageModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: any) => state.messageModal);

  const handleClose = () => {
    dispatch(messageModalActions.closeModal());
  };

  const submitApiCall = async (values: { msg: string; user_id: string }, actions: any) => {
    try {
      const data = await postAxios("user/send_msg", values);
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success("تم ارسال الرسالة !");
        handleClose();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    msg: Yup.string().required("هذا الحقل مطلوب"),
  });

  return (
    <>
      <Modal open={modalState.isOpen} onClose={handleClose} aria-labelledby="modal-add" aria-describedby="modal-add">
        <>
          <Formik
            initialValues={{
              msg: "",
              user_id: modalState.userId,
            }}
            onSubmit={(values, actions) => {
              submitApiCall(values, actions);
            }}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "2rem",
                        fontWeight: 700,
                        color: "#34B44A",
                        textAlign: "center",
                      }}
                    >
                      {`أرسل رسالة  (${modalState?.userName})`}
                    </Typography>
                    <Box
                      sx={{
                        margin: "1rem 0",
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      <CustomTextField name="msg" label="الرسالة" type="text" multiLine={5} />
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                          color: "#FFFFFF",
                          borderRadius: "6px",
                        }}
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                      >
                        {"أرسل"}
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "6px",
                          border: "2px solid #34B44A",
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        إلغاء
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default MessageModal;
