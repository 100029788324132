import { createSlice } from "@reduxjs/toolkit";

interface cityType {
  id: string;
  name_ar: string;
  name_en: string;
}

interface initialStateType {
  cities: cityType[];
  refresh: boolean;
  cityModal: boolean;
  addNewCityValues: {
    name_ar: string;
    name_en: string;
    city_id?: string;
  };
}

const initialState: initialStateType = {
  cities: [],
  refresh: false,
  cityModal: false,
  addNewCityValues: {
    name_ar: "",
    name_en: "",
  },
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    addCity(state, action) {
      state.cities = action.payload;
    },
    toggleRefresh(state) {
      state.refresh = !state.refresh;
    },
    openCityModal(state) {
      state.cityModal = true;
    },
    closeCityModal(state) {
      state.cityModal = false;
    },
    editCity(state, action) {
      state.addNewCityValues = action.payload;
    },
    addNewCity(state) {
      state.addNewCityValues = {
        name_ar: "",
        name_en: "",
      };
    },
  },
});

export const cityActions = citySlice.actions;
export default citySlice;
