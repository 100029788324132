import { Stack, Typography, styled } from "@mui/material";
import { useMemo } from "react";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";
import TableLoader from "../../components/Loaders/TableLoader";
import SubCategoryTable from "../../components/Tables/SubCategoryTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { modalActions } from "../../store/modal-slice";

type Props = {
  new?: boolean;
};

const RawakdSubCategory = (props: Props) => {
  const { id } = useParams();

  const subApiUrl = useMemo(() => `categories/single?cat_id=${id}`, []);
  const mainState = useSelector((state: any) => state.main);
  const dependencies = useMemo(
    () => [mainState.refreshApi],
    [mainState.refreshApi]
  );
  const dispatch = useDispatch();

  const { data, loading } = useGetSingleApi(subApiUrl, dependencies);

  return (
    <div>
      <Header title="الفئات" />

      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <StyledTypography variant="h6">{"الفئات الفرعية"}</StyledTypography>
          <OutlinedButton
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  type: "add",
                  apiUrl: "categories/create",
                  initialValues: {
                    name_ar: "",
                    name_en: "",
                    type: 1,
                    parent_id: Number(id),
                  },
                })
              );
            }}
            endIcon={<IoMdAdd />}
          >
            اضافة فئة
          </OutlinedButton>
        </Stack>
        {loading ? (
          <TableLoader />
        ) : data ? (
          <>
            <SubCategoryTable data={data.data.cats} type="cat" />
          </>
        ) : null}{" "}
      </MainContainer>
    </div>
  );
};

export default RawakdSubCategory;

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "700",
  fontSize: "1.375rem",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "8px",
    left: "0",
    width: "90px",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
  },
}));
