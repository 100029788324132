import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";

import CustomTextField from "../CustomTextField/CustomTextField";
import { useEffect } from "react";
import { useFormikContext } from "formik";

type Props = {
  handleOpen: () => void;
  handleClose: () => void;
  open: boolean;
  loading?: boolean;
};

const AuctionMessagesModal = ({ handleOpen, handleClose, open, loading = false }: Props) => {
  const { values, handleSubmit, resetForm } = useFormikContext<{
    checked: number[];
    message: string;
  }>();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    backgroundColor: "#FFFF",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
    borderRadius: "10px",
    padding: "1rem",
    width: "400px",
    display: "flex",
    flexDirection: "column",
  };
  useEffect(() => {
    resetForm();
  }, [loading]);
  return (
    <>
      <Button sx={{ alignSelf: "flex-end" }} disabled={values.checked.length === 0} onClick={handleOpen}>
        ارسل رسالة
      </Button>
      <Modal open={open} aria-labelledby="modal-modal-Address" aria-describedby="modal-modal-add-address">
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "700",
                color: "#34B44A",
                textAlign: "center",
                marginBottom: ".25rem",
                textDecoration: "underline",
              }}
            >
              محتوي الرسالة
            </Typography>
            <CustomTextField name="message" label="الرسالة" multiLine={4} />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                type="submit"
                color="primary"
                disabled={loading}
                endIcon={loading ? <CircularProgress size={10} /> : null}
              >
                تأكيد
              </Button>
              <Button onClick={handleClose}>الغاء</Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AuctionMessagesModal;
