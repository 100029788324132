import { Box, Button, Grid, Modal, Stack, Typography } from "@mui/material";
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import CustomTextField from "../CustomTextField/CustomTextField";
import OutlinedButton from "../Buttons/OutlinedButton";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import StyledIconButton from "../Buttons/StyledIconButton";
type Props = {
  open: boolean;
  handleClose: () => void;
  type?: "product" | "client";
  setFieldValue?: any;
  values?: any;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 1.5rem",
  width: "auto",
};

const AddAdditionalClientInfo = ({ open, handleClose, type, setFieldValue, values }: Props) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.125rem",
                textAlign: "center",
                color: "#34B44A",
                fontWeight: 700,
              }}
            >
              {type === "product" ? "إضافة معلومات إضافية للمنتج" : " إضافة بينات للعميل"}{" "}
            </Typography>
            {/* <Formik
              initialValues={{ adds: [{ key: "", value: "" }] }}
              onSubmit={(values) => {
                // [{"key":"note","value":"my note"},{"key":"note","value":"my note"}]
                //stringify  the objects inside the array
                const adds = values.adds.map((item: any) => {
                  return JSON.stringify(item);
                });
                
        
              }}
            >
              {({ values }) => (
                <Form> */}
            <FieldArray name="adds">
              {({ push, remove }) => (
                <>
                  <Stack spacing={3}>
                    {values.adds.map((item: any, index: number) => (
                      <Stack
                        key={index}
                        direction="row"
                        sx={{
                          gap: "1rem",
                        }}
                      >
                        <Stack spacing={2}>
                          <Stack direction="row" spacing={2}>
                            <CustomTextField label="الاسم بالعربيه" name={`adds[${index}].key_ar`} />
                            <CustomTextField label="القيمة بالعربيه" name={`adds[${index}].value_ar`} />
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <CustomTextField label="الاسم بالانجليزيه" name={`adds[${index}].key_en`} />
                            <CustomTextField label="القيمة بالانجليزيه" name={`adds[${index}].value_en`} />
                          </Stack>
                        </Stack>

                        <Box
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            gap: ".5rem",
                            width: 150,
                          }}
                        >
                          <StyledIconButton
                            variant="outlined"
                            onClick={() =>
                              push({
                                key_ar: "",
                                value_ar: "",
                                key_en: "",
                                value_en: "",
                              })
                            }
                          >
                            <IoMdAdd />{" "}
                          </StyledIconButton>
                          {index !== 0 && (
                            <StyledIconButton
                              sx={{
                                svg: {
                                  color: "#D90009",
                                },
                                "&:hover": {
                                  backgroundColor: "#F8F8F8",
                                  svg: {
                                    color: "#D90009",
                                  },
                                },
                              }}
                              color="error"
                              variant="outlined"
                              onClick={() => remove(index)}
                            >
                              <RiDeleteBinLine />{" "}
                            </StyledIconButton>
                          )}
                        </Box>
                      </Stack>
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    sx={{
                      marginTop: "1.5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        height: "45px",
                        width: "200px",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      إضافة{" "}
                    </Button>
                    <Button
                      sx={{
                        borderRadius: "6px",
                        border: "2px solid #34B44A",
                        fontSize: "1rem",
                        fontWeight: 700,
                        height: "45px",
                        width: "200px",
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                    >
                      إلغاء
                    </Button>
                  </Stack>
                </>
              )}
            </FieldArray>
            {/* </Form>
              )}
            </Formik> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddAdditionalClientInfo;
