import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  refreshApi: boolean;
  refreshNotification: boolean;
  websiteSettings: any;
}

const initialState: initialStateType = {
  refreshApi: false,
  refreshNotification: false,
  websiteSettings: {},
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    toggleRefreshApi(state) {
      state.refreshApi = !state.refreshApi;
    },
    toggleRefreshNotification(state) {
      state.refreshNotification = !state.refreshNotification;
    },
    setWebsiteSettings(state, action) {
      state.websiteSettings = action.payload;
    },
  },
});

export const mainSliceActions = mainSlice.actions;
export default mainSlice;
