import axios from "axios";
import { TestimonialForm } from "../types";

export const getTestimonials = async () => {
  try {
    const response = await axios.get(`testimonial/get`);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateTestimonial = async (values: TestimonialForm) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (
        (key === "image" && typeof values[key] === "string") ||
        (key === "icon" && typeof values[key] === "string")
      ) {
        continue;
      }
      if (key === "id") {
        formData.append("testimonial_id", String(values[key]));
        continue;
      }
      formData.append(key, values[key]);
    }
    const response = await axios.post(`testimonial/update`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const createTestimonial = async (values: TestimonialForm) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    const response = await axios.post(`testimonial/create`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};
