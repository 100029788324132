import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateMarkoonNumber } from "../../apis/numbers_apis";

export const useUpdateMarkoonNumber = (fn?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMarkoonNumber,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["markoon_numbers"] });
      if (data?.status === 1) {
        toast.success("تم تحديث العنصر بنجاح");
      }
      fn && fn();
    },
    onError: () => {
      toast.error("حدث خطأ ما");
    },
  });
};
