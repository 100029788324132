import { useEffect, useState } from "react";
import { getAxios } from "../Services/axios-services";
import { toast } from "react-toastify";

//log hi

const useGetSingleApi = (url: string, dependencies?: any) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);

  useEffect(() => {
    setLoading(true);
    getAxios(`${url}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, ...dependencies]);

  return { data, loading, error };
};
export default useGetSingleApi;
