import { IUser } from "../types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: IUser = {
  token: null,
  id: 0,
  name: "",
  phone: "",
  email: "",
  image: "",
  roles: null,
  status: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
      state.image = action.payload.image;
      state.roles = action.payload.roles;
    },
    logout: (state) => {
      state.token = null;
      state.id = 0;
      state.name = "";
      state.phone = "";
      state.email = "";
      state.image = "";
      state.roles = [];
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
