import { styled, Button } from "@mui/material";

const StyledIconButton = styled(Button)({
  minWidth: "24px",
  width: "24px",
  height: 28,
  padding: "4px 0 4px 0",
  "& svg": {
    fontSize: "1.3rem",
    color: "#34B44A",
  },
  "&:hover": {
    backgroundColor: "#34B44A",
    "& svg": {
      color: "white",
    },
  },
});

export default StyledIconButton;
