import axios from "axios";
import { InfoDto } from "../types";

export const getMrkoonInfo = async () => {
  try {
    const response = await axios.get(`information/get`);
    return response?.data as {
      data: InfoDto;
    };
  } catch (error) {
    console.log("error", error);
  }
};

export const updateMrkoonInfo = async (values: InfoDto) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (key === "file" && typeof values[key] === "string") {
        continue;
      }

      //   @ts-ignore
      formData.append(key, values[key]);
    }
    const response = await axios.post(`information/update`, formData);
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};
