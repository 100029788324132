import axios from "axios";

export const getProducts = async (props: {
  pageParam: number | false;
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(
    `products/get?type=${props?.queryKey[1] || ""}&status_id=${props.queryKey[2] || ""}&price_type=${
      props.queryKey[3] || ""
    }&search_text=${props.queryKey[4] || ""}&parent_id=${props.queryKey[5] || ""}&active_bid=${
      props.queryKey[6] || ""
    }&page=${props.pageParam || ""}`
  );
  return response?.data?.data?.data;
};

export const getSingleProduct = async (props: { queryKey: (string | null | undefined | number)[] }) => {
  const response = await axios.get(`products/single?product_id=${props.queryKey[1]}`);
  return response?.data?.data;
};

export const getProductForCombobox = async (props: { queryKey: (string | null | undefined | number)[] }) => {
  const response = await axios.get(`products/get?search_text=${props.queryKey[1]}&limit=30`);
  return response?.data?.data?.data;
};
