import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import ConfirmActionDialog from "../../components/ConfirmActionDialog/ConfirmActionDialog";
import FiltersContainer from "../../components/Containers/FiltersContainer";
import MainContainer from "../../components/Containers/MainContainer";
import CustomSelectSmall from "../../components/CustomSelect/CustomSelectSmall";
import CustomTextFieldSmall from "../../components/CustomTextFieldSmall/CustomTextFieldSmall";
import Header from "../../components/Header/Header";
import TableLoader from "../../components/Loaders/TableLoader";
import RawakdTable from "../../components/Tables/RawakdTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useProducts } from "../../hooks/useProducts";
import { mainSliceActions } from "../../store/main-slice";

type Props = {
  new?: boolean;
};

const Rawakd = (props: Props) => {
  // const location = useLocation();
  // const query = queryString.parse(location.search);
  // const rawakdApiUrl = useMemo(
  //   () =>
  //     `products/get?type=1&status_id=${props.new ? "1" : query.status_id || ""}&price_type=${
  //       query.price_type || ""
  //     }&search_text=${query.search_text || ""}&page=${query.page || "1"}`,
  //   [query.status_id, query.page, query.price_type, query.search_text, query?.cat_id]
  // );
  const dispatch = useDispatch();
  // const mainState = useSelector((state: any) => state.main);

  // const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  // const { data, loading, error } = useGetSingleApi(rawakdApiUrl, dependencies);

  //filter logic
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const handleChange = () => {
    setFilterExpanded((prev) => !prev);
  };
  const [filters, setFilters] = useState({
    type: 1,
    status_id: props.new ? 1 : null,
    price_type: null,
    search_text: "",
  });
  const navigate = useNavigate();

  // const handleSubmitFilters = () => {
  //   //take the state and construct it to a url to use a query string
  //   const urlToPush = `?status_id=${filters.status_id || ""}&price_type=${filters.price_type || ""}&search_text=${
  //     filters.search_text || ""
  //   }`;
  //   navigate(urlToPush);
  // };

  //delete multiple logic
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);

  const handleDelete = async (values: any) => {
    try {
      let deleteApiUrl = `products/delete?product_id=`;
      let deleteIds = "";
      const newUrl = values.checked.map((value: any, index: number) => {
        if (index === 0) {
          deleteIds = `${value}`;
        } else {
          deleteIds = `${deleteIds},${value}`;
        }
      });
      deleteApiUrl = deleteApiUrl + deleteIds;

      setDeleting(true);

      await postAxios(deleteApiUrl).then((data) => {
        if (data.data.status !== 1) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
          dispatch(mainSliceActions.toggleRefreshApi());
        }
      });
    } catch (err) {
    } finally {
      setDeleting(false);
      setDeleteMultiple(false);
      setDeleteAction(null);
    }
  };
  //get user status
  //get filters
  const filterStatusApi = useMemo(() => `helper/get_status?model_name=product`, []);
  const { data: filterData } = useGetSingleApi(filterStatusApi, []);

  //react-query

  const { data, isPending, hasNextPage, ref } = useProducts(filters);

  return (
    <div>
      {" "}
      <Formik
        initialValues={{ checked: [] }}
        onSubmit={(values) => {
          setDeleteAction(() => () => handleDelete(values));
          setDeleteMultiple(true);
        }}
      >
        {({ values, handleSubmit }) => (
          <Form>
            <Header title="الرواكد" />
            <MainContainer>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="h6"
                  sx={{
                    color: "primary.main",
                    fontWeight: "700",
                    fontSize: "1.375rem",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: "8px",
                      left: "0",
                      width: "90px",
                      height: "2px",
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  {props.new ? "رواكد جديده" : "كل الرواكد"}{" "}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <OutlinedButton
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant="outlined"
                    endIcon={<RiDeleteBin6Line />}
                    disabled={values.checked.length === 0}
                  >
                    حذف رواكد
                  </OutlinedButton>
                  <OutlinedButton
                    endIcon={<IoMdAdd />}
                    onClick={() => {
                      navigate("/rawakd/add");
                    }}
                  >
                    إضافة رواكد
                  </OutlinedButton>
                  <Button
                    sx={{
                      color: "#F7F7FC",
                      borderRadius: "8px",
                      width: "190px",
                      height: "50px",
                      fontSize: "1.125rem",
                      fontWeight: "800",
                    }}
                    onClick={handleChange}
                    variant="contained"
                    color="primary"
                    endIcon={filterExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                  >
                    تصفية الرواكد
                  </Button>
                </Stack>
              </Stack>
              <FiltersContainer showButton={false} filterExpanded={filterExpanded}>
                <CustomTextFieldSmall setFilters={setFilters} filters={filters} />
                {!props.new && filterData && (
                  <CustomSelectSmall
                    placeholder="حاله المنتج"
                    optionsProp={filterData?.data?.map((item: any) => {
                      return {
                        label: item.name_ar,
                        value: item.id,
                      };
                    })}
                    setFilters={setFilters}
                    type="status_id"
                    multi
                  />
                )}

                <CustomSelectSmall
                  type="price_type"
                  placeholder="نوع التسعير"
                  optionsProp={[
                    { value: "1", label: "سعر ثابت" },
                    { value: "2", label: "اعلي سعر" },
                  ]}
                  setFilters={setFilters}
                />
              </FiltersContainer>
              {isPending ? (
                <TableLoader />
              ) : (
                data && (
                  <>
                    <RawakdTable
                      values={values}
                      type="rawakd"
                      rows={data?.pages.map((page) => page).flat()}
                      statusOptions={filterData?.data?.map((item: any) => {
                        return {
                          name: item.name_ar,
                          id: item.id,
                          class: item.class,
                        };
                      })}
                    />
                    {hasNextPage && (
                      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <CircularProgress size={24} ref={ref} />
                      </Box>
                    )}
                  </>
                )
              )}
            </MainContainer>
          </Form>
        )}
      </Formik>
      <ConfirmActionDialog
        title={"هل أنت متأكد من حذف الرواكد ؟"}
        action={deleteAction}
        open={deleteMultiple}
        handleClose={() => {
          setDeleteMultiple(false);
        }}
        loading={deleting}
      />
    </div>
  );
};

export default Rawakd;
