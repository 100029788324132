import { Box, IconButton, Input, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { BsFillCloudUploadFill } from "react-icons/bs";

type Props = {
  title: string;
  setFieldValue: any;
  imageFromProps: string;
  name: string;
};

const SmallUpload = ({ title, setFieldValue, name, imageFromProps }: Props) => {
  const [imageFromApi, setImageFromApi] = useState<string>(imageFromProps);
  // upload Images
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={
        {
          // width: "460px",
        }
      }
    >
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: 700,
        }}
      >
        {title} :
      </Typography>
      <Box
        sx={{
          cursor: "pointer",
        }}
      >
        {/* <BsFillCloudUploadFill size={30} /> */}
        <IconButton
          sx={{
            position: "relative",
            "& svg": {
              color: "#34B44A",
              cursor: "pointer",
            },
            "& input[type=file]": {
              cursor: "pointer",
              opacity: 0,
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
            },
          }}
          id="upload-image"

          //   type="file"
          //   hidden

          //   type="file"
          //   hidden
          //   accept="image/*"
          //   onChange={(e) => {
          //     if (e.target.files) {
          //       setFieldValue(name, e.target.files[0]);
          //       setImageFromApi(URL.createObjectURL(e.target.files[0]));
          //     }
          //   }}
        >
          <BsFillCloudUploadFill size={30} />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files) {
                setFieldValue(name, e.target.files[0]);
                setImageFromApi(URL.createObjectURL(e.target.files[0]));
              }
            }}
          />
        </IconButton>
      </Box>
      {/* preview */}
      <Box
        sx={{
          width: "200px",
          height: "200px",
        }}
      >
        <Box
          component="img"
          src={imageFromApi}
          sx={{
            width: "100%",
            borderRadius: "12px",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
    </Stack>
  );
};

export default SmallUpload;
