import { AdminsPage, CreateAdmin, EditAdmin } from "../../features/admins";
import { Route, Routes } from "react-router-dom";

import AcceptedAuctions from "../../pages/Auctions/AcceptedAuctions/AcceptedAuctions";
import AddClient from "../../pages/Clients/AddClient";
import AddConstructionMaterials from "../../pages/ConstructionMaterials/AddConstructionMaterials";
import AddKhorda from "../../pages/Khorda/AddKhorda";
import AddProductRoute from "../../pages/CompoundProducts/AddProductRoute";
import AddRawakd from "../../pages/Rawakd/AddRawakd";
import AllClients from "../../pages/Clients/AllClients";
import Cities from "../../pages/Cities/Cities";
import ClientAddress from "../../pages/Clients/ClientAddress";
import ClientsMainTypes from "../../pages/ClientsTypes/ClientsMainTypes";
import ClientsOrders from "../../pages/Orders/ClientsOrders";
import ClientsSubTypes from "../../pages/ClientsTypes/ClientsSubTypes";
import CompoundProductsPage from "../../pages/CompoundProducts/CompoundProductsPage";
import ConstructionCategory from "../../pages/Categories/ConstructionCategory";
import ConstructionMaterials from "../../pages/ConstructionMaterials/ConstructionMaterials";
import ConstructionSubCategory from "../../pages/Categories/ConstructionSubCategory";
import ContactUs from "../../pages/ContactUs/ContactUs.";
import { CreateProductStatus } from "../../features/productStatus/component/createProductStatus.page";
import EditClient from "../../pages/Clients/EditClient";
import EditConstructionMaterials from "../../pages/ConstructionMaterials/EditConstructionMaterials";
import EditKhorda from "../../pages/Khorda/EditKhorda";
import { EditProductStatus } from "../../features/productStatus/component/editProductStatus.page";
import EditRawakd from "../../pages/Rawakd/EditRawakd";
import ForgetPassword from "../../pages/ForgetPassword/ForgetPassword";
import Home from "../../pages/Home/Home";
import Khorda from "../../pages/Khorda/Khorda";
import KhordaCategory from "../../pages/Categories/KhordaCategory";
import KhordaSubCategory from "../../pages/Categories/KhordaSubCategory";
import Layout from "../Layout/Layout";
import Login from "../../pages/Login/Login";
import NewAuctions from "../../pages/Auctions/NewAuctions/NewAuctions";
import NewClients from "../../pages/Clients/NewClients";
import NewConstructionMaterials from "../../pages/ConstructionMaterials/NewConstructionMaterials";
import NewKhorda from "../../pages/Khorda/NewKhorda";
import NewOrders from "../../pages/Orders/NewOrders";
import NewRawakd from "../../pages/Rawakd/NewRawakd";
import OngoingAuctions from "../../pages/Auctions/OngoingAuctions/OngoingAuctions";
import { PayRequestsPage } from "../../features";
import PrivateRoutes from "../PrivateRoutes/PrivateRoutes";
import ProductStatistics from "../../pages/ProductStatistics/ProductStatistics";
import ProductStatusPage from "../../features/productStatus/component/productStatus";
import Profile from "../../pages/Profile/Profile";
import Rawakd from "../../pages/Rawakd/Rawakd";
import RawakdCategory from "../../pages/Categories/RawakdCategory";
import RawakdSubCategory from "../../pages/Categories/RawakdSubCategory";
import Settings from "../../pages/Settings/Settings";
import SingleClient from "../../pages/Clients/SingleClient";
import SingleConstructionMaterials from "../../pages/ConstructionMaterials/SingleConstructionMaterials";
import SingleKhorda from "../../pages/Khorda/SingleKhorda";
import SingleOrder from "../../pages/Orders/SingleOrder";
import SingleRawakd from "../../pages/Rawakd/SingleRawakd";
import Slider from "../../pages/Slider/Slider";
import Tags from "../../pages/Tags/Tags";
import TrustedClients from "../../pages/Clients/TrustedClients";
import Units from "../../pages/Units/Units";
import BrandsPage from "../../pages/brands/brands";
import { Partners } from "../../pages/Partners/Partners";
import { Testimonials } from "../../pages/Testimonials/Testimonials";
import { Blogs } from "../../pages/blogs/blogs";
import { WhyMarkoon } from "../../pages/WhyMarkoon/WhyMarkoon";
import { Numbers } from "../../pages/Numbers/Numbers";
import { AboutPage } from "../../pages/about/about.page";
import { InformationPage } from "../../pages/information/information.page";

type Props = {};

const AppRoutes = (props: Props) => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route element={<PrivateRoutes />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/categories">
            <Route path="rawa.kd-category" element={<RawakdCategory />} />
            <Route path="rawa.kd-category/:id" element={<RawakdSubCategory />} />
            <Route path="kho.rda-category" element={<KhordaCategory />} />
            <Route path="kho.rda-category/:id" element={<KhordaSubCategory />} />
            <Route path="construction-materials-category" element={<ConstructionCategory />} />
            <Route path="construction-materials-category/:id" element={<ConstructionSubCategory />} />
          </Route>
          <Route path="/clients-types">
            <Route index element={<ClientsMainTypes />} />
            <Route path=":id" element={<ClientsSubTypes />} />
          </Route>

          <Route path="/orders">
            <Route index path="all" element={<ClientsOrders />} />
            <Route path=":id" element={<SingleOrder />} />
            <Route path="new" element={<NewOrders />} />
          </Route>
          <Route path="/auctions">
            <Route index path="new-auctions" element={<NewAuctions />} />
            <Route path="accepted-auctions" element={<AcceptedAuctions />} />
            <Route path="ongoing-auctions" element={<OngoingAuctions />} />
          </Route>
          <Route path="/rawakd">
            <Route index path="all" element={<Rawakd />} />
            <Route index path="new" element={<NewRawakd />} />
            <Route path="add" element={<AddRawakd />} />
            <Route path="all/:id" element={<SingleRawakd />} />
            <Route path="all/:id/edit" element={<EditRawakd />} />
          </Route>
          <Route path="/khorda">
            <Route index path="all" element={<Khorda />} />
            <Route index path="new" element={<NewKhorda />} />
            <Route path="add" element={<AddKhorda />} />
            <Route path="all/:id" element={<SingleKhorda />} />
            <Route path="all/:id/edit" element={<EditKhorda />} />
          </Route>
          <Route path="/products/view/:parent_id" element={<CompoundProductsPage />} />
          <Route path="/products/add/:product_id/:type" element={<AddProductRoute />} />
          <Route path="/construction-materials">
            <Route index path="all" element={<ConstructionMaterials />} />
            <Route index path="new" element={<NewConstructionMaterials />} />
            <Route path="add" element={<AddConstructionMaterials />} />
            <Route path="all/:id" element={<SingleConstructionMaterials />} />
            <Route path="all/:id/edit" element={<EditConstructionMaterials />} />
          </Route>
          <Route path="/product-statistics" element={<ProductStatistics />} />
          <Route path="/clients">
            <Route index path="all" element={<AllClients />} />
            <Route index path="trusted-clients" element={<TrustedClients />} />
            <Route path="new" element={<NewClients />} />
            <Route path="all/:id" element={<SingleClient />} />
            <Route path="all/:id/address-details" element={<ClientAddress />} />
            <Route path="all/:id/edit" element={<EditClient />} />
            <Route path="add" element={<AddClient />} />
          </Route>
          <Route path="/main-info">
            <Route path="cities" index element={<Cities />} />
            <Route path="units" index element={<Units />} />
            <Route path="slider" index element={<Slider />} />
            <Route path="partners" index element={<Partners />} />
            <Route path="testimonials" index element={<Testimonials />} />
            <Route path="blogs" index element={<Blogs />} />
            <Route path="why-markoon" index element={<WhyMarkoon />} />
            <Route path="numbers" index element={<Numbers />} />
            <Route path="information" index element={<InformationPage />} />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/admins" element={<AdminsPage />} />
          <Route path="/admins/create" element={<CreateAdmin />} />
          <Route path="/admins/:id" element={<EditAdmin />} />
          <Route path="/product-status" element={<ProductStatusPage />} />
          <Route path="/product-status/create" element={<CreateProductStatus />} />
          <Route path="/product-status/:id" element={<EditProductStatus />} />
          {/* payRequests Page */}
          <Route path="/pay-requests" element={<PayRequestsPage />} />
          <Route path="/pay-requests/:product_id" element={<PayRequestsPage />} />
          {/* brands */}
          <Route path="/brands" element={<BrandsPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
