import { IPayRequest } from "../types";
import axios from "axios";

export const getPayRequests = async (props: {
  pageParam: number | false;
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(
    `pay_request/get?search_text=${props.queryKey[1] || ""}&status=${
      props.queryKey[2] || ""
    }&payment_method=${props.queryKey[3] || ""}&product_id=${
      props.queryKey[4] || ""
    }&insurance_status=${props.queryKey[5] || ""}&page=${props.pageParam || ""}`
  );
  return response?.data?.data as {
    data: IPayRequest[];
    pagination: {
      total: number;
    };
  };
};

export const payRequest = async (data: any) => {
  const formData = new FormData();

  for (const key in data) {
    formData.append(key, data[key]);
  }

  const response = await axios.post("pay_request/pay", formData);
  return response?.data;
};
export const createPayRequest = async (data: any) => {
  const formData = new FormData();

  if (data.user) {
    delete data.user;
  }

  for (const key in data) {
    formData.append(key, data[key]);
  }

  const response = await axios.post("pay_request/create", formData);
  return response?.data;
};
