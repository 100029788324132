import { Box, Stack, Typography, styled } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  count: number;
  color?: string;
  icon: React.ReactNode;
};

const StyledContainer = styled("div")({
  display: "flex",
  boxShadow: "0px 4px 15px rgba(156, 156, 156, 0.25)",
  borderRadius: "10px",
  padding: "1.25rem 2rem",
  gap: "1.5rem",
  width: "100%",
  backgroundColor: "#fff",
  justifyContent: "center",
  alignItems: "center",
});

const StatusCard = ({ title, count, color, icon }: Props) => {
  return (
    <StyledContainer>
      <Box>{icon}</Box>
      {/* <Stack>
          <Typography
            sx={{
              color: "#CC0000",
              display: "flex",
              fontSize: "1rem",
              fontWeight: "600",
              direction: "rtl",
              gap: "3px",
            }}
          >
            <img src={redArrow} alt="red-arrow" />
            21%
          </Typography>
          <Typography
            sx={{
              color: "rgba(27, 40, 57, 0.7)",
              fontSize: "12px",
              fontWeight: "400",
              direction: "rtl",
            }}
          >
            Last 30 days
          </Typography>
        </Stack> */}
      <Stack
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <Typography
          sx={{
            color: color,
            fontWeight: "700",
            fontSize: "1.125rem",
          }}
        >
          {title}{" "}
        </Typography>
        <Typography
          sx={{
            color: "#1B2839",
            fontSize: "2.25rem",
            fontWeight: "300",
            gap: ".8rem",
            textAlign: "center",
            // marginTop: "0 !important",
            "& span": {
              fontSize: "14px",
              color: "rgba(27, 40, 57, 0.7)",
              marginLeft: ".5rem",
            },
          }}
        >
          {count}
        </Typography>
      </Stack>
    </StyledContainer>
  );
};

export default StatusCard;

type StatusCardIconProps = {
  color?: string;
};
