import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { AiOutlineSetting } from "react-icons/ai";
import { UserType, WhyMarkoonItem } from "../../types";
import { useState } from "react";
import { WhyMarkoonModal } from "../Modals/WhyMarkoonModal";
import { useUpdateWhyItem } from "../../hooks/whyMarkoon/useUpdateWhyItem.hook";
import { pageTypes } from "../../constants/pageTypes.constant";

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 3fr 1fr 1fr 1fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 3fr 1fr 1fr 1fr",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  //   color: "#34B44A",
  fontWeight: "500",
  textAlign: "center",
});

interface CustomTableRowProps {
  id: number;
  index: number;
  type: number;
  title_ar: string;
  title_en: string;
  description_ar: string;
  description_en: string;
  icon: string;
  page: number;
  openEditBlogModal: (item: WhyMarkoonItem) => void;
}

const CustomTableRow: React.FC<CustomTableRowProps> = ({
  id,
  index,
  type,
  title_ar,
  title_en,
  description_ar,
  description_en,
  icon,
  page,
  openEditBlogModal,
}) => {
  return (
    <StyledTableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
        border: "unset",
      }}
    >
      <StyledMainCell>
        <Link to={icon} target="__blank">
          <Box
            component={"img"}
            src={icon}
            sx={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          ></Box>
        </Link>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">{title_ar}</Typography>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">{description_ar}</Typography>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">
          {type === UserType.Seller ? "بائع" : "مشتري"}
        </Typography>
      </StyledMainCell>{" "}
      <StyledMainCell>
        <Typography variant="body2">
          {page === pageTypes.HOME ? "الرئيسية" : "عن مركون"}
        </Typography>
      </StyledMainCell>
      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        justifyContent={"center"}
      >
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& a": {
              color: "#34B44A",
            },
            "& svg": {
              fill: "gray",
            },
          }}
          onClick={() =>
            openEditBlogModal({
              id,
              title_ar,
              title_en,
              description_ar,
              description_en,
              icon,
              type,
              page,
            })
          }
        >
          <AiOutlineSetting />
        </IconButton>
        <DeleteIcon
          id={Number(id)}
          apiUrl="why_markoon/delete"
          payload={{
            whyMarkoon_id: id,
          }}
          refresh="why_markoon"
        />
      </Stack>
    </StyledTableRow>
  );
};

interface WhyMarkoonTableProps {
  data: any;
}

export const WhyMarkoonTable: React.FC<WhyMarkoonTableProps> = ({ data }) => {
  const [whyMarkoonItem, setWhyMarkoonItem] = useState<WhyMarkoonItem | null>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { mutate: updateWhyItem, isPending: updateLoading } = useUpdateWhyItem(
    () => setEditModalOpen(false)
  );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader>
          <h6>صورة العنصر</h6>
          <h6>عنوان العنصر</h6>
          <h6>وصف العنصر</h6>
          <h6>نوع المستخدم</h6>
          <h6>الصفحة</h6>
          <h6></h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {data.map((row: any, index: number) => {
            return (
              <CustomTableRow
                {...row}
                id={row.id}
                key={row.id}
                openEditBlogModal={(Blog) => {
                  setWhyMarkoonItem(Blog);
                  setEditModalOpen(true);
                }}
              />
            );
          })}
        </Stack>
        <WhyMarkoonModal
          handleClose={() => {
            setEditModalOpen(false);
            setWhyMarkoonItem(null);
          }}
          handleConfirm={updateWhyItem}
          isLoading={updateLoading}
          open={editModalOpen}
          title="تعديل العنصر"
          item={whyMarkoonItem ?? null}
          isEdit
        />
      </Box>
    </>
  );
};
