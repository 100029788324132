import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  open: boolean;
  initialValues: {
    order_id: string;
    delivery_price: string;
  };
}

const initialState: initialStateType = {
  open: false,
  initialValues: {
    order_id: "",
    delivery_price: "",
  },
};

const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    openModal(state, action) {
      state.open = true;
      state.initialValues = action.payload;
    },
    closeModal(state) {
      state.open = false;
      state.initialValues = {
        order_id: "",
        delivery_price: "",
      };
    },
  },
});

export const deliveryActions = deliverySlice.actions;
export default deliverySlice;
