import { Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";

import CustomTextField from "../CustomTextField/CustomTextField";
import { Link } from "react-router-dom";
import LoginFormContainer from "../LoginFormContainer/LoginFormContainer";
import { OtpModal } from "../../features";
import React from "react";
import { loginValidationSchema } from "../../validation/Validation";
import { postAxios } from "../../Services/axios-services";
import styles from "./LoginForm.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

type Props = {};

const LoginForm = (props: Props) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpModalOpen, setOtpModalOpen] = React.useState(false);

  // console.log(state, "0hi");
  const login = async (values: any, actions: any) => {
    try {
      await postAxios("auth/login", values).then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          setOtpModalOpen(true);
          // dispatch(userActions.login(res.data.data));

          // console.log(res?.data?.data);
          // window.location.href = "/";
        } else if (res.status === 200 && res.data.status === 0) {
          toast.error(res.data.message, {
            toastId: "LoginForm",
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(`Something Went wrong`, {
        toastId: "LoginForm",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          phone: "",
          password: "",
          code: "",
        }}
        onSubmit={login}
        validationSchema={loginValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <LoginFormContainer>
              <h3>تسجيل دخول</h3>
              <div className={styles.formContainer}>
                <CustomTextField name="phone" label="رقم الهاتف" required />
                <CustomTextField name="password" type="password" label="كلمة المرور" required />
                <Link to="/forget-password" className={styles.forgetPassword}>
                  نسيت كلمة المرور
                </Link>
              </div>
              <div className={styles.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    color: "white",
                    borderRadius: "10px",
                    height: "60px",
                    fontSize: "18px",
                    fontWeight: "800",
                  }}
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                >
                  تسجيل دخول{" "}
                </Button>
              </div>
              <OtpModal open={otpModalOpen} onClose={() => setOtpModalOpen(false)} />
            </LoginFormContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default React.memo(LoginForm);
