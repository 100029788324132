import { Button, Stack, Typography } from "@mui/material";
import queryString from "query-string";
import { useMemo, useState } from "react";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import FiltersContainer from "../../components/Containers/FiltersContainer";
import MainContainer from "../../components/Containers/MainContainer";
import DatePicker from "../../components/DatePicker/DatePicker";
import Header from "../../components/Header/Header";
import MainOrdersTable from "../../components/Tables/MainOrdersTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { mainSliceActions } from "../../store/main-slice";
import TableLoader from "../../components/Loaders/TableLoader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { Form, Formik } from "formik";
import ConfirmActionDialog from "../../components/ConfirmActionDialog/ConfirmActionDialog";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomSelectSmall from "../../components/CustomSelect/CustomSelectSmall";

type Props = {};

const NewOrders = (props: Props) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  //fetch data logic
  const mainState = useSelector((state: any) => state.main);

  const productsApiUrl = useMemo(
    () => `orders/all?status_id=${query.status_id || 19}&page=${query.page || "1"}`,
    [query.page, query.status_id]
  );
  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  const { data, loading, error } = useGetSingleApi(productsApiUrl, dependencies);

  //filter logic
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  //get filters
  const filterStatusApi = useMemo(() => `helper/get_status?model_name=order`, []);

  const { data: filterData, loading: filterDataLoading, error: filterDateError } = useGetSingleApi(filterStatusApi, []);
  const handleChange = () => {
    setFilterExpanded((prev) => !prev);
  };
  const [filters, setFilters] = useState({
    status_id: null,
    // price_type: null,
  });

  const handleSubmitFilters = () => {
    //take the state and construct it to a url to use a query string
    const urlToPush = `?status_id=${filters.status_id || "5"}`;
    navigate(urlToPush);
  };
  //delete multiple logic
  const dispatch = useDispatch();
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);
  const handleDelete = async (values: any) => {
    try {
      let deleteApiUrl = `orders/delete?order_id=`;
      const newUrl = values.checked.map((value: any, index: number) => {
        if (index === 0) {
          deleteApiUrl = deleteApiUrl + `${value}`;
        } else {
          deleteApiUrl = deleteApiUrl + `,${value}`;
        }
        return deleteApiUrl;
      });
      setDeleting(true);
      dispatch(mainSliceActions.toggleRefreshApi());

      await postAxios(newUrl).then((res) => {
        if (data.data.status !== 1) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
        }
      });
    } catch (err) {
    } finally {
      setDeleting(false);
      setDeleteMultiple(false);
      setDeleteAction(null);
    }
  };

  const navigate = useNavigate();
  return (
    <div>
      <Header title="الطلبات" />
      <MainContainer>
        <Formik
          initialValues={{ checked: [] }}
          onSubmit={(values) => {
            setDeleteAction(() => () => handleDelete(values));
            setDeleteMultiple(true);
          }}
        >
          {({ values, handleSubmit }) => (
            <Form>
              <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    sx={{
                      color: "primary.main",
                      fontWeight: "700",
                      fontSize: "1.375rem",
                      position: "relative",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        bottom: "8px",
                        left: "0",
                        width: "90px",
                        height: "2px",
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    طلبات جديده{" "}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <OutlinedButton
                      onClick={() => {
                        handleSubmit();
                      }}
                      variant="outlined"
                      endIcon={<RiDeleteBin6Line />}
                      disabled={values.checked.length === 0}
                    >
                      حذف طلب
                    </OutlinedButton>
                    <Button
                      sx={{
                        color: "#F7F7FC",
                        borderRadius: "8px",
                        width: "190px",
                        height: "50px",
                        fontSize: "1.125rem",
                        fontWeight: "800",
                      }}
                      onClick={handleChange}
                      variant="contained"
                      color="primary"
                      endIcon={filterExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                    >
                      تصفية الطلبات
                    </Button>
                  </Stack>
                </Stack>
                <FiltersContainer handleSubmitFilters={handleSubmitFilters} filterExpanded={filterExpanded}>
                  {filterData && (
                    <CustomSelectSmall
                      placeholder="حاله الطلب"
                      optionsProp={[
                        { value: 0, label: "الكل" },
                        ...filterData?.data?.map((item: any) => {
                          return {
                            label: item.name_ar,
                            value: item.id,
                          };
                        }),
                      ]}
                      setFilters={setFilters}
                      type="status_id"
                    />
                  )}
                </FiltersContainer>
                {loading ? (
                  <TableLoader />
                ) : (
                  data?.data?.data && (
                    <>
                      <MainOrdersTable
                        products={data?.data?.data}
                        values={values}
                        statusOptions={filterData?.data?.map((item: any) => {
                          return {
                            name: item.name_ar,
                            id: item.id,
                            class: item.class,
                          };
                        })}
                      />

                      {data.data.pagination.total_pages > 1 && (
                        <CustomPagination totalPages={data.data.pagination.total_pages} />
                      )}
                    </>
                  )
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      </MainContainer>
      <ConfirmActionDialog
        title={"هل أنت متأكد من حذف الطلبات ؟"}
        action={deleteAction}
        open={deleteMultiple}
        handleClose={() => {
          setDeleteMultiple(false);
        }}
        loading={deleting}
      />
    </div>
  );
};

export default NewOrders;
