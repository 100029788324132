import { Typography, styled } from "@mui/material";
import React from "react";
import HeaderActions from "./HeaderActions";

type Props = {
  title: string;
};
const StyledHeader = styled("header")({
  margin: "1.25rem 0rem",

  display: "flex",
  justifyContent: "space-between",
});

const Header = (props: Props) => {
  return (
    <StyledHeader>
      <Typography
        variant="h3"
        sx={{
          color: "primary.main",
          fontSize: "2rem",
          fontWeight: "700",
          alignSelf: "end",
        }}
      >
        {props.title}
      </Typography>
      <HeaderActions />
    </StyledHeader>
  );
};

export default React.memo(Header);
