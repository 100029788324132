import { Button, CircularProgress, Grid, IconButton, Rating, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { mainSliceActions } from "../../store/main-slice";
import { modalActions } from "../../store/modal-slice";
import { clientValidationSchema, editClientValidationSchema } from "../../validation/Validation";
import OutlinedButton from "../Buttons/OutlinedButton";
import MainContainer from "../Containers/MainContainer";
import CustomSelect from "../CustomSelect/CustomSelect";
import SelectWithApi from "../CustomSelect/SelectWithApi";
import CustomTextField from "../CustomTextField/CustomTextField";
import AddAdditionalClientInfo from "../Modals/AddAdditionalClientInfoModal";
import ClientInterestsModal from "../Modules/Clients/ClientInterestsModal";
import { StyledDivider, StyledInfo, StyledSecondaryContainer } from "../StyledComponents/AddClientFormComponents";
import SecondaryTitle from "../StyledComponents/SecondaryTitle";
import ClientTypeForm from "./ClientTypeForm";
type Props = {
  type: "new" | "edit";
  initialValues: any;
};

const AddClientForm = ({ type, initialValues }: Props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  //interests modal
  const [openInterests, setOpenInterests] = useState(false);
  const handleOpenInterests = () => setOpenInterests(true);
  const handleCloseInterests = () => {
    setOpenInterests(false);
  };
  const { data } = useGetSingleApi("categories/get?limit=100000", []);
  const allCats = data?.data?.data;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  //submit form logic
  const handleSubmit = async (values: any, actions: any) => {
    let apiUrl;
    console.log("values", values);

    try {
      if (type === "new") {
        apiUrl = "user/create";
      } else {
        apiUrl = "user/update";
      }
      //check if image is url or file
      if (typeof values.image === "string") {
        delete values.image;
      }

      if (typeof values.company_logo === "string") {
        delete values.company_logo;
      }
      await postAxios(apiUrl, values).then((res) => {
        console.log("res", res);
        if (res.data.status === 1) {
          if (type === "new") {
            navigate("/clients/all");
          } else {
            toast.success("تم تعديل العميل بنجاح");
            dispatch(mainSliceActions.toggleRefreshApi());
          }
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (err: any) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  //image preview logic there is error in the console
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [companyImagePreview, setCompanyImagePreview] = useState<any>(null);
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imagePreview);
      URL.revokeObjectURL(companyImagePreview);
      setImagePreview(null);
      setCompanyImagePreview(null);
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        // validationSchema={type === "new" ? clientValidationSchema : editClientValidationSchema}
      >
        {({ isSubmitting, setFieldValue, values, touched, errors }) => (
          <Form>
            <MainContainer>
              <SecondaryTitle>{type === "new" ? "اضافة عميل جديد" : "تعديل بيانات العميل"}</SecondaryTitle>
              <Stack spacing={3}>
                <StyledSecondaryContainer>
                  <StyledInfo>بيانات اساسية :</StyledInfo>
                  <StyledDivider />
                  <Grid
                    container
                    spacing={5}
                    sx={{
                      padding: "1rem",
                    }}
                  >
                    <Grid item xs={12} md={6}>
                      <CustomTextField label="اسم العميل" name="name" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField label="كلمة السر" name="password" type="password" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField label="البريد الالكتروني" name="email" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {" "}
                      <CustomTextField label="رقم الهاتف" name="phone" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField label="العنوان" name="address" />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: "flex", gap: "1rem" }}>
                      <SelectWithApi
                        apiUrl="city/get?limit=all"
                        name="city_id"
                        title="المدينة"
                        errors={errors}
                        touched={touched}
                        placeholder="اختر المدينة"
                        selectedValue={values.city_id}
                      />
                      <IconButton
                        onClick={() => {
                          // dispatch(cityActions.openCityModal());
                          // dispatch(cityActions.addNewCity());
                          dispatch(
                            modalActions.openModal({
                              type: "add",
                              apiUrl: "city/create",
                              initialValues: {
                                name_ar: "",
                                name_en: "",
                              },
                            })
                          );
                        }}
                      >
                        <IoMdAdd />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <CustomSelect
                        touched={touched}
                        errors={errors}
                        title="حاله العميل"
                        placeholder="اختر حاله العميل"
                        name="status"
                        setFieldValue={setFieldValue}
                        optionsProp={[
                          { value: 1, label: "مفعل" },
                          { value: 2, label: "غير مفعل" },
                          { value: 3, label: "عضو جديد" },
                          { value: 4, label: "لم يتم الرد" },
                        ]}
                        values={values}
                      />
                    </Grid>
                    <ClientTypeForm values={values} />

                    <Grid item xs={12} md={3}>
                      <CustomSelect
                        touched={touched}
                        title="تصنيف العميل"
                        placeholder="اختر تصنيف العميل"
                        name="individual_or_company"
                        errors={errors}
                        setFieldValue={setFieldValue}
                        optionsProp={[
                          { value: 1, label: "فرد" },
                          { value: 2, label: "شركة" },
                        ]}
                        values={values}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField label="اسم الشركه" name="company_name" />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        name="half-rating-read"
                        // defaultValue={values.rate ? values.rate : 0}
                        value={values.rate ? values.rate : 0}
                        onChange={(e, v) => {
                          setFieldValue("rate", v);
                        }}
                      />
                      <span>تقييم العميل</span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="outlined" onClick={handleOpenInterests}>
                        اهتمامات العميل
                      </Button>
                      <ClientInterestsModal
                        allCats={allCats}
                        myCats={initialValues?.cats}
                        open={openInterests}
                        handleClose={handleCloseInterests}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        gap: "2rem",
                      }}
                    >
                      {/* upload button */}
                      <Button
                        color="primary"
                        variant="contained"
                        aria-label="upload picture"
                        component="label"
                        sx={{
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        endIcon={<AiOutlineCloudUpload />}
                      >
                        اضافه صوره
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => {
                            setFieldValue("image", e.target.files![0]);
                            setImagePreview(URL.createObjectURL(e.target.files![0]));
                          }}
                        />
                      </Button>
                      {/* image preview */}
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="uploaded"
                          style={{
                            width: "auto",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      ) : values.image ? (
                        <img
                          src={values.image}
                          alt="uploaded"
                          style={{
                            width: "auto",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        gap: "2rem",
                      }}
                    >
                      {/* upload button */}
                      <Button
                        color="primary"
                        variant="contained"
                        aria-label="upload picture"
                        component="label"
                        sx={{
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        endIcon={<AiOutlineCloudUpload />}
                      >
                        اضافه لوجو الشركه
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => {
                            setFieldValue("company_logo", e.target.files![0]);
                            setCompanyImagePreview(URL.createObjectURL(e.target.files![0]));
                          }}
                        />
                      </Button>
                      {/* image preview */}
                      {companyImagePreview ? (
                        <img
                          src={companyImagePreview}
                          alt="uploaded"
                          style={{
                            width: "auto",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      ) : values?.company_logo ? (
                        <img
                          src={values?.company_logo}
                          alt="uploaded"
                          style={{
                            width: "auto",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </StyledSecondaryContainer>
                <StyledSecondaryContainer>
                  {" "}
                  <StyledInfo>بيانات اضافيه :</StyledInfo>
                  <StyledDivider />
                  <Grid
                    container
                    spacing={5}
                    sx={{
                      padding: "1rem",
                    }}
                  >
                    <Grid item xs={12} md={6}>
                      <CustomTextField label="السجل التجاري" name="commercial_register" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <CustomTextField label="البطاقة الضريبية" name="tax_card" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {" "}
                      <CustomTextField label="رقم ضريبة القيمة المضافة" name="added_tax_number" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {" "}
                      <CustomTextField label="رقم الفاتوة الالكترونية" name="electronic_invoice" />
                    </Grid>
                  </Grid>
                </StyledSecondaryContainer>
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                  sx={{
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "200px",
                      borderRadius: "8px",
                    }}
                    type="submit"
                    disabled={isSubmitting}
                    endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                  >
                    حفظ
                  </Button>
                  <OutlinedButton
                    sx={{
                      width: "200px",
                      borderRadius: "8px",
                    }}
                    onClick={handleOpen}
                  >
                    إضافة البيانات
                  </OutlinedButton>
                </Stack>
              </Stack>

              <AddAdditionalClientInfo
                setFieldValue={setFieldValue}
                handleClose={handleClose}
                open={open}
                values={values}
              />
            </MainContainer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddClientForm;
