import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

type optionsType = {
  value: string | number;
  label: string;
  lat?: string;
  lng?: string;
}[];

export const useSelectApi = (apiUrl: string, name: string) => {
  const [options, setOptions] = useState<optionsType>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const mainState = useSelector((state: any) => state.main);
  let newApiUrlDestructured = apiUrl.split("?");
  let newApiUrl = apiUrl;
  if (newApiUrlDestructured.length > 1) {
    newApiUrl = newApiUrlDestructured[0] + `?page=${page}&` + newApiUrlDestructured[1];
  }

  const fetchOptions = async (page: number) => {
    try {
      setIsLoading(true);
      let data;
      if (name === "status_id") {
        data = await axios.get(process.env.REACT_APP_API_URL + apiUrl);
        if (data) {
          const { data: selectItems } = data?.data;
          let newSelectOptions = [];

          newSelectOptions = selectItems?.map((item: any) => ({
            value: item?.id,
            label: item?.name_ar || item?.name,
          }));

          setOptions(newSelectOptions);
        }
      } else {
        data = await axios.get(process.env.REACT_APP_API_URL + newApiUrl);
        if (data) {
          const { data: selectItems } = data?.data?.data;
          setTotalPages(data?.data?.data?.pagination?.total_pages);
          let newSelectOptions = [];

          newSelectOptions = selectItems?.map((item: any) => ({
            value: item?.id,
            label: item?.name_ar || item?.name,
          }));

          if (name === "address") {
            newSelectOptions = selectItems?.map((city: any) => ({
              value: city.id,
              label: city.address,
              lat: city?.lat,
              lng: city?.lng,
            }));
          }
          if (name === "address_id") {
            newSelectOptions = selectItems?.map((city: any) => ({
              value: city.id,
              label: city.address,
            }));
          }
          if (name === "user_id") {
            newSelectOptions = selectItems?.map((person: any) => ({
              value: person?.id,
              label: person.company_name ? `${person?.name}-${person?.company_name}` : person.name,
            }));
          }
          if (name === "city_id") {
            newSelectOptions = selectItems?.map((city: any) => ({
              value: city?.id,
              label: city?.name_ar,
            }));
          }

          if (options && options.length > 0 && page > 1) {
            setOptions(options.concat(newSelectOptions));
          } else {
            setOptions(newSelectOptions);
          }
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions(page);
  }, [page, apiUrl, mainState?.refreshApi]);

  return { options, isLoading, setPage, totalPages, page };
};
