import { Box, Typography } from "@mui/material";

import { useFormikContext } from "formik";
import { useState } from "react";
import Select, { ControlProps, GroupProps, OptionProps, StylesConfig, components } from "react-select";
import { useSelectApi } from "../../hooks/useSelectApi";
import "./CustomSelect.scss";

interface OptionType {
  value: string | number;
  label: string;
}

interface Props {
  placeholder: string;
  width?: string;
  title: string;
  required?: boolean;
  name: any;
  apiUrl: string;
  selectedValue?: any;
  errors?: any;
  touched?: any;
}

const SelectWithApi = ({
  title,
  placeholder,
  width,
  required,
  name,
  apiUrl,
  selectedValue,
  errors,
  touched,
}: Props) => {
  const Control = ({ children, ...props }: ControlProps<OptionType, false>) => {
    return (
      <components.Control {...props}>
        <Box
          sx={{
            position: "absolute",
            top: "-11px",
            left: "9px",
            background: "white",
            padding: "0 5px",
            "& span": {
              position: "absolute",
              right: "-3px",
              top: "-2px",
              color: "red",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "black",
              fontWeight: "700",
              fontSize: ".8rem",
            }}
          >
            {title}
          </Typography>
          {required && <span>*</span>}
        </Box>

        {children}
      </components.Control>
    );
  };

  const Option = (props: OptionProps<OptionType>) => {
    return <components.Option {...props} />;
  };

  const Group = (props: GroupProps<OptionType>) => {
    return <components.Group {...props} />;
  };

  const customStyles: StylesConfig<OptionType, false> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: width ? width : "auto",
      boxShadow: "none",
      cursor: "pointer",
      borderRadius: "8px",
      border: name && errors[name] && touched[name] ? "1px solid red" : "1px solid #EAEAEA",
      padding: "9px 14px",
      position: "relative",
      "&:hover": {
        border: "1px solid #34B44A",
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      paddingLeft: "16px",
    }),
    group: (baseStyles, state) => ({
      ...baseStyles,
      paddingBottom: "4px",
      marginBottom: "4px",
    }),
  };

  const { setFieldValue } = useFormikContext();
  const { options, isLoading, setPage, totalPages, page } = useSelectApi(apiUrl, name);
  const [value, setValue] = useState(selectedValue);

  return (
    <Select
      value={
        options?.find((option: any) => option.value === value) ||
        options?.find((option: any) => option.value === selectedValue) ||
        null
      }
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={selectedValue ? options?.find((option: any) => option.value === selectedValue)?.label : placeholder}
      options={options}
      isSearchable
      components={{ Control, Option, Group }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#DEF7E5",
          primary: "#1BBC39",
        },
      })}
      styles={customStyles}
      name={name}
      onChange={(e) => {
        setFieldValue(name, e?.value);
        setValue(e?.value);
      }}
      onMenuScrollToBottom={() => {
        if (page < totalPages) {
          setPage(page + 1);
        } else {
          return;
        }
      }}
      isLoading={isLoading}
    />
  );
};

export default SelectWithApi;
