import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import addressSlice from "./address-slice";
import categorySlice from "./kohrda-category-slice";
import citySlice from "./city-slice";
import clientsSlice from "./clients-slice";
import deliverySlice from "./delivery-slice";
import genericMessageSlice from "./generic-message-slice";
import mainSlice from "./main-slice";
import messageModalSlice from "./message-modal-slice";
import modalSlice from "./modal-slice";
import percentageSlice from "./percentage-slice";
import sliderSlice from "./slider-slice";
import storage from "redux-persist/lib/storage";
import subCategorySlice from "./sub-cat-slice";
import unitSlice from "./unit-slice";
import userSlice from "./user-slice";

// only persist user
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userSlice.reducer,
  city: citySlice.reducer,
  unit: unitSlice.reducer,
  clients: clientsSlice.reducer,
  category: categorySlice.reducer,
  subCategory: subCategorySlice.reducer,
  main: mainSlice.reducer,
  modal: modalSlice.reducer,
  address: addressSlice.reducer,
  slider: sliderSlice.reducer,
  percentage: percentageSlice.reducer,
  delivery: deliverySlice.reducer,
  messageModal: messageModalSlice.reducer,
  genericMessageModal: genericMessageSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
