import { Stack } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { ClientFilterItem } from "../../features";
import { useUserTypes } from "../../hooks/useUserTypes";

type Props = {
  setFilters: any;
  filters: any;
};

const ClientTypesFilter = ({ setFilters, filters }: Props) => {
  // const usersTypes = useMemo(() => "userTypes/get", []);
  // const { data: usersData } = useGetSingleApi(usersTypes, []);
  const { data: usersData } = useUserTypes();
  const [subItems, setSubItems] = useState<any>([]);

  useEffect(() => {
    if (filters.user_type_parent_id) {
      setSubItems(
        usersData?.data
          ?.find((user: any) => Number(user.id) === Number(filters.user_type_parent_id))
          ?.subTypes?.map((user: any) => {
            return {
              value: user.id,
              label: user.title,
            };
          }) || []
      );
    }
  }, [filters?.user_type_parent_id, usersData?.data]);
  return (
    <Stack direction="row" spacing={2}>
      <ClientFilterItem
        name="user_type_parent_id"
        reset="user_type_id"
        title="نوع العميل"
        options={usersData?.data?.map((user: any) => {
          return {
            value: user.id,
            label: user.title,
          };
        })}
        setFilters={setFilters}
        filters={filters}
      />
      {subItems.length > 0 && (
        <ClientFilterItem
          multi
          name="user_type_id"
          title="نوع العميل الفرعي"
          options={subItems}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </Stack>
  );
};

export default memo(ClientTypesFilter);
