import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { AiOutlineSetting } from "react-icons/ai";
import { TestimonialForm } from "../../types";
import { useState } from "react";
import { TestimonialModal } from "../Modals/TestimonialModal";
import { useUpdateTestimonial } from "../../hooks/testimonials/useUpdateTestimonial.hook";

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 3fr 1fr 1fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 3fr 1fr 1fr",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  //   color: "#34B44A",
  fontWeight: "500",
  textAlign: "center",
});

interface CustomTableRowProps {
  id: number;
  index: number;
  icon: string;
  name_ar: string;
  name_en: string;
  title_ar: string;
  title_en: string;
  description_ar: string;
  description_en: string;
  image: string;
  openEditTestimonialModal: (testimonial: TestimonialForm) => void;
}

const CustomTableRow: React.FC<CustomTableRowProps> = ({
  id,
  index,
  icon,
  name_ar,
  name_en,
  title_ar,
  title_en,
  description_ar,
  description_en,
  image,
  openEditTestimonialModal,
}) => {
  return (
    <StyledTableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
        border: "unset",
      }}
    >
      <StyledMainCell>
        <Link to={image} target="__blank">
          <Box
            component={"img"}
            src={image}
            sx={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          ></Box>
        </Link>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">{name_ar}</Typography>
      </StyledMainCell>
      <StyledMainCell>
        <Typography variant="body2">{title_ar}</Typography>
      </StyledMainCell>
      {/* <StyledMainCell>
        <Typography variant="body2">{description_ar}</Typography>
      </StyledMainCell> */}
      <StyledMainCell>
        <Link to={icon} target="__blank">
          <Box
            component={"img"}
            src={icon}
            sx={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              objectFit: "contain",
            }}
          ></Box>
        </Link>
      </StyledMainCell>
      <Stack direction="row" spacing={1.5} alignItems="center" justifyContent={"center"}>
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& a": {
              color: "#34B44A",
            },
            "& svg": {
              fill: "gray",
            },
          }}
          onClick={() =>
            openEditTestimonialModal({
              id,
              icon,
              name_ar,
              name_en,
              title_ar,
              title_en,
              description_ar,
              description_en,
              image,
            })
          }
        >
          <AiOutlineSetting />
        </IconButton>
        <DeleteIcon
          id={Number(id)}
          apiUrl="testimonial/delete"
          payload={{
            testimonial_id: id,
          }}
          refresh="testimonials"
        />
      </Stack>
    </StyledTableRow>
  );
};

interface TestimonialsTableProps {
  data: any;
}

export const TestimonialsTable: React.FC<TestimonialsTableProps> = ({ data }) => {
  const [testimonial, setTestimonial] = useState<TestimonialForm | null>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { mutate: updateTestimonialHandler, isPending: uodateLoading } = useUpdateTestimonial(() =>
    setEditModalOpen(false)
  );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader>
          <h6>صورة العميل</h6>
          <h6>اسم العميل</h6>
          <h6>لقب العميل</h6>
          {/* <h6>الرأي</h6> */}
          <h6>صورة الشركة</h6>
          <h6></h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {data.map((row: any, index: number) => {
            return (
              <CustomTableRow
                {...row}
                id={row.id}
                key={row.id}
                openEditTestimonialModal={(testimonial) => {
                  setTestimonial(testimonial);
                  setEditModalOpen(true);
                }}
              />
            );
          })}
        </Stack>
        <TestimonialModal
          handleClose={() => {
            setEditModalOpen(false);
            setTestimonial(null);
          }}
          handleConfirm={updateTestimonialHandler}
          isLoading={uodateLoading}
          open={editModalOpen}
          title="تعديل رأي العميل"
          testimonial={testimonial ?? null}
          isEdit
        />
      </Box>
    </>
  );
};
