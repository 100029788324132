import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { endOfYesterday, format } from "date-fns";

import { AddProductAdditionalInfo } from "./AddProductAdditionalInfo";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomInputWithBtns from "../../components/CustomInputWithBtns/CustomInputWithBtns";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import MainContainer from "../../components/Containers/MainContainer";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { memo } from "react";
import { useGetBrands } from "../../hooks";

type Props = {
  catData: any;
  unitsData: any;
  values: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  productStatus: any;
  type?: number;
};

const NotMemoizedThirdStep = ({
  catData,
  unitsData,
  values,
  setFieldValue,
  errors,
  touched,
  productStatus,
  type,
}: Props) => {
  //   const { values, setFieldValue, errors, touched } = useFormikContext<any>();
  const { data, isPending } = useGetBrands({
    enabled: type === 1,
    filters: {
      search_text: "",
      limit: 100,
    },
  });

  return (
    <MainContainer>
      <Grid container spacing={2} justifyItems="center">
        <Grid item xs={12} md={6}>
          <CustomTextField label="اسم المنتج باللغه العربيه" name="name_ar" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label="اسم المنتج باللغه الانجليزيه"
            name="name_en"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" spacing={2}>
            <CustomSelect
              values={values}
              placeholder="اختر من القائمه"
              title="نوع المنتج"
              optionsProp={[
                { value: 0, label: "منتج عادي" },
                { value: 1, label: "منتج لوط" },
              ]}
              name="lot"
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              required
              // value={values.lot}
            />
            {values.parent_id && (
              <CustomTextField label="ترتيب المنتج" name="sort" type="number" />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField label="نسبة مسافه" name="masafa_price" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSelect
            values={values}
            placeholder="اختر من القائمه"
            title="تسعير المنتج"
            optionsProp={[
              { value: 1, label: "سعر ثابت" },
              { value: 2, label: "اعلي سعر" },
            ]}
            name="price_type"
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            required
          />
        </Grid>
        {/* سعر المنتج لو مزاد */}
        {String(values.price_type) === "2" && (
          <>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                  }}
                >
                  <CustomSelect
                    placeholder="اختر من القائمه"
                    title="نوع المزاد"
                    optionsProp={[
                      { value: 1, label: "له تاريخ نهايه" },
                      { value: 2, label: "بدون تاريخ" },
                    ]}
                    name="mazad_type"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    required
                  />
                </Box>

                {String(values.mazad_type) === "1" && (
                  <Box
                    sx={{
                      flexGrow: 1,
                      width: "100%",
                    }}
                  >
                    <DemoItem>
                      <MobileDateTimePicker
                        views={["month", "day", "hours", "minutes"]}
                        minDate={endOfYesterday()}
                        label={
                          errors.end_date && touched.end_date
                            ? " التاريخ مطلوب"
                            : "تاريخ النهايه"
                        }
                        onChange={(e: Date | null) => {
                          if (e) {
                            setFieldValue(
                              "end_date",
                              format(e, "yyyy-MM-dd HH:mm:ss")
                            );
                          }
                        }}
                        defaultValue={
                          values.end_date ? new Date(values.end_date) : null
                        }
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "10px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor:
                                errors.end_date && touched.end_date
                                  ? "red"
                                  : "#EAEAEA",
                            },
                            borderColor:
                              errors.end_date && touched.end_date
                                ? "red"
                                : "#EAEAEA",
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              errors.end_date && touched.end_date
                                ? "red"
                                : "primary.main",
                            zIndex: 0,
                            "&.Mui-focused": {
                              color:
                                errors.end_date && touched.end_date
                                  ? "red"
                                  : "primary.main",
                            },
                          },
                        }}
                      />
                    </DemoItem>
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <CustomTextField label="أقل سعر للمزاد" name="start_price" />
                <CustomTextField label="مقدار المزايدة" name="step_price" />
              </Stack>
            </Grid>
          </>
        )}
        {/* سعر المنتج لو سعر ثابت */}
        {String(values.price_type) === "1" && (
          <>
            <Grid item xs={12} md={6}>
              <CustomTextField label="سعر المنتج الرئيسي" name="real_price" />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField label="سعر المنتج بعد الخصم" name="price" />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "flex-end",
          }}
        >
          <CustomInputWithBtns
            title="الكمية المتاحة"
            name="quantity"
            setFieldValue={setFieldValue}
          />
          <CustomTextField label="اقل كميه للطلب" name="less_quantity" />
        </Grid>
        <Grid item xs={12} md={6}>
          {unitsData && (
            <CustomSelect
              placeholder="اختر من القائمه"
              title="وحده القياس"
              name="unit_id"
              optionsProp={unitsData.data.data.map((unit: any) => {
                return {
                  value: unit.id,
                  label: unit.name_ar,
                };
              })}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              values={values}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {catData && (
            <Stack direction="row" spacing={2}>
              <CustomSelect
                placeholder="اختر من القائمه"
                title="التصنيف"
                name="cat_id"
                optionsProp={catData.data.data.map((item: any) => {
                  if (item.cats.length > 0) {
                    return {
                      value: item.id,
                      label: item.name_ar,
                      sub: item.cats.map((cat: any) => {
                        return {
                          value: cat.id,
                          label: cat.name_ar,
                        };
                      }),
                    };
                  } else {
                    return {
                      value: item.id,
                      label: item.name_ar,
                    };
                  }
                })}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                values={values}
                onChange={() => {
                  setFieldValue("sub_cat_id", "");
                }}
              />
              {values.cat_id &&
                catData.data.data.find((item: any) => item.id === values.cat_id)
                  ?.cats.length > 0 && (
                  <CustomSelect
                    placeholder="اختر من القائمه"
                    title="التصنيف الفرعي"
                    name="sub_cat_id"
                    optionsProp={catData.data.data
                      .find((item: any) => item.id === values.cat_id)
                      ?.cats.map((cat: any) => {
                        return {
                          value: cat.id,
                          label: cat.name_ar,
                        };
                      })}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                )}
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {productStatus && (
            <CustomSelect
              placeholder="اختر من القائمه"
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              name="status_id"
              title="حاله المنتج"
              optionsProp={productStatus?.data?.map((item: any) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
              values={values}
            />
          )}
        </Grid>

        <Grid item xs={6} md={3}>
          <FormControl sx={{ flexDirection: "row", alignItems: "center" }}>
            <FormLabel
              component="legend"
              sx={{
                fontSize: "1rem",
              }}
            >
              هل يوجد كراسة شروط ؟{" "}
            </FormLabel>

            <RadioGroup
              row
              aria-label="has_conditions_document"
              name="has_conditions_document"
              value={values.has_conditions_document}
              onChange={(e) => {
                setFieldValue(
                  "has_conditions_document",
                  Number(e.target.value)
                );
                if (Number(e.target.value) === 0) {
                  setFieldValue("conditions_document_price", "");
                }
              }}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio value={1} color="primary" size="small" />
              <label>نعم</label>
              <Radio value={0} color="primary" size="small" />
              <label>لا</label>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomTextField
            disabled={values.has_conditions_document === 0}
            label="سعر كراسة الشروط"
            name="conditions_document_price"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {data && !isPending && type === 1 && (
            <CustomSelect
              placeholder="اختر من القائمه"
              title="البراند"
              name="brand_id"
              optionsProp={data?.pages
                .map((page) => page)
                .flat()
                .map((item: any) => {
                  return {
                    value: item.id,
                    label: item.name_ar,
                  };
                })}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              values={values}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <FormLabel
              sx={{
                fontSize: "1rem",
              }}
            >
              وصف المنتج باللغه الانجليزيه
            </FormLabel>
            <CKEditor
              config={{
                language: "en",
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",

                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={values?.description_en}
              onChange={(_, editor) => {
                const data = editor.getData();

                setFieldValue("description_en", data);
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <FormLabel
              sx={{
                fontSize: "1rem",
              }}
            >
              وصف المنتج باللغه العربيه
            </FormLabel>
            <CKEditor
              config={{
                language: "ar",
                toolbar: {
                  items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",

                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                  ],
                },
              }}
              editor={ClassicEditor}
              data={values?.description_ar}
              onChange={(_, editor) => {
                const data = editor.getData();

                setFieldValue("description_ar", data);
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AddProductAdditionalInfo values={values} />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export const AddProductThirdStep = memo(NotMemoizedThirdStep);
