import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import { useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import { mainSliceActions } from "../../store/main-slice";
import { modalActions } from "../../store/modal-slice";
import ConfirmActionDialog from "../ConfirmActionDialog/ConfirmActionDialog";
import { useQueryClient } from "@tanstack/react-query";
import { REACT_QUERY_BRANDS } from "../../constants/reactQuery.constants";

//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr  .7fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr .7fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

const StyledIconButton = styled(Button)({
  minWidth: "24px",
  width: "24px",
  height: 28,
  padding: "4px 0 4px 0",
  "& svg": {
    fontSize: "1.3rem",
    color: "#34B44A",
  },
  "&:hover": {
    backgroundColor: "#34B44A",
    "& svg": {
      color: "white",
    },
  },
});

//  row component
interface CustomTableRowProps {
  name_ar: string;
  name_en: string;
  id: number;
  values: any;
  setFieldValue: any;
  index: number;
  type: "cat" | "tag" | "brand";
}

const CustomTableRow = ({ name_ar, name_en, id, index, values, setFieldValue, type }: CustomTableRowProps) => {
  //deleting City logic
  const [deleting, setDeleting] = useState(false);
  const queryClient = useQueryClient();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);
  const handleOpenDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };
  const dispatch = useDispatch();

  const deleteSub = async (id: number) => {
    try {
      setDeleting(true);
      const data = await postAxios(
        `${type === "cat" ? "categories/delete" : type === "tag" ? "tag/delete" : "brand/delete"}`,
        {
          [type === "cat" ? "cat_id" : type === "tag" ? "tag_id" : "brand_id"]: id,
        }
      );
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success(data.data.message);
        dispatch(mainSliceActions.toggleRefreshApi());
        handleCloseDialog();
        queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_BRANDS],
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDeleting(false);
    }
  };

  let apiUrl = "";
  if (type === "cat") {
    apiUrl = "categories/update";
  } else if (type === "tag") {
    apiUrl = "tag/update";
  } else {
    apiUrl = "brand/update";
  }

  return (
    <StyledTableRow
      sx={{
        backgroundColor: values.checked.includes(id)
          ? " rgba(222, 247, 229, 0.35)"
          : index % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: values.checked.includes(id) ? "1px solid #34B44A" : "unset",
      }}
    >
      <Checkbox
        checked={values.checked.includes(id)}
        onChange={() => {
          if (values.checked.includes(id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== id)
            );
          } else {
            setFieldValue("checked", [...values.checked, id]);
          }
        }}
      />
      <StyledMainCell>{name_ar}</StyledMainCell>
      <StyledMainCell>{name_en}</StyledMainCell>
      <Stack direction="row" spacing={0.5}>
        <StyledIconButton
          disabled={deleting}
          variant="outlined"
          color="error"
          sx={{
            svg: {
              color: "#D90009",
            },
            "&:hover": {
              backgroundColor: "#F8F8F8",
              svg: {
                color: "#D90009",
              },
            },
          }}
          onClick={() => {
            handleOpenDialog();
            setDeleteAction(() => {
              return () => deleteSub(id);
            });
          }}
        >
          <RiDeleteBin6Line />
        </StyledIconButton>
        <StyledIconButton
          variant="outlined"
          onClick={() => {
            dispatch(
              modalActions.openModal({
                type: "edit",
                apiUrl: apiUrl,
                initialValues: {
                  name_ar,
                  name_en,
                  [type === "cat" ? "cat_id" : type === "tag" ? "tag_id" : "brand_id"]: id,
                  type: type === "cat" ? 1 : "",
                },
              })
            );
          }}
        >
          <AiOutlineSetting />
        </StyledIconButton>
      </Stack>
      <ConfirmActionDialog
        title={"هل انت متأكد من الحذف؟"}
        action={deleteAction}
        open={openDeleteDialog}
        handleClose={handleCloseDialog}
        loading={deleting}
      />
    </StyledTableRow>
  );
};

// main component

interface SubCategoryTableProps {
  data: any;
  type: "cat" | "tag" | "brand";
}

export default function SubCategoryTable({ data, type }: SubCategoryTableProps) {
  return (
    <>
      <Formik
        initialValues={{ checked: [] }}
        onSubmit={(values) => {
          alert(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <StyledTableHeader>
                <Checkbox
                  disabled={data.length === 0}
                  onChange={() => {
                    if (values.checked.length > 0) {
                      setFieldValue("checked", []);
                    } else {
                      setFieldValue(
                        "checked",
                        data.map((row: any, index: number) => row.id)
                      );
                    }
                  }}
                  checked={values.checked.length === data.length}
                />
                <h6>الاسم باللغه العربيه</h6>
                <h6>الاسم باللغه الانجليزيه</h6>
              </StyledTableHeader>
              <Stack spacing={1}>
                {data.length === 0 && (
                  <Typography
                    sx={{
                      color: "#34B44A",
                      fontWeight: "700",
                      fontSize: "1.25rem",
                      textAlign: "center",
                    }}
                  >
                    No Data{" "}
                  </Typography>
                )}
                {data.map((row: any, index: number) => {
                  return (
                    <CustomTableRow
                      {...row}
                      id={row.id}
                      index={index}
                      key={row.id}
                      values={values}
                      setFieldValue={setFieldValue}
                      type={type}
                    />
                  );
                })}
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
