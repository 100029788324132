import { Button, Stack, Typography } from "@mui/material";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";

import AcceptedAuctionsTable from "../../../components/Tables/AcceptedAuctionsTable";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import CustomSelectSmall from "../../../components/CustomSelect/CustomSelectSmall";
import CustomTextFieldSmall from "../../../components/CustomTextFieldSmall/CustomTextFieldSmall";
import FiltersContainer from "../../../components/Containers/FiltersContainer";
import Header from "../../../components/Header/Header";
import MainContainer from "../../../components/Containers/MainContainer";
import TableLoader from "../../../components/Loaders/TableLoader";
import queryString from "query-string";
import useGetSingleApi from "../../../hooks/useGetSingleApi";
import { useSelector } from "react-redux";

type Props = {};

const AcceptedAuctions = (props: Props) => {
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const filterStatusApi = useMemo(() => `helper/get_status?model_name=auction_active`, []);
  const [filters, setFilters] = useState({
    status_id: "",
    search_text: "",
  });
  const handleSubmitFilters = () => {
    const urlToPush = `?status_id=${filters.status_id || ""}&search_text=${filters.search_text || ""}`;
    navigate(urlToPush);
  };

  const { data: filterData, loading: filterDataLoading, error: filterDateError } = useGetSingleApi(filterStatusApi, []);

  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = () => {
    setFilterExpanded((prev) => !prev);
  };
  const query = queryString.parse(location.search);
  const auctionsApi = useMemo(
    () =>
      `auction/get_active_auctions?status_id=${query.status_id || ""}&page=${query.page || 1}&search_text=${
        query.search_text || ""
      }`,
    [query.page, query.status_id, query.search_text]
  );
  const mainState = useSelector((state: any) => state.main);

  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);

  const { data, loading } = useGetSingleApi(auctionsApi, dependencies);
  return (
    <div>
      <Header title="المزادات" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            مزادات مقبوله{" "}
          </Typography>
          <Button
            sx={{
              color: "#F7F7FC",
              borderRadius: "8px",
              width: "190px",
              height: "50px",
              fontSize: "1.125rem",
              fontWeight: "800",
            }}
            onClick={handleChange}
            variant="contained"
            color="primary"
            endIcon={filterExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
          >
            تصفية المزادات
          </Button>
        </Stack>
        <FiltersContainer handleSubmitFilters={handleSubmitFilters} filterExpanded={filterExpanded}>
          <CustomTextFieldSmall setFilters={setFilters} filters={filters} />
          <CustomSelectSmall
            type="status_id"
            placeholder="حالة المزاد"
            setFilters={setFilters}
            optionsProp={filterData?.data?.map((item: any) => {
              return {
                label: item?.name_ar || "",
                value: item.id,
              };
            })}
          />{" "}
        </FiltersContainer>
        {loading ? (
          <TableLoader />
        ) : data && data?.data?.data ? (
          <>
            <AcceptedAuctionsTable
              rows={data.data.data}
              statusOptions={filterData?.data?.map((item: any) => {
                return {
                  name: item?.name_ar || "",
                  id: item.id,
                  class: item.class,
                };
              })}
            />
            {data.data.pagination.total_pages > 1 && <CustomPagination totalPages={data.data.pagination.total_pages} />}
          </>
        ) : null}{" "}
      </MainContainer>
    </div>
  );
};

export default AcceptedAuctions;
