export interface IUser {
  id: number;
  name: string;
  phone: string;
  email: string;
  image: string;
  roles: string[] | null;
  token: string | null;
  status: number;
}

export interface IPayRequest {
  id: number;
  name: IUser;
  pay_receipt: string | null;
  payment_method: IPaymentMethod | null;
  status: 1 | 2 | 3 | 4 | 0; // 1 new , 2 accepted , 3 rejected , 4 refund
  product: IProduct;
  note: string;
  created_at: string;
  amount: string;
  insurance_status: 1 | 2 | 3 | 4 | 0; // 1 new , 2 accepted , 3 rejected , 4 refund;
}

export interface IProduct {
  id: number;
  type: 1 | 2;
  name: string;
  has_conditions_document: 0 | 1;
}

export interface IPaymentMethod {
  icon: string;
  id: number;
  name: string;
  name_ar: string;
  name_en: string;
}

export interface IRealTimeAuction {
  buyer_name: string;
  buyer_phone: string;
  buyer_price: string;
  seller_price: string;
  buyer_id: number;
  created_at: string;
  id: number;
  quantity: string;
}

export interface IWebsiteProduct {
  id: number;
  logo: string;
  name: string;
  description: string | null;
  pdf: string | null;
  masafa_price: number | null;
  showCompany: ShowCompany | null; // 1 => yes, 2 => no
  quantity: number | null;
  less_quantity: number | null; // less_quantity for order in 5orda
  type: ProductType | null; // 1 => rwaked, 2 => 5orda
  price_type: PriceType | null; // 1 => fixed price, 2 => high price
  price: number | null;
  real_price: number | null;
  start_price: number | null;
  step_price: number | null;
  mazad_type: MazadType | null; // 1 => has data, 2 => open with no date
  end_date: string | null;
  lot: 1 | 0;
  hasConditionsDocument: 1 | 0;
  conditionsDocument: string | null;
  conditionsDocumentPrice: string | null;
  unitId?: number | null;
  catId?: number | null;
  addressId?: number | null;
  statusId?: number | null;
  userId: number | null;
  parentId: number | null;
  unit: any;
  user: any;
  status: any;
}

enum ShowCompany {
  Yes = 1,
  No = 2,
}

enum ProductType {
  Rwaked = 1,
  Horda = 2,
}

enum PriceType {
  Fixed = 1,
  High = 2,
}

enum MazadType {
  HasData = 1,
  OpenNoDate = 2,
}
export enum UserType {
  Seller = 1,
  Customer = 2,
}
export interface CommonModalProps<T> {
  open: boolean;
  handleClose: () => void;
  title: string;
  children?: React.ReactNode;
  handleConfirm: (value: T) => void;
  isLoading: boolean;
  width?: string;
  confirmButtonText?: string;
}

export interface TestimonialForm {
  [key: string]: any;
  id: number | null;
  image: string;
  name_ar: string;
  name_en: string;
  title_ar: string;
  title_en: string;
  description_ar: string;
  description_en: string;
  icon: string;
}

export interface BlogForm {
  [key: string]: any;
  id: number | null;
  image: string;
  title_ar: string;
  title_en: string;
  description_ar: string;
  description_en: string;
  date: string;
}

export interface WhyMarkoonItem {
  [key: string]: any;
  id: number | null;
  icon: string;
  title_ar: string;
  title_en: string;
  description_ar: string;
  description_en: string;
  type: number;
  page: number;
}

export interface MarkoonNumberItem {
  [key: string]: any;
  id: number | null;
  number: number | null;
  comment: string;
  comment_ar: string;
  comment_en: string;
  page: number;
}

export interface AboutDto {
  about_ar: string;
  about_en: string;
  title_ar: string;
  title_en: string;
  always_ar: string;
  always_en: string;
  always_title_ar: string;
  always_title_en: string;
  button_ar: string;
  button_en: string;
  button_link: string;
  what_ar: string;
  what_en: string;
  // always_image: string;
}

export interface InfoDto {
  name_ar: string;
  name_en: string;
  whyMrkoon_title_ar: string;
  whyMrkoon_title_en: string;
  ourNumbers_title_ar: string;
  ourNumbers_title_en: string;
  ourNumbers_desc_ar: string;
  ourNumbers_desc_en: string;

  testimonial_title_ar: string;
  testimonial_title_en: string;
  blog_title_ar: string;
  blog_title_en: string;
  join_title_ar: string;
  join_title_en: string;
  join_desc_ar: string;
  join_desc_en: string;
  footer_ar: string;
  footer_en: string;
  file: string;
}
