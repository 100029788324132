import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { cityValidationSchema, clientTypeValidationSchema } from "../../validation/Validation";
import { useDispatch, useSelector } from "react-redux";

import CustomSelect from "../CustomSelect/CustomSelect";
import CustomTextField from "../CustomTextField/CustomTextField";
import { mainSliceActions } from "../../store/main-slice";
import { modalActions } from "../../store/modal-slice";
import { postAxios } from "../../Services/axios-services";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { REACT_QUERY_BRANDS } from "../../constants/reactQuery.constants";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 4.75rem",
  width: "670px",
};

const MainModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: any) => state.modal);

  const handleClose = () => {
    dispatch(modalActions.closeModal());
  };
  const queryClient = useQueryClient();

  const submitApiCall = async (values: any, actions: any) => {
    try {
      const data = await postAxios(modalState.apiUrl, values);
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success("تمت العمليه بنجاح");
        handleClose();
        dispatch(mainSliceActions.toggleRefreshApi());
        queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_BRANDS],
        });
      }
    } catch (error: any) {
      //display the error in toast
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Modal open={modalState.open} onClose={handleClose} aria-labelledby="modal-add" aria-describedby="modal-add">
        <>
          <Formik
            initialValues={modalState.initialValues}
            onSubmit={(values, actions) => {
              submitApiCall(values, actions);
            }}
            validationSchema={modalState?.title === "clients" ? clientTypeValidationSchema : cityValidationSchema}
          >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
              <Form>
                <Box sx={style}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "2rem",
                        fontWeight: 700,
                        color: "#34B44A",
                        textAlign: "center",
                      }}
                    >
                      {modalState.type === "edit" ? " تعديل" : "أضافه"}
                    </Typography>
                    <Box
                      sx={{
                        margin: "1rem 0",
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      {/* display the custom fields dynamically depending on initial values */}
                      {Object.keys(modalState.initialValues).map((key: string) => {
                        if (key.includes("id") || key.includes("type") || key.includes("parent_id")) return null;
                        if (key?.includes("show_price")) {
                          return (
                            <CustomSelect
                              key={key}
                              name={key}
                              title="عرض السعر"
                              optionsProp={[
                                { value: "1", label: "السعر الأعلي" },
                                { value: "2", label: "السعر الأقل" },
                              ]}
                              placeholder="اختر السعر"
                              value={modalState.initialValues[key]}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          );
                        } else {
                          return (
                            <CustomTextField
                              label={key.includes("ar") ? "الاسم باللغه العربيه" : "الاسم باللغه الانجليزيه"}
                              required
                              name={key}
                              key={key}
                            />
                          );
                        }
                      })}
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                          color: "#FFFFFF",
                          borderRadius: "6px",
                        }}
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                      >
                        {modalState.type === "edit" ? " تعديل" : "أضافه"}
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "6px",
                          border: "2px solid #34B44A",
                          fontSize: "1rem",
                          fontWeight: 700,
                          height: "45px",
                          width: "200px",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        إلغاء
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default MainModal;
