import { Box, Typography } from "@mui/material";

import axios from "axios";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import Select, {
  ControlProps,
  GroupProps,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import "./CustomSelect.scss";

interface OptionType {
  value: string | number;
  label: string;
}

interface Props {
  placeholder: string;
  width?: string;
  title: string;
  required?: boolean;
  name: any;
  selectedValue?: any;
  errors?: any;
  touched?: any;
  userId?: any;
}

const SelectAddress = ({
  title,
  placeholder,
  width,
  required,
  name,
  selectedValue,
  errors,
  touched,
  userId,
}: Props) => {
  const { setFieldValue } = useFormikContext();

  const Control = ({ children, ...props }: ControlProps<OptionType, false>) => {
    return (
      <components.Control {...props}>
        <Box
          sx={{
            position: "absolute",
            top: "-11px",
            left: "9px",
            background: "white",
            padding: "0 5px",
            "& span": {
              position: "absolute",
              right: "-3px",
              top: "-2px",
              color: "red",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "black",
              fontWeight: "700",
              fontSize: ".8rem",
            }}
          >
            {title}
          </Typography>
          {required && <span>*</span>}
        </Box>

        {children}
      </components.Control>
    );
  };

  const Option = (props: OptionProps<OptionType>) => {
    return <components.Option {...props} />;
  };

  const Group = (props: GroupProps<OptionType>) => {
    return <components.Group {...props} />;
  };

  const customStyles: StylesConfig<OptionType, false> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: width ? width : "auto",
      boxShadow: "none",
      cursor: "pointer",
      borderRadius: "8px",
      border:
        name && errors[name] && touched[name]
          ? "1px solid red"
          : "1px solid #EAEAEA",
      padding: "9px 14px",
      position: "relative",
      "&:hover": {
        border: "1px solid #34B44A",
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      paddingLeft: "16px",
    }),
    group: (baseStyles, state) => ({
      ...baseStyles,
      paddingBottom: "4px",
      marginBottom: "4px",
    }),
  };
  const [value, setValue] = useState<any>(null);

  //getting data from api
  const [selectOptions, setSelectOptions] = useState<any>(null);

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const fetchSelectOptions = async (page: number) => {
    try {
      setLoadingOptions(true);
      let data;

      data = await axios.get(
        process.env.REACT_APP_API_URL +
          "address/all?user_id=" +
          userId +
          `?page=${page}`
      );
      if (data) {
        console.log(data);
        const { data: selectItems } = data?.data?.data;
        setTotalPages(data?.data?.data?.pagination?.total_pages);
        let newSelectOptions = [];

        newSelectOptions = selectItems?.map((city: any) => ({
          value: city.id,
          label: city.address,
          lat: city?.lat,
          lng: city?.lng,
        }));

        setSelectOptions(newSelectOptions);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOptions(false);
    }
  };

  useEffect(() => {
    fetchSelectOptions(1);
  }, [userId]);

  return (
    <Select
      value={
        selectOptions?.find((option: any) => option.value === value) ||
        selectOptions?.find((option: any) => option.value === selectedValue) ||
        null
      }
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={
        selectedValue
          ? selectOptions?.find((option: any) => option.value === selectedValue)
              ?.label
          : placeholder
      }
      options={selectOptions}
      isSearchable
      components={{ Control, Option, Group }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "#DEF7E5",
          primary: "#1BBC39",
        },
      })}
      styles={customStyles}
      name={name}
      onChange={(e) => {
        setFieldValue(name, e?.value);
        setValue(e?.value);
      }}
      onMenuScrollToBottom={() => {
        if (page < totalPages) {
          setPage(page + 1);
          fetchSelectOptions(page + 1);
        } else {
          return;
        }
      }}
      isLoading={loadingOptions}
    />
  );
};

export default SelectAddress;
