import { Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";
import TableLoader from "../../components/Loaders/TableLoader";
import SubCategoryTable from "../../components/Tables/SubCategoryTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { clientsActions } from "../../store/clients-slice";
import { modalActions } from "../../store/modal-slice";

type Props = {
  new?: boolean;
};

const ConstructionSubCategory = (props: Props) => {
  const { id } = useParams();

  const subApiUrl = useMemo(() => `categories/single?cat_id=${id}`, []);
  const mainState = useSelector((state: any) => state.main);
  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  const dispatch = useDispatch();

  const { data, loading, error } = useGetSingleApi(subApiUrl, dependencies);

  const handleDelete = async (values: any) => {
    try {
      let deleteApiUrl = `user/delete?user_id=`;
      const newUrl = values.checked.map((value: any, index: number) => {
        if (index === 0) {
          deleteApiUrl = deleteApiUrl + `${value}`;
        } else {
          deleteApiUrl = deleteApiUrl + `,${value}`;
        }
        return deleteApiUrl;
      });
      setDeleting(true);
      dispatch(clientsActions.toggleRefresh());

      await postAxios(newUrl).then((res) => {
        if (data.data.status !== 1) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
        }
      });
    } catch (err) {
    } finally {
      setDeleting(false);
      setDeleteMultiple(false);
      setDeleteAction(null);
    }
  };

  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);

  return (
    <div>
      <Header title="الفئات" />
      {/* <Formik
        initialValues={{
          checked: [],
        }}
        onSubmit={(values) => {
          setDeleteAction(() => () => handleDelete(values));
          setDeleteMultiple(true);
        }}
      >
        {({ values, handleSubmit }) => (
          <Form> */}
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            {"الفئات الفرعية"}
          </Typography>
          <OutlinedButton
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  type: "add",
                  apiUrl: "categories/create",
                  initialValues: {
                    name_ar: "",
                    name_en: "",
                    type: 2,
                    parent_id: Number(id),
                  },
                })
              );
            }}
            endIcon={<IoMdAdd />}
          >
            اضافة فئة
          </OutlinedButton>
        </Stack>
        {loading ? (
          <TableLoader />
        ) : data ? (
          <>
            <SubCategoryTable data={data.data.cats} type="cat" />
          </>
        ) : null}{" "}
      </MainContainer>
    </div>
  );
};

export default ConstructionSubCategory;
