import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmActionDialog({
  open,
  title,
  action,
  handleClose,
  loading,
}: {
  title: string;
  action: () => void;
  open: boolean;
  handleClose: () => void;
  loading: boolean;
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="confirm-action-dialog-slide-description"
      disableScrollLock={false}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <Button
          onClick={action}
          color="error"
          disabled={loading}
          endIcon={loading ? <CircularProgress size={10} /> : null}
        >
          تأكيد
        </Button>
        <Button onClick={handleClose}>الغاء</Button>
      </DialogActions>
    </Dialog>
  );
}
