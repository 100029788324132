import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  refresh: boolean;

  categoryModal: boolean;
  addNewCategoryValues: {
    name_ar: string;
    name_en: string;
    cat_id?: string;
    type: number | null;
  };
}

const initialState: initialStateType = {
  refresh: false,
  categoryModal: false,
  addNewCategoryValues: {
    name_ar: "",
    name_en: "",
    type: 2,
  },
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    toggleRefresh(state) {
      state.refresh = !state.refresh;
    },
    openModal(state) {
      state.categoryModal = true;
    },
    closeModal(state) {
      state.categoryModal = false;
      state.addNewCategoryValues = {
        name_ar: "",
        name_en: "",
        type: null,
      };
    },
    edit(state, action) {
      state.addNewCategoryValues = action.payload;
    },
    addNew(state, action) {
      state.addNewCategoryValues = {
        name_ar: "",
        name_en: "",
        type: action.payload,
      };
    },
  },
});

export const categoryActions = categorySlice.actions;
export default categorySlice;
