import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";

// import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAGeqRuscxv6G2DUCQovZ00-0BoPkgoUMw",
  authDomain: "markoon-e7ff3.firebaseapp.com",
  databaseURL: "https://markoon-e7ff3-default-rtdb.firebaseio.com",
  projectId: "markoon-e7ff3",
  storageBucket: "markoon-e7ff3.appspot.com",
  messagingSenderId: "179335307938",
  appId: "1:179335307938:web:0505ad7289182a0ed12dfb",
  measurementId: "G-WW4HYSVVVJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
