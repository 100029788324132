import React, { useState } from "react";
import Header from "../../components/Header/Header";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal";
import ProfileForm from "../../components/Forms/ProfileForm";
import ChangePasswordForm from "../../components/Forms/ChangePasswordForm";

type Props = {};

const StyledMainContainer = styled("div")({
  background: "#FFFFFF",
  boxShadow: "0px 1px 10px rgba(52, 180, 74, 0.25)",
  borderRadius: "8px",
  padding: "3rem .5rem",
});
const StyledTitle = styled("h3")({
  fontWeight: 700,
  fontSize: "1.325rem",
  paddingLeft: "1rem",
  color: "#34B44A",
  position: "relative",
  "&::before": {
    content: "''",
    position: "absolute",
    left: "1rem",
    bottom: "-8px",
    width: "90px",
    height: "2px",
    background: "#34B44A",
  },
});
const StyledSecondaryContainer = styled("div")({
  background: "#FFFFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "1rem 1rem 1.5rem 1rem",
});

const Profile = (props: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <main>
      <Header title="الملف الشخصي" />
      <StyledMainContainer>
        <Stack
          spacing={5}
          divider={
            <Divider
              sx={{
                borderColor: "rgba(0, 0, 0, 0.08)",
              }}
            />
          }
        >
          <Stack spacing={4}>
            <StyledTitle>تفاصيل حسابي</StyledTitle>
            <ProfileForm />
          </Stack>
          <Stack spacing={4}>
            <StyledTitle>تغيير كلمة السر</StyledTitle>
            <StyledSecondaryContainer>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(27, 40, 57, 0.7)",
                    fontSize: ".875rem",
                  }}
                >
                  من أجل حماية حسابك ، تأكد من أن كلمة المرور الخاصة بك تكون
                  مثل:
                </Typography>
                <Typography
                  sx={{
                    fontSize: ".875rem",
                    color: "#FF0000",
                    position: "relative",
                    paddingLeft: "8px",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      left: "-2px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "4px",
                      height: "4px",
                      borderRadius: "50%",
                      background: "#FF0000",
                    },
                  }}
                >
                  يجب ان لا تقل كلمة السر الجديدة عن 8 حروف
                </Typography>
                <Typography
                  sx={{
                    fontSize: ".875rem",
                    color: "#FF0000",
                    position: "relative",
                    paddingLeft: "8px",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      left: "-2px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "4px",
                      height: "4px",
                      borderRadius: "50%",
                      background: "#FF0000",
                    },
                    "& span": {
                      color: "rgba(27, 40, 57, 0.7)",
                    },
                  }}
                >
                  قم بتضمين الأحرف الكبيرة, الاحرف الصغيرة, الأرقام, الأحرف
                  الخاصة <span>(@#$&*!)</span>
                </Typography>
              </Box>
              {/* <Grid container columnSpacing={2.5}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    margin: "1.5rem 0",
                  }}
                >
                  <CustomTextField label="كلمة المرور" required />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{
                  fontWeight: 800,
                  fontSize: "1.125rem",
                  color: "#FFFFFF",
                  height: "50px",
                  width: "190px",
                  borderRadius: "8px",
                }}
                onClick={handleOpen}
              >
                نسيت كلمة المرور
              </Button> */}
              <ChangePasswordForm />
            </StyledSecondaryContainer>
          </Stack>
        </Stack>
      </StyledMainContainer>
      {/* <ChangePasswordModal open={open} handleClose={handleClose} /> */}
    </main>
  );
};

export default Profile;
