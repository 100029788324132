import { Typography, styled } from "@mui/material";

const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

export default StyledMainCell;
