import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  open: boolean;
  initialValues: any;
  type: "edit" | "add";
  apiUrl: string | null;
}

const initialState: initialStateType = {
  open: false,
  initialValues: {},
  type: "add",
  apiUrl: null,
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    openModal(state, action) {
      state.open = true;
      state.apiUrl = action.payload.apiUrl;
      state.type = action.payload.type;
      state.initialValues = action.payload.initialValues;
    },
    closeModal(state) {
      state.open = false;
      state.apiUrl = null;
      state.type = "add";
      state.initialValues = {};
    },
  },
});

export const addressActions = addressSlice.actions;
export default addressSlice;
