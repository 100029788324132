import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  open: boolean;
  initialValues: {
    product_id: string;
    masafa_price: string;
  };
}

const initialState: initialStateType = {
  open: false,
  initialValues: {
    product_id: "",
    masafa_price: "",
  },
};

const percentageSlice = createSlice({
  name: "percentage",
  initialState,
  reducers: {
    openModal(state, action) {
      state.open = true;
      state.initialValues = action.payload;
    },
    closeModal(state) {
      state.open = false;
      state.initialValues = {
        product_id: "",
        masafa_price: "",
      };
    },
  },
});

export const percentageActions = percentageSlice.actions;
export default percentageSlice;
