import * as React from "react";

import { ClientFilterItem, StyledDivider } from "../../clients";
import { Grid, Stack, Typography } from "@mui/material";

import { Combobox } from "../../../components";
import Drawer from "@mui/material/Drawer";
import { IPaymentMethod } from "../../../types";

type Props = {
  open: boolean;
  handleClose: () => void;
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>> | undefined;
  paymentMethodFilters?: IPaymentMethod[];
};

export const PayRequestFilterDrawer: React.FC<Props> = React.memo(
  ({ open, handleClose, filters, setFilters, paymentMethodFilters }) => {
    return (
      <Drawer
        keepMounted
        anchor="bottom"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack
          spacing={1}
          alignItems={"flex-start"}
          sx={{
            padding: "1rem",
          }}
        >
          <Typography variant="h2" color="primary.main">
            فلتر طلبات الدفع
            <StyledDivider />
          </Typography>
          <Grid container spacing={2} columns={100}>
            <Grid item xs={20}>
              <ClientFilterItem
                filters={filters}
                setFilters={setFilters}
                name="status"
                title="حالة دفع كراسة الشروط"
                options={[
                  { value: "1", label: "جديد" },
                  { value: "2", label: "مدفوع" },
                  { value: "3", label: "غير مدفوع" },
                  { value: "4", label: "استرجاع" },
                ]}
                multi
              />
            </Grid>
            <Grid item xs={20}>
              <ClientFilterItem
                filters={filters}
                setFilters={setFilters}
                name="insurance_status"
                title="حالة دفع المبلغ التأميني"
                options={[
                  { value: "1", label: "جديد" },
                  { value: "2", label: "مدفوع" },
                  { value: "3", label: "غير مدفوع" },
                  { value: "4", label: "استرجاع" },
                ]}
                multi
              />
            </Grid>
            {paymentMethodFilters && (
              <Grid item xs={40}>
                <ClientFilterItem
                  setFilters={setFilters}
                  filters={filters}
                  name="payment_method"
                  title="طريقة الدفع"
                  options={
                    paymentMethodFilters?.map((item) => ({
                      value: String(item.id),
                      label: item.name,
                    })) || []
                  }
                  multi
                />
              </Grid>
            )}
            <Grid item xs={20}>
              <Combobox />
            </Grid>
          </Grid>
        </Stack>
      </Drawer>
    );
  }
);
