import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  options: { value: string; label: string }[];
  name: string;
  setFilters?: React.Dispatch<React.SetStateAction<any>> | undefined;
  filters?: any;
  multi?: boolean;
  reset?: string;
};

export const ClientFilterItem = ({
  title,
  options,
  name,
  filters,
  setFilters,
  multi,
  reset,
}: Props) => {
  return (
    <Stack>
      <Typography variant="body1" color="primary.main">
        {title}
      </Typography>
      <Stack
        sx={{
          maxHeight: 225,
          flexWrap: "wrap",
        }}
      >
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={
                  multi
                    ? filters && filters[name]?.includes(option.value)
                    : filters && Number(filters[name]) === Number(option.value)
                }
                onChange={(e) => {
                  if (multi) {
                    if (filters && filters[name].includes(option.value)) {
                      setFilters &&
                        setFilters({
                          ...filters,
                          [name]: filters[name].filter(
                            (item: string) => item !== option.value
                          ),
                        });
                    } else {
                      setFilters &&
                        setFilters({
                          ...filters,
                          [name]: [
                            ...(filters && filters[name] ? filters[name] : []),
                            option.value,
                          ],
                        });
                    }
                  } else {
                    if (
                      filters &&
                      Number(filters[name]) === Number(option.value)
                    ) {
                      setFilters && setFilters({ ...filters, [name]: "" });
                    } else {
                      if (reset) {
                        setFilters &&
                          setFilters({
                            ...filters,
                            [reset]: "",
                            [name]: option.value,
                          });
                      } else {
                        setFilters &&
                          setFilters({ ...filters, [name]: [option.value] });
                      }
                    }
                  }
                }}
              />
            }
            label={option.label}
          />
        ))}
      </Stack>
    </Stack>
  );
};
