import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const rows = [
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
  {
    date: "20/08/2022",
    name: "حديد مسلح",
    quantity: "20 طن",
    price: "3,650",
  },
];
const tableHeaders: string[] = ["التاريخ", "اسم المنتج", "الكمية", "السعر"];

//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  //   padding: "1.125rem 0",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});
const StyledPrice = styled(Typography)({
  fontWeight: "800",
  fontSize: "1.5rem",
  textAlign: "center",
  color: "#34B44A",
  "& span": {
    fontSize: "13px",
    color: "black",
    fontWeight: "400",
  },
});

//  row component
interface CustomTableRowProps {
  name: string;
  quantity: string;
  price: string;
  date: string;

  id: number;
}

const CustomTableRow = ({
  name,
  quantity,
  price,
  date,

  id,
}: CustomTableRowProps) => {
  return (
    <StyledTableRow
      sx={{
        backgroundColor: id % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
      }}
    >
      <StyledMainCell>{date}</StyledMainCell>

      <StyledMainCell>{name}</StyledMainCell>
      <StyledMainCell>{quantity}</StyledMainCell>
      <StyledPrice>
        {price} <span>جنيها</span>
      </StyledPrice>
    </StyledTableRow>
  );
};

// main component

interface StatisticsTableProps {
  // tableType: 1 | 2;
}

export default function StatisticsTable({}: StatisticsTableProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <StyledTableHeader>
        {tableHeaders.map((header, index) => {
          return <h6 key={`${header + index}`}>{header}</h6>;
        })}
      </StyledTableHeader>
      <Stack spacing={1}>
        {rows.map((row, index) => {
          return <CustomTableRow {...row} id={index} />;
        })}
      </Stack>
    </Box>
  );
}
