import { Form, Formik } from "formik";
import { CommonModalProps, TestimonialForm } from "../../types";
import { CommonModal } from "./commonModal";
import { Box, Button, Stack } from "@mui/material";
import { BiPlus } from "react-icons/bi";
import CustomTextField from "../CustomTextField/CustomTextField";
import { ChangeEventHandler, useEffect, useState } from "react";

interface TestimonialModalProps extends CommonModalProps<TestimonialForm> {
  testimonial: TestimonialForm | null;
  isEdit?: boolean;
}

export const TestimonialModal: React.FC<TestimonialModalProps> = ({
  open,
  handleClose,
  title,
  testimonial,
  handleConfirm,
  isLoading,
  isEdit,
}) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(testimonial?.image ?? undefined);
  const [iconUrl, setIconUrl] = useState<string | undefined>(testimonial?.icon ?? undefined);
  const handleImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setImageUrl(URL.createObjectURL(file));
    }
  };
  const handleIconChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setIconUrl(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    if (testimonial?.image) {
      setImageUrl(testimonial.image);
    }
    if (testimonial?.icon) {
      setIconUrl(testimonial.icon);
    }
  }, [testimonial]);
  return (
    <Formik
      initialValues={{
        id: testimonial?.id || null,
        name_ar: testimonial?.name_ar ?? "",
        name_en: testimonial?.name_en ?? "",
        title_ar: testimonial?.title_ar ?? "",
        title_en: testimonial?.title_en ?? "",
        description_ar: testimonial?.description_ar ?? "",
        description_en: testimonial?.description_en ?? "",
        image: testimonial?.image ?? "",
        icon: testimonial?.icon ?? "",
      }}
      onSubmit={(v) => handleConfirm(v)}
      enableReinitialize={true}
    >
      {({ setFieldValue, handleSubmit, resetForm }) => (
        <CommonModal
          open={open}
          handleClose={() => {
            setImageUrl(undefined);
            setIconUrl(undefined);
            resetForm();
            handleClose();
          }}
          title={title}
          handleConfirm={() => {
            //@ts-ignore
            return handleSubmit()?.then(() => {
              resetForm();
              setImageUrl(undefined);
              setIconUrl(undefined);
            });
          }}
          isLoading={isLoading}
          width={"80%"}
          confirmButtonText={isEdit ? "تعديل" : undefined}
        >
          <Form>
            <Stack spacing={4}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <CustomTextField name="name_ar" label="اسم العميل بالعربي" multiLine={1} />
                <CustomTextField name="name_en" label="اسم العميل بالإنجليزي" multiLine={1} />
                <CustomTextField name="title_ar" label="لقب العميل بالعربي" multiLine={1} />
                <CustomTextField name="title_en" label="لقب العميل بالانجليزي" multiLine={1} />
                <CustomTextField name="description_ar" label="رأى العميل بالعربي" multiLine={3} />
                <CustomTextField name="description_en" label="رأى العميل بالانجليزي" multiLine={3} />
              </Box>
              {/* Image and icom */}
              <Stack gap={"1rem"}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <label htmlFor="image">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        height: "45px",
                        width: "200px",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      component="span"
                      disabled={isLoading}
                      startIcon={<BiPlus size={24} />}
                    >
                      إضافة صورة العميل
                    </Button>
                  </label>
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                    name="image"
                    disabled={isLoading}
                    onChange={(e) => {
                      handleImageChange(e);
                      setFieldValue("image", e.target.files?.[0]);
                    }}
                  />
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Selected"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Stack>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <label htmlFor="icon">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        height: "45px",
                        width: "200px",
                        color: "#FFFFFF",
                        borderRadius: "6px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      component="span"
                      disabled={isLoading}
                      startIcon={<BiPlus size={24} />}
                    >
                      إضافة صورة الشركة
                    </Button>
                  </label>
                  <input
                    id="icon"
                    type="file"
                    accept="image/*"
                    name="icon"
                    style={{
                      display: "none",
                    }}
                    disabled={isLoading}
                    onChange={(e) => {
                      handleIconChange(e);
                      setFieldValue("icon", e.target.files?.[0]);
                    }}
                  />
                  {iconUrl && (
                    <img
                      src={iconUrl}
                      alt="Selected"
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Form>
        </CommonModal>
      )}
    </Formik>
  );
};
