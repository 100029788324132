import {
  Box,
  Button,
  Chip,
  Modal,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import CustomSelectSmall from "../CustomSelect/CustomSelectSmall";
import TableLoader from "../Loaders/TableLoader";
import { useDebounce } from "@uidotdev/usehooks";
import { useFormikContext } from "formik";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useState } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 4.75rem",
  width: "1100px",
  maxHeight: "90vh",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#34B44A",
    borderRadius: "24px",
  },
};

const AddClientToProductModal: React.FC<Props> = ({ open, onClose }) => {
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({
    status: null,
  });
  const debouncedSearch = useDebounce(searchText, 500);
  const { data, loading } = useGetSingleApi(
    `user/get?status=${
      filters?.status || ""
    }&limit=all&search_text=${debouncedSearch}`,
    [debouncedSearch, filters]
  );
  const { setFieldValue, values } = useFormikContext<{
    user_id: string;
  }>();
  const handleClient = (user: any) => {
    if (values?.user_id === user?.id) {
      setFieldValue("user_id", "");
      setFieldValue("user", "");
    } else {
      setFieldValue("user_id", user?.id);
      setFieldValue("user", user);
      onClose();
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" color="#34B44A" fontWeight="400">
              اضافة عميل الى المنتج
            </Typography>
            <Stack direction="row" spacing={2}>
              <TextField
                sx={{
                  width: "450px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "1rem",
                  },
                }}
                size="small"
                label="بحث"
                onChange={(e) => setSearchText(e.target.value)}
              />
              <CustomSelectSmall
                placeholder="حاله العميل"
                optionsProp={[
                  { value: "1", label: "مفعل" },
                  { value: "2", label: "غير مفعل" },
                  { value: "3", label: "جديد" },
                  { value: "4", label: "لم يتم الرد" },
                ]}
                setFilters={setFilters}
                type="status"
              />
            </Stack>
          </Stack>
          <StyledTableHeader>
            <Typography variant="h6" color="#34B44A" fontWeight="400">
              #
            </Typography>
            <Typography variant="h6" color="#34B44A" fontWeight="400">
              اسم العميل
            </Typography>
            <Typography variant="h6" color="#34B44A" fontWeight="400">
              رقم الهاتف
            </Typography>
            <Typography variant="h6" color="#34B44A" fontWeight="400">
              نوع العميل
            </Typography>
            <Typography variant="h6" color="#34B44A" fontWeight="400">
              حالة العميل
            </Typography>
          </StyledTableHeader>
          {loading ? (
            <TableLoader />
          ) : (
            data?.data?.data?.map((client: any) => (
              <StyledTableRow
                key={client.id}
                sx={{
                  backgroundColor:
                    values?.user_id === client.id ? "#34b44a3b" : "white",
                }}
                onClick={() => {
                  handleClient(client);
                }}
              >
                <StyledMainCell>{client.id}</StyledMainCell>
                <StyledMainCell>{client.name}</StyledMainCell>
                <StyledMainCell>{client.phone}</StyledMainCell>
                <StyledMainCell>{client.user_type.title}</StyledMainCell>
                <Chip
                  sx={{
                    width: "120px",
                  }}
                  label={
                    client?.status === 1
                      ? "مفعل"
                      : client?.status === 2
                      ? "غير مفعل"
                      : client?.status === 4
                      ? "لم يتم الرد"
                      : "جديد"
                  }
                />
              </StyledTableRow>
            ))
          )}
          <Button
            onClick={onClose}
            variant="contained"
            sx={{ margin: "2rem auto !important", width: "350px" }}
          >
            متابعة
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const StyledTableHeader = styled("div")({
  minWidth: "990px",
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr 1fr 1fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});
const StyledTableRow = styled("div")({
  minWidth: "990px",
  cursor: "pointer",
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr 1fr 1fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "70px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  " &:hover": {
    border: "1px solid #34B44A",
  },
});
const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

export default AddClientToProductModal;
