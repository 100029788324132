import { REACT_QUERY_PRODUCTS } from "../constants/reactQuery.constants";
import { getProducts } from "../apis/products_apis";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useProducts = (filters?: any) => {
  const { data, error, isPending, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: [
      REACT_QUERY_PRODUCTS,
      filters?.type,
      filters?.status_id,
      filters?.price_type,
      filters?.search_text,
      filters?.parent_id,
      filters?.active_bid,
    ],
    queryFn: getProducts,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < 10) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return {
    data,
    error,
    isPending,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    ref,
  };
};
