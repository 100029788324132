import { Box, Button, CircularProgress, Grid, Modal, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";
import { IPaymentMethod } from "../../../types";
import { MdUpload } from "react-icons/md";
import { INSURANCE_STATUS_OPTIONS, PAY_REQUEST_STATUS_OPTIONS } from "../../../constants/payRequests.constants";
import { usePay } from "../hooks";

type Props = {
  handleClose: () => void;
  open: boolean;
  payRequest_id: string;
  paymentMethods: IPaymentMethod[];
  initialValues?: any;
};

export const PayRequestFormModal = ({ handleClose, open, payRequest_id, paymentMethods, initialValues }: Props) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    backgroundColor: "#FFFF",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
    borderRadius: "10px",
    padding: "1rem 3rem",
    width: "800px",
    display: "flex",
    flexDirection: "column",
  };

  const { mutate } = usePay(handleClose);
  return (
    <>
      <Modal open={open} aria-labelledby="modal-modal-Address" aria-describedby="modal-modal-add-address">
        <Box sx={style}>
          <Stack spacing={2}>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "700",
                color: "#34B44A",
                textAlign: "center",
                marginBottom: ".25rem",
                textDecoration: "underline",
              }}
            >
              طلب دفع
            </Typography>
            <Formik
              enableReinitialize
              // initialValues={{
              //   // payRequest_id: payRequest_id,
              //   // // 2=>pay , 3=>un pay
              //   // status: "",
              //   // payment_method_id: "",
              //   // pay_receipt: "",
              //   // note: "",
              //   // file upload
              // }}
              initialValues={initialValues}
              onSubmit={(v) => {
                mutate(v);
              }}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xs={6}>
                      <CustomSelect
                        name="status"
                        title="حالة دفع كراسة الشروط"
                        optionsProp={[
                          {
                            value: PAY_REQUEST_STATUS_OPTIONS.PAID,
                            label: "دفع",
                          },
                          {
                            value: PAY_REQUEST_STATUS_OPTIONS.UNPAID,
                            label: "غير مدفوع",
                          },
                          {
                            value: PAY_REQUEST_STATUS_OPTIONS.REFUND,
                            label: "استرجاع",
                          },
                          {
                            value: PAY_REQUEST_STATUS_OPTIONS.EXCLUDED,
                            label: "مستثني",
                          },
                        ]}
                        placeholder="حالة الدفع"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomSelect
                        name="payment_method_id"
                        title="وسيلة الدفع"
                        optionsProp={paymentMethods.map((method) => ({
                          value: method.id.toString(),
                          label: method.name,
                        }))}
                        placeholder="وسيلة الدفع"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomSelect
                        name="insurance_status"
                        title="حالة دفع المبلغ التأميني"
                        optionsProp={[
                          {
                            value: INSURANCE_STATUS_OPTIONS.PAID,
                            label: "دفع",
                          },
                          {
                            value: INSURANCE_STATUS_OPTIONS.UNPAID,
                            label: "غير مدفوع",
                          },
                          {
                            value: INSURANCE_STATUS_OPTIONS.REFUND,
                            label: "استرجاع",
                          },
                          {
                            value: INSURANCE_STATUS_OPTIONS.EXCLUDED,
                            label: "مستثني",
                          },
                        ]}
                        placeholder="حالة دفع"
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField name="note" label="ملاحظات" multiLine={3} />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1} justifyContent="center" alignItems="center">
                        <Button
                          variant="text"
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                          sx={{
                            border: "1px solid rgba(0, 0, 0, 0.08)",
                            borderRadius: "6px",
                            minWidth: "250px",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                              const files = e.target ? e.target.files : null;
                              if (files) {
                                setFieldValue("pay_receipt", files[0]);
                              }
                            }}
                          />
                          <Typography>اضافة ايصال</Typography>
                          <MdUpload />
                        </Button>
                        {(values?.pay_receipt as any) instanceof File && (
                          <Typography>{(values.pay_receipt as any).name.split(".")[0]}</Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                          endIcon={isSubmitting ? <CircularProgress size={10} /> : null}
                        >
                          تأكيد
                        </Button>
                        <Button onClick={handleClose} color="error">
                          الغاء
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
