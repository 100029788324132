import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import { AiFillEye } from "react-icons/ai";
import AuctionRealTimeModal from "../Modals/AuctionRealTimeModal";
import AuctionsDetailsModal from "../Modals/AuctionsDetailsModal";
import MainChip from "../ClientStatusChip/MainChip";
import { REACT_QUERY_PRODUCTS } from "../../constants/reactQuery.constants";
import StyledClientName from "../StyledComponents/StyledClientName";
import StyledPrice from "../StyledComponents/StyledPrice";
import durationIcon from "../../assets/images/icons/timer-icon.svg";
import getRemainingDays from "../../helpers/getRemainingDays";
import getSmallerText from "../../helpers/getSmallerText";
import quantityIcon from "../../assets/images/icons/quantity-icon.svg";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

//  row component
interface CustomTableRowProps {
  name: string;
  quantity: string;
  high_price: string;
  end_date: string;
  auctions_count: number;
  unit: {
    id: number;
    name_ar: string;
  };
  user: {
    id: number;
    name: string;
    image: string;
    phone: string;
  };
  id: number;
  type: number;
  handleOpen: () => void;

  setId: React.Dispatch<React.SetStateAction<number | null>>;
  start_price: string;
  statusOptions: any;
  status: any;
}

const CustomTableRow = ({
  setId,
  name,
  quantity,
  user,
  end_date,
  auctions_count,
  unit,
  id,
  type,
  high_price,
  start_price,
  handleOpen,
  statusOptions,
  status,
}: CustomTableRowProps) => {
  const navigate = useNavigate();
  return (
    <StyledTableRow
      sx={{
        backgroundColor: id % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
      }}
    >
      <StyledMainCell>{id}</StyledMainCell>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "100%",
            bgcolor: "transparent",
            height: "100%",
            zIndex: 59894977,
          }}
          onClick={() => {
            window.open(`/clients/all/${user.id}`, "_blank");
          }}
        />
        <StyledClientName name={user.name} image={user.image} />
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "700",
            color: "gray",
            textAlign: "center",
          }}
        >
          {user.phone}
        </Typography>
      </Box>
      <Tooltip title={name} placement="top" arrow>
        <StyledMainCell
          onClick={() => {
            if (type === 1) {
              navigate(`/rawakd/all/${id}`);
            } else {
              navigate(`/khorda/all/${id}`);
            }
          }}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {getSmallerText(name, 20)}
        </StyledMainCell>
      </Tooltip>

      <StyledQuantityCell>
        <img src={quantityIcon} alt="weight" />
        باقي {Number(quantity)} {unit.name_ar}
      </StyledQuantityCell>

      {end_date ? (
        getRemainingDays(end_date) === 0 ? (
          <StyledMainCell
            sx={{
              color: "#FF1F1F",
            }}
          >
            انتهي
          </StyledMainCell>
        ) : (
          <StyledDurationCell>
            <img src={durationIcon} alt="duration-icon" />
            ينتهي خلال {getRemainingDays(end_date)} يوم !
          </StyledDurationCell>
        )
      ) : (
        <StyledMainCell>لم يتم تحديد المدة</StyledMainCell>
      )}
      <StyledPrice>
        {Number(high_price).toLocaleString()} <span>جنيها</span>
      </StyledPrice>
      <StyledPrice>
        {Number(start_price).toLocaleString()} <span>جنيها</span>
      </StyledPrice>
      <StyledBiddersCell>
        {auctions_count} <span>مزايد</span>
      </StyledBiddersCell>
      <MainChip
        modelName="product"
        status={{
          name: status?.name_ar,
          id: status?.id,
          class: status?.class,
        }}
        statusApiUrl="products/change_status"
        itemId={String(id)}
        statusOptions={statusOptions}
      />
      <IconButton
        onClick={() => {
          setId(id);
          handleOpen();
        }}
        size="small"
        aria-label="open-details-Modal"
      >
        <AiFillEye />
      </IconButton>
    </StyledTableRow>
  );
};

// main component

interface AuctionsTableProps {
  status?: "new" | undefined;
  rows: {
    high_price: string;
    start_price: string;
    user: {
      id: number;
      name: string;
      image: string;
      phone: string;
    };
    name: string;
    id: number;
    type: number;
    quantity: string;
    unit: {
      id: number;
      name_ar: string;
    };
    auctions_count: number;
    end_date: string;
    status: any;
  }[];
  statusOptions?: any;
  realTime?: boolean;
}

export default function AuctionsTable({ rows, status, statusOptions, realTime = false }: AuctionsTableProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const queryClient = useQueryClient();

  const [id, setId] = useState<number | null>(null);
  const handleClose = () => {
    queryClient.invalidateQueries({
      queryKey: [REACT_QUERY_PRODUCTS],
    });
    setOpen(false);
    setId(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflowX: "auto",
      }}
    >
      <StyledTableHeader>
        <h6>كود المنتج</h6>
        <h6>البائع</h6>
        <h6>اسم المنتج</h6>
        <h6>الكمية</h6>
        <h6>المدة</h6>
        <h6>أعلي سعر</h6>
        <h6>أقل سعر</h6>
        <h6>عدد المزايدين</h6>
        <h6>حالة المنتج</h6>
      </StyledTableHeader>
      <Stack spacing={1}>
        {rows.length === 0 && (
          <Typography
            sx={{
              color: "#34B44A",
              fontWeight: "700",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            لا يوجد مزادات لعرضها
          </Typography>
        )}
        {rows.map((row) => {
          return (
            <CustomTableRow
              setId={setId}
              {...row}
              id={row.id}
              key={row.id}
              handleOpen={handleOpen}
              statusOptions={statusOptions}
            />
          );
        })}
      </Stack>
      {realTime && id ? (
        <AuctionRealTimeModal id={id} handleClose={handleClose} open={open} />
      ) : (
        <AuctionsDetailsModal id={id} open={open} handleClose={handleClose} status={status} />
      )}
    </Box>
  );
}

const StyledTableHeader = styled("div")({
  minWidth: "1280px",

  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  gridTemplateColumns: ".75fr 1.25fr 1fr 1.5fr 1.75fr 1fr 1fr 1fr 1fr .25fr",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  minWidth: "1280px",
  display: "grid",
  gridTemplateColumns: ".75fr 1.25fr 1fr 1.5fr 1.75fr 1fr 1fr 1fr 1fr .25fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "80px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

const StyledQuantityCell = styled("div")({
  background: "rgba(0, 0, 0, 0.09)",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  color: "#767676",
  fontWeight: "700",
  gap: ".5rem",
  padding: "5px 12px",
});

const StyledDurationCell = styled("div")({
  background: " rgba(255, 140, 103, 0.2)",
  borderRadius: "4px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  color: "#FF1F1F",
  gap: ".5rem",
  padding: "5px 12px",
});
const StyledBiddersCell = styled("div")({
  background: "rgba(222, 247, 229, 0.68)",
  borderRadius: "5px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  fontSize: "1.25rem",
  fontWeight: "800",
  color: "#34B44A",
  gap: ".5rem",
  padding: "5px 12px",
  "& span": {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "1rem",
    fontWeight: "400",
  },
});
