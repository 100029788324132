import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateWhyMarkoon } from "../../apis/whyMarkoon_apis";

export const useUpdateWhyItem = (fn?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateWhyMarkoon,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["why_markoon"] });
      if (data?.status === 1) {
        toast.success("تم تحديث العنصر بنجاح");
      }
      fn && fn();
    },
    onError: () => {
      toast.error("حدث خطأ ما");
    },
  });
};
