import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddClientForm from "../../components/Forms/AddClientForm";
import Header from "../../components/Header/Header";
import MainLoader from "../../components/Loaders/MainLoader";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const EditClient = (props: Props) => {
  const { id } = useParams();
  const mainState = useSelector((state: any) => state.main);
  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);

  const { data, loading } = useGetSingleApi(`user/single?user_id=${id}`, dependencies);

  const [initialValues, setInitialValues] = useState({
    name: "",
    company_name: "",
    company_logo: "",
    phone: "",
    email: "",
    user_type: "",
    user_type_id: "",
    status: "",
    commercial_register: "",
    tax_card: "",
    added_tax_number: "",
    electronic_invoice: "",
    image: "",
    password: "",
    individual_or_company: "",
    cats: "",
    address: "",
    city_id: "",
    rate: "",
    adds: [
      {
        key: "",
        value: "",
      },
    ],
    user_id: id,
  });

  useEffect(() => {
    setInitialValues({
      name: data?.data.name || "",
      company_name: data?.data.company_name || "",
      company_logo: data?.data.company_logo || "",
      phone: data?.data.phone || "",
      email: data?.data.email || "",
      user_type: data?.data.user_type?.parent_id || "",
      user_type_id: data?.data?.user_type?.id || "",
      status: data?.data.status,
      commercial_register: data?.data.commercial_register || "",
      tax_card: data?.data.tax_card || "",
      added_tax_number: data?.data.added_tax_number || "",
      electronic_invoice: data?.data.electronic_invoice || "",
      image: data?.data.image || "",
      password: data?.data.password || "",
      individual_or_company: data?.data?.individual_or_company || "",
      cats: data?.data?.cats.map((item: any) => item.id) || "",
      user_id: id || "",
      address: data?.data?.mainAddress?.address || "",
      city_id: data?.data?.mainAddress?.city?.id || "",
      rate: data?.data?.rate || "",
      adds:
        data?.data.adds.length > 0
          ? data.data.adds
          : [
              {
                key: "",
                value: "",
              },
            ],
    });
  }, [data]);

  return (
    <div>
      <Header title="العملاء" />
      {loading ? <MainLoader /> : <AddClientForm initialValues={initialValues} type="edit" />}
    </div>
  );
};

export default EditClient;
