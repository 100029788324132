import { Box, Stack, Typography } from "@mui/material";
import { memo } from "react";
import Header from "../../components/Header/Header";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import MyOrdersMainCard from "../../components/MyOrdersMainCard/MyOrdersMainCard";
import NewProductsCard from "../../components/MyOrdersMainCard/NewProductsCard";
import StaticsContainer from "../../components/StaticsContainer/StaticsContainer";
import { HotAuctions } from "../../features";
import styles from "./Home.module.scss";

const Home = () => {
  return (
    <main className={styles.home}>
      <Header title="مرحبا بك" />
      <Typography variant="h6" sx={{ fontSize: "1.25rem", color: "rgba(0, 0, 0, 0.65)" }}>
        هذه احصائيات لاخر فترة عملت فيها تحديثات علي حسابك
      </Typography>
      <Stack
        spacing={3}
        sx={{
          marginTop: "2rem",
        }}
      >
        <HomeBanner />
        <StaticsContainer />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "12px",
          }}
        >
          {/* <MyChart /> */}
          <MyOrdersMainCard />
          <NewProductsCard />
          <HotAuctions />
        </Box>
      </Stack>
    </main>
  );
};

export default memo(Home);
