import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  isOpen: boolean;
  userId: string;
  userName: string;
}

const initialState: ModalState = {
  isOpen: false,
  userId: "",
  userName: "",
};
type payloadType = {
  userId: string;
  userName: string;
};

const messageModalSlice = createSlice({
  name: "messageModal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<payloadType>) => {
      state.isOpen = true;
      state.userId = action.payload.userId;
      state.userName = action.payload.userName;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.userId = "";
      state.userName = "";
    },
  },
});

export const messageModalActions = messageModalSlice.actions;
export default messageModalSlice;
