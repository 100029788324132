import { Box } from "@mui/material";
import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

type Props = {};

const MainLoader = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        width: "100%",
      }}
    >
      <PulseLoader color="#1bbc39" size={25} />
    </Box>
  );
};

export default MainLoader;
