import { Stack, Typography } from "@mui/material";
import MainContainer from "../Containers/MainContainer";
import Header from "../Header/Header";
import { AboutForm } from "./aboutForm.component";
import { useGetAbout } from "../../hooks/about/useGetAbout";
import MainLoader from "../Loaders/MainLoader";

export const AboutContent: React.FC = () => {
  const { data, isPending } = useGetAbout();
  console.log("data", data);
  return (
    <div>
      <Header title="عن مركون" />
      <MainContainer>
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: "700",
            fontSize: "1.375rem",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "2px",
              left: "0",
              width: "90px",
              height: "2px",
              backgroundColor: "primary.main",
            },
          }}
        >
          عن مركون
        </Typography>
        <Stack>{isPending ? <MainLoader /> : <AboutForm initialValues={data?.data} />}</Stack>
      </MainContainer>
    </div>
  );
};
