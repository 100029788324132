import { useMutation } from "@tanstack/react-query";
import { addPartner } from "../../apis/partners_apis";
import { toast } from "react-toastify";

export const useAddPartner = (fn?: () => void) => {
  return useMutation({
    mutationFn: addPartner,
    onSuccess: (data) => {
      if (data.status === 1) {
        toast.success("تمت العمليه بنجاح");
        fn && fn();
      } else {
        toast.error("حدث خطأ ما");
      }
    },
    onError: (error) => {
      toast.error("حدث خطأ ما");
    },
  });
};
