import { Box, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import { dir } from "console";
import { postAxios } from "../../../Services/axios-services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { userActions } from "../../../store/user-slice";

interface OtpModalProps {
  open: boolean;
  onClose: () => void;
}

export const OtpModal: React.FC<OtpModalProps> = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const checkcode = async (values: any) => {
    try {
      let data = {
        phone: values.phone || "",
        code: values.code || "",
      };
      setSubmitting(true);

      await postAxios("auth/check_code_to_login", data).then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          dispatch(userActions.login(res.data.data));
          // console.log(res?.data?.data);
          window.location.href = "/";
        } else if (res.status === 200 && res.data.status === 0) {
          toast.error(res.data.message, {
            toastId: "LoginForm",
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(`Something Went wrong`, {
        toastId: "LoginForm",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const { values, setFieldValue } = useFormikContext<{
    code: string;
    phone: string;
    password: string;
  }>();
  useEffect(() => {
    if (values.code.length === 4) {
      checkcode(values);
    }
  }, [values]);
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-otp" aria-describedby="modal-otp">
      <Box sx={style}>
        <Stack spacing={3} alignItems={"center"}>
          <Typography
            variant="h3"
            align="center"
            color="primary"
            sx={{
              textDecoration: "underline",
            }}
          >
            Enter OTP
          </Typography>
          <OtpInput
            value={values.code}
            onChange={(code) => {
              setFieldValue("code", code);
            }}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} disabled={isSubmitting} />}
            inputStyle={isSubmitting ? { ...otpInputStyle, ...otpInputDisabledStyle } : otpInputStyle}
            containerStyle={otpContainerStyle}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "1.25rem",
  padding: "2rem 4.75rem",
  width: "450px",
  direction: "rtl",
};

const otpInputStyle = {
  width: "4rem",
  height: "4rem",
  margin: "0 0.5rem",
  fontSize: "1.5rem",
  borderRadius: "0.5rem",
  border: "1px solid rgba(0, 0, 0, 0.3)",
  textAlign: "center" as "center",
  backgroundColor: "#FFF",
};

const otpInputDisabledStyle = {
  backgroundColor: "rgb(204 204 204)",
};

const otpContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
