import { Box, Collapse, Typography, styled } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
  filterExpanded: boolean;
  handleSubmitFilters?: () => void;
  showButton?: boolean;
};
const StyledFilterContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  padding: "1.125rem 1.75rem",
});

const FiltersContainer = (props: Props) => {
  return (
    <Collapse in={props.filterExpanded} timeout={200}>
      <StyledFilterContainer>
        <Box
          sx={{
            width: "100%",
            // display: "flex",
            // alignItems: "center",
            // gap: "1rem",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {props.children}
        </Box>
        {props?.showButton === false ? (
          <></>
        ) : (
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: "700",
              color: "primary.main",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={props.handleSubmitFilters}
          >
            تطبيق
          </Typography>
        )}
      </StyledFilterContainer>
    </Collapse>
  );
};

export default FiltersContainer;
