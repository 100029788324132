import { useMutation, useQueryClient } from "@tanstack/react-query";

import { REACT_QUERY_PAY_REQUESTS } from "../../../constants/reactQuery.constants";
import { createPayRequest } from "../../../apis";
import { toast } from "react-toastify";

type Props = {
  handleClose: () => void;
};

export const useCreatePayRequest = ({ handleClose }: Props) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPayRequest,
    onSuccess: (data) => {
      if (data?.status === 1) {
        toast.success("تم الدفع بنجاح");
        queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_PAY_REQUESTS],
        });
        handleClose();
      } else {
        toast.error(data?.message || "حدث خطأ ما");
      }
    },
    onError: (e: any) => {
      handleClose();
      toast.error(e?.response?.data?.message || "حدث خطأ ما");
    },
  });
};
