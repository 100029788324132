import { ProductForm } from "../../features";
import useAddProducts from "../../hooks/useAddProcuts";

type Props = {};

const AddKhorda = (props: Props) => {
  const { initialValues, handleSubmit } = useAddProducts({ type: 2 });

  // return <AddProduct type={2} />;
  return <ProductForm type={2} initialValues={initialValues} handleSubmit={handleSubmit} />;
};

export default AddKhorda;
