import { Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import MainChip from "../../components/ClientStatusChip/MainChip";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";
import MainLoader from "../../components/Loaders/MainLoader";
import Map from "../../components/Map/Map";
import BuyerInfo from "../../components/SingleClientMainInfo/BuyerInfo";
import OrdersTable from "../../components/Tables/OrdersTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useMemo } from "react";

type Props = {};

const SingleOrder = (props: Props) => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetSingleApi(
    `orders/single?order_id=${id}`,
    []
  );

  const buyer = data?.data?.buyer;

  const constructAddress = (lat: number, lang: number) => {
    return `https://maps.google.com/maps?q=${lat},${lang}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
  };

  const statusApi = useMemo(() => `helper/get_status?model_name=order`, []);
  const MainChipStatus = useMemo(() => `helper/get_status?model_name=cart`, []);
  const {
    data: mainChipStatus,
    loading: mainChipStatusLoading,
    error: mainChipStatusError,
  } = useGetSingleApi(MainChipStatus, []);

  const {
    data: filterData,
    loading: filterDataLoading,
    error: filterDateError,
  } = useGetSingleApi(statusApi, []);

  return (
    <div>
      <Header title="الطلبات" />
      {loading ? (
        <MainLoader />
      ) : (
        <MainContainer>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="h6"
              sx={{
                color: "primary.main",
                fontWeight: "700",
                fontSize: "1.375rem",
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "8px",
                  left: "0",
                  width: "90px",
                  height: "2px",
                  backgroundColor: "primary.main",
                },
              }}
            >
              تفاصيل الطلب
            </Typography>
            <MainChip
              itemId={id}
              status={{
                name: data?.data?.status?.name_ar,
                id: data?.data?.status?.id,
                class: data?.data?.status?.class,
              }}
              modelName="order"
              statusApiUrl={"orders/change_status?order_id="}
              statusOptions={filterData?.data?.map((item: any) => {
                return {
                  name: item.name_ar,
                  id: item.id,
                  class: item.class,
                };
              })}
            />
          </Stack>
          <Stack spacing={3}>
            <BuyerInfo
              id={buyer?.id}
              name={buyer?.name}
              image={buyer?.image}
              phone={buyer?.phone}
              address={data?.data?.address?.address}
              seller={data?.data?.seller}
            />
            {/* {data?.data?.address && (
              <Map
                height="350px"
                url={constructAddress(
                  data?.data?.address?.lat,
                  data?.data?.address?.lng
                )}
              />
            )} */}
          </Stack>
          <OrdersTable
            secondaryHeaderStyle={true}
            products={data?.data?.products}
            buyer={data?.data?.buyer}
            seller={data?.data?.seller}
            statusOptions={mainChipStatus?.data?.map((item: any) => {
              return {
                name: item.name_ar,
                id: item.id,
                class: item.class,
              };
            })}
          />
          <Stack
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{
              width: "fit-content",
              margin: "1rem 0",
              alignSelf: "flex-end",
              backgroundColor: "#def7e5",
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.625rem",
                fontWeight: "800",
              }}
            >
              اجمالي السلة :{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "800",
                color: "primary.main",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                "& span": {
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "black",
                },
              }}
            >
              {Number(data?.data?.total_price).toFixed(2)}
              <span>جنيها</span>
            </Typography>
          </Stack>
        </MainContainer>
      )}
    </div>
  );
};

export default SingleOrder;
