import { Box, Button, Checkbox, Chip, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import { AUCTION_NEW_STATUS } from "../../constants/auction.constants";
import AuctionMessagesModal from "../AuctionMessagesModal/AuctionMessagesModal";
import ConfirmActionDialog from "../ConfirmActionDialog/ConfirmActionDialog";
import StyledClientName from "../StyledComponents/StyledClientName";
import axios from "axios";
import { mainSliceActions } from "../../store/main-slice";
import { postAxios } from "../../Services/axios-services";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useState } from "react";

//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1.25fr 1fr 1fr 1fr 1fr 1.25fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  //   padding: "1.125rem 0",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1.25fr 1fr 1fr 1fr 1fr 1.25fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});
const StyledPrice = styled(Typography)({
  fontWeight: "800",
  fontSize: "1.5rem",
  textAlign: "center",
  color: "#34B44A",
  "& span": {
    fontSize: "13px",
    color: "black",
    fontWeight: "400",
  },
});

//  row component
interface CustomTableRowProps {
  quantity: string;
  total_price: string;
  masafa_price: string;
  created_at: string;
  id: number;
  status: {
    name: string;
    id: number;
    color: string | null;
  };
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAction: React.Dispatch<React.SetStateAction<any>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  buyer: {
    name: string;
    image: string;
    id: number;
    phone: string;
  };
  seller: {
    name: string;
    image: string;
    id: number;
    phone: string;
  };
  product: {
    unit: {
      name_ar: string;
    };
  };
  values: any;
  setFieldValue: any;
}

const CustomTableRow = ({
  quantity,
  total_price,
  created_at,
  id,
  setOpen,
  setLoading,
  setAction,
  buyer,
  seller,
  product,
  setTitle,
  values,
  setFieldValue,
  status,
  masafa_price,
}: CustomTableRowProps) => {
  const dispatch = useDispatch();
  const handleAccept = async (id: number) => {
    try {
      setLoading(true);
      const data = await postAxios("auction/accept_auction", {
        auction_id: id,
      });
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success(data.data.message);
        setOpen(false);
        dispatch(mainSliceActions.toggleRefreshApi());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleReject = async (id: number) => {
    try {
      setLoading(true);
      const data = await postAxios("auction/reject_auction", {
        auction_id: id,
      });
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success(data.data.message);
        setOpen(false);
        dispatch(mainSliceActions.toggleRefreshApi());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledTableRow
      sx={{
        backgroundColor: id % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
      }}
    >
      <Checkbox
        checked={values.checked.includes(buyer?.id)}
        onChange={() => {
          if (values.checked.includes(buyer?.id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== buyer?.id)
            );
          } else {
            setFieldValue("checked", [...values.checked, buyer?.id]);
          }
        }}
      />
      <StyledClientName name={buyer.name} image={buyer.image} id={buyer.id} />
      <StyledMainCell>{buyer.phone}</StyledMainCell>
      <StyledPrice>
        {Number(total_price).toLocaleString()} <span>جنيها</span>
      </StyledPrice>
      <StyledPrice>
        {Number(Number(total_price) - Number(masafa_price)).toLocaleString()} <span>جنيها</span>
      </StyledPrice>
      <StyledMainCell>
        {quantity} {product?.unit?.name_ar}
      </StyledMainCell>
      <StyledMainCell>{created_at}</StyledMainCell>
      {status?.id === AUCTION_NEW_STATUS ? (
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={() => {
              setAction(() => () => handleAccept(id));
              setTitle("هل انت متاكد من قبول هذا العرض ؟");
              setOpen(true);
            }}
          >
            قبول
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setAction(() => () => handleReject(id));
              setTitle("هل انت متاكد من رفض هذا العرض ؟");
              setOpen(true);
            }}
          >
            رفض
          </Button>
        </Stack>
      ) : (
        <Chip
          label={status.name}
          sx={{
            backgroundColor: status.color,
          }}
        />
      )}
    </StyledTableRow>
  );
};

// main component

interface auctionsProps {
  productId: number;
  rows: {
    status: {
      name: string;
      id: number;
      color: string | null;
    };
    values: any;
    setFieldValue: any;
    id: number;
    buyer: {
      name: string;
      image: string;
      id: number;
      phone: string;
    };
    seller: {
      name: string;
      image: string;
      id: number;
      phone: string;
    };
    total_price: string;
    masafa_price: string;
    quantity: string;
    created_at: string;
    product: {
      unit: {
        name_ar: string;
      };
    };
  }[];
}

export default function AuctionsDetailsTable({ rows, productId }: auctionsProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<any>(null);
  const [title, setTitle] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const [openMessage, setOpenMessage] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);
  const handleOpenMessage = () => setOpenMessage(true);

  const handleSendMessage = async (values: any, setSubmitting: any, resetForm: any) => {
    const formData = new FormData();
    console.log(values);

    //append it to one string with , separator
    const ids = values.checked.join(",");

    formData.append("ids", ids);
    formData.append("msg", values.message);
    formData.append("send", String(values.send));
    formData.append("product_id", String(values?.productId));
    setSubmitting(true);

    try {
      const data = await axios.post("products/send_msg_bidders", formData);
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success(data.data.message);
        resetForm();
        handleCloseMessage();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        checked: [],
        message: "",
        send: 1,
        productId,
      }}
      onSubmit={(v, a) => {
        handleSendMessage(v, a.setSubmitting, a.resetForm);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <AuctionMessagesModal open={openMessage} handleClose={handleCloseMessage} handleOpen={handleOpenMessage} />

            <StyledTableHeader>
              <Checkbox
                disabled={rows?.length === 0}
                onChange={() => {
                  if (values?.checked.length > 0) {
                    setFieldValue("checked", []);
                  } else {
                    setFieldValue(
                      "checked",
                      rows.map((row: any, index: number) => row.buyer.id)
                    );
                  }
                }}
                checked={values.checked.length === rows?.length}
              />
              <h6>اسماء المزايدين</h6>
              <h6>رقم هاتف المزايد</h6>
              <h6>سعر الشراء</h6>
              <h6>سعر البيع</h6>
              <h6>الكمية</h6>
              <h6>التاريخ</h6>
            </StyledTableHeader>
            <Stack spacing={1}>
              {rows.length === 0 && (
                <Typography
                  sx={{
                    color: "#34B44A",
                    fontWeight: "700",
                    fontSize: "1.25rem",
                    textAlign: "center",
                  }}
                >
                  لا يوجد مزادات لعرضها
                </Typography>
              )}
              {rows.map((row) => {
                return (
                  <CustomTableRow
                    key={row.id}
                    {...row}
                    id={row.id}
                    setOpen={setOpen}
                    setLoading={setLoading}
                    setAction={setAction}
                    setTitle={setTitle}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                );
              })}
            </Stack>
            <ConfirmActionDialog
              loading={loading}
              action={action}
              open={open}
              handleClose={handleClose}
              title={title}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
