import { Button, styled } from "@mui/material";

const OutlinedButton = styled(Button)(() => {
  return {
    display: "flex",
    alignItems: "center",
    width: "150px",
    height: "50px",
    borderRadius: "8px",
    border: "2px solid #34B44A",
  };
});
export default OutlinedButton;
