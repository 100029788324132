import { Box } from "@mui/material";
import React from "react";

type Props = {
  height?: string;
  url?: string;
};

const Map = (props: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: props.height || "365px",
      }}
    >
      <iframe
        src={
          props.url ||
          "https://maps.google.com/maps?q=17 Markez Al Malomat &t=&z=15&ie=UTF8&iwloc=&output=embed"
        }
        frameBorder="0"
        width="100%"
        height="100%"
        allowFullScreen={false}
        aria-hidden="false"
        tabIndex={0}
        style={{
          border: "0",
          borderRadius: "25px",
        }}
      />
    </Box>
  );
};

export default Map;
