import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const rows = [
  {
    commercialRegister: "123456789",
    taxCard: "123456789",
    vatNumber: "123456789",
    invoiceNumber: "123456789",
  },
  {
    commercialRegister: "123456789",
    taxCard: "123456789",
    vatNumber: "123456789",
    invoiceNumber: "123456789",
  },
  {
    commercialRegister: "123456789",
    taxCard: "123456789",
    vatNumber: "123456789",
    invoiceNumber: "123456789",
  },
  {
    commercialRegister: "123456789",
    taxCard: "123456789",
    vatNumber: "123456789",
    invoiceNumber: "123456789",
  },
];

//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(4,1fr)",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(4,1fr)",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

//  row component
interface CustomTableRowProps {
  commercial_register?: string;
  tax_card?: string;
  added_tax_number?: string;
  electronic_invoice?: string;
}

const CustomTableRow = ({
  commercial_register,
  tax_card,
  added_tax_number,
  electronic_invoice,
}: CustomTableRowProps) => {
  return (
    <StyledTableRow
      sx={{
        backgroundColor: 0 % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
      }}
    >
      <StyledMainCell>{commercial_register || "لا يوجد"}</StyledMainCell>
      <StyledMainCell>{tax_card || "لا يوجد"}</StyledMainCell>
      <StyledMainCell>{added_tax_number || "لا يوجد"}</StyledMainCell>
      <StyledMainCell>{electronic_invoice || "لا يوجد"}</StyledMainCell>
    </StyledTableRow>
  );
};

// main component

interface StatisticsTableProps {
  commercial_register?: string;
  tax_card?: string;
  added_tax_number?: string;
  electronic_invoice?: string;
}

export default function TaxInfoTable(props: StatisticsTableProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <StyledTableHeader>
        <StyledMainCell>السجل التجاري</StyledMainCell>
        <StyledMainCell>البطاقة الضريبية</StyledMainCell>
        <StyledMainCell>رقم ضريبة القيمة المضافة</StyledMainCell>
        <StyledMainCell>رقم الفاتوة الالكترونية</StyledMainCell>
      </StyledTableHeader>
      <Stack spacing={1}>
        <CustomTableRow {...props} />
      </Stack>
    </Box>
  );
}
