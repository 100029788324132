import AddClientForm from "../../components/Forms/AddClientForm";
import Header from "../../components/Header/Header";

type Props = {};

const initialValues: any = {
  name: "",
  company_name: "",
  company_logo: "",
  phone: "",
  email: "",
  user_type: "",
  status: "",
  commercial_register: "",
  tax_card: "",
  added_tax_number: "",
  electronic_invoice: "",
  image: "",
  password: "",
  individual_or_company: "",
  cats: "",
  address: "",
  city_id: "",
  rate: "1",
  adds: [
    {
      key: "",
      value: "",
    },
  ],
};

const AddClient = (props: Props) => {
  return (
    <div>
      <Header title="العملاء" />
      <AddClientForm type="new" initialValues={initialValues} />
    </div>
  );
};

export default AddClient;
