import { ADMINS_ROLES } from "../constants/admins.constants";
import axios from "axios";

export const getAdmins = async (props: {
  pageParam: number | false;
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(`admin/get?page=${props.pageParam || ""}`);
  return response?.data?.data?.data;
};

export const getAdmin = async (props: { queryKey: (string | null | undefined | number)[] }) => {
  const response = await axios.get(`admin/single?admin_id=${props.queryKey[1]}`);
  return response?.data?.data;
};

export const createAdmin = async (data: any) => {
  const formData = new FormData();

  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("password", data.password);
  formData.append("status", data.status);
  const roles = [];
  data.admins_control === "1" && roles.push(ADMINS_ROLES.ADMIN_CONTROL);
  data.website_settings === "1" && roles.push(ADMINS_ROLES.WEBSITE_SETTINGS);
  data.visitors_messages === "1" && roles.push(ADMINS_ROLES.VISITORS_MESSAGES);
  data.clients === "1" && roles.push(ADMINS_ROLES.CLIENTS);
  data.products === "1" && roles.push(ADMINS_ROLES.PRODUCTS);
  data.auctions === "1" && roles.push(ADMINS_ROLES.AUCTIONS);
  formData.append("roles", roles.join(","));

  const response = await axios.post("admin/create", formData);
  return response?.data;
};
export const updateAdmin = async (data: any) => {
  const formData = new FormData();
  formData.append("admin_id", data.admin_id);
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("password", data.password);
  formData.append("status", data.status);
  const roles = [];
  data.admins_control === "1" && roles.push(ADMINS_ROLES.ADMIN_CONTROL);
  data.website_settings === "1" && roles.push(ADMINS_ROLES.WEBSITE_SETTINGS);
  data.visitors_messages === "1" && roles.push(ADMINS_ROLES.VISITORS_MESSAGES);
  data.clients === "1" && roles.push(ADMINS_ROLES.CLIENTS);
  data.products === "1" && roles.push(ADMINS_ROLES.PRODUCTS);
  data.auctions === "1" && roles.push(ADMINS_ROLES.AUCTIONS);
  formData.append("roles", roles.join(","));
  const response = await axios.post("admin/update", formData);
  return response?.data;
};

export const getAdminsForCombobox = async (props: { queryKey: (string | null | undefined | number)[] }) => {
  const response = await axios.get(`admin/get?search_text=${props.queryKey[1]}&limit=30`);
  return response?.data?.data?.data;
};
