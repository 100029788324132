import { IPaymentMethod } from "../types";
import axios from "axios";

export const getPaymentMethods = async (props: {
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(
    `payment_methods/get?product_id=${props.queryKey[1] || ""}`
  );
  return response?.data?.data?.data as IPaymentMethod[];
};
