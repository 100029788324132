import Select, { ControlProps, components } from "react-select";
import "./CustomSelect.scss";
interface optionsType {
  value: string | number;
  label: string;
}

interface Props {
  placeholder: string;
  width?: string;
  required?: boolean;
  optionsProp: optionsType[];
  setFilters?: any;
  type: "status" | "user_type_id" | "user_type_parent_id" | "price_type" | "status_id" | "bid";
  multi?: boolean;
}

const CustomSelectSmall = ({ placeholder, optionsProp, width, required, setFilters, type, multi = false }: Props) => {
  const Control = ({ children, ...props }: ControlProps<any>) => {
    return <components.Control {...props}>{children}</components.Control>;
  };

  return (
    <Select
      isMulti={multi}
      onChange={(e) => {
        if (type === "status") {
          let newStatus = "";
          e?.map((status: any, index: number) => {
            if (index === e.length - 1) {
              newStatus += status.value;
            } else {
              newStatus += status.value + ",";
            }
          });
          setFilters((prev: any) => ({ ...prev, status: newStatus }));
        } else if (type === "user_type_id") {
          setFilters((prev: any) => ({ ...prev, user_type_id: e?.value }));
        } else if (type === "user_type_parent_id") {
          setFilters((prev: any) => ({
            ...prev,
            user_type_id: "",
            user_type_parent_id: e?.value,
          }));
        } else if (type === "price_type") {
          setFilters((prev: any) => ({ ...prev, price_type: e?.value }));
        } else if (type === "status_id") {
          let newStatus = "";
          e?.map((status: any) => {
            newStatus += status.value + ",";
          });
          setFilters((prev: any) => ({
            ...prev,
            status_id: newStatus,
          }));
        } else if (type === "bid") {
          setFilters(e?.value);
        } else {
          return;
        }
      }}
      className="react-select-container-small"
      classNamePrefix="react-select"
      placeholder={placeholder}
      options={optionsProp}
      components={{ Control }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,

        colors: {
          ...theme.colors,
          primary25: "#DEF7E5",
          primary: "#1BBC39",
        },
      })}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          width: width ? width : "auto",
          boxShadow: "none",
          cursor: "pointer",
          borderRadius: "8px",
          border: "1px solid #EAEAEA",
          position: "relative",
          "&:hover": {
            border: "1px solid #34B44A",
          },
        }),
      }}
    />
  );
};

export default CustomSelectSmall;
