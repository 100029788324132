import { ChangeEventHandler, useEffect, useState } from "react";
import { useUpdateAbout } from "../../hooks/about/useUpdateAbout";
import { AboutDto } from "../../types";
import { Formik, Form } from "formik";
import { Box, Button, CircularProgress } from "@mui/material";
import CustomTextField from "../CustomTextField/CustomTextField";

export const AboutForm: React.FC<{
  initialValues?: AboutDto;
}> = ({ initialValues }) => {
  const { mutate, isPending } = useUpdateAbout();
  //   const [imageUrl, setImageUrl] = useState<string | undefined>(initialValues?.always_image ?? undefined);
  //   const handleImageChange: ChangeEventHandler<HTMLInputElement> = (event) => {
  //     const file = event?.target?.files?.[0];
  //     if (file) {
  //       setImageUrl(URL.createObjectURL(file));
  //     }
  //   };
  //   useEffect(() => {
  //     if (initialValues?.always_image) {
  //       setImageUrl(initialValues.always_image);
  //     }
  //   }, [initialValues]);
  return (
    <Formik
      //   enableReinitialize={true}
      initialValues={{
        title_ar: initialValues?.title_ar ?? "",
        title_en: initialValues?.title_en ?? "",
        about_ar: initialValues?.about_ar ?? "",
        about_en: initialValues?.about_en ?? "",
        always_ar: initialValues?.always_ar ?? "",
        always_en: initialValues?.always_en ?? "",
        always_title_ar: initialValues?.always_title_ar ?? "",
        always_title_en: initialValues?.always_title_en ?? "",
        button_ar: initialValues?.button_ar ?? "",
        button_en: initialValues?.button_en ?? "",
        button_link: initialValues?.button_link ?? "",
        what_ar: initialValues?.what_ar ?? "",
        what_en: initialValues?.what_en ?? "",
        // always_image: initialValues?.always_image ?? "",
      }}
      onSubmit={async (v) => {
        await mutate(v);
      }}
    >
      {() => (
        <Form>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <CustomTextField name="title_ar" label="عنوان عننا بالعربي" multiLine={1} />
            <CustomTextField name="title_en" label="عنوان عننا بالانجليزي" multiLine={1} />
            <CustomTextField name="about_ar" label="النص بالعربي" multiLine={4} />
            <CustomTextField name="about_en" label="النص بالانجليزي" multiLine={4} />
            <CustomTextField name="always_ar" label="النص بالعربي" multiLine={4} />
            <CustomTextField name="always_en" label="النص بالانجليزي" multiLine={4} />
            <CustomTextField name="always_title_ar" label="العنوان الفرعي بالعربي" multiLine={1} />
            <CustomTextField name="always_title_en" label="العنوان الفرعي بالانجليزي" multiLine={1} />
            <CustomTextField name="button_ar" label="الزر بالعربي" multiLine={1} />
            <CustomTextField name="button_en" label="الزر بالانجليزي" multiLine={1} />
            <Box
              sx={{
                gridColumn: "span 2",
              }}
            >
              <CustomTextField name="button_link" label="رابط الزر" multiLine={1} />
            </Box>
            <CustomTextField name="what_ar" label="عنوان فرعي ثالث بالعربي" />
            <CustomTextField name="what_en" label="اعنوان فرعي ثالث بالانجليزي" />
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "1rem",
                fontWeight: 700,
                height: "45px",
                width: "200px",
                color: "#FFFFFF",
                borderRadius: "6px",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              disabled={isPending}
              type="submit"
              endIcon={isPending ? <CircularProgress size={20} color="primary" /> : null}
            >
              تعديل
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
