import * as Yup from "yup";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { postAxios } from "../Services/axios-services";
import { toast } from "react-toastify";

type AddProductsProps = {
  type: number;
  values?: any;
  edit?: boolean;
};

const useAddProducts = ({ type, values, edit }: AddProductsProps) => {
  const navigate = useNavigate();
  let apiUrl = "products/create";
  if (edit) {
    apiUrl = "products/update";
  }
  const handleSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);

    let newValues = values;
    //check if pdf is file or string
    if (newValues.price_type === "1") {
      //remove mazad_type and end_date if price_type is 1
      delete newValues.mazad_type;
      delete newValues.end_date;
    }
    if (newValues.price_type === "2") {
      //remove real_price if price_type is 2
      delete newValues.real_price;
      delete newValues.price;
    }
    //check if adds has an empty key or value
    newValues.adds = newValues.adds.filter(
      (add: any) => add.key !== "" && add.value !== ""
    );
    // send only cat_id and if sub_cat_id is not empty send it but in the same object
    if (newValues.sub_cat_id) {
      newValues.cat_id = values.sub_cat_id;
      delete newValues.sub_cat_id;
    }

    //delete logo if it is not a file
    if (!(newValues.logo instanceof File)) {
      delete newValues.logo;
    }
    //check if adds has an empty key or value
    newValues.adds = newValues.adds.filter(
      (add: any) =>
        add.key_ar !== "" &&
        add.value_ar !== "" &&
        add.key_en !== "" &&
        add.value_en !== ""
    );

    try {
      await postAxios(apiUrl, newValues).then((res) => {
        if (res.data.status === 1) {
          if (values?.parent_id) {
            navigate(`/products/view/${values.parent_id}`);
          } else if (type === 1) {
            navigate("/rawakd/all");
          } else {
            navigate("/khorda/all");
          }
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };
  const { product_id } = useParams();
  const [initialValues, setInitialValues] = useState({
    parent_id: product_id || "",
    name_ar: "",
    description_ar: "",
    name_en: "",
    description_en: "",
    logo: "" as any,
    pdf: "" as any,
    brand_id: "",
    lot: "0",
    sort: "1",
    quantity: "",
    unit_id: "",
    type: type,
    price_type: "",
    price: "",
    real_price: "",
    mazad_type: "",
    end_date: "",
    less_quantity: "",
    cat_id: "",
    masafa_price: "",
    status_id: "",
    user: "" as any,
    user_id: "",
    address_id: "",
    show_company: 0,
    step_price: "",
    has_conditions_document: 0,
    conditions_document_price: "",
    cats_messages: "",
    images: [],
    tags: [],

    adds: [
      {
        key_ar: "",
        value_ar: "",
        key_en: "",
        value_en: "",
      },
    ],
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("اسم المنتج مطلوب"),
    unit_id: Yup.string().required("وحده القياس مطلوبه"),
    cat_id: Yup.string().required("التصنيف مطلوب"),
    status_id: Yup.string().required("حاله المنتج مطلوبه"),
    user_id: Yup.string().required("التاجر مطلوب"),
    quantity: Yup.number().required("الكميه مطلوبه"),
    price_type: Yup.string().required("تسعير المنتج مطلوب"),
    less_quantity: Yup.number().required("ادخل اقل كميه للطلب"),
    price: Yup.string().test(
      "required-if-price-type-2",
      "سعر المنتج بعد الخصم مطلوب",
      function (value) {
        const priceType = this.resolve(Yup.ref("price_type"));

        if (priceType === "1") {
          return Yup.string().required().isValidSync(value);
        }
        return true;
      }
    ),
    step_price: Yup.string()
      .notRequired()
      .matches(/^\d+$/, "أقل سعر للمزايدة يجب ان يكون رقم"),

    mazad_type: Yup.string().test(
      "required-if-price-type-2",
      "نوع المزاد مطلوب",
      function (value) {
        const priceType = this.resolve(Yup.ref("price_type"));
        if (priceType === "2") {
          return Yup.string().required().isValidSync(value);
        }
        return true;
      }
    ),
    end_date: Yup.string().test(
      "required-if-mazad-type-1",
      "التاريخ  مطلوب",
      function (value) {
        const mazadType = this.resolve(Yup.ref("mazad_type"));
        if (mazadType === "1") {
          return Yup.string().required().isValidSync(value);
        }
        return true;
      }
    ),
  });
  useEffect(() => {
    if (values) {
      setInitialValues(values);
    }
  }, [values]);

  return { initialValues, validationSchema, handleSubmit };
};

export default useAddProducts;
