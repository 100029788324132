import { Box, CircularProgress } from "@mui/material";

import MainClientsComponent from "../../components/MainClientsComponent/MainClientsComponent";
import { useClients } from "../../hooks/useClients";
import { useState } from "react";

const TrustedClients = () => {
  const [filters, setFilters] = useState({
    status: "1",
    user_type_id: "",
    user_type_parent_id: "",
    search_text: "",
    cat_id: [],
    rate: "",
    bid: "1",
  });
  const { isPending, data, hasNextPage, ref } = useClients(filters);
  return (
    <div>
      <MainClientsComponent
        data={data?.pages.map((page) => page.data).flat()}
        loading={isPending}
        filters={filters}
        setFilters={setFilters}
        trusted={1}
        total={data?.pages[0]?.pagination?.total}
      />
      {hasNextPage && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress size={24} ref={ref} />
        </Box>
      )}
    </div>
  );
};

export default TrustedClients;
