import { AdminsForm } from "./adminsForm";
import { useCreateAdmin } from "../hooks";

export const CreateAdmin = () => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    password: "",
    status: "",
    admins_control: "0",
    website_settings: "0",
    visitors_messages: "0",
    clients: "0",
    products: "0",
    auctions: "0",
  };
  const { mutate, isPending } = useCreateAdmin();

  return <AdminsForm initialValues={initialValues} loading={isPending} handleSubmit={mutate} />;
};
