import { Box, Button, Stack, Typography } from "@mui/material";

import React from "react";
import bannerImage from "../../assets/images/bannerImage.svg";
import styles from "./HomeBanner.module.scss";
import { useNavigate } from "react-router-dom";

type Props = {};

const HomeBanner = (props: Props) => {
  const navigate = useNavigate();
  const handleNavigateToWebsite = () => {
    window.open(`${process.env.REACT_APP_CLIENT_URL}`, "_blank");
  };
  const handleNewAuctionsNavigate = () => {
    navigate("/auctions/ongoing-auctions");
  };
  return (
    <div className={styles.homeBanner}>
      <Stack spacing={2} justifyContent="center">
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.625rem",
            fontWeight: "800",
            color: "black",
          }}
        >
          هل تريد شراء المزيد من المنتجات الحديثة
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.625rem",
            fontWeight: "800",
            color: "#156323",
            textDecoration: "underline",
          }}
        >
          بسعر اقل من الطبيعي ؟
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.625rem",
            fontWeight: "800",
            color: "black",
            "& span": {
              color: "#156323",
            },
          }}
        >
          يمكنك الان <span>زيارة موقعنا مجانا !</span>
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              width: "295px",
              height: "60px",
              background: "white",
              color: "black",
              fontSize: "1.25rem",
              fontWeight: "800",
              marginTop: "28px !important",
              boxShadow: "none !important",

              "&:hover": {
                background: "#f5f5f5",
              },
            }}
            onClick={handleNavigateToWebsite}
          >
            تصفح الموقع
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              width: "295px",
              height: "60px",
              background: "linear-gradient(to right, #ff4343fc,#fdcf58)",
              color: "white",
              fontSize: "1.25rem",
              fontWeight: "800",
              marginTop: "28px !important",
              boxShadow: "none !important",
            }}
            onClick={handleNewAuctionsNavigate}
          >
            مزادات سارية{" "}
          </Button>{" "}
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
      >
        <img src={bannerImage} alt="Banner" />
      </Box>
    </div>
  );
};

export default HomeBanner;
