import { Typography, styled } from "@mui/material";
import exp from "constants";

const StyledPrice = styled(Typography)({
  fontWeight: "800",
  fontSize: "1.25rem",
  textAlign: "center",
  color: "#34B44A",
  "& span": {
    fontSize: "13px",
    color: "black",
    fontWeight: "400",
  },
});

export default StyledPrice;
