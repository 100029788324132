import { Box, CircularProgress, Divider, Stack, Typography, styled } from "@mui/material";
import { memo, useMemo } from "react";

import { Link } from "react-router-dom";
import MainChip from "../ClientStatusChip/MainChip";
import StyledClientName from "../StyledComponents/StyledClientName";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {};

const StyledContainer = styled("div")({
  backgroundColor: "#fff",
  padding: "1.25rem 1.5rem",
  boxShadow: " 0px 4px 15px rgba(156, 156, 156, 0.25)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  flex: "1",
});
const StyledStack = styled(Stack)(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 15px rgba(188, 188, 188, 0.25)",
  borderRadius: "8px",
  padding: ".8rem .5rem",
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1.75fr 1fr 1fr .75fr",
  alignItems: "center",
}));

const MyOrdersMainCard = (props: Props) => {
  const ordersApiUrl = useMemo(() => "user/get?status=3&limit=5", []);
  const { data, loading, error } = useGetSingleApi(ordersApiUrl, []);
  if (loading)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={55} color={"primary"} />
      </Box>
    );
  if (error) return <></>;
  return (
    <StyledContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          margin: "10px 0 1.5rem 0",
        }}
      >
        <Typography
          sx={{
            color: "primary.main",
            fontWeight: "700",
            fontSize: "1.25rem",
          }}
        >
          عملاء جدد
        </Typography>
        <Link to="/clients/new">
          <Typography
            sx={{
              color: "primary.main",
              fontSize: "1rem",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            مشاهدة المزيد
          </Typography>
        </Link>
      </Stack>
      <Stack spacing={1.5}>
        {data?.data?.data?.map((order: any) => (
          <SingleOrderMainCard {...order} key={order?.id} />
        ))}
      </Stack>
    </StyledContainer>
  );
};

export default memo(MyOrdersMainCard);

type SingleOrderMainCardProps = {
  name: string;
  // buyer: {
  //   id: number;
  //   name: string;
  //   image: string;
  // };
  id: number;
  image: string;
  company_name: string;
  user_type: {
    title: string;
  };
  status: number;
};
const statusOptions = [
  {
    id: 3,
    name: "جديد",
    class: "new",
  },
  {
    id: 1,
    name: "مفعل",
    class: "active",
  },
  {
    id: 2,
    name: "غير مفعل",
    class: "danger",
  },
  {
    id: 4,
    name: "لم يتم الرد",
    class: "danger",
  },
];

const SingleOrderMainCard = ({ id, name, image, company_name, user_type, status }: SingleOrderMainCardProps) => {
  return (
    <StyledStack>
      <StyledClientName name={name} id={id} image={image} />

      <Typography
        sx={{
          fontSize: "1rem",
          color: "primary.main",
          fontWeight: "800",
          "& span": {
            fontSize: ".9rem",
            fontWeight: "400",
            color: "black",
          },
        }}
      >
        {company_name || "-----"}
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          color: "primary.main",
          fontWeight: "800",
          "& span": {
            fontSize: ".9rem",
            fontWeight: "400",
            color: "black",
          },
        }}
      >
        {user_type.title}
      </Typography>
      <MainChip
        status={{
          id: status,
          name: status === 1 ? "مفعل" : status === 2 ? "غير مفعل" : status === 4 ? "لم يتم الرد" : "جديد",
          class: status === 1 ? "active" : status === 2 ? "danger" : status === 4 ? "danger" : "new",
        }}
        statusOptions={statusOptions}
        itemId={String(id)}
        modelName="user"
        statusApiUrl={`user/change_status?user_id=`}
      />
      {/* <Typography
        sx={{
          fontSize: "1.25rem",
          color: "primary.main",
          fontWeight: "800",
          "& span": {
            color: "black",
            fontSize: ".9rem",
            fontWeight: "400",
            margin: "0 5px",
          },
        }}
      >
        {Number(total_price).toLocaleString()}
        <span>جنيها</span>
      </Typography> */}
      {/* <IconButton
        sx={{
          width: "20px",
          height: "20px",
          "& a": {
            color: "#34B44A",
          },
        }}
      >
        <Link
          to={`/orders/${id}`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiOutlineEye />
        </Link>
      </IconButton> */}
    </StyledStack>
  );
};
