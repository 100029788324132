import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateBlog } from "../../apis/blogs_apis";

export const useUpdateBlog = (fn?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateBlog,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
      if (data?.status === 1) {
        toast.success("تم تحديث المقال بنجاح");
      }
      fn && fn();
    },
    onError: () => {
      toast.error("حدث خطأ ما");
    },
  });
};
