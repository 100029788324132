import { Button, Grid, Stack, Typography } from "@mui/material";
import { RiDeleteBin6Line, RiEditBoxLine } from "react-icons/ri";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BasicAccordionContainer from "../../components/BasicAccordionContainer/BasicAccordionContainer";
import { CiLocationOn } from "react-icons/ci";
import ClientLoginButton from "../../components/ClientLoginButton/ClientLoginButton";
import ClientTabs from "../../components/ClientTabs/ClientTabs";
import Header from "../../components/Header/Header";
import MainChip from "../../components/ClientStatusChip/MainChip";
import MainContainer from "../../components/Containers/MainContainer";
import MainLoader from "../../components/Loaders/MainLoader";
import MessageModal from "../../components/Modals/MessageModal";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import SecondaryTitle from "../../components/StyledComponents/SecondaryTitle";
import { SendMessageToClient } from "../../features";
import SingleClientMainInfo from "../../components/SingleClientMainInfo/SingleClientMainInfo";
import SingleClientOrders from "../../components/Tables/SingleClientOrdersTable";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useSelector } from "react-redux";

type Props = {};

const SingleClient = (props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const singleClientUrl = useMemo(() => `user/single?user_id=${id}`, [id]);
  const mainState = useSelector((state: any) => state.main);

  const dependencies = useMemo(
    () => [mainState.refreshApi],
    [id, mainState.refreshApi]
  );
  const { data, loading } = useGetSingleApi(singleClientUrl, dependencies);
  const singleClientOrdersApi = useMemo(
    () => `orders/all?buyer_id=${id}&limit=5`,
    []
  );
  const statusOptions = [
    {
      id: 3,
      name: "جديد",
      class: "new",
    },
    {
      id: 1,
      name: "مفعل",
      class: "active",
    },
    {
      id: 2,
      name: "غير مفعل",
      class: "danger",
    },
    {
      id: 4,
      name: "لم يتم الرد",
      class: "danger",
    },
  ];

  const { data: ordersData } = useGetSingleApi(singleClientOrdersApi, []);
  const [interests, setInterests] = useState<any>([]);
  useEffect(() => {
    let khordaInterests: any = [];
    let rwakedInterests: any = [];
    if (data?.data?.cats?.length > 0) {
      data.data.cats.map((cat: any) => {
        if (cat.type === 2) {
          khordaInterests.push(cat);
        } else {
          rwakedInterests.push(cat);
        }
      });
      setInterests({
        khorda: khordaInterests,
        rwaked: rwakedInterests,
      });
    }
  }, [data]);
  return (
    <div>
      <Header title="العملاء" />
      <MainContainer>
        {loading ? (
          <MainLoader />
        ) : (
          data && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <SecondaryTitle>{`تفاصيل العميل #${id}`}</SecondaryTitle>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  {id && <ClientLoginButton id={id} />}{" "}
                  <OutlinedButton
                    sx={{
                      height: "40px",
                    }}
                    endIcon={<RiDeleteBin6Line />}
                  >
                    حذف عميل
                  </OutlinedButton>
                  <OutlinedButton
                    sx={{
                      height: "40px",
                      width: "100px",
                    }}
                    endIcon={<RiEditBoxLine />}
                    onClick={() => {
                      navigate("edit");
                    }}
                  >
                    تعديل
                  </OutlinedButton>
                  <MainChip
                    status={{
                      id: data.data.status,
                      name:
                        data.data.status === 1
                          ? "مفعل"
                          : data.data.status === 2
                          ? "غير مفعل"
                          : "جديد",
                      class:
                        data.data.status === 1
                          ? "active"
                          : data.data.status === 2
                          ? "danger"
                          : "new",
                    }}
                    statusOptions={statusOptions}
                    itemId={id}
                    modelName="user"
                    statusApiUrl={`user/change_status?user_id=`}
                  />{" "}
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate("address-details");
                    }}
                    sx={{
                      borderRadius: "10px",
                      "& .MuiButton-endIcon": {
                        fontSize: "1.5rem",
                      },
                    }}
                    endIcon={<CiLocationOn />}
                  >
                    العناوين
                  </Button>
                  <SendMessageToClient id={Number(id)} name={data.data.name} />
                </Stack>
              </Stack>
              <SingleClientMainInfo
                name={data.data.name}
                phone={data.data.phone}
                company_name={data.data.company_name}
                email={data.data.email}
                type={data.data.type}
                image={data.data.image}
                user_type={data?.data?.user_type}
              />
              {/* <Stack
                direction="row"
                spacing={2}
                sx={{
                  marginTop: "1.5rem",
                }}
              >
                <StatusCard />
                <StatusCard />
                <StatusCard />
              </Stack> */}
              <ClientTabs
                commercialInfo={{
                  commercial_register: data.data.commercial_register,
                  tax_card: data.data.tax_card,
                  added_tax_number: data.data.added_tax_number,
                  electronic_invoice: data.data.electronic_invoice,
                }}
                adds={data.data.adds}
              />
              <BasicAccordionContainer title="الطلبات">
                {ordersData && ordersData?.data?.data.length > 0 && (
                  <SingleClientOrders items={ordersData?.data?.data} />
                )}
                {ordersData && ordersData?.data?.data.length === 0 && (
                  <Typography
                    sx={{
                      color: "primary.main",
                      fontWeight: "700",
                      fontSize: "2rem",
                      textAlign: "center",
                      marginTop: "1.5rem",
                    }}
                  >
                    لا يوجد طلبات
                  </Typography>
                )}
              </BasicAccordionContainer>
              {data?.data?.cats?.length > 0 && (
                <BasicAccordionContainer title="اهتمامات العميل">
                  <Stack spacing={4}>
                    {interests?.khorda?.length > 0 && (
                      <Stack spacing={2}>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "1.5rem",
                          }}
                        >
                          {`الخردة (${interests?.khorda?.length})`}
                        </Typography>
                        <Grid container>
                          {interests?.khorda?.map(
                            (interest: any, index: number) => (
                              <Grid item xs={3} key={index}>
                                <Typography>{interest.name}</Typography>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Stack>
                    )}
                    {interests?.rwaked?.length > 0 && (
                      <Stack spacing={2}>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "1.5rem",
                          }}
                        >
                          {`الراوكد (${interests?.rwaked?.length})`}
                        </Typography>
                        <Grid container>
                          {interests?.rwaked?.map(
                            (interest: any, index: number) => (
                              <Grid item xs={3} key={index}>
                                <Typography>{interest.name}</Typography>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Stack>
                    )}
                  </Stack>
                </BasicAccordionContainer>
              )}
            </>
          )
        )}
      </MainContainer>
      <MessageModal />
    </div>
  );
};

export default SingleClient;
