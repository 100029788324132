import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import CategoryTable from "../../components/Tables/CategoryTable";
import Header from "../../components/Header/Header";
import { IoMdAdd } from "react-icons/io";
import MainContainer from "../../components/Containers/MainContainer";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import TableLoader from "../../components/Loaders/TableLoader";
import { modalActions } from "../../store/modal-slice";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useMemo } from "react";

type Props = {};

const KhordaCategory = (props: Props) => {
  const mainState = useSelector((state: any) => state.main);
  const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  const dispatch = useDispatch();
  const citiesApiUrl: string = useMemo(() => "categories/get?type=2", []);
  const { data, loading, error } = useGetSingleApi(citiesApiUrl, dependencies);

  return (
    <div>
      <Header title="الفئات" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            فئات الخرده
          </Typography>
          <OutlinedButton
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  type: "add",
                  apiUrl: "categories/create",
                  initialValues: {
                    name_ar: "",
                    name_en: "",
                    type: 2,
                  },
                })
              );
            }}
            endIcon={<IoMdAdd />}
            sx={{
              width: "200px",
            }}
          >
            اضافة فئة خرده
          </OutlinedButton>
        </Stack>
        {loading ? (
          <TableLoader />
        ) : error ? (
          <></>
        ) : (
          data?.data?.data.length > 0 && <CategoryTable type="khorda" data={data.data.data} />
        )}
      </MainContainer>
    </div>
  );
};

export default KhordaCategory;
