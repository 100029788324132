import { Box, Chip, Fade, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { AiOutlineEye } from "react-icons/ai";
import DeleteIcon from "../../../components/DeleteIcon/DeleteIcon";
import { IoMdMore } from "react-icons/io";
import { REACT_QUERY_ADMINS } from "../../../constants/reactQuery.constants";
import { styled } from "@mui/material/styles";
import { useState } from "react";

//  row component
interface CustomTableRowProps {
  id: number;
  name: string;
  email: string;
  phone: string;
  status: 0 | 1;
  index: number;
}

const CustomTableRow = ({ status, id, name, email, phone, index }: CustomTableRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleNavigateToSingle = () => {
    navigate(`/admins/${id}`);
  };

  return (
    <StyledTableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
      }}
    >
      <StyledMainCell>{id}</StyledMainCell>
      <StyledMainCell>{name}</StyledMainCell>
      <StyledSubCell>{email}</StyledSubCell>
      <StyledSubCell>{phone}</StyledSubCell>
      <Chip
        label={status === 1 ? "مفعل" : "غير مفعل"}
        sx={{
          width: "100px",
        }}
      />
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          padding: "0px",
        }}
      >
        <IoMdMore />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          "& .MuiMenu-paper": {
            borderRadius: "1rem",
            boxShadow: "0px 0px 10px 0px #0000001f",
          },
        }}
      >
        <MenuItem onClick={handleNavigateToSingle}>
          <Stack direction="row" justifyContent="flex-start" width="100%" spacing={1}>
            <IconButton
              size="small"
              sx={{
                "& .MuiIconButton-root": {
                  padding: "0px",
                },
                width: "20px",
                height: "20px",
                "& a": {
                  color: "#34B44A",
                },
              }}
            >
              <Link
                to={`/admins/${id}`}
                style={{
                  padding: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiOutlineEye />
              </Link>
            </IconButton>
            <Typography variant="caption" color="primary.main">
              تفاصيل المستخدم
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem>
          <DeleteIcon
            id={Number(id)}
            label="حذف المستخدم"
            apiUrl="admin/delete"
            payload={{
              admin_id: id,
            }}
            refresh={REACT_QUERY_ADMINS}
          />
        </MenuItem>
      </Menu>
    </StyledTableRow>
  );
};

// main component

interface adminTableProps {
  rows: {
    id: number;
    name: string;
    email: string;
    phone: string;
    status: any;
  }[];
}

export const AdminsTable = ({ rows }: adminTableProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader
          sx={{
            height: "63px",
            "& h6": {
              fontSize: "1.5rem",
              color: "#34B44A",
              fontWeight: "400",
              textAlign: "center",
            },
          }}
        >
          <h6>#</h6>
          <h6>اسم المستخدم</h6>
          <h6>الايميل</h6>
          <h6>رقم الهاتف </h6>
          <h6>الحالة</h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {rows?.map((row: any, index: number) => {
            return <CustomTableRow key={index} id={row.id} index={index} {...row} />;
          })}
        </Stack>
      </Box>
    </>
  );
};

//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: ".5fr repeat(4,1fr) .25fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: ".5fr repeat(4,1fr) .25fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledSubCell = styled(Typography)({
  color: "#000000",
  textAlign: "center",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});
