import { Box, Grid, IconButton, Stack, Tooltip, Typography, styled } from "@mui/material";
import React, { useCallback } from "react";

import { CropImageModal } from "../Modals/CropImageModal";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { REACT_QUERY_SINGLE_PRODUCT } from "../../constants/reactQuery.constants";
import deleteIcon from "../../assets/images/icons/delete-icon.svg";
import previewIcon from "../../assets/images/icons/preview-icon.svg";
import uploadImage from "../../assets/images/icons/upload-image.svg";
import { useDropzone } from "react-dropzone";
import { useFormikContext } from "formik";

type Props = {
  setFieldValue?: any;
  images?: any;
};

const Container = styled("div")({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  border: " 1px dashed",
  borderRadius: "10px",
  borderColor: `rgba(0, 0, 0, 0.18)`,
  color: "#333333",
  backgroundColor: "#F5F6F8",
  outline: "none",
  transition: "border 0.24s ease-in-out",
  cursor: "pointer",
});
const StyledItemsContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FFFFFF",
  border: "1px solid rgba(0, 0, 0, 0.18)",
  borderRadius: "6px",
  padding: "11px 13px",
});
interface FileProps {
  file?: File;
  setFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  id: number;
  type?: string;
  url?: string;
}

const FileAccepted = ({ file, setFiles, id, type, url }: FileProps) => {
  if (type === "url") {
    return (
      <StyledItemsContainer>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            "& img": {
              width: "200px",
              height: "150px",
              borderRadius: "10px",
              objectFit: "cover",
            },
          }}
        >
          <img src={url} alt="image preview" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "3px",
          }}
        >
          <DeleteIcon
            id={id}
            apiUrl="/products/delete_image"
            payload={{
              image_id: id,
            }}
            refresh={REACT_QUERY_SINGLE_PRODUCT}
          />

          <IconButton>
            <img src={previewIcon} alt="preview-icon" />
          </IconButton>
        </Box>
      </StyledItemsContainer>
    );
  }
  let imagePreview = "";
  if (file) {
    imagePreview = URL?.createObjectURL(file);
  }

  return (
    <StyledItemsContainer>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          "& img": {
            width: "200px",
            height: "150px",
            borderRadius: "10px",
            objectFit: "cover",
          },
        }}
      >
        <img src={imagePreview} alt="image preview" />
        <Stack>
          <Tooltip title={file && file.name}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
              }}
            >
              {file && file.name && file.name.length > 30 ? file.name.slice(0, 30) + "..." : file?.name}
            </Typography>
          </Tooltip>

          <Typography
            sx={{
              fontSize: "1rem",
              color: "rgba(51, 51, 51, 0.6)",
            }}
          >
            {file && Math.round(file.size / 1024)} KB
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "3px",
        }}
      >
        <IconButton
          onClick={() => {
            if (!setFiles) return;
            setFiles((prev: any) =>
              // remove file after comparing id with index
              prev.filter((file: any, index: number) => index !== id)
            );
          }}
        >
          <img src={deleteIcon} alt="delete icon" />
        </IconButton>

        {/* <IconButton>
          <img src={previewIcon} alt="preview-icon" />
        </IconButton> */}
      </Box>
    </StyledItemsContainer>
  );
};

const Dropzone = (props: Props) => {
  const { values } = useFormikContext<any>();
  const [files, setFiles] = React.useState<any>(values?.images || []);
  const [open, setOpen] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState<any>(null);

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles((prev: any) => [...prev, ...acceptedFiles]);
    props.setFieldValue("images", [...acceptedFiles]);
    // setFiles(
    //   acceptedFiles.map((file: any) =>
    //     Object.assign(file, {
    //       preview: URL.createObjectURL(file),
    //     })
    //   )
    // );
    // setOpen(true);
    // setCurrentFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [] },
    onDrop: onDrop,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Container {...getRootProps({ isDragAccept, isDragReject, isFocused })}>
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            "& img": {
              height: {
                xs: "50px",
                md: "75px",
                lg: "unset",
              },
            },
          }}
        >
          <img src={uploadImage} alt="upload" />
          <Typography
            sx={{
              fontSize: {
                xs: ".7rem",
                sm: "1rem",
              },
              fontWeight: "500",
              color: "#333333",
              "& span": {
                color: "#34B44B",
                textDecoration: "underline",
              },
            }}
          >
            Drag and drop or <span>Browse Here</span>
          </Typography>
        </Box>
      </Container>
      <Grid container spacing={1}>
        {files.map((file: any, index: number) => (
          <Grid item xs={12} md={6}>
            <FileAccepted key={index} id={index} file={file} setFiles={setFiles} />
          </Grid>
        ))}
        {props.images?.map((image: any, index: number) => {
          return (
            <Grid item xs={12} md={6}>
              <FileAccepted type="url" key={index} id={image.id} url={image.image} />{" "}
            </Grid>
          );
        })}
      </Grid>
      {/* <CropImageModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        setFiles={setFiles}
        src={currentFile}
      /> */}
    </Box>
  );
};

export default Dropzone;
