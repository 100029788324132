import { InputBase, Stack, Typography } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";

type Props = {
  title: string;
  setFieldValue?: any;
  name?: string;
};

const CustomInputWithBtns = ({ title, name, setFieldValue }: Props) => {
  const { values, errors, touched } = useFormikContext<any>();
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <Stack spacing={0.5}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "600",
              position: "relative",
              width: "fit-content",
              "&::after": {
                content: '"*"',
                color: "red",
                right: "-8px",
                position: "absolute",
              },
            }}
          >
            {title}
          </Typography>
          <InputBase
            id={name}
            {...field}
            value={
              name && values[name] !== undefined
                ? values[name]
                : "No value found"
            }
            onChange={(e) => {
              setFieldValue(name, Number(e.target.value));
            }}
            startAdornment={
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (name) {
                    setFieldValue(name, Number(values[name]) + 1);
                  }
                }}
              >
                +
              </div>
            }
            endAdornment={
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (name) {
                    setFieldValue(name, Number(values[name]) - 1);
                  }
                }}
              >
                -
              </div>
            }
            placeholder="1"
            sx={{
              width: {
                xs: "120px",
                xl: "160px",
              },
              height: "40px",
              "& .MuiInputBase-input": {
                textAlign: "center",
              },
              border:
                meta.error && meta.touched
                  ? "3px solid #ff9ca2"
                  : "3px solid #DEF7E5",
              borderRadius: "6px",
              fontSize: "1.25rem",
              fontWeight: "700",
              padding: "0 1rem",
              // "&:hover": {
              //   border: errors.name ? "3px solid #ff9ca2" : "3px solid #DEF7E5",
              // },
            }}
          />
        </Stack>
      )}
    </Field>
  );
};

export default CustomInputWithBtns;
