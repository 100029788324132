import { Button } from "@mui/material";
import { CreatePayRequest } from "./createPayRequest";
import { IPaymentMethod } from "../../../types";
import { useState } from "react";

type Props = {
  product_id: string;
  paymentMethods: IPaymentMethod[];
};

export const CreatePayRequestContent = ({ product_id, paymentMethods }: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          minWidth: "200px",
          height: "50px",
          borderRadius: "10px",
        }}
        onClick={handleOpen}
      >
        اضافة عملاء
      </Button>
      <CreatePayRequest product_id={product_id} handleClose={handleClose} open={open} paymentMethods={paymentMethods} />
    </>
  );
};
