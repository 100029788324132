import * as React from "react";
import * as Yup from "yup";

import { CircularProgress, styled } from "@mui/material";
import { Form, Formik } from "formik";

import { AddProductFirstStep } from "./AddProductFirstStep";
import { AddProductSecondStep } from "./AddProductSecondStep";
import { AddProductThirdStep } from "./AddProductThirdStep";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Header from "../../components/Header/Header";
import MainContainer from "../../components/Containers/MainContainer";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type ProductFormProps = {
  initialValues: any;
  handleSubmit: any;
  type: number;
  images?: any;
  edit?: boolean;
};

export const ProductForm = ({ initialValues, handleSubmit, type, images, edit = false }: ProductFormProps) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //   const handleReset = () => {
  //     setActiveStep(0);
  //   };

  const unitsApiUrl = React.useMemo(() => "unit/get", []);
  const catApiUrl = React.useMemo(() => `categories/get?type=${type}&limit=all`, [type]);

  const filterStatusApi = React.useMemo(() => `helper/get_status?model_name=product`, []);

  const { data: unitsData } = useGetSingleApi(unitsApiUrl, []);
  const { data: catData } = useGetSingleApi(catApiUrl, []);
  const { data: productStatusData } = useGetSingleApi(filterStatusApi, []);
  const validationSchemaSecondStep = Yup.object().shape({
    user_id: Yup.string().required("التاجر مطلوب"),
  });
  const validationSchemaThirdStep = Yup.object().shape({
    name_ar: Yup.string().required("اسم المنتج بالعربيه"),
    name_en: Yup.string().required("اسم المنتج بالانجليزيه"),
    unit_id: Yup.string().required("وحده القياس مطلوبه"),
    cat_id: Yup.string().required("التصنيف مطلوب"),
    brand_id: Yup.string().notRequired(),
    status_id: Yup.string().required("حاله المنتج مطلوبه"),
    quantity: Yup.number().required("الكميه مطلوبه"),
    price_type: Yup.string().required("تسعير المنتج مطلوب"),
    less_quantity: Yup.number().required("ادخل اقل كميه للطلب"),
    price: Yup.string().test("required-if-price-type-2", "سعر المنتج بعد الخصم مطلوب", function (value) {
      const priceType = this.resolve(Yup.ref("price_type"));

      if (priceType === "1") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
    step_price: Yup.string().notRequired().matches(/^\d+$/, "أقل سعر للمزايدة يجب ان يكون رقم"),

    mazad_type: Yup.string().test("required-if-price-type-2", "نوع المزاد مطلوب", function (value) {
      const priceType = this.resolve(Yup.ref("price_type"));
      if (priceType === "2") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
    end_date: Yup.string().test("required-if-mazad-type-1", "التاريخ  مطلوب", function (value) {
      const mazadType = this.resolve(Yup.ref("mazad_type"));
      if (mazadType === "1") {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }),
  });

  return (
    <div>
      <Header title={`${edit ? "تعديل" : "اضافه"}  ${type === 2 ? "خردة" : "رواكد"}`} />
      <MainContainer>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (activeStep === 2) {
              handleSubmit(values, actions);
            } else {
              handleNext();
              actions.setSubmitting(false);
              actions.setTouched({});
            }
          }}
          validationSchema={
            activeStep === 1 ? validationSchemaSecondStep : activeStep === 2 ? validationSchemaThirdStep : undefined
          }
        >
          {({ values, setFieldValue, errors, touched, isSubmitting, handleSubmit }) => (
            <Form>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step key={"صور المنتج"}>
                  <StyledStepLabel
                    onClick={() => {
                      setActiveStep(0);
                    }}
                  >
                    {"صور المنتج"}
                  </StyledStepLabel>
                  <StepContent>
                    <AddProductFirstStep images={images} />
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1, width: "150px" }}>
                          متابعة
                        </Button>
                        <Button disabled onClick={handleBack} sx={{ mt: 1, mr: 1, width: "150px" }}>
                          العودة
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"بيانات العميل"}>
                  <StyledStepLabel
                    onClick={() => {
                      setActiveStep(1);
                    }}
                  >
                    {"بيانات العميل"}
                  </StyledStepLabel>
                  <StepContent>
                    <AddProductSecondStep catData={catData} />
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button variant="contained" sx={{ mt: 1, mr: 1, width: "150px" }} type="submit">
                          متابعة
                        </Button>
                        <Button onClick={handleBack} sx={{ mt: 1, mr: 1, width: "150px" }}>
                          العودة
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"بيانات المنتج"}>
                  <StyledStepLabel
                    onClick={() => {
                      setActiveStep(2);
                    }}
                  >
                    {"بيانات المنتج"}
                  </StyledStepLabel>
                  <StepContent>
                    <AddProductThirdStep
                      catData={catData}
                      unitsData={unitsData}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      productStatus={productStatusData}
                      type={type}
                    />
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ mt: 1, mr: 1, width: "150px" }}
                          disabled={isSubmitting}
                          endIcon={isSubmitting && <CircularProgress size={20} />}
                        >
                          {edit ? "تعديل" : "اضافه"}
                        </Button>
                        <Button onClick={handleBack} sx={{ mt: 1, mr: 1, width: "150px" }}>
                          العودة
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              </Stepper>
            </Form>
          )}
        </Formik>
      </MainContainer>
    </div>
  );
};

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  cursor: "pointer",
  "& .MuiStepLabel-label": {
    fontSize: "1.5rem",
    fontWeight: "700",
  },
  "& .MuiStepLabel-label.Mui-active": {
    color: theme.palette.primary.main,
  },
}));
