import { useQuery } from "@tanstack/react-query";
import { getMrkoonInfo } from "../../apis/information";
import { REACT_QUERY_INFO } from "../../constants/reactQuery.constants";

export const useGetInfo = () => {
  return useQuery({
    queryKey: [REACT_QUERY_INFO],
    queryFn: getMrkoonInfo,
  });
};
