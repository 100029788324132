import { Box, styled } from "@mui/material";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
  images: any[];
  discount?: number;
};

const Gallery = ({ images, discount }: Props) => {
  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);
  const [slider1, setSlider1] = useState<any>(null);
  const [slider2, setSlider2] = useState<any>(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    autoplaySpeed: 1000,
    adaptiveHeight: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          dots: true,
        },
      },
    ],
  };
  const settingsThumbs = {
    infinite: true,
    pauseOnHover: true,
    slidesToShow: images.length > 5 ? 5 : images.length - 1,

    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    // vertical: true,
    // verticalSwiping: true,
    // rtl: true,

    vertical: false,
    verticalSwiping: false,

    // responsive: [
    //   {
    //     breakpoint: 1200,
    //     slidesToShow: images.length > 3 ? 3 : images.length - 1,

    //     settings: {
    //       rtl: true,
    //       vertical: false,
    //       verticalSwiping: false,
    //     },
    //   },
    //   {
    //     breakpoint: 900,
    //     settings: {
    //       rtl: true,
    //       slidesToShow: images.length > 3 ? 3 : images.length - 1,
    //       vertical: false,
    //       verticalSwiping: false,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       rtl: true,
    //       slidesToShow: images.length > 3 ? 3 : images.length - 1,
    //       vertical: false,
    //       verticalSwiping: false,
    //     },
    //   },
    // ],
  };

  return (
    <Box
      sx={{
        display: "grid",
        justifyItems: "center",
        gridTemplateColumns: {
          xs: "1fr",
          // lg: images.length > 1 ? "3fr 1fr" : "1fr",
        },
        gap: "2rem",
        height: {
          mobile: "auto",
          // md: "500px",
          // lg: "500px",
          // xl: "650px",
        },
        "& .slick-slider": {
          "& .slick-arrow": {
            display: "none !important",
          },
        },
      }}
    >
      <Box
        sx={{
          // height: {
          //   xs: "340px",
          //   md: "320px",
          //   lg: "500px",
          //   xl: "650px",
          // },
          width: {
            xs: "60vw",
            // xs: "320px",
            // sm: "400px",
          },
        }}
      >
        <Slider {...settings} asNavFor={nav2} ref={(slider) => setSlider1(slider)} rtl>
          {images.map((img: any) => {
            return (
              <Box
                key={img.id}
                sx={{
                  height: {
                    xs: "340px",
                    md: "320px",
                    lg: "500px",
                    xl: "650px",
                  },
                  width: {
                    xs: "320px",
                    sm: "400px",
                    md: "calc(80vw / 14 * 7)",
                  },
                }}
              >
                <MainImg
                  sx={
                    {
                      // height: {
                      //   xs: "320px",
                      //   md: "320px",
                      //   lg: "500px",
                      //   xl: "650px",
                      // },
                    }
                  }
                  src={img.image}
                  alt="main-image"
                />
              </Box>
            );
          })}
        </Slider>
      </Box>

      {images.length > 1 && (
        <Box
          sx={{
            // height: {
            //   xs: "200px",
            //   lg: "500px",
            //   xl: "650px",
            // },
            width: {
              // xs: "400px",
              // sm: "400px",
              xs: "50vw",
            },
            display: {
              xs: "none",
              sm: "flex",
            },
            alignItems: "center",
            "& .slick-slider": {
              width: "100%",
            },
          }}
        >
          <Slider {...settingsThumbs} asNavFor={nav1} ref={(slider) => setSlider2(slider)}>
            {images.map((img: any) => {
              return <SmallImg img={img} key={img.id} />;
            })}
          </Slider>
        </Box>
      )}
    </Box>
  );
};

export default Gallery;

type SmallImgProps = {
  img: any;
};

const SmallImg = ({ img }: SmallImgProps) => {
  return (
    <ImgContainer
      sx={{
        height: {
          xs: "80px",
          sm: "125px",
        },
        width: {
          xs: "100%",
          xl: "200px",
        },
        padding: {
          xs: ".5rem !important",
        },
      }}
      key={img?.id}
    >
      <Img
        src={img.image}
        alt="banner"
        sx={{
          borderRadius: "7px",
        }}
      />
    </ImgContainer>
  );
};

// Styled Components

const MainImg = styled("img")({
  width: "100%",
  objectFit: "contain",
  height: "100% !important",
  // display: "block",
  borderRadius: "30px",
});
const Img = styled("img")({
  width: "100%",
  height: "100%",
  borderRadius: "7px",
  cursor: "pointer",
  // "@media (max-width: 768px)": {
  //   border: "1px solid #E5E5E5",
  // },
});
const ImgContainer = styled("div")({
  width: "100%",
  position: "relative",
});
