export const REACT_QUERY_CLIENTS = "clients";
export const REACT_QUERY_CLIENTS_INTERESTED = "clientsInterested";
export const REACT_QUERY_CLIENTS_TYPES = "clientsTypes";
// products

export const REACT_QUERY_PRODUCTS = "products";
export const REACT_QUERY_SINGLE_PRODUCT = "singleProduct";

export const REACT_QUERY_ADMINS = "admins";

// status-products
export const REACT_QUERY_STATUS_PRODUCTS = "statusProducts";

// auctions
export const REACT_QUERY_AUCTIONS = "auctions";

// payment-requests
export const REACT_QUERY_PAY_REQUESTS = "payRequests";
export const REACT_QUERY_CREATE_PAY_REQUESTS = "createPayRequests";
// payment-methods
export const REACT_QUERY_PAYMENT_METHODS = "paymentMethods";
// brands
export const REACT_QUERY_BRANDS = "brands";
// about
export const REACT_QUERY_ABOUT = "about";
export const REACT_QUERY_INFO = "INFO";
