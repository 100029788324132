import { Box, Checkbox, Stack, Typography } from "@mui/material";

import { ClientsTableActionMenu } from "../../features";
import MainChip from "../ClientStatusChip/MainChip";
import MessageModal from "../Modals/MessageModal";
import StyledClientName from "../StyledComponents/StyledClientName";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { format } from "date-fns";

//  row component
interface CustomTableRowProps {
  name: string;
  phone: string;
  company_name: string;
  user_type: any;
  status: number;
  id: number;
  tableType: 1 | 2;
  values: any;
  setFieldValue: any;
  index: number;
  image: string;
  created_at: string;
  mainAddress: {
    address: string;
    city: {
      id: number;
      name: string;
    };
  };
}
const CustomTableRow = ({
  image,
  name,
  phone,
  created_at,
  company_name,
  user_type,
  status,
  id,
  tableType,
  values,
  setFieldValue,
  index,
  mainAddress,
}: CustomTableRowProps) => {
  //end of deleting city logics
  const statusOptions = [
    {
      id: 3,
      name: "جديد",
      class: "new",
    },
    {
      id: 1,
      name: "مفعل",
      class: "active",
    },
    {
      id: 2,
      name: "غير مفعل",
      class: "danger",
    },
    {
      id: 4,
      name: "لم يتم الرد",
      class: "danger",
    },
  ];
  // const dispatch = useDispatch();
  return (
    <StyledTableRow
      sx={{
        backgroundColor: values.checked.includes(id)
          ? " rgba(222, 247, 229, 0.35)"
          : index % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: values.checked.includes(id) ? "1px solid #34B44A" : "unset",
      }}
    >
      <Checkbox
        checked={values.checked.includes(id)}
        onChange={() => {
          if (values.checked.includes(id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== id)
            );
          } else {
            setFieldValue("checked", [...values.checked, id]);
          }
        }}
      />
      <StyledSubCell>{id}</StyledSubCell>

      {tableType === 2 && <StyledClientName name={name} image={image} id={id} />}
      <StyledSubCell>{phone}</StyledSubCell>
      <StyledMainCell>{mainAddress?.city?.name || "-----"}</StyledMainCell>
      <StyledSubCell>{company_name}</StyledSubCell>
      <StyledSubCell>{user_type?.title || "------"}</StyledSubCell>
      <StyledSubCell>{created_at?.split(" ")[0] || "-----"}</StyledSubCell>

      <MainChip
        status={{
          id: status,
          name: status === 1 ? "مفعل" : status === 2 ? "غير مفعل" : status === 4 ? "لم يتم الرد" : "جديد",
          class: status === 1 ? "active" : status === 2 ? "danger" : status === 4 ? "danger" : "new",
        }}
        statusOptions={statusOptions}
        itemId={String(id)}
        modelName="user"
        statusApiUrl={`user/change_status?user_id=`}
      />
      <Stack direction="row" spacing={1} alignItems="inherit">
        <ClientsTableActionMenu id={id} name={name} type={tableType} />
      </Stack>
    </StyledTableRow>
  );
};

// main component

interface ClientsTableProps {
  tableType: 1 | 2;
  values: any;
  rows: any;
}

export default function ClientsTable({ tableType, values, rows }: ClientsTableProps) {
  const { setFieldValue } = useFormikContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflowX: "auto",
      }}
    >
      <StyledTableHeader
        sx={{
          minWidth: "1000px",
        }}
      >
        <Checkbox
          disabled={rows.length === 0 || !rows}
          onChange={() => {
            if (values.checked.length > 0) {
              setFieldValue("checked", []);
            } else {
              setFieldValue(
                "checked",
                rows.map((row: any) => row.id)
              );
            }
          }}
          checked={values.checked.length === rows.length}
        />
        <h6>#</h6>
        <h6>{tableType === 1 ? "المنتج" : "اسم العميل"}</h6>
        <h6>رقم العميل</h6>
        <h6>المدينة</h6>
        <h6>اسم الشركة</h6>
        <h6>نوع العميل</h6>
        <h6>تاريخ الانضمام</h6>
        <h6>الحالة</h6>
      </StyledTableHeader>
      <Stack
        spacing={1}
        sx={{
          minWidth: "1000px",
        }}
      >
        {rows && rows.length > 0 ? (
          rows.map((row: any, index: number) => {
            return (
              <CustomTableRow
                {...row}
                tableType={tableType}
                values={values}
                setFieldValue={setFieldValue}
                index={index}
                key={row.id}
              />
            );
          })
        ) : (
          <Typography
            sx={{
              color: "#34B44A",
              fontWeight: "700",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            لا يوجد عملاء للعرض
          </Typography>
        )}
        {tableType !== 1 && <MessageModal />}
      </Stack>
    </Box>
  );
}

//styled components for the table

const StyledTableHeader = styled("div")({
  minWidth: "990px",

  display: "grid",
  gridTemplateColumns: ".25fr .25fr 1.5fr 1fr 1fr 1fr .7fr .75fr .8fr .3fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  minWidth: "990px",
  display: "grid",
  gridTemplateColumns: ".25fr .25fr 1.5fr 1fr 1fr 1fr .7fr .75fr .8fr .3fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "70px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledSubCell = styled(Typography)({
  fontSize: "1.125rem",
  fontWeight: "400",
  color: "#000000",
  textAlign: "center",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});
