import LoginForm from "../../components/LoginForm/LoginForm";
import bg from "../../assets/images/login-bg.svg";
import styles from "./Login.module.scss";

type Props = {};

const Login = (props: Props) => {
  return (
    <div className={styles.login}>
      <div className={styles.mainStep}>
        <div className={styles.image}>
          <img src={bg} alt="bg" />
        </div>
        <div className={styles.form}>
          <h1>
            اهلاً بك ابدأ استخدام <span>لوحة التحكم</span>
          </h1>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
