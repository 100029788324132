import { styled } from "@mui/material";

export const StyledSubContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  padding: "23px 27px",
  gap: "30px",
});

export const StyledTitle = styled("h4")({
  fontSize: "1.25rem",
  fontWeight: "700",
});
