import "./App.scss";
import "./i18n";
import "./Services/firebase";

import { cacheLtr, cacheRtl } from "./helpers/cache.js";
import { ltrTheme, rtlTheme } from "./helpers/themes.js";
import { useDispatch, useSelector } from "react-redux";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AlertMessage from "./components/AlertMessage/AlertMessage";
import AppRoutes from "./components/AppRoutes/AppRoutes";
import { CacheProvider } from "@emotion/react";
import DeliveryModal from "./components/Modals/DeliveryModal";
import GenericSendMessageModal from "./components/Modals/GenericSendMessageModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import MainModal from "./components/Modals/MainModal";
import ProductPercentageModal from "./components/Modals/ProductPercentageModal";
import { RootState } from "./store";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userActions } from "./store/user-slice";

function App() {
  //load notification on firebase message
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  // const cookies = new Cookies();
  const navigate = useNavigate();
  const dir = i18n.dir();
  useLayoutEffect(() => {
    document.body.setAttribute("dir", i18n.dir());
  }, [i18n.dir()]);

  const token = useSelector((state: RootState) => state.user.token);
  console.log("base", process.env.REACT_APP_API_UR);
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  // axios interceptors
  axios.interceptors.request.use((config: any) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token || ""}`;
    }
    config.headers.api_sec = "qJB0rGtIn5UB1xG03efyCp";
    //lang
    config.headers["Accept-Language"] = "ar";
    config.headers["lang"] = "ar";

    return config;
  });
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (error.response && error.response.status === 401) {
        dispatch(userActions.logout());

        navigate("/login");
      }

      return Promise.reject(error);
    }
  );

  // const deleteWrongPayRequest = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("payRequest_id", "325");
  //     await postAxios("pay_request/delete");
  //     toast.success("تم حذف الطلب بنجاح");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   deleteWrongPayRequest();
  // }, []);

  return (
    <>
      <CacheProvider value={dir === "rtl" ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={dir === "rtl" ? rtlTheme : ltrTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AppRoutes />
            <AlertMessage />
            <MainModal />
            <GenericSendMessageModal />
            <ProductPercentageModal />
            <DeliveryModal />
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

export default App;
