import { Box, Stack, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { useGetPartners } from "../../hooks/partners/useGetPartners.hook";

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 5fr 1fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 2rem",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

interface CustomTableRowProps {
  id: number;
  index: number;
  logo: string;
}

const CustomTableRow: React.FC<CustomTableRowProps> = ({ id, index, logo }) => {
  return (
    <StyledTableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
        border: "unset",
      }}
    >
      <StyledMainCell>
        <Link to={logo} target="__blank">
          <Box
            component={"img"}
            src={logo}
            sx={{
              width: "100px",
              height: "45px",
              borderRadius: "10px",
              objectFit: "contain",
            }}
          ></Box>
        </Link>
      </StyledMainCell>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <DeleteIcon
          id={Number(id)}
          apiUrl="partner/delete"
          payload={{
            partner_id: id,
          }}
          refresh="partners"
        />
      </Stack>
    </StyledTableRow>
  );
};

interface PartnersTableProps {
  data: any;
}

export const PartnersTable: React.FC<PartnersTableProps> = ({ data }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader>
          <h6>الصور</h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {data.map((row: any, index: number) => {
            return (
              <CustomTableRow
                {...row}
                id={row.id}
                index={index}
                key={row.id}
                logo={row.logo}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
};
