import { Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { postAxios } from "../../Services/axios-services";
import CustomTextField from "../CustomTextField/CustomTextField";
import { useNavigate } from "react-router-dom";

type Props = {};

const ChangePasswordForm = (props: Props) => {
  const navigate = useNavigate();
  const handleChangePassword = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);

      await postAxios("auth/change_password", values).then((res) => {
        if (res.status === 200 && res.data.status === 1) {
          toast.success(`تم تعديل كلمة المرور بنجاح`, {
            toastId: "EditProfileForm",
          });
          setTimeout(() => {
            navigate("/login");
          }, 500);
        } else {
          toast.error(res?.data?.message || `Something Went wrong`, {
            toastId: "EditProfileForm",
          });
        }
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || `Something Went wrong`, {
        toastId: "EditProfileForm",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };
  const validationSchema = yup.object({
    oldPassword: yup.string().required(" كلمة المرور القديمة مطلوبة"),
    newPassword: yup
      .string()
      .required(" كلمة المرور الجديدة مطلوبة")
      .min(8, "كلمة المرور يجب ان تكون اكثر من 8 احرف"),
  });
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
      }}
      onSubmit={handleChangePassword}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form>
          <Grid
            container
            spacing={2}
            rowSpacing={4}
            sx={{
              margin: "1rem 0",
            }}
          >
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="oldPassword"
                label="كلمة المرور القديمة"
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="newPassword"
                label="كلمة المرور الجديده"
                required
                type="password"
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              xs={12}
              item
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  height: "60px",
                  width: "250px",
                }}
                disabled={isSubmitting}
                endIcon={isSubmitting && <CircularProgress size={20} />}
              >
                تعديل كلمة المرور
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
