import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";
import { useFormikContext } from "formik";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {
  values: any;
};

const ClientTypeForm = ({ values }: Props) => {
  const { touched, errors, setFieldValue } = useFormikContext();
  const { data, loading } = useGetSingleApi("userTypes/get", []);
  const [subItems, setSubItems] = useState([]);
  useEffect(() => {
    if (values && values?.user_type && !loading && data) {
      setSubItems(
        data?.data?.data
          ?.filter((item: any) => item?.id == values?.user_type)[0]
          ?.subTypes?.map((item: any) => ({
            value: item?.id,
            label: item?.title,
          }))
      );
    }
  }, [values?.user_type, loading]);
  return (
    <>
      <Grid item xs={12} md={3}>
        <CustomSelect
          touched={touched}
          loading={loading}
          title="نوع العميل"
          placeholder="اختر نوع العميل"
          name="user_type"
          errors={errors}
          setFieldValue={setFieldValue}
          values={values}
          optionsProp={
            (data &&
              !loading &&
              data?.data?.data?.map((item: any) => ({
                value: item?.id,
                label: item?.title,
              }))) ||
            []
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CustomSelect
          touched={touched}
          //   disabled={values?.user_type}
          title="نوع العميل"
          placeholder="اختر نوع العميل الفرعي"
          name="user_type_id"
          errors={errors}
          setFieldValue={setFieldValue}
          optionsProp={subItems}
          values={values}
        />
      </Grid>
    </>
  );
};

export default ClientTypeForm;
