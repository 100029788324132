import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { AiOutlineEye } from "react-icons/ai";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";

import { RiEditBoxLine } from "react-icons/ri";
import SingleClientMainInfo from "../../components/SingleClientMainInfo/SingleClientMainInfo";
import Gallery from "../../components/Gallery/Gallery";
import StatusCard from "../../components/StatusCard/StatusCard";
import BasicAccordionContainer from "../../components/BasicAccordionContainer/BasicAccordionContainer";
import Map from "../../components/Map/Map";
import { useNavigate, useParams } from "react-router-dom";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import MainLoader from "../../components/Loaders/MainLoader";
import ProductTabs from "../../components/ProductTabs/ProductTabs";
import MainChip from "../../components/ClientStatusChip/MainChip";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledMainCell from "../../components/StyledComponents/StyledMainCell";
import { percentageActions } from "../../store/percentage-slice";
type Props = {};

const SingleKhorda = (props: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mainState = useSelector((state: any) => state.main);
  const { data, loading, error } = useGetSingleApi(`products/single?product_id=${id}`, [mainState.refreshApi]);
  //get filters
  const filterStatusApi = useMemo(() => `helper/get_status?model_name=product`, []);

  const { data: filterData, loading: filterDataLoading, error: filterDateError } = useGetSingleApi(filterStatusApi, []);
  const dispatch = useDispatch();
  const handleOpenPercentageModal = () => {
    dispatch(
      percentageActions.openModal({
        product_id: id,
        masafa_price: Number(data?.data?.masafa_price),
      })
    );
  };

  return (
    <div>
      <Header title="الخرده" />
      {loading ? (
        <MainLoader />
      ) : (
        <MainContainer>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="h6"
              sx={{
                color: "primary.main",
                fontWeight: "700",
                fontSize: "1.375rem",
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "8px",
                  left: "0",
                  width: "90px",
                  height: "2px",
                  backgroundColor: "primary.main",
                },
              }}
            >
              تفاصيل المنتج{" "}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleOpenPercentageModal}
              >
                <Typography>نسبة مسافه</Typography>
                <StyledMainCell>{Number(data?.data?.masafa_price).toLocaleString() + "%"}</StyledMainCell>
              </Stack>
              <MainChip
                modelName="product"
                status={{
                  name: data?.data?.status?.name_ar,
                  id: data?.data?.status?.id,
                  class: data?.data?.status?.class,
                }}
                statusApiUrl="products/change_status"
                itemId={String(id)}
                statusOptions={filterData?.data?.map((item: any) => {
                  return {
                    name: item.name_ar,
                    id: item.id,
                    class: item.class,
                  };
                })}
              />
              <OutlinedButton
                endIcon={<RiEditBoxLine />}
                onClick={() => {
                  navigate(`/khorda/all/${id}/edit`);
                }}
              >
                تعديل{" "}
              </OutlinedButton>
              <Button
                sx={{
                  color: "#F7F7FC",
                  borderRadius: "8px",
                  height: "50px",
                  fontSize: "1.125rem",
                  fontWeight: "800",
                }}
                variant="contained"
                color="primary"
                endIcon={<AiOutlineEye />}
                onClick={() => {
                  navigate(`/orders/all?product_id=${id}`);
                }}
              >
                مشاهدة طلبات المنتج{" "}
              </Button>
            </Stack>
          </Stack>
          <SingleClientMainInfo
            componentType="seller"
            image={data?.data?.user?.image}
            name={data?.data?.user?.name}
            phone={data?.data?.user?.phone}
            email={data?.data?.user?.email}
            company_name={data?.data?.user?.company_name}
            user_type={data?.data?.user?.user_type}
            price_type={data?.data?.price_type}
            userId={data?.data?.user?.id}
            category={data?.data?.category?.name_ar}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              marginTop: "2rem",
            }}
          >
            {data?.data?.images && <Gallery images={data?.data?.images} />}

            <ProductTabs description={data?.data?.description} adds={data?.data?.adds} />
            {data?.data?.address && (
              <BasicAccordionContainer title="الموقع علي الخريطة">
                <Stack
                  direction="row"
                  spacing={"128px"}
                  sx={{
                    marginBottom: "1.5rem",
                  }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="h3"
                      color="primary.main"
                      sx={{
                        fontWeight: "800",
                        fontSize: "1.25rem",
                      }}
                    >
                      المدينة
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      {data?.data?.address?.city?.name_ar}
                    </Typography>
                  </Stack>
                  <Stack direction="column">
                    <Typography
                      variant="h3"
                      color="primary.main"
                      sx={{
                        fontWeight: "800",
                        fontSize: "1.25rem",
                      }}
                    >
                      العنوان
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "400",
                      }}
                    >
                      {data?.data?.address?.address}
                    </Typography>
                  </Stack>
                </Stack>

                <Map
                  height="290px"
                  url={`https://maps.google.com/maps?q=${data?.data?.address?.lat},${data?.data?.address?.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                />
              </BasicAccordionContainer>
            )}
          </Box>
        </MainContainer>
      )}
    </div>
  );
};

export default SingleKhorda;
