import { Box, Checkbox, Stack, Typography } from "@mui/material";

import ClientsInterestedModal from "../Modals/ClientsInterestedModal";
import { IWebsiteProduct } from "../../types";
import { Link } from "react-router-dom";
import MainChip from "../ClientStatusChip/MainChip";
import { ProductTableMenu } from "../../features";
import StyledPrice from "../StyledComponents/StyledPrice";
import { percentageActions } from "../../store/percentage-slice";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { useState } from "react";

//styled components for the table

const StyledTableHeader = styled("div")({
  minWidth: "1050px",
  display: "grid",
  gridTemplateColumns: ".25fr repeat(8,1fr) .5fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  minWidth: "1050px",
  display: "grid",
  gridTemplateColumns: ".25fr repeat(8,1fr) .5fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledSubCell = styled(Typography)({
  fontSize: ".875rem",
  fontWeight: "400",
  color: "#000000",
  textAlign: "center",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

//  row component
interface CustomTableRowProps extends IWebsiteProduct {
  // name: string;
  // unit: any;
  // quantity: string;
  // price_type: number;
  // user: any;
  // status: any;
  // id: number;
  // logo?: string;
  // masafa_price?: string;
  // type?: string;
  values: any;
  setFieldValue: any;
  tableType?: string;
  index: number;
  statusOptions: any;
  handleOpenInterestedClients?: (id: number) => void;
}

const CustomTableRow = ({
  tableType,

  masafa_price,
  unit,
  name,
  quantity,
  price_type,
  user,
  status,
  logo,
  id,
  values,
  setFieldValue,
  index,
  statusOptions,
  lot,
  handleOpenInterestedClients,
}: CustomTableRowProps) => {
  const dispatch = useDispatch();

  const handleOpenPercentageModal = () => {
    dispatch(
      percentageActions.openModal({
        product_id: id,
        masafa_price: Number(masafa_price),
      })
    );
  };

  return (
    <StyledTableRow
      sx={{
        backgroundColor: values.checked.includes(id)
          ? " rgba(222, 247, 229, 0.35)"
          : index % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: values.checked.includes(id) ? "1px solid #34B44A" : "unset",
      }}
    >
      <Checkbox
        checked={values.checked.includes(id)}
        onChange={() => {
          if (values.checked.includes(id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== id)
            );
          } else {
            setFieldValue("checked", [...values.checked, id]);
          }
        }}
      />
      <Typography>{id}</Typography>
      <Box
        component="img"
        sx={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        src={logo}
        alt={name}
        onClick={() => {
          window.open(logo, "_blank");
        }}
      />
      <Link
        to={
          tableType === "khorda"
            ? `/khorda/all/${id}`
            : tableType === "construction"
            ? `/construction-materials/all/${id}`
            : `/rawakd/all/${id}`
        }
      >
        <StyledSubCell>{name}</StyledSubCell>
      </Link>
      <StyledPrice>
        {quantity} <span>{unit?.name_ar}</span>
      </StyledPrice>{" "}
      <StyledPrice>{price_type === 1 ? "سعر ثابت" : "مزاد"}</StyledPrice>
      <Link to={`/clients/all/${user.id}`}>
        <StyledMainCell>{user.name}</StyledMainCell>
      </Link>
      <StyledMainCell
        sx={{
          cursor: "pointer",
        }}
        onClick={handleOpenPercentageModal}
      >
        {Number(masafa_price) + "%"}
      </StyledMainCell>
      <MainChip
        modelName="product"
        status={{
          name: status?.name_ar,
          id: status?.id,
          class: status?.class,
        }}
        statusApiUrl="products/change_status"
        itemId={String(id)}
        statusOptions={statusOptions}
      />
      <ProductTableMenu
        id={id}
        tableType={tableType}
        handleOpenInterestedClients={handleOpenInterestedClients}
        product_name={name}
        lot={lot}
      />
    </StyledTableRow>
  );
};

// main component

interface ClientsTableProps {
  type?: string;
  rows: any;
  values: any;
  statusOptions?: any;
}

export default function RawakdTable({
  values,
  rows,
  type,
  statusOptions,
}: ClientsTableProps) {
  const { setFieldValue } = useFormikContext();
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = (id: any) => {
    setSelectedProduct(id);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedProduct(null);
    setOpen(false);
  };
  console.log(type, "type");
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflowX: "auto",
        }}
      >
        <StyledTableHeader>
          <Checkbox
            disabled={rows?.length === 0}
            onChange={() => {
              if (values?.checked.length > 0) {
                setFieldValue("checked", []);
              } else {
                setFieldValue(
                  "checked",
                  rows.map((row: any, index: number) => row.id)
                );
              }
            }}
            checked={values.checked.length === rows?.length}
          />
          <h6>كود المنتج</h6>
          <h6>صورة المنتج </h6>
          <h6>اسم المنتج</h6>
          <h6>الكمية</h6>
          <h6> نوع التسعير </h6>
          <h6>اسم البائع</h6>
          <h6>نسبة مسافة</h6>
          <h6>حالة المنتج</h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {rows && rows.length > 0 ? (
            rows?.map((row: any, index: number) => {
              return (
                <CustomTableRow
                  tableType={type}
                  {...row}
                  id={row.id}
                  index={index}
                  key={index}
                  values={values}
                  setFieldValue={setFieldValue}
                  statusOptions={statusOptions}
                  handleOpenInterestedClients={handleOpen}
                />
              );
            })
          ) : (
            <Typography
              sx={{
                color: "primary.main",
                fontSize: "1.25rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              لا يوجد بيانات لعرضها في الجدول
            </Typography>
          )}
        </Stack>
        {selectedProduct && (
          <ClientsInterestedModal
            open={open}
            onClose={handleClose}
            productId={selectedProduct}
            users={values}
          />
        )}
      </Box>
    </>
  );
}
