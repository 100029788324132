import { Box, Button, CircularProgress, Modal, Stack, TextField, Typography } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useInterestedClients } from "../../hooks/useInterestedClients";
import TableLoader from "../Loaders/TableLoader";
import InterestedClientsTable from "../Tables/InterestedClientsTable";
import CustomSelectSmall from "../CustomSelect/CustomSelectSmall";
import { useDispatch, useSelector } from "react-redux";
import { genericMessageModalActions } from "../../store/generic-message-slice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 1rem",
  minWidth: "1024px",
  width: "100%",
  maxWidth: "90vw",
  maxHeight: "90vh",
  overflowY: "auto",
};

type Props = {
  open: boolean;
  onClose: () => void;
  productId: string;
  users:any;
};
const ClientsInterestedModal: React.FC<Props> = ({ onClose, open, productId,users }) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: any) => state.genericMessageModal);

  const getClients: any = () => {
    return clientsData?.pages.map((page) => page).flat();
  };

  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState("");

  const debouncedValue = useDebounce(searchText, 500);
  const { data: clientsData, ref, hasNextPage, isPending } = useInterestedClients(productId, debouncedValue, filters);
  let initialChecked: any = [];

  if (modalState.userId) {
    initialChecked = modalState.userId?.split(",").map((item: string) => {
      item && initialChecked.push(item);
    });
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        dispatch(genericMessageModalActions.closeModal());
        dispatch(genericMessageModalActions.setUserId(""));
        dispatch(genericMessageModalActions.setProductId(""));
      }}
      aria-labelledby="modal-add"
      aria-describedby="modal-add"
    >
      <Box sx={style}>
        <Formik
          initialValues={{
            checked: initialChecked,
          }}
          onSubmit={(values) => {
            console.log(values)
        
            // console.log(users,items)
            // dispatch(genericMessageModalActions.setUserId(items));
          }}
        >
          {({ values }) => (
            <Form>
              <Stack spacing={1}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Typography variant="h5" color="primary">
                    العملاء المهتمين
                  </Typography>
                  <Stack spacing={2} direction="row" sx={{ minWidth: "650px" }}>
                    <Button
                      sx={{ alignSelf: "flex-end" }}
                      disabled={values.checked.length === 0 || !modalState.productId}
                      onClick={() => {
                        console.log("modalState.productId", values.checked);
                        let items = "";
                        items = values.checked.join(",");
                        dispatch(genericMessageModalActions.setUserId(items));
                        dispatch(genericMessageModalActions.openModal());
                      }}
                    >
                      ارسل رسالة
                    </Button>
                    <CustomSelectSmall
                      optionsProp={[
                        { value: "", label: "الكل" },
                        { value: 1, label: "المزايدين" },
                        { value: 2, label: "غير المزايدين" },
                      ]}
                      placeholder="اختيار نوع المزايدين"
                      type="bid"
                      setFilters={setFilters}
                    />
                    <TextField
                      size="small"
                      label="بحث"
                      variant="outlined"
                      onChange={(e) => setSearchText(e.target.value)}
                      value={searchText}
                      sx={{ minWidth: "200px" }}
                    />
                  </Stack>
                </Stack>
                {isPending ? (
                  <TableLoader />
                ) : (
                  <InterestedClientsTable rows={getClients()} values={values} tableType={2} />
                )}
              </Stack>

              {hasNextPage && (
                <Stack alignItems="center" sx={{ marginTop: "1rem" }}>
                  <CircularProgress size={24} ref={ref} />
                </Stack>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ClientsInterestedModal;
