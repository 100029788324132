import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { getAdmins } from "../../../apis";
import { REACT_QUERY_ADMINS } from "../../../constants/reactQuery.constants";

export const useGetAdmins = () => {
  const { data, error, isPending, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: [REACT_QUERY_ADMINS],
    queryFn: getAdmins,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < 10) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return {
    data,
    error,
    isPending,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    ref,
  };
};
