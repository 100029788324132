import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 4.75rem",
  width: "670px",
};

type Props = {
  message: string;
  open: boolean;
  handleClose: () => void;
};

const ContactUsModal: React.FC<Props> = ({ open, handleClose, message }) => {
  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-add" aria-describedby="modal-add">
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: 700,
                color: "#34B44A",
                textAlign: "center",
              }}
            >
              {`الرسالة`}
            </Typography>
            <Box
              sx={{
                margin: "1rem 0",
                display: "flex",
                gap: "1rem",
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "1rem",
                padding: "1rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                }}
              >
                {message}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ContactUsModal;
