import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { mainSliceActions } from "../../store/main-slice";
import CustomTextField from "../CustomTextField/CustomTextField";
import MainLoader from "../Loaders/MainLoader";
import SmallUpload from "../SmallUpload/SmallUpload";
import { StyledSecondaryContainer } from "../StyledComponents/AddClientFormComponents";
const StyledTitle = styled("h3")({
  fontWeight: 700,
  fontSize: "1.325rem",
  paddingLeft: "1rem",
  color: "#34B44A",
  position: "relative",
  "&::before": {
    content: "''",
    position: "absolute",
    left: "1rem",
    bottom: "-8px",
    width: "90px",
    height: "2px",
    background: "#34B44A",
  },
});

const SettingsForm = () => {
  //   const onSubmit = (values: any) => {
  //     console.log(values);
  //   };
  const apiUrl = useMemo(() => "setting/get", []);
  const mainState = useSelector((state: any) => state.main);
  //   const dependencies = mainState
  const { data, loading } = useGetSingleApi(apiUrl, [mainState.refreshApi]);
  const dispatch = useDispatch();

  const onSubmit = async (values: any, actions: any) => {
    //remove the strings from images fields rawakd_bar , korda_bar , rawakd_menu , korda_menu
    let valuesWithOutStrings = {};
    for (const key in values) {
      if (key === "rwaked_bar" || key === "korda_bar") {
        if (typeof values[key] === "string") {
          //ddo nothing
        } else {
          valuesWithOutStrings = {
            ...valuesWithOutStrings,
            [key]: values[key],
          };
        }
      } else {
        valuesWithOutStrings = {
          ...valuesWithOutStrings,
          [key]: values[key],
        };
      }
    }
    try {
      const response = await postAxios(`setting/update`, valuesWithOutStrings);
      if (response.status === 200) {
        toast.success("تم تعديل البيانات بنجاح");
        dispatch(mainSliceActions.toggleRefreshApi());
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };
  if (loading) return <MainLoader />;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        wahtsapp: data?.data?.wahtsapp || "",
        facebook: data?.data?.facebook || "",
        instagram: data?.data?.instagram || "",
        email: data?.data?.email || "",
        about_ar: data?.data?.about_ar || "",
        about_en: data?.data?.about_en || "",
        about_title_ar: data?.data?.about_title_ar || "",
        about_title_en: data?.data?.about_title_en || "",
        rwaked_bar: data?.data?.rwaked_bar || "",
        korda_bar: data?.data?.korda_bar || "",
        privacy_ar: data?.data?.privacy_ar || "",
        privacy_en: data?.data?.privacy_en || "",
        construction_materials: data?.data?.construction_materials || "",
        interests_number: data?.data?.interests_number || "",
        show_prices_guests: data?.data?.show_prices_guests || "",
      }}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
        <Form>
          <Stack spacing={4}>
            <StyledTitle>معلومات رئيسية</StyledTitle>
            <StyledSecondaryContainer>
              <Grid container rowSpacing={5} columnSpacing={2.5}>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="رقم الواتس اب" name="wahtsapp" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="رابط Facebook" name="facebook" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="رابط instagram" name="instagram" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="البريد الالكتروني" name="email" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="عدد اهتمامات التاجر" name="interests_number" type="number" />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label="تفعيل مواد البناء"
                    control={
                      <Switch
                        name="construction_materials"
                        checked={values?.construction_materials == 1 ? true : false}
                        onChange={(e) => {
                          setFieldValue("construction_materials", e.target.checked ? 1 : 0);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label="عرض الاسعار للزوار"
                    control={
                      <Switch
                        name="show_prices_guests"
                        checked={values?.show_prices_guests == 1 ? true : false}
                        onChange={(e) => {
                          setFieldValue("show_prices_guests", e.target.checked ? 1 : 0);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </StyledSecondaryContainer>
            <StyledTitle>الصفحات</StyledTitle>
            <StyledSecondaryContainer>
              <Grid container rowSpacing={5} columnSpacing={2.5}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "1.325rem",
                    }}
                  >
                    سياسة الموقع{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="الخصوصيه ar" name="privacy_ar" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="الخصوصيه en" name="privacy_en" />
                </Grid>
              </Grid>
              <Grid container rowSpacing={5} columnSpacing={2.5}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "1.325rem",
                    }}
                  >
                    عن الموقع
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <CustomTextField label="العنوان ar" name="about_title_ar" />
                    <CustomTextField label="الوصف ar" name="about_ar" multiLine={5} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <CustomTextField label="العنوان en" name="about_title_en" />
                    <CustomTextField label="الوصف en" name="about_en" multiLine={5} />
                  </Stack>
                </Grid>
              </Grid>
            </StyledSecondaryContainer>
            <StyledTitle>الصور</StyledTitle>
            <StyledSecondaryContainer>
              <Grid container rowSpacing={5} columnSpacing={2.5}>
                <Grid item xs={12} md={6}>
                  <SmallUpload
                    imageFromProps={data?.data?.rwaked_bar}
                    title="راوكد بار"
                    name="rwaked_bar"
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SmallUpload
                    imageFromProps={data?.data?.korda_bar}
                    title="خرده بار"
                    name="korda_bar"
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </StyledSecondaryContainer>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  height: "45px",
                  borderRadius: "8px",
                  fontSize: "1.5rem",
                }}
                type="submit"
                disabled={isSubmitting}
                endIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {" "}
                ادخال
              </Button>
            </Box>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default SettingsForm;
