import React from "react";
import { styled } from "@mui/material";

const LoginFormContainer = styled("div")({
  flex: "1",
  backgroundColor: "white",
  border: "0.5px solid #E5E5E5",
  boxShadow: "0px 4px 12px rgba(57, 255, 91, 0.03)",
  borderRadius: "25px",
  padding: "2.25rem 2rem",
  display: "flex",
  flexDirection: "column",
  gap: "50px",
  h3: {
    fontSize: "1.5rem",
    fontWeight: "700",
    color: "primary.main",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      left: "0",
      bottom: "-5px",
      width: "93px",
      height: "3px",
      backgroundColor: "primary.main",
    },
  },
});

export default LoginFormContainer;
