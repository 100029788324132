import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { CommonModalProps } from "../../types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 4.75rem",
  maxHeight: "90vh",
  overflowY: "auto",
};

export const CommonModal: React.FC<CommonModalProps<any>> = ({
  open,
  handleClose,
  title,
  children,
  handleConfirm,
  isLoading,
  width,
  confirmButtonText,
}) => {
  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-add" aria-describedby="modal-add">
        <Box sx={{ ...style, width: width ?? "670px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "2rem",
                fontWeight: 700,
                color: "#34B44A",
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
            {children && children}
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  height: "45px",
                  width: "200px",
                  color: "#FFFFFF",
                  borderRadius: "6px",
                }}
                type="submit"
                disabled={isLoading}
                endIcon={isLoading ? <CircularProgress size={20} /> : null}
                onClick={handleConfirm}
              >
                {confirmButtonText ?? "إضافة"}{" "}
              </Button>
              <Button
                sx={{
                  borderRadius: "6px",
                  border: "2px solid #34B44A",
                  fontSize: "1rem",
                  fontWeight: 700,
                  height: "45px",
                  width: "200px",
                }}
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                إلغاء
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
