import React, { useEffect } from "react";

import MainLoader from "../../components/Loaders/MainLoader";
import { ProductForm } from "../../features";
import { REACT_QUERY_SINGLE_PRODUCT } from "../../constants/reactQuery.constants";
import { getSingleProduct } from "../../apis";
import useAddProducts from "../../hooks/useAddProcuts";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const EditRawakd = () => {
  const { id } = useParams<{ id: string }>();
  const [productInitialValues, setProductInitialValues] = React.useState<any>({});
  const [images, setImages] = React.useState<any>([]);
  // const [loading, setLoading] = React.useState(false);

  const { isPending, data, isSuccess } = useQuery({
    queryKey: [REACT_QUERY_SINGLE_PRODUCT, id],
    queryFn: getSingleProduct,
  });

  useEffect(() => {
    if (isSuccess && data) {
      let tags = data?.tags.map((tag: any) => tag.id);
      setImages(data?.images);
      setProductInitialValues({
        product_id: id || "",
        lot: data.lot || 0,
        sort: data.sort || 1,
        name_ar: data.name_ar || "",
        name_en: data.name_en || "",
        description_ar: data.description_ar || "",
        description_en: data.description_en || "",
        logo: data.logo || "",
        brand_id: data.brand?.id || "",
        conditions_document: data.conditions_document || "",
        pdf: data.pdf || "",
        quantity: data.quantity || "",
        unit_id: data.unit?.id || "",
        type: data.type || "",
        price_type: data.price_type || "",
        start_price: data.start_price || "",
        price: data.price || "",
        real_price: data.real_price || "",
        mazad_type: data.mazad_type || "",
        end_date: data.end_date || "",
        cat_id: data?.parent_cat_id || data.category?.id || "",
        sub_cat_id: data?.parent_cat_id ? data.category?.id : "",
        address_id: data.address?.id || "",
        masafa_price: data.masafa_price,
        less_quantity: data.less_quantity || "",
        status_id: data.status?.id || "",
        user_id: data.user?.id || "",
        user: data.user || "",
        images: [],
        tags: tags || [],
        show_company: data.show_company || 0,
        step_price: Number(data.step_price) || "",
        has_conditions_document: data.has_conditions_document || 0,
        conditions_document_price: data.conditions_document_price || "",
        parent_id: data.parent_id || "",
        adds: (data.adds.length > 0 && data.adds) || [
          {
            key_ar: "",
            value_ar: "",
            key_en: "",
            value_en: "",
          },
        ],
      });
    }
  }, [isSuccess, data, id]);

  const { handleSubmit } = useAddProducts({ type: 1, edit: true });
  if (isPending) return <MainLoader />;
  return <ProductForm initialValues={productInitialValues} handleSubmit={handleSubmit} type={1} images={images} edit />;
};

export default EditRawakd;
