import { Button, Stack, Typography, styled } from "@mui/material";
import { Form, Formik } from "formik";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import React, { useState } from "react";

import AuctionMessagesModal from "../../components/AuctionMessagesModal/AuctionMessagesModal";
import { ClientsFilterDrawer } from "../../features";
import ClientsTable from "../../components/Tables/ClientsTable";
import ConfirmActionDialog from "../../components/ConfirmActionDialog/ConfirmActionDialog";
import CustomTextFieldSmall from "../CustomTextFieldSmall/CustomTextFieldSmall";
import { IoMdAdd } from "react-icons/io";
import MainContainer from "../../components/Containers/MainContainer";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import { REACT_QUERY_CLIENTS } from "../../constants/reactQuery.constants";
import { RiDeleteBin6Line } from "react-icons/ri";
import TableLoader from "../../components/Loaders/TableLoader";
import axios from "axios";
import { postAxios } from "../../Services/axios-services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  new?: boolean;
  data?: any;
  loading?: boolean;
  total?: number;
  filters: {
    status: null | string;
    user_type_id: null | string;
    user_type_parent_id: null | string;
    search_text: string;
    cat_id: any[];
    rate: string;
  };
  setFilters: React.Dispatch<React.SetStateAction<any>> | undefined;
  trusted?: 1 | 2;
};

const MainClientsComponent: React.FC<Props> = (props) => {
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleChange = () => {
    setFilterExpanded((prev) => !prev);
  };
  const handleCloseFilters = () => {
    setFilterExpanded(false);
  };

  // const handleSubmitFilters = () => {
  //   props.setFilters && props.setFilters(props.filters);
  // };

  // React Query
  const queryClient = useQueryClient();

  const handleDelete = async (values: any) => {
    try {
      let deleteApiUrl = `user/delete?user_id=`;
      let deleteIds = "";
      values.checked.map((value: any, index: number) => {
        if (index === 0) {
          deleteIds = `${value}`;
        } else {
          deleteIds = `${deleteIds},${value}`;
        }
      });
      deleteApiUrl = deleteApiUrl + deleteIds;

      setDeleting(true);

      await postAxios(deleteApiUrl).then((data) => {
        if (data.data.status !== 1) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
          queryClient.invalidateQueries({ queryKey: [REACT_QUERY_CLIENTS] });

          // dispatch(mainSliceActions.toggleRefreshApi());
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      setDeleting(false);
      setDeleteMultiple(false);
      setDeleteAction(null);
    }
  };

  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);
  const handleCloseMessage = () => setOpenMessage(false);
  const handleOpenMessage = () => setOpenMessage(true);
  const handleSendMessage = async (values: any, setSubmitting: any, resetForm: any) => {
    const formData = new FormData();

    const ids = values.checked.join(",");

    formData.append("user_id", ids);
    formData.append("msg", values?.message || "");
    setSubmitting(true);

    try {
      const data = await axios.post("user/send_msg", formData);
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success(data.data.message);
        resetForm();
        handleCloseMessage();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          checked: [],
        }}
        onSubmit={(values, actions) => {
          if (!openMessage) {
            setDeleteAction(() => () => handleDelete(values));
            setDeleteMultiple(true);
          } else {
            handleSendMessage(values, actions.setSubmitting, actions.resetForm);
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <MainContainer>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={0.25}>
                  <StyledTitle variant="h6">
                    {props.new ? "عملاء جدد" : "كل العملاء"} {props.total && `(${props.total})`}
                  </StyledTitle>
                  {props.data?.data?.pagination?.total > 0 && (
                    <Typography variant="h6" color="primary.main">
                      {"(" + props.data?.data?.pagination?.total + ")"}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <AuctionMessagesModal
                    open={openMessage}
                    handleClose={handleCloseMessage}
                    handleOpen={handleOpenMessage}
                    loading={isSubmitting}
                  />
                  <OutlinedButton
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant="outlined"
                    endIcon={<RiDeleteBin6Line />}
                    disabled={values.checked.length === 0}
                  >
                    حذف عميل
                  </OutlinedButton>
                  <OutlinedButton
                    endIcon={<IoMdAdd />}
                    onClick={() => {
                      navigate("/clients/add");
                    }}
                  >
                    إضافة عميل
                  </OutlinedButton>

                  <Button
                    sx={{
                      color: "#F7F7FC",
                      borderRadius: "8px",
                      width: "190px",
                      height: "50px",
                      fontSize: "1.125rem",
                      fontWeight: "800",
                    }}
                    onClick={handleChange}
                    variant="contained"
                    color="primary"
                    endIcon={filterExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                  >
                    تصفية العملاء
                  </Button>
                </Stack>
              </Stack>

              <CustomTextFieldSmall setFilters={props.setFilters} filters={props.filters} />

              {props.loading ? (
                <TableLoader />
              ) : (
                props.data && (
                  <>
                    <ClientsTable tableType={2} values={values} rows={props.data} />
                  </>
                )
              )}
            </MainContainer>
            <ClientsFilterDrawer
              open={filterExpanded}
              handleClose={handleCloseFilters}
              filters={props.filters}
              setFilters={props.setFilters}
              new={props.new}
              trusted={props.trusted}
            />
          </Form>
        )}
      </Formik>
      <ConfirmActionDialog
        title={"هل أنت متأكد من حذف العملاء؟"}
        action={deleteAction}
        open={deleteMultiple}
        handleClose={() => {
          setDeleteMultiple(false);
        }}
        loading={deleting}
      />
    </>
  );
};
export default React.memo(MainClientsComponent);

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "700",
  fontSize: "1.375rem",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "8px",
    left: "0",
    width: "90px",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
  },
}));
