import MainContainer from "../../components/Containers/MainContainer";
import { Stack, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import { IoMdAdd } from "react-icons/io";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import TableLoader from "../../components/Loaders/TableLoader";
import { useGetPartners } from "../../hooks/partners/useGetPartners.hook";
import { PartnersTable } from "../../components/Tables/PartnersTable";
import { useState } from "react";
import { AddPartnerModal } from "../../components/Modals/AddPartnerModal";
import { useAddPartner } from "../../hooks/partners/useAddPartner.hook";
import { useQueryClient } from "@tanstack/react-query";

interface PartnersProps {}

export const Partners: React.FC<PartnersProps> = () => {
  const { data, error, isLoading } = useGetPartners();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutate: submitPartnerImage } = useAddPartner(() => {
    queryClient.invalidateQueries({ queryKey: ["partners"] });
    setAddModalOpen(false);
  });
  return (
    <Stack>
      <Header title="الشركاء" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            صور الشركاء
          </Typography>
          <Stack direction="row" spacing={2}>
            <OutlinedButton
              onClick={() => {
                setAddModalOpen(true);
              }}
              endIcon={<IoMdAdd />}
              sx={{
                width: "200px",
              }}
            >
              اضافه شريك
            </OutlinedButton>
          </Stack>
        </Stack>
        {isLoading ? (
          <TableLoader />
        ) : error ? (
          <></>
        ) : (
          data?.length > 0 && <PartnersTable data={data} />
        )}
        {data?.data?.pagination?.total_pages > 1 && (
          <CustomPagination totalPages={data.data.pagination.total_pages} />
        )}
      </MainContainer>
      <AddPartnerModal
        handleClose={() => setAddModalOpen(false)}
        handleConfirm={(value: File) => {
          submitPartnerImage({ logo: value });
        }}
        isLoading={false}
        open={addModalOpen}
        title="إضافة"
      />
    </Stack>
  );
};
