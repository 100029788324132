import { ProductForm } from "../../features";
import useAddProducts from "../../hooks/useAddProcuts";
import { useParams } from "react-router-dom";

type Props = {};

const AddProductRoute = (props: Props) => {
  const { type } = useParams();
  const { initialValues, handleSubmit } = useAddProducts({ type: Number(type) });

  return <ProductForm type={Number(type)} initialValues={initialValues} handleSubmit={handleSubmit} />;
};

export default AddProductRoute;
