import styled from "@emotion/styled";
import { Avatar, Stack, Typography } from "@mui/material";
import clientImage from "../../assets/images/client.png";
import { useNavigate } from "react-router-dom";

type Props = {
  id: number;
  image?: string;
  name?: string;
  phone?: string;
  userId?: number;
  address?: string;
  seller?: any;
};
const StyledTitle = styled("h4")({
  fontSize: "1rem",
  fontWeight: "300",
});

const BuyerInfo = ({
  id,
  name,
  phone,
  image,
  userId,
  address,
  seller,
}: Props) => {
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      <Stack
        spacing={10}
        direction="row"
        sx={{
          marginTop: "1.5rem",
        }}
      >
        <Stack spacing={1}>
          <StyledTitle>{"اسم المشتري"}</StyledTitle>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Avatar
              alt={name}
              src={image}
              sx={{ width: "45px", height: "45px" }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                color: id ? "primary.main" : "rgb(69 86 213 / 87%)",
                cursor: id ? "pointer" : "unset",
                textDecoration: id ? "underline" : "unset",
              }}
              onClick={() => {
                navigate(`/clients/all/${id}`);
              }}
            >
              {name}{" "}
            </Typography>
          </Stack>
        </Stack>
        <Stack justifyContent="space-between">
          <StyledTitle>رقم الهاتف</StyledTitle>

          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
              color: "primary.main",
            }}
          >
            {phone}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <StyledTitle>العنوان</StyledTitle>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
              color: "primary.main",
            }}
          >
            {address}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <StyledTitle>{"اسم البائع"}</StyledTitle>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Avatar
              alt={seller?.name}
              src={seller?.image}
              sx={{ width: "45px", height: "45px" }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                color: id ? "primary.main" : "rgb(69 86 213 / 87%)",
                cursor: id ? "pointer" : "unset",
                textDecoration: id ? "underline" : "unset",
              }}
              onClick={() => {
                navigate(`/clients/all/${seller?.id}`);
              }}
            >
              {seller?.name}
            </Typography>
          </Stack>
        </Stack>
        <Stack justifyContent="space-between">
          <StyledTitle>رقم الهاتف</StyledTitle>

          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
              color: "primary.main",
            }}
          >
            {seller?.phone}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BuyerInfo;
