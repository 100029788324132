import { Button, Collapse, Stack, Typography, styled } from "@mui/material";
import Header from "../../components/Header/Header";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useState } from "react";
import DatePicker from "../../components/DatePicker/DatePicker";
import OrdersTable from "../../components/Tables/OrdersTable";
import StatisticsTable from "../../components/Tables/StatisticsTable";

type Props = {};

const ProductStatistics = (props: Props) => {
  const StyledContainer = styled("div")({
    display: "flex",
    gap: "2.5rem",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 1px 10px rgba(52, 180, 74, 0.25)",
    padding: "2rem 1.5rem 1.5rem 1.5rem",
  });

  return (
    <>
      <Header title="إحصائيات بيع المنتج" />
      <StyledContainer>
        <Typography
          variant="h6"
          sx={{
            color: "primary.main",
            fontWeight: "700",
            fontSize: "1.375rem",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-8px",
              left: "0",
              width: "90px",
              height: "2px",
              backgroundColor: "primary.main",
            },
          }}
        >
          المخزون المباع
        </Typography>
        <StatisticsTable />
      </StyledContainer>
    </>
  );
};

export default ProductStatistics;
