import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "300px",
};
interface MapsProps {
  setFieldValue?: any;
}

function GoogleMaps({ setFieldValue }: MapsProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });
  const [center, setCenter] = React.useState({
    lat: 30.033333,
    lng: 31.233334,
  });
  const [selectedLocation, setSelectedLocation] = React.useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const handleMapClick = (event: any) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setFieldValue("lat", event.latLng.lat());
    setFieldValue("lng", event.latLng.lng());
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={5}
      onClick={handleMapClick}
    >
      <Marker
        position={selectedLocation || center}
        animation={window.google.maps.Animation.DROP}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMaps);
