import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import MainChip from "../ClientStatusChip/MainChip";
import ConfirmActionDialog from "../ConfirmActionDialog/ConfirmActionDialog";
import StyledClientName from "../StyledComponents/StyledClientName";
import { useNavigate } from "react-router-dom";
import AuctionsDetailsModal from "../Modals/AuctionsDetailsModal";
import { AiFillEye } from "react-icons/ai";

//styled components for the table

const StyledTableHeader = styled("div")({
  minWidth: "990px",

  display: "grid",
  gridTemplateColumns: ".75fr repeat(7, 1fr) .5fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  //   padding: "1.125rem 0",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: ".75fr repeat(7, 1fr) .5fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  minWidth: "990px",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});
const StyledPrice = styled(Typography)({
  fontWeight: "800",
  fontSize: "1.5rem",
  textAlign: "center",
  color: "#34B44A",
  "& span": {
    fontSize: "13px",
    color: "black",
    fontWeight: "400",
  },
});

//  row component
interface CustomTableRowProps {
  handleOpenDetails: () => void;
  setId: React.Dispatch<React.SetStateAction<number | null>>;
  quantity: string;
  total_price: string;
  created_at: string;
  id: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAction: React.Dispatch<React.SetStateAction<any>>;
  buyer: {
    name: string;
    image: string;
    id: number;
  };
  seller: {
    name: string;
    image: string;
    id: number;
  };
  product: {
    name: string;
    id: number;
    type: number;
    unit: {
      name_ar: string;
    };
  };
  status: {
    id: number;
    class: string;
    name_ar: string;
  };
  statusOptions: {
    id: number;
    name: string;
    class?: string;
  }[];
}

const CustomTableRow = ({
  quantity,
  total_price,
  created_at,
  id,
  setOpen,
  setLoading,
  setAction,
  buyer,
  seller,
  product,
  status,
  statusOptions,
  setId,
  handleOpenDetails,
}: CustomTableRowProps) => {
  const navigate = useNavigate();
  return (
    <StyledTableRow
      sx={{
        backgroundColor: id % 2 === 0 ? "#FDFFFC" : "#F8F8F8",
      }}
    >
      <StyledMainCell>{product?.id}</StyledMainCell>
      <Tooltip title={product?.name} placement="top" arrow>
        <StyledMainCell
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (product.type === 1) {
              navigate(`/rawakd/all/${product.id}`);
            } else {
              navigate(`/khorda/all/${product.id}`);
            }
          }}
        >
          {product?.name?.length > 20 ? product?.name?.slice(0, 10) + "..." : product?.name || "---"}
        </StyledMainCell>
      </Tooltip>
      <StyledClientName name={buyer?.name || "---"} image={buyer?.image || ""} id={buyer?.id || 0} />
      <StyledClientName name={seller?.name || "---"} image={seller?.image || ""} id={seller?.id || 0} />
      <StyledPrice>
        {Number(total_price).toLocaleString()} <span>جنيها</span>
      </StyledPrice>
      <StyledMainCell>
        {Number(quantity).toLocaleString()} {product?.unit?.name_ar}
      </StyledMainCell>
      <StyledMainCell>{created_at}</StyledMainCell>
      <MainChip
        modelName="auction"
        itemId={String(id)}
        statusApiUrl={`auction/change_progress_status`}
        status={{
          id: status.id,
          class: status.class,
          name: status?.name_ar || "---",
        }}
        statusOptions={statusOptions}
      />
      <IconButton
        onClick={() => {
          setId(product.id);
          handleOpenDetails();
        }}
        size="small"
        aria-label="open-details-Modal"
      >
        <AiFillEye />
      </IconButton>
    </StyledTableRow>
  );
};

// main component

interface auctionsProps {
  rows: {
    id: number;
    status: {
      id: number;
      class: string;
      name_ar: string;
    };
    buyer: {
      name: string;
      image: string;
      id: number;
    };
    seller: {
      name: string;
      image: string;
      id: number;
    };
    total_price: string;
    quantity: string;
    created_at: string;
    product: {
      name: string;
      id: number;
      type: number;
      unit: {
        name_ar: string;
      };
    };
  }[];
  statusOptions: {
    id: number;
    name: string;
    class?: string;
  }[];
}

export default function AcceptedAuctionsTable({ rows, statusOptions }: auctionsProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<any>(null);
  const handleClose = () => {
    setOpen(false);
  };
  const [openDetails, setOpenDetails] = useState(false);
  const [id, setId] = useState<number | null>(null);

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };
  const handleOpenDetails = () => setOpenDetails(true);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflowX: "auto",
      }}
    >
      <StyledTableHeader>
        <h6>كود المنتج</h6>
        <h6>اسم المنتج</h6>
        <h6>اسم المشتري</h6>
        <h6>اسم البائع</h6>
        <h6>سعر المزايده</h6>
        <h6>الكمية</h6>
        <h6>التاريخ</h6>
        <h6>الحاله</h6>
      </StyledTableHeader>
      <Stack spacing={1}>
        {rows.length === 0 && (
          <Typography
            sx={{
              color: "#34B44A",
              fontWeight: "700",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            لا يوجد مزادات لعرضها
          </Typography>
        )}
        {rows.map((row, index) => {
          return (
            <CustomTableRow
              setId={setId}
              key={row.id}
              {...row}
              id={row.id}
              setOpen={setOpen}
              setLoading={setLoading}
              setAction={setAction}
              statusOptions={statusOptions}
              handleOpenDetails={handleOpenDetails}
            />
          );
        })}
      </Stack>
      <ConfirmActionDialog
        loading={loading}
        action={action}
        open={open}
        handleClose={handleClose}
        title="هل انت متاكد من قبول هذا العرض ؟"
      />
      <AuctionsDetailsModal id={id} open={openDetails} handleClose={handleCloseDetails} />
    </Box>
  );
}
