import { Field, FieldProps } from "formik";

import { TextField } from "@mui/material";

type Props = {
  label: string;
  helperText?: string;
  required?: boolean;
  multiLine?: number;
  name?: string;
  type?: string;
  step?: number;
  min?: number;
  disabled?: boolean;
};

const CustomTextField = ({
  label,
  helperText,
  multiLine,
  name,
  type,
  step,
  min,
  disabled,
}: Props) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <TextField
            disabled={disabled}
            id={name}
            {...field}
            color="secondary"
            type={type}
            // //if type is number then min step is 1 and display number in this format 1
            // value={
            //   type === "number" ? Number(field.value) || "" : field.value || ""
            // }
            inputProps={
              type === "number"
                ? {
                    min: min ? min : 1,
                    step: step ? step : 1,
                  }
                : {}
            }
            autoComplete="off"
            // required={required}
            label={meta.touched && meta.error ? meta.error : label}
            error={meta.touched && !!meta.error}
            fullWidth
            minRows={multiLine}
            multiline={typeof multiLine === "number" ? true : false}
            helperText={helperText}
            sx={{
              backgroundColor: "#fff",
              ".MuiFormLabel-asterisk": {
                color: "red",
              },
              ".MuiOutlinedInput-root": {
                borderRadius: "8px",
                "&:hover": {
                  "&.Mui-focused fieldset": {
                    borderColor:
                      meta.touched && meta.error ? "error.main" : "#34B44A",
                  },
                  fieldset: {
                    borderColor:
                      meta.touched && meta.error ? "error.main" : "#34B44A",
                  },
                },
                "&.Mui-focused fieldset": {
                  borderColor:
                    meta.touched && meta.error ? "error.main" : "#34B44A",
                  borderWidth: "1px",
                },
                fieldset: {
                  borderWidth: "1px",
                  borderColor:
                    meta.touched && meta.error ? "error.main" : "#EAEAEA",
                },
              },
              ".MuiInputLabel-root": {
                zIndex: 0,

                "&.Mui-focused": {
                  color: meta.touched && meta.error ? "error.main" : "#000",
                  fontWeight: "700",
                  fontSize: "1rem",
                },
              },
              ".MuiFormHelperText-root": {
                position: "absolute",
                top: " -12px",
                left: "85px",
                padding: "0 5px",
                color: "#a0a0a0",
                fontSize: "1rem",
                width: "fit-content",
                background: "white",
                margin: "0 0 0 0",
              },
            }}
          />
        );
      }}
    </Field>
  );
};

export default CustomTextField;
