import { Stack, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import MainContainer from "../../components/Containers/MainContainer";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import { IoMdAdd } from "react-icons/io";
import TableLoader from "../../components/Loaders/TableLoader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { useGetWhyMarkoon } from "../../hooks/whyMarkoon/useGetWhyMarkoon.hook";
import { useState } from "react";
import { WhyMarkoonTable } from "../../components/Tables/whyMarkoonTable";
import { WhyMarkoonModal } from "../../components/Modals/WhyMarkoonModal";
import { useCreateWhyItem } from "../../hooks/whyMarkoon/useCreateWhyItem.hook";

interface WhyMarkoonProps {}

export const WhyMarkoon: React.FC<WhyMarkoonProps> = () => {
  const { data, error, isLoading } = useGetWhyMarkoon();
  const [modalOpen, setModalOpen] = useState(false);
  const { mutate: createWhyItemHandler, isPending: createLoading } =
    useCreateWhyItem(() => setModalOpen(false));
  return (
    <Stack>
      <Header title="لماذا مركون" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            لماذا مركون
          </Typography>
          <Stack direction="row" spacing={2}>
            <OutlinedButton
              onClick={() => {
                setModalOpen(true);
              }}
              endIcon={<IoMdAdd />}
              sx={{
                width: "200px",
              }}
            >
              اضافة عنصر
            </OutlinedButton>
          </Stack>
        </Stack>
        {isLoading ? (
          <TableLoader />
        ) : error ? (
          <></>
        ) : (
          data?.data?.data?.length > 0 && (
            <WhyMarkoonTable data={data?.data?.data} />
          )
        )}
        {data?.data?.pagination?.total_pages > 1 && (
          <CustomPagination totalPages={data.data.pagination.total_pages} />
        )}
        <WhyMarkoonModal
          handleClose={() => {
            setModalOpen(false);
          }}
          handleConfirm={createWhyItemHandler}
          isLoading={createLoading}
          open={modalOpen}
          title="عنصر جديد"
          item={null}
          width="80%"
        />
      </MainContainer>
    </Stack>
  );
};
