import { ProductStatusForm } from "./productStatusForm";
import { useCreateStatus } from "../hooks/use-create-status";

export const CreateProductStatus = () => {
  const initialValues = {
    name_ar: "",
    name_en: "",
    show_status: "1",
    bid: "0",
    show_name: "0",
    show_price: "0",
    show_countdown: "0",
    show_price_list: "0",
    show_price_for_seller: "0",
    send_message: "0",
    class: "",
    color: "",
    button_ar: "",
    button_en: "",
  };
  const { mutate, isPending } = useCreateStatus();
  return <ProductStatusForm initialValues={initialValues} loading={isPending} handleSubmit={mutate} />;
};
