import { Stack, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import MainContainer from "../../components/Containers/MainContainer";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import { IoMdAdd } from "react-icons/io";
import TableLoader from "../../components/Loaders/TableLoader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { useGetBlogs } from "../../hooks/blogs/useGetBlogs.hook";
import { useState } from "react";
import { useCreateBlog } from "../../hooks/blogs/useCreateBlog.hook";
import { BlogsTable } from "../../components/Tables/blogsTable";
import { BlogModal } from "../../components/Modals/BlogModal";

interface BlogsProps {}

export const Blogs: React.FC<BlogsProps> = () => {
  const { data, error, isLoading } = useGetBlogs();

  const [modalOpen, setModalOpen] = useState(false);
  const { mutate: createBlogHandler, isPending: createLoading } = useCreateBlog(() => setModalOpen(false));
  return (
    <Stack>
      <Header title="المقالات" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            المقالات
          </Typography>
          <Stack direction="row" spacing={2}>
            <OutlinedButton
              onClick={() => {
                setModalOpen(true);
              }}
              endIcon={<IoMdAdd />}
              sx={{
                width: "200px",
              }}
            >
              اضافة مقال
            </OutlinedButton>
          </Stack>
        </Stack>
        {isLoading ? (
          <TableLoader />
        ) : error ? (
          <></>
        ) : (
          data?.data?.data?.length > 0 && <BlogsTable data={data?.data?.data} />
        )}
        {data?.data?.pagination?.total_pages > 1 && <CustomPagination totalPages={data.data.pagination.total_pages} />}
        <BlogModal
          handleClose={() => {
            setModalOpen(false);
          }}
          handleConfirm={createBlogHandler}
          isLoading={createLoading}
          open={modalOpen}
          title="مقال جديد"
          blog={null}
          width="80%"
        />
      </MainContainer>
    </Stack>
  );
};
