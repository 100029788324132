import axios from "axios";

export const getPartners = async () => {
  const response = await axios.get(`partner/get`);
  console.log("axios", axios.defaults.baseURL);
  return response?.data?.data?.data;
};

export const addPartner = async (values: { logo: File }) => {
  const formData = new FormData();
  formData.append("logo", values.logo);
  const response = await axios.post(`partner/create`, formData);
  return response?.data;
};
