import { Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";

import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";
import Header from "../../../components/Header/Header";
import MainContainer from "../../../components/Containers/MainContainer";
import { StyledTitle } from "../../styles";
import { useNavigate } from "react-router-dom";

type Props = {
  edit?: boolean;
  initialValues: any;
  loading?: boolean;
  handleSubmit?: any;
};

export const AdminsForm: React.FC<Props> = ({ edit = false, initialValues, handleSubmit, loading }) => {
  const inputs = [
    {
      name: "name",
      label: "الاسم",
      type: "text",
      required: true,
    },
    {
      name: "email",
      label: "البريد الالكتروني",
      type: "text",
      required: true,
    },
    {
      name: "phone",
      label: "رقم الهاتف",
      type: "text",
      required: true,
    },
    {
      name: "password",
      label: "كلمه المرور",
      type: "password",
      required: true,
    },
    {
      name: "status",
      label: "الحاله",
      type: "select",
      options: [
        { value: 1, label: "مفعل" },
        { value: 2, label: "غير مفعل" },
      ],
      required: true,
    },
    {
      divider: true,
    },
    {
      checkAll: true,
    },

    {
      name: "admins_control",
      label: "التحكم بالمستخدمين",
      type: "checkbox",
    },
    {
      name: "website_settings",
      label: "التحكم بالاعدادات و المعلومات الأساسيه",
      type: "checkbox",
    },
    {
      name: "visitors_messages",
      label: "التحكم برسائل الزوار",
      type: "checkbox",
    },
    {
      name: "clients",
      label: "التحكم بالعملاء",
      type: "checkbox",
    },
    {
      name: "products",
      label: "التحكم بالمنتجات",
      type: "checkbox",
    },
    {
      name: "auctions",
      label: "التحكم بالمزادات",
      type: "checkbox",
    },
  ];

  const navigate = useNavigate();

  return (
    <div>
      <Header title={`${edit ? "تعديل" : "اضافه"} مستخدم`} />
      <MainContainer>
        <Stack spacing={2}>
          <StyledTitle
            sx={{
              color: "primary.main",
            }}
          >
            بيانات المستخدم
          </StyledTitle>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            enableReinitialize
          >
            {({ setFieldValue, resetForm, values }) => (
              <Form>
                <Grid container columnSpacing={4} rowSpacing={2}>
                  {inputs.map((input) => {
                    if (input.type === "text")
                      return (
                        <Grid key={input.name} xs={6} item>
                          <CustomTextField name={input.name} label={input.label} required={input.required} />
                        </Grid>
                      );
                    if (input.type === "password") {
                      return (
                        <Grid key={input.name} xs={6} item>
                          <CustomTextField
                            name={input.name}
                            label={input.label}
                            required={input.required}
                            type="password"
                          />
                        </Grid>
                      );
                    }
                    if (input.type === "select") {
                      return (
                        <Grid key={input.name} xs={6} item>
                          <CustomSelect
                            name={input.name}
                            title={input.label}
                            required={input.required}
                            placeholder="اختر الحاله"
                            optionsProp={
                              input.options as {
                                value: number;
                                label: string;
                              }[]
                            }
                            setFieldValue={setFieldValue}
                            values={values}
                            value={values[input.name]}
                          />
                        </Grid>
                      );
                    }
                    if (input.type === "checkbox") {
                      return (
                        <Grid key={input.name} xs={3} item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values[input.name] == "1" ? true : false}
                                onChange={(e) => {
                                  setFieldValue(input.name, e.target.checked ? "1" : "0");
                                }}
                              />
                            }
                            label={input.label}
                          />
                        </Grid>
                      );
                    }
                    if (input.checkAll) {
                      return (
                        <Grid key={input.name} xs={12} item>
                          <Stack width="fit-content" spacing={-0.5}>
                            <FormControlLabel
                              control={
                                <>
                                  <Checkbox
                                    checked={
                                      values.admins_control == "1" &&
                                      values.website_settings == "1" &&
                                      values.visitors_messages == "1" &&
                                      values.clients == "1" &&
                                      values.products == "1" &&
                                      values.auctions == "1"
                                    }
                                    onChange={(e) => {
                                      setFieldValue("admins_control", e.target.checked ? "1" : "0");
                                      setFieldValue("website_settings", e.target.checked ? "1" : "0");
                                      setFieldValue("visitors_messages", e.target.checked ? "1" : "0");
                                      setFieldValue("clients", e.target.checked ? "1" : "0");
                                      setFieldValue("products", e.target.checked ? "1" : "0");
                                      setFieldValue("auctions", e.target.checked ? "1" : "0");
                                    }}
                                  />
                                </>
                              }
                              label="اختر بالكل"
                            />
                            <Divider
                              sx={{
                                color: "primary.main",
                                borderColor: "primary.main",
                                borderWidth: "1.5px",
                                width: "100%",
                              }}
                            />
                          </Stack>
                        </Grid>
                      );
                    }
                    if (input.divider)
                      return (
                        <Grid key={input.name} item xs={12}>
                          <Stack
                            spacing={0.5}
                            sx={{
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Divider
                              sx={{
                                color: "primary.main",
                                borderColor: "primary.main",
                                borderWidth: "1.5px",
                                width: "80%",
                              }}
                            />
                            <Divider
                              sx={{
                                color: "primary.main",
                                borderColor: "primary.main",
                                borderWidth: "1.5px",
                                width: "78%",
                              }}
                            />
                          </Stack>
                        </Grid>
                      );
                    return <></>;
                  })}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: ".5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        mt: 2,
                        width: "150px",
                      }}
                      type="submit"
                      disabled={loading}
                      endIcon={loading && <CircularProgress size={16} />}
                    >
                      {edit ? "تعديل" : "اضافه"}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        mt: 2,
                        width: "150px",
                      }}
                      onClick={() => {
                        navigate("/admins");
                        resetForm();
                      }}
                    >
                      الغاء
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Stack>
      </MainContainer>
    </div>
  );
};
