import { Skeleton, Stack } from "@mui/material";
import React from "react";

type Props = {};

const TableLoader = (props: Props) => {
  return (
    <Stack
      sx={{
        width: "100%",
      }}
      spacing={2}
    >
      <Skeleton
        sx={{
          height: "63px",
          borderRadius: "10px",
          width: "100%",
          bgcolor: "#DEF7E5",
          transform: "scale(1)",
        }}
        animation="pulse"
      />
      <Skeleton
        sx={{
          height: "63px",
          borderRadius: "10px",
          width: "100%",
          bgcolor: "#fbfbfb",
          transform: "scale(1)",
        }}
        animation="pulse"
      />
      <Skeleton
        sx={{
          height: "63px",
          borderRadius: "10px",
          width: "100%",
          transform: "scale(1)",
        }}
        animation="pulse"
      />
      <Skeleton
        sx={{
          height: "63px",
          borderRadius: "10px",
          width: "100%",
          bgcolor: "#fbfbfb",
          transform: "scale(1)",
        }}
        animation="pulse"
      />
      <Skeleton
        sx={{
          height: "63px",
          borderRadius: "10px",
          width: "100%",
          transform: "scale(1)",
        }}
        animation="pulse"
      />
      <Skeleton
        sx={{
          height: "63px",
          borderRadius: "10px",
          width: "100%",
          bgcolor: "#fbfbfb",
          transform: "scale(1)",
        }}
        animation="pulse"
      />
      <Skeleton
        sx={{
          height: "63px",
          borderRadius: "10px",
          width: "100%",
          transform: "scale(1)",
        }}
        animation="pulse"
      />
    </Stack>
  );
};

export default TableLoader;
