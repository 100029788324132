import axios from "axios";

export const postAxios = async (url: any, values?: any) => {
  const formData = new FormData();
  if (values === undefined) {
    return axios.post(`${url}`);
  }
  //append all values to formData
  for (const key in values) {
    // check if values[key] is an array
    // if it is an array of images
    if (key === "pdf") {
      //for string send null and for file send file
      if (typeof values[key] === "string") {
        continue;
      } else {
        formData.append(key, values[key]);
        continue;
      }
    }
    if (key === "logo") {
      //for string send nothing and for file send file
      if (typeof values[key] === "string") {
        // formData.append(key, "");
        continue;
      } else {
        formData.append(key, values[key]);
        continue;
      }
    }
    if (key === "conditions_document") {
      //for string send nothing and for file send file
      if (typeof values[key] === "string") {
        // formData.append(key, "");
        continue;
      } else {
        formData.append(key, values[key]);
        continue;
      }
    }

    if (key === "images") {
      values.images.map((image: any) => {
        if (image.id === undefined) {
          formData.append("images[]", image);
        }
      });
      continue;
    }
    if (key === "adds") {
      formData.append(key, JSON.stringify(values[key]));
      continue;
    }
    if (key === "tags") {
      if (values.tags.length === 0) {
        formData.append("tags[]", "");
      } else {
        values.tags.map((tag: any) => {
          formData.append("tags[]", tag);
        });
      }
      continue;
    }
    if (key === "cats") {
      if (values.cats.length === 0) {
        formData.append("cats[]", "");
      } else {
        values.cats.map((cat: any) => {
          formData.append("cats[]", cat);
        });
      }
      continue;
    }
    formData.append(key, values[key]);
  }

  return await axios.post(`${url}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      // Authorization: `Bearer ${token}`,
    },
  });
};

export const getAxios = async (url: any) => {
  return await axios.get(`${url}`, {
    headers: {
      // "Content-Type": "multipart/form-data",
      // Authorization: `Bearer ${token}`,
    },
  });
};

// delete with id
export const deleteAxiosWithId = (url: any, id: number, variableName: string) => {
  const formData = new FormData();
  formData.append(variableName, id.toString());
  return axios.post(`${url}`, formData);
};

// ${process.env.REACT_APP_API_URL}
// ${process.env.REACT_APP_API_URL}
// ${process.env.REACT_APP_API_URL}
// ${process.env.REACT_APP_API_URL}
