import styled from "@emotion/styled";
import { Avatar, Stack, Typography } from "@mui/material";
import clientImage from "../../assets/images/client.png";
import { useNavigate } from "react-router-dom";

type Props = {
  componentType?: string;
  image?: string;
  name?: string;
  phone?: string;
  email?: string;
  user_type?: any;
  type?: number;
  company_name?: string;
  price_type?: number;
  userId?: number;
  category?: string;
};
const StyledTitle = styled("h4")({
  fontSize: "1rem",
  fontWeight: "300",
});

const SingleClientMainInfo = ({
  type,
  componentType,
  user_type,
  name,
  company_name,
  phone,
  image,
  email,
  price_type,
  userId,
  category,
}: Props) => {
  const navigate = useNavigate();
  return (
    <Stack spacing={3}>
      <Stack
        spacing={componentType ? 10 : 0}
        direction="row"
        justifyContent={componentType ? "unset" : "space-between"}
        sx={{
          marginTop: "1.5rem",
        }}
      >
        <Stack spacing={1}>
          <StyledTitle>
            {componentType ? "اسم البائع" : "اسم العميل"}
          </StyledTitle>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Avatar
              alt={name}
              src={image}
              sx={{ width: "45px", height: "45px" }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                color: !userId ? "primary.main" : "rgb(69 86 213 / 87%)",
                cursor: userId ? "pointer" : "unset",
                textDecoration: userId ? "underline" : "unset",
              }}
              onClick={() => {
                if (componentType === "seller") {
                  navigate(`/clients/all/${userId}`);
                }
              }}
            >
              {name}{" "}
            </Typography>
          </Stack>
        </Stack>
        <Stack justifyContent="space-between">
          <StyledTitle>رقم الهاتف</StyledTitle>

          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
              color: "primary.main",
            }}
          >
            {phone}
          </Typography>
        </Stack>
        {componentType !== "seller" && (
          <Stack justifyContent="space-between">
            <StyledTitle>البريد الإلكتروني</StyledTitle>

            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "400",
                color: "primary.main",
              }}
            >
              {email}{" "}
            </Typography>
          </Stack>
        )}
        {componentType === "seller" && (
          <Stack justifyContent="space-between">
            <StyledTitle>القسم</StyledTitle>

            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "400",
                color: "primary.main",
              }}
            >
              {category}{" "}
            </Typography>
          </Stack>
        )}
        {user_type && (
          <Stack justifyContent="space-between">
            <StyledTitle>نوع العميل</StyledTitle>

            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "400",
                color: "primary.main",
              }}
            >
              {user_type === 1 ? "مقاول" : "تاجر خرده"}
            </Typography>
          </Stack>
        )}
        <Stack justifyContent="space-between">
          <StyledTitle>اسم الشركة</StyledTitle>

          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "400",
              color: "primary.main",
            }}
          >
            {company_name}{" "}
          </Typography>
        </Stack>
        {componentType === "seller" && (
          <Stack justifyContent="space-between">
            <StyledTitle>نوع التسعير</StyledTitle>

            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "400",
                color: "primary.main",
              }}
            >
              {price_type === 1 ? "سعر ثابت" : "مزاد"}{" "}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SingleClientMainInfo;
