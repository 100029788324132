import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  clients: [],
  refresh: false,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    addClients(state, action) {
      state.clients = action.payload;
    },
    toggleRefresh(state) {
      state.refresh = !state.refresh;
    },
  },
});

export const clientsActions = clientsSlice.actions;

export default clientsSlice;
