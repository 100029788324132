import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  styled,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { addressActions } from "../../store/address-slice";
import { mainSliceActions } from "../../store/main-slice";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomTextField from "../CustomTextField/CustomTextField";
import GoogleMaps from "../Map/GoogleMaps";
import * as Yup from "yup";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 2.5rem",
  width: "810px",
  display: "flex",
  flexDirection: "column",
  gap: "3rem",
};
const StyledTitle = styled("h3")({
  fontWeight: 700,
  fontSize: "1.325rem",
  color: "#34B44A",
  position: "relative",
  "&::before": {
    content: "''",
    position: "absolute",
    left: "0",
    bottom: "0px",
    width: "90px",
    height: "2px",
    background: "#34B44A",
  },
});
interface Props {}

const AddressModal = ({}: Props) => {
  const citiesApiUrl = useMemo(() => "city/get", []);
  const { data, loading, error } = useGetSingleApi(citiesApiUrl, []);
  const [selectOptions, setSelectOptions] = useState([]);
  useEffect(() => {
    if (data?.data?.data) {
      let options: any = [];
      data.data.data.map((item: any) => {
        options.push({ value: item.id, label: item.name });
      });
      setSelectOptions(options);
    }
  }, [data]);
  const dispatch = useDispatch();
  const addressState = useSelector((state: any) => state.address);
  const handleClose = () => {
    dispatch(addressActions.closeModal());
  };
  const handleSubmit = async (values: any, actions: any) => {
    try {
      await postAxios(addressState.apiUrl, values).then((res) => {
        console.log("res", res);
        if (res.data.status === 1) {
          toast.success("تمت العمليه بنجاح");
          dispatch(addressActions.closeModal());
          dispatch(mainSliceActions.toggleRefreshApi());
          actions.resetForm();
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (err: any) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    } finally {
      actions.setSubmitting(false);
    }
  };
  const validationSchema = Yup.object().shape({
    address: Yup.string().required("العنوان مطلوب"),
    city_id: Yup.string().required("المدينه مطلوبه"),
  });

  return (
    <>
      <Modal
        open={addressState.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-Address"
        aria-describedby="modal-modal-add-address"
      >
        <Formik
          initialValues={addressState.initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
            <Form>
              <Box sx={style}>
                <StyledTitle>تفاصيل العنوان :</StyledTitle>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "300px",
                      }}
                    >
                      <GoogleMaps setFieldValue={setFieldValue} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField name="address" label="العنوان" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomSelect
                      setFieldValue={setFieldValue}
                      name="city_id"
                      placeholder="اختر المدينة"
                      title="المدينه"
                      optionsProp={selectOptions}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                </Grid>

                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      height: "45px",
                      width: "200px",
                      color: "#FFFFFF",
                      borderRadius: "6px",
                    }}
                    disabled={isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={20} />}
                  >
                    حفظ العنوان
                  </Button>
                  <Button
                    sx={{
                      borderRadius: "6px",
                      border: "2px solid #34B44A",
                      fontSize: "1rem",
                      fontWeight: 700,
                      height: "45px",
                      width: "200px",
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    إلغاء
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddressModal;
