import { useQuery } from "@tanstack/react-query";
import { getClientsTypes } from "../apis";
import { REACT_QUERY_CLIENTS_TYPES } from "../constants/reactQuery.constants";

export const useUserTypes = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [REACT_QUERY_CLIENTS_TYPES],
    queryFn: getClientsTypes,
  });
};
