import { Button } from "@mui/material";
import React, { useState } from "react";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import ClientInterestsModal from "../Modules/Clients/ClientInterestsModal";

type Props = {
  setFilters: React.Dispatch<React.SetStateAction<any>> | undefined;
  filters: {
    status: null | string;
    user_type_id: null | string;
    user_type_parent_id: null | string;
    search_text: string;
    cat_id: any[];
  };
};

const FilterCatsModal = ({ filters, setFilters }: Props) => {
  const [open, setOpen] = useState(false);
  const { data } = useGetSingleApi("categories/get?limit=99999", []);
  const handleOpen = () => {
    setOpen(true);
  };
  const allCats = data?.data?.data;
  const handleClose = () => {
    setOpen(false);
  };
  // const navigate = useNavigate();
  // const location = useLocation();
  // const query = queryString.parse(location.search);
  let selectedCats =
    filters.cat_id
      ?.toString()
      .split(",")
      .map((cat: string) => parseInt(cat)) || [];
  const handleSubmitFilter = (values: number[]) => {
    let cats = "";
    values.map((cat) => {
      if (Number(cat)) {
        cats += cat + ",";
      }
    });
    // navigate(`?cat_id=${cats}`);
    if (filters) {
      setFilters && setFilters({ ...filters, cat_id: cats });
    }
  };
  return (
    <div>
      <Button onClick={handleOpen}>الاهتمامات</Button>
      <ClientInterestsModal
        myCats={selectedCats}
        allCats={allCats}
        open={open}
        handleClose={handleClose}
        filter={true}
        handleSubmitFilter={handleSubmitFilter}
      />
    </div>
  );
};

export default FilterCatsModal;
