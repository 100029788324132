import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  open: boolean;
  initialValues: any;
  type: "edit" | "add";
  apiUrl: string | null;
  title?: string;
}

const initialState: initialStateType = {
  open: false,
  initialValues: {},
  type: "add",
  apiUrl: null,
  title: "categories",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(state, action) {
      state.open = true;
      state.apiUrl = action.payload.apiUrl;
      state.type = action.payload.type;
      state.initialValues = action.payload.initialValues;
      state.title = action?.payload?.title;
    },
    closeModal(state) {
      state.open = false;
      state.apiUrl = null;
      state.type = "add";
      state.initialValues = {};
      state.title = "categories";
    },
  },
});

export const modalActions = modalSlice.actions;
export default modalSlice;
