import { Box, Checkbox, Chip, IconButton, Stack, Typography } from "@mui/material";

import { IPayRequest } from "../../../types";
import MainChip from "../../../components/ClientStatusChip/MainChip";
import { MdPayment } from "react-icons/md";
import { PAY_REQUEST_STATUS } from "../../../constants/payRequests.constants";
import StyledClientName from "../../../components/StyledComponents/StyledClientName";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

//  row component
const statusOptions = [
  {
    id: 3,
    name: "جديد",
    class: "new",
  },
  {
    id: 1,
    name: "مفعل",
    class: "active",
  },
  {
    id: 2,
    name: "غير مفعل",
    class: "danger",
  },
  {
    id: 4,
    name: "لم يتم الرد",
    class: "danger",
  },
];

const CustomTableRow = ({
  name,
  pay_receipt,
  payment_method,
  status,
  id,
  product,
  index,
  handlePay,
  note,
  created_at,
  insurance_status,
  amount,
}: IPayRequest & {
  index: number;
  handlePay?: (id: number, status: string, note: string, payment_method_id: string, insurance_status: string) => void;
}) => {
  const navigate = useNavigate();
  const { setFieldValue, values } = useFormikContext<any>();
  console.log(name);
  return (
    <StyledTableRow
      sx={{
        backgroundColor: values.checked.includes(name?.id)
          ? " rgba(222, 247, 229, 0.35)"
          : index % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: values.checked.includes(name?.id) ? "1px solid #34B44A" : "unset",
      }}
    >
      <Checkbox
        checked={values.checked.includes(name?.id)}
        onChange={() => {
          if (values.checked.includes(name?.id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== name.id)
            );
          } else {
            setFieldValue("checked", [...values.checked, name.id]);
          }
        }}
      />
      <StyledSubCell>{id}</StyledSubCell>

      <StyledClientName name={name?.name} image={name?.image} id={name?.id} />
      <StyledSubCell>{name?.phone}</StyledSubCell>

      <StyledMainCell
        sx={{ cursor: "pointer", fontSize: "1rem" }}
        onClick={() => {
          if (product.type === 1) navigate(`/rawakd/all/${product.id}`);
          else navigate(`/khorda/all/${product.id}`);
        }}
      >
        {product.name}
      </StyledMainCell>
      {payment_method ? (
        <Stack alignItems="center">
          {/* <StyledClientName name={payment_method?.name} image="" /> */}
          <StyledMainCell>{payment_method?.name}</StyledMainCell>
        </Stack>
      ) : (
        <StyledSubCell>-----</StyledSubCell>
      )}

      <StyledMainCell>
        {" "}
        <MainChip
          status={{
            id: status,
            name:
              name?.status === 1
                ? "مفعل"
                : name?.status === 2
                ? "غير مفعل"
                : name?.status === 4
                ? "لم يتم الرد"
                : "جديد",
            class:
              name?.status === 1 ? "active" : name?.status === 2 ? "danger" : name?.status === 4 ? "danger" : "new",
          }}
          statusOptions={statusOptions}
          itemId={String(id)}
          modelName="user"
          statusApiUrl={`user/change_status?user_id=`}
        />
      </StyledMainCell>

      <StyledSubCell>
        <PayRequestChip status={status} />
      </StyledSubCell>
      <StyledSubCell>
        <PayRequestChip status={insurance_status} />
      </StyledSubCell>
      <StyledMainCell>
        {product.has_conditions_document ? Number(amount || "0").toLocaleString() : "مجاني"}
      </StyledMainCell>

      <StyledSubCell
        sx={{
          direction: "rtl",
        }}
      >
        {created_at}
      </StyledSubCell>

      <StyledSubCell
        sx={{
          cursor: "pointer",
          color: "#34B44A",
          fontSize: ".75rem",
          textDecoration: "underline",
        }}
        onClick={() => {
          if (pay_receipt) window.open(pay_receipt, "_blank");
        }}
      >
        {pay_receipt?.slice(pay_receipt?.lastIndexOf("/") + 1) || "-----"}
      </StyledSubCell>

      <Stack direction="row" spacing={1} alignItems="inherit">
        <IconButton
          sx={{
            fontSize: "1.25rem",
          }}
          onClick={() => {
            // id: number, status: string, payment_method_id: string, note: string
            if (handlePay)
              handlePay(id, String(status), payment_method?.id.toString() || "", note, String(insurance_status));
          }}
        >
          <MdPayment />
        </IconButton>
      </Stack>
    </StyledTableRow>
  );
};

// main component

interface PayRequestsTableProps {
  rows: IPayRequest[];
  handlePay?: (id: number, status: string, note: string, payment_method_id: string, insurance_status: string) => void;
}

export const PayRequestsTable = ({ rows, handlePay }: PayRequestsTableProps) => {
  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflowX: "auto",
      }}
    >
      <StyledTableHeader>
        <Checkbox
          disabled={rows.length === 0 || !rows}
          onChange={() => {
            if (values.checked.length > 0) {
              setFieldValue("checked", []);
            } else {
              setFieldValue(
                "checked",
                rows.map((row: any) => row.name.id)
              );
            }
          }}
          checked={values.checked.length === rows.length}
        />
        <h6>#</h6>
        <h6>اسم العميل</h6>
        <h6>رقم العميل</h6>
        <h6>المنتج</h6>
        <h6>وسيلة الدفع</h6>
        <h6>حالة العميل</h6>
        <h6>حالة كراسه الشروط</h6>
        <h6>حالة المبلغ التأميني</h6>
        <h6>المبلغ التأميني</h6>
        <h6>تاريخ الطلب</h6>
        <h6>ايصال الدفع</h6>
      </StyledTableHeader>
      <Stack
        spacing={1}
        sx={{
          minWidth: "1000px",
        }}
      >
        {rows && rows.length > 0 ? (
          rows.map((row: IPayRequest, index: number) => {
            return <CustomTableRow key={row.id} index={index} handlePay={handlePay} {...row} />;
          })
        ) : (
          <Typography
            sx={{
              color: "#34B44A",
              fontWeight: "700",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            لا يوجد طلبات دفع للعرض
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

//styled components for the table

const StyledTableHeader = styled("div")({
  minWidth: "1400px",
  display: "grid",
  gridTemplateColumns: ".25fr .25fr 1.25fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr .25fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  minHeight: "63px",
  padding: ".25rem 0",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  minWidth: "1400px",
  display: "grid",
  gridTemplateColumns: ".25fr .25fr 1.25fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr .25fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "70px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledSubCell = styled(Typography)({
  fontSize: "1.125rem",
  fontWeight: "400",
  color: "#000000",
  textAlign: "center",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

type PayRequestStatus = {
  status: number;
};
const PayRequestChip: React.FC<PayRequestStatus> = ({ status }) => {
  let chipProps: {
    label: string;
    color: "primary" | "success" | "error" | "secondary" | "info" | "warning" | "default";
  };
  status === PAY_REQUEST_STATUS.NEW
    ? (chipProps = {
        label: "جديد",
        color: "primary",
      })
    : status === PAY_REQUEST_STATUS.PAID
    ? (chipProps = {
        label: "مدفوع",
        color: "secondary",
      })
    : status === PAY_REQUEST_STATUS.UNPAID || status === 0
    ? (chipProps = {
        label: "غير مدفوع",
        color: "error",
      })
    : (chipProps = {
        label: "استرجاع",
        color: "warning",
      });
  return (
    <Chip
      label={chipProps.label}
      sx={{
        minWidth: "100px",
        color:
          status === PAY_REQUEST_STATUS.NEW
            ? "#1bbc39"
            : status === PAY_REQUEST_STATUS.PAID
            ? "#9B55F4"
            : status === PAY_REQUEST_STATUS.UNPAID
            ? "rgb(217 0 9 / 99%)"
            : "rgb(223 108 24 / 99%)",

        backgroundColor:
          status === PAY_REQUEST_STATUS.NEW
            ? "#def7e5"
            : status === PAY_REQUEST_STATUS.PAID
            ? "rgb(155 85 244 / 19%)"
            : status === PAY_REQUEST_STATUS.UNPAID
            ? "rgb(217 0 9 / 29%)"
            : "	#efcb8d",
      }}
    />
  );
};
