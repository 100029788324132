import { Stack, Typography } from "@mui/material";
import Header from "../../components/Header/Header";
import MainContainer from "../../components/Containers/MainContainer";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import { IoMdAdd } from "react-icons/io";
import TableLoader from "../../components/Loaders/TableLoader";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import { useGetMarkoonNumbers } from "../../hooks/markoonNumbers/useGetMarkoonNubmers.hook";
import { useCreateMarkoonNumber } from "../../hooks/markoonNumbers/useCreateMarkoonNumber.hook";
import { useState } from "react";
import { MarkoonNumberTable } from "../../components/Tables/markoonNumbersTable";
import { MarkoonNumberModal } from "../../components/Modals/MarkoonNumberModal";

interface NumbersProps {}

export const Numbers: React.FC<NumbersProps> = () => {
  const { data, error, isLoading } = useGetMarkoonNumbers();
  const [modalOpen, setModalOpen] = useState(false);
  const { mutate: createMarkoonNumberHandler, isPending: createLoading } = useCreateMarkoonNumber(() =>
    setModalOpen(false)
  );

  return (
    <Stack>
      <Header title="أداء مركون" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            أداء مركون
          </Typography>
          <Stack direction="row" spacing={2}>
            <OutlinedButton
              onClick={() => {
                setModalOpen(true);
              }}
              endIcon={<IoMdAdd />}
              sx={{
                width: "200px",
              }}
            >
              اضافة رقم جديد
            </OutlinedButton>
          </Stack>
        </Stack>
        {isLoading ? (
          <TableLoader />
        ) : error ? (
          <></>
        ) : (
          data?.data?.data?.length > 0 && <MarkoonNumberTable data={data?.data?.data} />
        )}
        {data?.data?.pagination?.total_pages > 1 && <CustomPagination totalPages={data.data.pagination.total_pages} />}
        <MarkoonNumberModal
          handleClose={() => {
            setModalOpen(false);
          }}
          handleConfirm={createMarkoonNumberHandler}
          isLoading={createLoading}
          open={modalOpen}
          title="عنصر جديد"
          item={null}
          width="80%"
        />
      </MainContainer>
    </Stack>
  );
};
