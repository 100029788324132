import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/material";

import { AiOutlineEye } from "react-icons/ai";
import ContactUsModal from "../Modals/ContactUsModal";
import { styled } from "@mui/material/styles";
import { useState } from "react";

//styled components for the table

const StyledTableHeader = styled("div")({
  minWidth: "990px",

  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr 1.5fr 1fr 1fr .25fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  minWidth: "990px",

  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr 1.5fr 1fr 1fr .25fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "70px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledSubCell = styled(Typography)({
  fontSize: "1.125rem",
  fontWeight: "400",
  color: "#000000",
  textAlign: "center",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

//  row component
interface CustomTableRowProps {
  name: string;
  phone: string;
  email: string;
  type: {
    id: number;
    title: string;
  };
  message: string;
  handleOpenModal: (message: string) => void;
  date: string;
}

const CustomTableRow = ({ name, phone, email, message, type, handleOpenModal, date }: CustomTableRowProps) => {
  //end of deleting city logics
  //   backgroundColor: values.checked.includes(id)
  //       ? " rgba(222, 247, 229, 0.35)"
  //       : index % 2 === 0
  //       ? "#FDFFFC"
  //       :
  // values.checked.includes(id) ? "1px solid #34B44A" :

  return (
    <StyledTableRow
      sx={{
        backgroundColor: "#F8F8F8",
        border: "unset",
      }}
    >
      <Checkbox
        // checked={values.checked.includes(id)}
        onChange={() => {
          //   if (values.checked.includes(id)) {
          //     setFieldValue(
          //       "checked",
          //       values.checked.filter((item: number) => item !== id)
          //     );
          //   } else {
          //     setFieldValue("checked", [...values.checked, id]);
          //   }
        }}
      />

      <StyledSubCell>{name}</StyledSubCell>
      <StyledSubCell>{phone}</StyledSubCell>
      <StyledSubCell>{email}</StyledSubCell>
      <StyledMainCell>{type?.title || "-----"}</StyledMainCell>
      <StyledSubCell>{date}</StyledSubCell>

      <Stack direction="row" spacing={1} alignItems="inherit">
        <IconButton
          onClick={() => {
            handleOpenModal(message);
          }}
          sx={{
            padding: "4px",
            width: "24px",
            height: "24px",
            "& a": {
              color: "#34B44A",
            },
          }}
        >
          <AiOutlineEye />
        </IconButton>

        {/* <DeleteIcon
          id={Number(id)}
          apiUrl="user/delete"
          payload={{
            user_id: id,
          }}
        /> */}
      </Stack>
    </StyledTableRow>
  );
};

// main component

interface ContactUsTableProps {
  rows: any;
}

export default function ContactUsTable({ rows }: ContactUsTableProps) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
    setMessage("");
  };

  const handleOpen = (message: string) => {
    setMessage(message);
    setOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflowX: "auto",
      }}
    >
      <StyledTableHeader
        sx={{
          minWidth: "1000px",
        }}
      >
        {/* <Checkbox
          disabled={rows.length === 0 || !rows}
          onChange={() => {
            if (values.checked.length > 0) {
              setFieldValue("checked", []);
            } else {
              setFieldValue(
                "checked",
                rows.map((row: any) => row.id)
              );
            }
          }}
          checked={values.checked.length === rows.length}
        /> */}
        <h6>#</h6>
        <h6>اسم العميل</h6>
        <h6>رقم الهاتف</h6>
        <h6>البريد الالكتروني</h6>
        <h6>نوع العميل</h6>
        <h6>تاريخ الارسال</h6>
      </StyledTableHeader>
      <Stack
        spacing={1}
        sx={{
          minWidth: "1000px",
        }}
      >
        {rows && rows.length > 0 ? (
          rows.map((row: any, index: number) => {
            return <CustomTableRow {...row} index={index} key={row.id} handleOpenModal={handleOpen} />;
          })
        ) : (
          <Typography
            sx={{
              color: "#34B44A",
              fontWeight: "700",
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            لا يوجد رسائل للعرض
          </Typography>
        )}
      </Stack>
      <ContactUsModal open={open} message={message} handleClose={handleClose} />
    </Box>
  );
}
