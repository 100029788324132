import { Box, CircularProgress, Divider, Stack, Typography, styled } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import StyledClientName from "../StyledComponents/StyledClientName";
import MainChip from "../ClientStatusChip/MainChip";

type Props = {};

const StyledContainer = styled("div")({
  backgroundColor: "#fff",
  padding: "1.25rem 1.5rem",
  boxShadow: " 0px 4px 15px rgba(156, 156, 156, 0.25)",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  flex: "1",
});
const StyledStack = styled(Stack)(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 15px rgba(188, 188, 188, 0.25)",
  borderRadius: "8px",
  padding: ".8rem .5rem",
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1.75fr 1fr 1fr .75fr",
  alignItems: "center",
}));

const NewProductsCard = (props: Props) => {
  const ordersApiUrl = useMemo(() => "products/get?status_id=1&limit=5", []);
  const { data, loading, error } = useGetSingleApi(ordersApiUrl, []);
  const filterStatusApi = useMemo(() => `helper/get_status?model_name=product`, []);
  const [statusOptions, setStatusOptions] = useState(null);
  const { data: filterData } = useGetSingleApi(filterStatusApi, []);
  useEffect(() => {
    setStatusOptions(
      filterData?.data?.map((item: any) => {
        return {
          name: item.name_ar,
          id: item.id,
          class: item.class,
        };
      })
    );
  }, [filterData]);
  if (loading)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={55} color={"primary"} />
      </Box>
    );
  if (error) return <></>;
  return (
    <StyledContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          margin: "10px 0 1.5rem 0",
        }}
      >
        <Typography
          sx={{
            color: "primary.main",
            fontWeight: "700",
            fontSize: "1.25rem",
          }}
        >
          المنتجات الجديده
        </Typography>
        {/* <Link to="/clients/new">
          <Typography
            sx={{
              color: "primary.main",
              fontSize: "1rem",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            مشاهدة المزيد
          </Typography>
        </Link> */}
      </Stack>
      <Stack spacing={1.5}>
        {data?.data?.data?.map((order: any) => (
          <SingleOrderMainCard {...order} key={order?.id} statusOptions={statusOptions} />
        ))}
      </Stack>
    </StyledContainer>
  );
};

export default memo(NewProductsCard);

type SingleOrderMainCardProps = {
  name: string;
  type: number;
  id: number;
  images: any;
  status: any;
  statusOptions: any;
  price_type: any;
};

const SingleOrderMainCard = ({
  id,
  name,
  images,
  status,
  statusOptions,
  price_type,
  type,
}: SingleOrderMainCardProps) => {
  return (
    <StyledStack>
      <StyledClientName name={name} id={id} image={images[0]?.image} type={type === 2 ? "khorda" : "rawakd"} />

      <Typography
        sx={{
          fontSize: "1rem",
          color: "primary.main",
          fontWeight: "800",
          "& span": {
            fontSize: ".9rem",
            fontWeight: "400",
            color: "black",
          },
        }}
      >
        {price_type === 1 ? "سعر ثابت" : "مزاد"}
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          color: "primary.main",
          fontWeight: "800",
          "& span": {
            fontSize: ".9rem",
            fontWeight: "400",
            color: "black",
          },
        }}
      >
        {type === 2 ? "خردة" : "رواكد"}
      </Typography>
      <MainChip
        modelName="product"
        status={{
          name: status?.name_ar,
          id: status?.id,
          class: status?.class,
        }}
        statusApiUrl="products/change_status"
        itemId={String(id)}
        statusOptions={statusOptions}
      />
      {/* <Typography
        sx={{
          fontSize: "1.25rem",
          color: "primary.main",
          fontWeight: "800",
          "& span": {
            color: "black",
            fontSize: ".9rem",
            fontWeight: "400",
            margin: "0 5px",
          },
        }}
      >
        {Number(total_price).toLocaleString()}
        <span>جنيها</span>
      </Typography> */}
      {/* <IconButton
        sx={{
          width: "20px",
          height: "20px",
          "& a": {
            color: "#34B44A",
          },
        }}
      >
        <Link
          to={`/orders/${id}`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiOutlineEye />
        </Link>
      </IconButton> */}
    </StyledStack>
  );
};

{
  /* <StyledStack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  divider={
    <Divider
      orientation="vertical"
      sx={{
        borderColor: "rgba(0, 0, 0, 0.08)",
      }}
    />
  }
>
  <Stack direction="row" spacing={3}>
    <Box
      sx={{
        borderRadius: "10px",
        overflow: "hidden",
        width: "57px",
        height: "57px",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      }}
    >
      <img src={realEstate} alt="real-estate" />
    </Box>
    <Stack>
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "700",
        }}
      >
        حديد مسلح
      </Typography>
      <Typography
        sx={{
          fontSize: "1.125rem",
          fontWeight: "300",
        }}
      >
        احمر
      </Typography>
    </Stack>
  </Stack>
  <Typography
    sx={{
      fontSize: "1.25rem",
      color: "primary.main",
      fontWeight: "800",
      "& span": {
        fontSize: ".9rem",
        fontWeight: "400",
        color: "black",
      },
    }}
  >
    5 <span>طن</span>
  </Typography>
  <Typography
    sx={{
      fontSize: "1.25rem",
      color: "primary.main",
      fontWeight: "800",
      "& span": {
        color: "black",
        fontSize: ".9rem",
        fontWeight: "400",
      },
    }}
  >
    3,650 <span>جنيها</span>
  </Typography>
</StyledStack>; */
}
