import "./CustomSelect.scss";

import { Box, Typography } from "@mui/material";
import Select, {
  ControlProps,
  GroupProps,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";

import { Field } from "formik";
import { memo } from "react";

interface OptionType {
  value: string | number;
  label: string;
  sub?: OptionType[];
}

interface Props {
  placeholder: string;
  width?: string;
  title: string;
  required?: boolean;
  optionsProp: OptionType[] | [];
  name: string;
  setFieldValue?: any;
  value?: any;
  errors?: any;
  touched?: any;
  setState?: any;
  values?: any;
  loading?: boolean;
  disabled?: boolean;
  onChange?: any;
}

const CustomSelect = ({
  title,
  placeholder,
  optionsProp,
  width,
  required,
  name,
  setFieldValue,
  value,
  errors,
  touched,
  setState,
  values,
  loading,
  disabled = false,
  onChange,
}: Props) => {
  const Control = ({ children, ...props }: ControlProps<OptionType, false>) => {
    return (
      <components.Control {...props}>
        <Box
          sx={{
            position: "absolute",
            top: "-11px",
            left: "9px",
            background: "white",
            padding: "0 5px",
            "& span": {
              position: "absolute",
              right: "-3px",
              top: "-2px",
              color: "red",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "black",
              fontWeight: "700",
              fontSize: ".8rem",
            }}
          >
            {title}
          </Typography>
          {required && <span>*</span>}
        </Box>

        {children}
      </components.Control>
    );
  };

  const Option = (props: OptionProps<OptionType>) => {
    return <components.Option {...props} />;
  };

  const Group = (props: GroupProps<OptionType>) => {
    return <components.Group {...props} />;
  };

  const customStyles: StylesConfig<OptionType, false> = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      width: width ? width : "auto",
      boxShadow: "none",
      cursor: "pointer",
      borderRadius: "8px",
      // border: name && errors?.name && touched?.name ? "1px solid red" : "1px solid #EAEAEA",
      padding: "9px 14px",
      position: "relative",
      "&:hover": {
        border: "1px solid #34B44A",
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      paddingLeft: "16px",
    }),
    group: (baseStyles, state) => ({
      ...baseStyles,
      paddingBottom: "4px",
      marginBottom: "4px",
    }),
  };

  return (
    <Field name={name}>
      {({ field, meta, form }: any) => {
        return (
          <Select
            className={
              meta?.error && meta?.touched
                ? "react-select-container error"
                : "react-select-container"
            }
            classNamePrefix="react-select"
            isLoading={loading}
            isDisabled={disabled}
            placeholder={
              meta.error && meta.touched
                ? meta.error
                : field.value
                ? optionsProp.find((item) => item.value === field.value)?.label
                : value
                ? optionsProp.find((item) => item.value === value)?.label
                : placeholder
            }
            isOptionSelected={(option) => {
              if (field.value && option.value === field.value) {
                return true;
              } else {
                return false;
              }
            }}
            value={
              optionsProp.find((item) => item.value === value) || undefined
            }
            options={optionsProp}
            components={{ Control, Option, Group }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#DEF7E5",
                primary: "#1BBC39",
              },
            })}
            styles={customStyles}
            name={name}
            onChange={(e) => {
              onChange && onChange(e);
              setFieldValue(name, e?.value);

              if (setState) {
                setState(e?.value);
              }
            }}
          />
        );
      }}
    </Field>
  );
};
export default memo(CustomSelect);
