import { TextField } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

type Props = {
  setFilters: any;
  filters: any;
};

const CustomTextFieldSmall = ({ setFilters, filters }: Props) => {
  const [searchText, setSearchText] = useState("");
  const debouncedValue = useDebounce(searchText, 500);

  useEffect(() => {
    setFilters({
      ...filters,
      search_text: debouncedValue,
    });
  }, [debouncedValue]);
  return (
    <TextField
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      color="secondary"
      autoComplete="off"
      label="بحث"
      fullWidth
      size="small"
      sx={{
        maxWidth: "260px",
        height: "35px",
        minHeight: "unset",
        ".MuiFormLabel-asterisk": {
          color: "red",
        },
        ".MuiOutlinedInput-root": {
          borderRadius: "8px",
          "&:hover": {
            "&.Mui-focused fieldset": {
              borderColor: "#34B44A",
            },
            fieldset: {
              borderColor: "#34B44A",
            },
          },
          "&.Mui-focused fieldset": {
            borderColor: "#34B44A",
            borderWidth: "1px",
          },
          fieldset: {
            borderWidth: "1px",
            borderColor: "#EAEAEA",
          },
        },
        ".MuiInputLabel-root": {
          zIndex: 0,

          "&.Mui-focused": {
            color: "#000",
            fontWeight: "700",
            fontSize: "1rem",
          },
        },
        // ".MuiFormHelperText-root": {
        //   position: "absolute",
        //   top: " -12px",
        //   left: "85px",
        //   padding: "0 5px",
        //   color: "#a0a0a0",
        //   fontSize: "1rem",
        //   width: "fit-content",
        //   background: "white",
        //   margin: "0 0 0 0",
        // },
      }}
    />
  );
};

export default CustomTextFieldSmall;
