import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack } from "@mui/material";
import { FaChartPie, FaCity, FaTags } from "react-icons/fa";
import { MdOutlineExpandMore, MdOutlineQueryStats, MdPayment, MdPerson } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { TbInfoHexagon, TbShoppingCart } from "react-icons/tb";
import { useEffect, useState } from "react";
import { IoChatbox } from "react-icons/io5";

import { ADMINS_ROLES } from "../../constants/admins.constants";
import { BiStore } from "react-icons/bi";
import Box from "@mui/material/Box";
import { BsImages, BsFillPeopleFill, BsQuestionCircleFill } from "react-icons/bs";
import CssBaseline from "@mui/material/CssBaseline";
import { IoSettings } from "react-icons/io5";
import { RiAuctionFill } from "react-icons/ri";
import { RootState } from "../../store";
import logo from "../../assets/images/main-logo.png";
import styles from "./Drawer.module.scss";
import { useSelector } from "react-redux";
import { HiOutlineBookOpen } from "react-icons/hi2";
import { IoStatsChartSharp } from "react-icons/io5";

// import Cookies from "universal-cookie";

interface Props {
  window?: () => Window;
}

export default function DrawerAppBar(props: Props) {
  const [expanded, setExpanded] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const handleExpandAccordion = (panel: string) => {
    if (expanded === panel) return setExpanded("");
    setExpanded(panel);
    navigate(panel);
  };
  useEffect(() => {
    navItems.main.forEach((item) => {
      if (item.subLinks) {
        item.subLinks.forEach((subItem) => {
          if (location.pathname.includes(subItem.link)) {
            setExpanded(item.link);
          }
        });
      }
    });
  }, [location]);

  // const cookies = new Cookies();
  // const adminId = cookies.get("admin-id");

  const state = useSelector((state: RootState) => state.user);

  const navItems = {
    main: [
      {
        name: "احصائيات",
        link: "/",
        icon: <FaChartPie />,
        show: true,
      },
      {
        name: "معلومات أساسية",
        link: "/product-status",
        icon: <RiAuctionFill />,
        show: state?.roles?.includes(String(ADMINS_ROLES.WEBSITE_SETTINGS)) || false,
        subLinks: [
          {
            name: "حالات المنتج",
            link: "/product-status",
            icon: <MdOutlineQueryStats />,
          },
          {
            name: "البراندات",
            link: "/brands",
            icon: <FaTags />,
          },
          {
            name: "المدن",
            link: "/main-info/cities",
            icon: <FaCity />,
          },
          {
            name: "الوحدات",
            link: "/main-info/units",
            icon: <FaCity />,
          },
          {
            name: "الصور",
            link: "/main-info/slider",
            icon: <BsImages />,
          },
          {
            name: "Tags",
            link: "/tags",
            icon: <FaTags />,
          },
          {
            name: "الشركاء",
            link: "/main-info/partners",
            icon: <BsFillPeopleFill />,
          },
          {
            name: "آراء العملاء",
            link: "/main-info/testimonials",
            icon: <IoChatbox />,
          },
          {
            name: "المقالات",
            link: "/main-info/blogs",
            icon: <HiOutlineBookOpen />,
          },
          {
            name: "عن مركون",
            link: "/about",
            icon: <MdOutlineQueryStats />,
          },
          {
            name: "لماذا مركون",
            link: "/main-info/why-markoon",
            icon: <BsQuestionCircleFill />,
          },
          {
            name: "أرقام مركون",
            link: "/main-info/numbers",
            icon: <IoStatsChartSharp />,
          },
          {
            name: "معلومات الموقع",
            link: "/main-info/information",
            icon: <IoStatsChartSharp />,
          },
        ],
      },
      {
        name: "الفئات",
        link: "/categories/rawa.kd-category",
        icon: <TbInfoHexagon />,
        show: true,
        subLinks: [
          {
            name: "رواكد",
            link: "/categories/rawa.kd-category",
            icon: <TbInfoHexagon />,
          },
          {
            name: "خرده",
            link: "/categories/kho.rda-category",
            icon: <TbInfoHexagon />,
          },
          {
            name: "مواد بناء",
            link: "/categories/construction-materials-category",
            icon: <TbInfoHexagon />,
          },
        ],
      },
      {
        name: "طلبات شراء",
        link: "/orders/all",
        show: state?.roles?.includes(String(ADMINS_ROLES.AUCTIONS)) || false,
        icon: <TbShoppingCart />,
        subLinks: [
          {
            name: "طلبات بانتظار التأكيد",
            link: "/orders/new",
            icon: <FaChartPie />,
          },
          {
            name: "كل الطلبات",
            link: "/orders/all",
            icon: <FaChartPie />,
          },
        ],
      },
      {
        name: "المزادات",
        link: "/auctions/new-auctions",
        icon: <RiAuctionFill />,
        show: state?.roles?.includes(String(ADMINS_ROLES.AUCTIONS)) || false,
        subLinks: [
          {
            name: "المزادات",
            link: "/auctions/new-auctions",
            icon: <RiAuctionFill />,
          },
          {
            name: "مزادات مقبولة",
            link: "/auctions/accepted-auctions",
            icon: <RiAuctionFill />,
          },
          {
            name: "مزادات سارية",
            link: "/auctions/ongoing-auctions",
            icon: <RiAuctionFill />,
          },
        ],
      },
      {
        name: "الرواكد",
        link: "/rawakd/all",
        icon: <BiStore />,
        show: state?.roles?.includes(String(ADMINS_ROLES.PRODUCTS)) || false,

        subLinks: [
          {
            name: "رواكد جديده",
            link: "/rawakd/new",
            icon: <RiAuctionFill />,
          },
          {
            name: "كل الرواكد",
            link: "/rawakd/all",
            icon: <RiAuctionFill />,
            main: true,
          },
        ],
      },
      {
        name: "الخرده",
        link: "/khorda/all",
        icon: <BiStore />,
        show: state?.roles?.includes(String(ADMINS_ROLES.PRODUCTS)) || false,
        subLinks: [
          {
            name: "خرده جديده",
            link: "/khorda/new",
            icon: <RiAuctionFill />,
          },
          {
            name: "كل الخرده",
            link: "/khorda/all",
            icon: <RiAuctionFill />,
            main: true,
          },
        ],
      },
      {
        name: "مواد البناء",
        link: "/construction-materials/all",
        icon: <BiStore />,
        show: state?.roles?.includes(String(ADMINS_ROLES.PRODUCTS)) || false,
        subLinks: [
          {
            name: "مواد بناء جديده",
            link: "/construction-materials/new",
            icon: <RiAuctionFill />,
          },
          {
            name: "كل مواد البناء",
            link: "/construction-materials/all",
            icon: <RiAuctionFill />,
            main: true,
          },
        ],
      },
      {
        name: "العملاء",
        link: "/clients/all",
        icon: <RiAuctionFill />,
        show: state?.roles?.includes(String(ADMINS_ROLES.CLIENTS)) || false,

        subLinks: [
          {
            name: "عملاء جدد",
            link: "/clients/new",
            icon: <RiAuctionFill />,
          },
          {
            name: "عملاء موثوقين",
            link: "/clients/trusted-clients",
            icon: <RiAuctionFill />,
          },
          {
            name: "كل العملاء",
            link: "/clients/all",
            icon: <RiAuctionFill />,
          },
          {
            name: "أنواع العملاء",
            link: "/clients-types",
            icon: <RiAuctionFill />,
          },
        ],
      },

      {
        name: "طلبات الدفع",
        link: "/pay-requests",
        icon: <MdPayment />,
        show: true,
      },
      {
        name: "مستخدمي لوحة التحكم",
        link: "/admins",
        icon: <MdPerson />,
        show: state?.roles?.includes(String(ADMINS_ROLES.ADMIN_CONTROL)) || false,
      },
      {
        name: "رسائل الزوار",
        link: "/contact-us",
        icon: <IoSettings />,
        show: state?.roles?.includes(String(ADMINS_ROLES.VISITORS_MESSAGES)) || false,
      },
      {
        name: "اعدادات الموقع",
        link: "/settings",
        icon: <IoSettings />,
        show: state?.roles?.includes(String(ADMINS_ROLES.WEBSITE_SETTINGS)) || false,
      },
    ],
  };

  return (
    <div className={styles.drawerContainer}>
      <Box
        sx={{
          padding: "1.5rem 1rem",
          boxShadow: "0px 1px 10px rgba(52, 180, 74, 0.25)",
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          //change styles of scrollbar
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            width: "2px",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "2px",
            backgroundColor: "rgb(39 143 57 / 77%)",
            borderRadius: "10px",
          },
        }}
      >
        <CssBaseline />
        <Stack
          justifyContent="space-between"
          sx={{
            height: "100%",
          }}
          divider={
            <Divider
              sx={{
                borderWidth: "1px",
                borderColor: "rgba(0, 0, 0, 0.08)",
              }}
            />
          }
        >
          <Stack
            sx={{
              marginBottom: "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                marginBottom: "3rem",
                cursor: "pointer",
                "& img": {
                  width: "200px",
                  height: "auto",
                },
              }}
              onClick={() => navigate("/")}
            >
              <img src={logo} alt="logo" />
              <Divider
                sx={{
                  position: "absolute",
                  bottom: "-1.5rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderWidth: "1px",
                  borderColor: "rgba(0, 0, 0, 0.08)",
                  width: "100%",
                }}
              />
            </Box>
            {navItems.main.map((item, index) =>
              item.show && item.subLinks ? (
                <Accordion
                  expanded={expanded === item.link}
                  key={index}
                  sx={{
                    ".Mui-expanded": {
                      backgroundColor: location.pathname.includes(item.link) ? "#def7e5" : "#FBFBFB",
                      margin: "0 !important",
                      color: location.pathname.includes(item.link) ? "#34b44a" : "#767676",
                      svg: {
                        color: location.pathname.includes(item.link) ? "#34b44a" : "#767676",
                      },
                    },
                    margin: "5px 0",
                    boxShadow: "none",
                    "&:before": {
                      display: "none",
                    },
                  }}
                  onChange={() => handleExpandAccordion(item.link)}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: location.pathname.includes(item.link)
                        ? "#def7e5"
                        : expanded === item.link
                        ? "#FBFBFB"
                        : "white",
                      padding: ".7rem 1.5rem",
                      fontSize: "1.rem",
                      fontWeight: 700,
                      color: "#767676",
                      borderRadius: "10px",
                      transition: "all 0.1s ease-in-out",
                      svg: {
                        fontSize: "1.25rem",
                      },
                      "&:hover": {
                        backgroundColor: "#def7e5",
                        "& .MuiAccordionSummary-content": {
                          backgroundColor: "#def7e5",
                        },
                        color: "#34b44a",
                        svg: {
                          color: "#34b44a",
                          backgroundColor: "#def7e5",
                        },
                      },
                      "& .MuiAccordionSummary-content": {
                        margin: "0",
                      },
                    }}
                    expandIcon={<MdOutlineExpandMore />}
                    aria-controls={`${item.name}${index}`}
                    id={index.toString()}
                  >
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      {item.icon}
                      <span>{item.name}</span>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "1.5rem 2rem",
                      borderRadius: "10px",
                      backgroundColor: "#FBFBFB",
                      marginTop: "6px",
                    }}
                  >
                    <Stack spacing={1.5}>
                      {item.subLinks.map((subLink, index) => {
                        return (
                          <NavLink
                            key={index}
                            to={subLink.link}
                            className={({ isActive }) => {
                              return isActive ? styles.subLinkActive : styles.subLink;
                            }}
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            <Stack direction="row" spacing={1} alignItems="center">
                              {subLink.icon}
                              <span>{subLink.name}</span>
                            </Stack>
                          </NavLink>
                        );
                      })}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ) : (
                item.show && (
                  <NavLink
                    onClick={() => setExpanded("")}
                    key={index}
                    to={item.link}
                    className={({ isActive, isPending }) => {
                      return isActive ? styles.mainLinkActive : styles.mainLink;
                    }}
                    style={{
                      fontSize: "1rem",

                      // index === navItems.main.length - 1 ? "1rem" : "1.25rem",
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      {item.icon}
                      <span>{item.name}</span>
                    </Stack>
                  </NavLink>
                )
              )
            )}
          </Stack>
        </Stack>
      </Box>
    </div>
  );
}
