import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import MainChip from "../ClientStatusChip/MainChip";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { useDispatch } from "react-redux";
import { deliveryActions } from "../../store/delivery-slice";

//styled components for the table

const StyledTableHeader = styled("div")({
  minWidth: "1150px",
  display: "grid",
  gridTemplateColumns: ".5fr repeat(9,1fr)",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  minWidth: "1150px",
  display: "grid",
  gridTemplateColumns: ".5fr repeat(9,1fr)",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

const StyledPrice = styled(Typography)({
  fontWeight: "800",
  fontSize: "1.25rem",
  textAlign: "center",
  color: "#34B44A",
  "& span": {
    fontSize: "13px",
    color: "black",
    fontWeight: "400",
  },
});

//  row component
interface CustomTableRowProps {
  productName: string;
  quantity: string;
  price: string;
  deliveryPrice: string;
  totalPrice: string;
  sellerName: string;
  data: string;
  status: any;
  id: number;
  values: any;
  setFieldValue: any;
  masafa_price: string;
  product_price: string;
  total_price: string;
  seller: any;
  buyer: any;
  created_at: string;
  statusOptions: any;
  delivery_price: string;
}

const CustomTableRow = ({
  masafa_price,
  product_price,
  total_price,
  status,
  id,
  values,
  setFieldValue,
  seller,
  buyer,
  created_at,
  statusOptions,
  delivery_price,
}: CustomTableRowProps) => {
  const navigate = useNavigate();
  const date = new Date(created_at);
  const dispatch = useDispatch();

  const handleOpenDeliveryModal = () => {
    dispatch(
      deliveryActions.openModal({
        order_id: id,
        delivery_price: Number(delivery_price),
      })
    );
  };
  return (
    <StyledTableRow
      sx={{
        backgroundColor: values.checked.includes(id)
          ? " rgba(222, 247, 229, 0.35)"
          : id % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: values.checked.includes(id) ? "1px solid #34B44A" : "unset",
      }}
    >
      <Checkbox
        checked={values.checked.includes(id)}
        onChange={() => {
          if (values.checked.includes(id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== id)
            );
          } else {
            setFieldValue("checked", [...values.checked, id]);
          }
        }}
      />
      <StyledMainCell
        onClick={() => {
          navigate(`/clients/all/${buyer?.id}`);
        }}
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {buyer?.name}
      </StyledMainCell>
      <StyledPrice>
        {product_price} <span>جنيها</span>
      </StyledPrice>
      <StyledPrice>
        {masafa_price} <span>جنيها</span>
      </StyledPrice>
      <StyledPrice
        onClick={handleOpenDeliveryModal}
        sx={{
          cursor: "pointer",
        }}
      >
        {delivery_price} <span>جنيها</span>
      </StyledPrice>
      <StyledPrice>
        {total_price} <span>جنيها</span>
      </StyledPrice>

      <StyledMainCell
        onClick={() => {
          navigate(`/clients/all/${seller?.id}`);
        }}
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {seller?.name}
      </StyledMainCell>
      <StyledMainCell>{format(date, "yy-MM-dd")}</StyledMainCell>
      <MainChip
        modelName="order"
        status={{
          id: status.id,
          class: status.class,
          name: status.name_ar,
        }}
        statusApiUrl={"orders/change_status?order_id="}
        itemId={String(id)}
        statusOptions={statusOptions}
      />
      <Stack direction="row" spacing={1.5}>
        <IconButton
          sx={{
            width: "20px",
            height: "20px",
            "& a": {
              color: "#34B44A",
            },
          }}
        >
          <Link
            to={`/orders/${id}`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineEye />
          </Link>
        </IconButton>

        <DeleteIcon
          id={Number(id)}
          apiUrl="orders/delete"
          payload={{
            order_id: id,
          }}
        />
      </Stack>
    </StyledTableRow>
  );
};

// main component

interface ordersProps {
  products?: any;
  values: any;
  statusOptions?: any;
}

export default function MainOrdersTable({
  products,
  values,
  statusOptions,
}: ordersProps) {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflowX: "auto",
        }}
      >
        <StyledTableHeader>
          <Checkbox
            onChange={() => {
              if (values?.checked?.length > 0) {
                setFieldValue("checked", []);
              } else {
                setFieldValue(
                  "checked",
                  products?.map((row: any, index: number) => row.id)
                );
              }
            }}
            checked={values.checked.length === products?.length}
          />
          <h6>اسم المشتري</h6>
          <h6>سعر المنتج </h6>
          <h6>نسبه مسافه</h6>
          <h6>ٍسعر التوصيل</h6>
          <h6>السعر الكلي</h6>
          <h6>اسم البائع</h6>
          <h6>تاريخ الشراء</h6>
          <h6>حالة الطلب</h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {products?.map((row: any, index: number) => {
            return (
              <CustomTableRow
                key={index}
                {...row}
                id={row.id}
                values={values}
                setFieldValue={setFieldValue}
                statusOptions={statusOptions}
              />
            );
          })}
          {products?.length === 0 && (
            <Typography
              sx={{
                color: "primary.main",
                fontSize: "1.25rem",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              لا يوجد بيانات لعرضها في الجدول
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  );
}
