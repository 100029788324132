import axios from "axios";

export const getProductsAuctions = async (props: {
  pageParam: number | false;
  queryKey: (string | null | undefined | number)[];
}) => {
  const response = await axios.get(
    `auction/get_products?page=${props.pageParam || ""}&search_text=${
      props.queryKey[1] || ""
    }&watchers=${props.queryKey[2] || ""}&status_id=${props.queryKey[3] || ""}`
  );
  return response?.data?.data?.data;
};
