import { CircularProgress } from "@mui/material";
import OutlinedButton from "../Buttons/OutlinedButton";
import { RiLoginBoxFill } from "react-icons/ri";
import { useClientFastLogin } from "../../hooks";

type Props = {
  id: string;
};

const ClientLoginButton = ({ id }: Props) => {
  const { loading, loginHandler } = useClientFastLogin({ id });
  return (
    <OutlinedButton
      sx={{
        height: "40px",
      }}
      endIcon={loading ? <CircularProgress size={20} /> : <RiLoginBoxFill />}
      disabled={loading}
      onClick={loginHandler}
    >
      تسجيل الدخول{" "}
    </OutlinedButton>
  );
};

export default ClientLoginButton;
