import { createTheme, ThemeProvider } from "@mui/material/styles";

export const ltrTheme = createTheme({
  direction: "ltr",
  typography: {
    fontFamily: `"Almarai", sans-serif;`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 800,
    h2: {
      fontSize: "1.5rem",
    },
  },

  palette: {
    primary: {
      light: "#DEF7E5",
      main: "#34B44A",
    },
    secondary: {
      main: "#a1c0c3",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" && {
            color: "#fff",
          }),
        }),
      },
    },
  },
});
export const rtlTheme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: `"Almarai", sans-serif;`,

    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 800,
    h2: {
      fontSize: "1.5rem",
      fontWeight: 700,
    },
  },

  palette: {
    primary: {
      light: "#DEF7E5",
      main: "#1bbc39",
    },
    secondary: {
      main: "#a1c0c3",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" && {
            color: "#fff",
          }),
        }),
      },
    },
  },
});
