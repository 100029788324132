import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import ClientStatusChip from "../ClientStatusChip/ClientStatusChip";
import StyledIconButton from "../Buttons/StyledIconButton";
import { format } from "date-fns";
import MainChip from "../ClientStatusChip/MainChip";

// custom Chip component for the status
interface StyledChipProps {
  status: number;
}

//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(6,1fr)",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(6,1fr)",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});
const StyledSubCell = styled(Typography)({
  color: "#000000",
  textAlign: "center",
});
const StyledMainCell = styled(Typography)({
  fontSize: "1rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

const StyledPrice = styled(Typography)({
  fontWeight: "800",
  fontSize: "1.25rem",
  textAlign: "center",
  color: "#34B44A",
  "& span": {
    fontSize: "13px",
    color: "black",
    fontWeight: "400",
  },
});

//  row component
interface CustomTableRowProps {
  productName: string;
  quantity: string;
  price: string;
  categoryName?: string;
  deliveryPrice: string;
  totalPrice: string;
  sellerName: string;
  status: any;
  id: number;
  secondaryHeaderStyle?: boolean;
  product: any;
  buyer?: any;
  seller?: any;
  real_price?: string;
  total_price?: string;
  masafa_price?: string;
  product_price?: string;
  statusOptions?: any;
}

const CustomTableRow = ({
  secondaryHeaderStyle,
  status,
  id,
  product,
  buyer,
  seller,
  total_price,
  masafa_price,
  product_price,
  real_price,
  statusOptions,
}: CustomTableRowProps) => {
  const navigate = useNavigate();
  return (
    <StyledTableRow
      sx={{
        backgroundColor: secondaryHeaderStyle
          ? "white"
          : id % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: secondaryHeaderStyle
          ? "1px solid rgba(0, 0, 0, 0.08)"
          : "unset",
        boxShadow: secondaryHeaderStyle
          ? "0px 4px 15px 0px rgba(188, 188, 188, 0.25)"
          : "unset",
        height: secondaryHeaderStyle ? "140px" : "auto",
        padding: "0 1rem",
      }}
    >
      <StyledMainCell>{id}</StyledMainCell>
      <StyledPrice>
        {masafa_price} <span>جنيها</span>
      </StyledPrice>
      <StyledPrice>
        {total_price} <span>جنيها</span>
      </StyledPrice>
      <StyledMainCell
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`/clients/all/${seller?.id}`);
        }}
      >
        {seller?.name}
      </StyledMainCell>
      <StyledMainCell>
        {format(new Date(buyer.created_at), "yy-MM-dd")}
      </StyledMainCell>
      <MainChip
        modelName="cart"
        status={{
          name: status.name_ar,
          id: status.id,
          class: status.class,
        }}
        itemId={String(id)}
      />
    </StyledTableRow>
  );
};

// main component

interface orderTableProps {
  items: any;
}

export default function SingleClientOrders({ items }: orderTableProps) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader
          sx={{
            height: "45px",
            "& h6": {
              fontSize: "1.5rem",
              color: "#34B44A",
              fontWeight: "400",
              textAlign: "center",
            },
          }}
        >
          <h6>كود الطلب</h6>
          <h6>نسبه مسافه</h6>
          <h6>السعر الكلي</h6>
          <h6>اسم البائع</h6>
          <h6>تاريخ الشراء</h6>
          <h6>حالة الطلب</h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {items?.map((row: any, index: number) => {
            return (
              <CustomTableRow
                key={index}
                {...row}
                id={row.id}
                product={row.product}

                // status={{
                //   name_ar: row.status.name_ar,
                //   id: row.status.id,
                //   class: row.status.class,
                // }}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
}
