import { ProductForm } from "../../features";
import useAddProducts from "../../hooks/useAddProcuts";

type Props = {};

const AddRawakd = (props: Props) => {
  const { initialValues, handleSubmit } = useAddProducts({ type: 1 });

  return <ProductForm type={1} initialValues={initialValues} handleSubmit={handleSubmit} />;
};

export default AddRawakd;
