import { Avatar, Stack, Tooltip } from "@mui/material";

import StyledMainCell from "./StyledMainCell";
import clientImage from "../../assets/images/client.png";

interface clientNameProps {
  name: string;
  image?: string;
  id?: number;
  type?: "rawakd" | "khorda";
}
const StyledClientName = ({ name, image, id, type }: clientNameProps) => {
  // const navigate = useNavigate();
  let url = "";
  // if (id) {
  //   if (type === "rawakd") {
  //     // window.open(`/rawakd/all/${id}`, "_blank", "noreferrer");
  //     url = `/rawakd/all/${id}`;
  //   } else if (type === "khorda") {
  //     // window.open(`/khorda/all/${id}`, "_blank", "noreferrer");
  //     url = `/khorda/all/${id}`;
  //   } else {
  //     url = `/clients/all/${id}`;
  //     // window.open(`/clients/all/${id}`, "_blank", "noreferrer");
  //   }
  // }
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: "flex",
        gap: ".5rem",
        cursor: "pointer",
        justifyContent: "flex-start",
        alignItems: "center",
        // textAlign: "flex-start",
        width: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (id) {
          if (type === "rawakd") {
            window.open(`/rawakd/all/${id}`, "_blank", "noreferrer");
          } else if (type === "khorda") {
            window.open(`/khorda/all/${id}`, "_blank", "noreferrer");
          } else {
            window.open(`/clients/all/${id}`, "_blank", "noreferrer");
          }
        }
      }}
      // onClick={() => {
      //   console.log("hi");
      //   if (id) {
      //     if (type === "rawakd") {
      //       window.open(`/rawakd/all/${id}`, "_blank", "noreferrer");
      //     } else if (type === "khorda") {
      //       window.open(`/khorda/all/${id}`, "_blank", "noreferrer");
      //     } else {
      //       window.open(`/clients/all/${id}`, "_blank", "noreferrer");
      //     }
      //   }
      // }}
      // href={url}
      // target="_blank"
      // rel="noreferrer"
    >
      <Avatar alt={name} src={image ? image : ""} sx={{ width: "45px", height: "45px" }} />
      <Tooltip title={name} placement="top">
        <StyledMainCell>{name?.length > 15 ? name.slice(0, 15) + "..." : name}</StyledMainCell>
      </Tooltip>
    </Stack>
  );
};

export default StyledClientName;
