import { Box, Checkbox, IconButton, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { BsFillPlayFill } from "react-icons/bs";
//add icon
import { AiOutlineSetting } from "react-icons/ai";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { sliderActions } from "../../store/slider-slice";
import { pageTypes } from "../../constants/pageTypes.constant";
//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 5fr 1fr 1fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 5fr 1fr 1fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

//  row component
interface CustomTableRowProps {
  image: string;
  id: number;
  values?: any;
  setFieldValue?: any;
  index: number;
  type: number;
  title_ar: string;
  title_en: string;
  desc_ar: string;
  desc_en: string;
  button_ar: string;
  button_en: string;
  button_link: string;
  sort: string;
  page: number;
}

const CustomTableRow = ({
  image,
  id,
  values,
  index,
  setFieldValue,
  type,
  title_ar,
  title_en,
  desc_ar,
  desc_en,
  button_ar,
  button_en,
  button_link,
  sort,
  page,
}: CustomTableRowProps) => {
  const dispatch = useDispatch();
  return (
    <StyledTableRow
      sx={{
        backgroundColor: values?.checked?.includes(id)
          ? " rgba(222, 247, 229, 0.35)"
          : index % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: values.checked.includes(id) ? "1px solid #34B44A" : "unset",
      }}
    >
      <Checkbox
        checked={values?.checked?.includes(id)}
        onChange={() => {
          if (values?.checked?.includes(id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== id)
            );
          } else {
            setFieldValue("checked", [...values.checked, id]);
          }
        }}
      />
      <StyledMainCell>
        <Link href={image} target="__blank">
          {type === 1 ? (
            <Box
              component={"img"}
              src={image}
              sx={{
                width: "100px",
                height: "45px",
                borderRadius: "10px",
                objectFit: "contain",
              }}
            ></Box>
          ) : (
            <Box
              component={"div"}
              sx={{
                width: "100px",
                height: "45px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F8F8F8",
              }}
            >
              <BsFillPlayFill />
            </Box>
          )}
        </Link>
      </StyledMainCell>
      <StyledMainCell>{page && page === pageTypes.HOME ? "الرئيسية" : "عن مركون"}</StyledMainCell>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& a": {
              color: "#34B44A",
            },
            "& svg": {
              fill: "gray",
            },
          }}
          onClick={() => {
            dispatch(
              sliderActions.edit({
                slider_id: id,
                image: image,
                type: type,
                title_ar: title_ar ?? "",
                title_en: title_en ?? "",
                desc_ar: desc_ar ?? "",
                desc_en: desc_en ?? "",
                button_ar: button_ar ?? "",
                button_en: button_en ?? "",
                button_link: button_link ?? "",
                sort: sort ?? "",
                page: page ?? 0,
              })
            );
            dispatch(sliderActions.openModal());
          }}
        >
          <AiOutlineSetting />
        </IconButton>

        <DeleteIcon
          id={Number(id)}
          apiUrl="slider/delete"
          payload={{
            slider_id: id,
          }}
        />
      </Stack>
    </StyledTableRow>
  );
};

// main component

interface SliderTableProps {
  data: any;
}

export default function ({ data }: SliderTableProps) {
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <StyledTableHeader>
          <Checkbox
            onChange={() => {
              if (values.checked.length > 0) {
                setFieldValue("checked", []);
              } else {
                setFieldValue(
                  "checked",
                  data.map((row: any, index: number) => row.id)
                );
              }
            }}
            checked={values?.checked?.length === data.length}
          />
          <h6>الصور</h6>
          <h6>الصفحة</h6>
        </StyledTableHeader>
        <Stack spacing={1}>
          {data.map((row: any, index: number) => {
            return (
              <CustomTableRow
                {...row}
                id={row.id}
                index={index}
                key={row.id}
                type={row.type}
                values={values}
                setFieldValue={setFieldValue}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
}
