import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import { useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postAxios } from "../../Services/axios-services";
import OutlinedButton from "../Buttons/OutlinedButton";
import StyledIconButton from "../Buttons/StyledIconButton";
import ConfirmActionDialog from "../ConfirmActionDialog/ConfirmActionDialog";
//add icon
import { IoMdAdd } from "react-icons/io";
import { mainSliceActions } from "../../store/main-slice";
import { modalActions } from "../../store/modal-slice";
//styled components for the table

const StyledTableHeader = styled("div")({
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr  .7fr",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#DEF7E5",
  borderRadius: "10px",
  height: "63px",
  "& h6": {
    fontSize: "1.25rem",
    color: "#34B44A",
    fontWeight: "400",
    textAlign: "center",
  },
});

const StyledTableRow = styled("div")({
  display: "grid",
  gridTemplateColumns: ".25fr 1fr 1fr .7fr",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  height: "63px",
  borderRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
});

const StyledMainCell = styled(Typography)({
  fontSize: "1.25rem",
  color: "#34B44A",
  fontWeight: "700",
  textAlign: "center",
});

//  row component
interface CustomTableRowProps {
  title_ar: string;
  title_en: string;
  id: number;
  values: any;
  setFieldValue: any;
  index: number;
  show_price: string | number;
}

const CustomTableRow = ({ title_ar, title_en, id, index, values, setFieldValue, show_price }: CustomTableRowProps) => {
  const navigate = useNavigate();
  //deleting City logic
  const [deleting, setDeleting] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);
  const handleOpenDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };
  const dispatch = useDispatch();

  //deleting category logic
  const deleteUserType = async (id: number) => {
    try {
      setDeleting(true);
      const data = await postAxios("userTypes/delete", { type_id: id });
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success(data.data.message);
        dispatch(mainSliceActions.toggleRefreshApi());
        handleCloseDialog();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDeleting(false);
    }
  };
  return (
    <StyledTableRow
      sx={{
        backgroundColor: values.checked.includes(id)
          ? " rgba(222, 247, 229, 0.35)"
          : index % 2 === 0
          ? "#FDFFFC"
          : "#F8F8F8",
        border: values.checked.includes(id) ? "1px solid #34B44A" : "unset",

        gridTemplateColumns: ".25fr 1fr 1fr .7fr .7fr",
      }}
    >
      <Checkbox
        checked={values.checked.includes(id)}
        onChange={() => {
          if (values.checked.includes(id)) {
            setFieldValue(
              "checked",
              values.checked.filter((item: number) => item !== id)
            );
          } else {
            setFieldValue("checked", [...values.checked, id]);
          }
        }}
      />
      <StyledMainCell>{title_ar}</StyledMainCell>
      <StyledMainCell>{title_en}</StyledMainCell>

      <OutlinedButton
        endIcon={<IoMdAdd />}
        onClick={() => {
          navigate(`${id}`);
        }}
        sx={{
          "& a": {
            textDecoration: "none",
            color: "#34B44A",
            fontSize: "1rem",
          },
        }}
      >
        اضف المزيد{" "}
      </OutlinedButton>

      <Stack direction="row" spacing={0.5}>
        <StyledIconButton
          disabled={deleting}
          variant="outlined"
          color="error"
          sx={{
            svg: {
              color: "#D90009",
            },
            "&:hover": {
              backgroundColor: "#F8F8F8",
              svg: {
                color: "#D90009",
              },
            },
          }}
          onClick={() => {
            setDeleteAction(() => () => deleteUserType(id));
            handleOpenDialog();
          }}
        >
          <RiDeleteBin6Line />
        </StyledIconButton>
        <StyledIconButton
          variant="outlined"
          onClick={() => {
            dispatch(
              modalActions.openModal({
                type: "edit",
                title: "clients",
                apiUrl: "userTypes/update",
                initialValues: {
                  title_ar: title_ar,
                  title_en: title_en,
                  type_id: id,
                  show_price: String(show_price) || "",
                },
              })
            );
          }}
        >
          <AiOutlineSetting />
        </StyledIconButton>
      </Stack>
      <ConfirmActionDialog
        title={"هل انت متأكد من حذف الفئة ؟"}
        action={deleteAction}
        open={openDeleteDialog}
        handleClose={handleCloseDialog}
        loading={deleting}
      />
    </StyledTableRow>
  );
};

// main component

interface ClientsTypesTableProps {
  data: any;
}

export default function ClientsTypesTable({ data }: ClientsTypesTableProps) {
  if (data.length === 0)
    return (
      <Typography variant="h6" sx={{ color: "#34B44A", fontWeight: "700", textAlign: "center" }}>
        لا يوجد فئات
      </Typography>
    );
  return (
    <>
      <Formik
        initialValues={{ checked: [] }}
        onSubmit={(values) => {
          alert(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <StyledTableHeader
                sx={{
                  gridTemplateColumns: ".25fr 1fr 1fr .7fr .7fr",
                }}
              >
                <Checkbox
                  onChange={() => {
                    if (values.checked.length > 0) {
                      setFieldValue("checked", []);
                    } else {
                      setFieldValue(
                        "checked",
                        data.map((row: any, index: number) => row.id)
                      );
                    }
                  }}
                  checked={values.checked.length === data.length}
                />
                <h6>الاسم باللغه العربيه</h6>
                <h6>الاسم باللغه الانجليزيه</h6>
                <h6>فئات فرعيه</h6>
              </StyledTableHeader>
              <Stack spacing={1}>
                {data.map((row: any, index: number) => {
                  return (
                    <CustomTableRow
                      {...row}
                      id={row.id}
                      index={index}
                      key={row.id}
                      values={values}
                      setFieldValue={setFieldValue}
                      show_price={row?.show_price}
                    />
                  );
                })}
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
