import { Box, CircularProgress } from "@mui/material";

import { IPayRequest } from "../../../types";
import { PayRequestsContent } from "./payRequestsContent";
import { REACT_QUERY_PAYMENT_METHODS } from "../../../constants/reactQuery.constants";
import { getPaymentMethods } from "../../../apis";
import { useGetPaymentRequests } from "../hooks";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const PayRequestsPage = () => {
  const { product_id } = useParams();

  const [filters, setFilters] = useState({
    search_text: "",
    status: [],
    payment_method: [],
    insurance_status: [],
  });
  const { data, isPending, hasNextPage, ref } = useGetPaymentRequests(filters, product_id || "");
  const { data: paymentMethodData } = useQuery({
    queryKey: [REACT_QUERY_PAYMENT_METHODS],
    queryFn: getPaymentMethods,
  });
  return (
    <>
      <PayRequestsContent
        filters={filters}
        setFilters={setFilters}
        data={data?.pages
          .map(
            (page: {
              data: IPayRequest[];
              pagination: {
                total: number;
              };
            }) => page.data
          )
          .flat()}
        loading={isPending}
        paymentMethodFilters={paymentMethodData}
        product_id={product_id}
        total={data?.pages[0]?.pagination.total}
      />
      {hasNextPage && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress size={24} ref={ref} />
        </Box>
      )}
    </>
  );
};
