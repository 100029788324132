export default function getRemainingDays(endDate: string): number {
  if (!endDate) return 0;

  const today = new Date();
  const date = new Date(endDate);
  const remainingTime = date.getTime() - today.getTime();
  const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
  if (remainingDays < 0) return 0;
  return remainingDays;
}
