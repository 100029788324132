import { MenuItem } from "@mui/material";
import { postAxios } from "../../Services/axios-services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../store/user-slice";

type Props = {};

const LogoutMenuItem = (props: Props) => {
  // const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = async () => {
    try {
      await postAxios("auth/logout").then(() => {
        // if (res.status === 200 && res.data.status === 1) {
        dispatch(userActions.logout());
        //remove all cookies

        navigate("/login");
      });
      // .then(() => {
      //   cookies.remove("admin-token", { path: "/" });
      //   cookies.remove("admin-image", { path: "/" });
      //   cookies.remove("admin-email", { path: "/" });
      //   cookies.remove("admin-id", { path: "/" });
      // });
    } catch (error) {}
  };
  return (
    <MenuItem
      onClick={() => {
        logout();
      }}
    >
      تسجيل الخروج
    </MenuItem>
  );
};

export default LogoutMenuItem;
