import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  isOpen: boolean;
  userId: string;
  productId: string;
}

const initialState: ModalState = {
  isOpen: false,
  userId: "",
  productId: "",
};

const genericMessageSlice = createSlice({
  name: "genericMessage",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setProductId: (state, action: PayloadAction<string>) => {
      state.productId = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
  },
});

export const genericMessageModalActions = genericMessageSlice.actions;
export default genericMessageSlice;
