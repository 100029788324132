import { Box, Chip, CircularProgress, Menu, Stack, Tooltip } from "@mui/material";
import {
  REACT_QUERY_CLIENTS,
  REACT_QUERY_PAY_REQUESTS,
  REACT_QUERY_PRODUCTS,
} from "../../constants/reactQuery.constants";
import React, { useState } from "react";

import { MdOutlineExpandMore } from "react-icons/md";
import axios from "axios";
import { mainSliceActions } from "../../store/main-slice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  itemId?: string;
  status: {
    id: number;
    name: string;
    class?: string;
  };
  statusOptions?: {
    id: number;
    name: string;
    class?: string;
  }[];
  modelName: string;
  statusApiUrl?: string;
};

const MainChip = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const mainStyles = {
    fontSize: "1rem",
    fontWeight: "700",
    flexDirection: "row-reverse",
    cursor: props.statusOptions && props.statusOptions.length > 0 ? "pointer" : "default",
    paddingRight: props.statusOptions && props.statusOptions.length > 0 ? "18px" : 0,
    width: "110px",
    height: "35px",
  };
  const dispatch = useDispatch();

  const [loadingNewStatus, setLoadingNewStatus] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleChangeStatus = async (statusId: number) => {
    try {
      setLoadingNewStatus(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${props.statusApiUrl}${props.itemId}&status=${statusId}`
      );
      if (response?.data?.status === 1) {
        toast.success("تم تغيير الحاله بنجاح");
        dispatch(mainSliceActions.toggleRefreshApi());
        setLoadingNewStatus(!loadingNewStatus);
      } else {
        toast.error(response?.data?.message, {
          toastId: "changeStatus",
        });
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    } finally {
      setLoadingNewStatus(false);
      handleClose();
      queryClient.invalidateQueries({ queryKey: [REACT_QUERY_CLIENTS, REACT_QUERY_PAY_REQUESTS] });
    }
  };

  const handleChangeAuctionStatus = async (statusId: number) => {
    try {
      setLoadingNewStatus(true);
      const formData = new FormData();
      formData.append("auction_id", String(props.itemId));
      formData.append("status_id", String(statusId));

      const response = await axios.post(`${process.env.REACT_APP_API_URL}${props.statusApiUrl}`, formData);
      if (response?.data?.status === 1) {
        toast.success("تم تغيير الحاله بنجاح");
        dispatch(mainSliceActions.toggleRefreshApi());
        setLoadingNewStatus(!loadingNewStatus);
      } else {
        toast.error(response?.data?.message, {
          toastId: "changeStatus",
        });
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    } finally {
      setLoadingNewStatus(false);
      handleClose();
    }
  };
  const handleChangeProduct = async (statusId: number) => {
    try {
      setLoadingNewStatus(true);
      const formData = new FormData();
      formData.append("product_id", String(props.itemId));
      formData.append("status_id", String(statusId));

      const response = await axios.post(`${process.env.REACT_APP_API_URL}${props.statusApiUrl}`, formData);
      if (response?.data?.status === 1) {
        toast.success("تم تغيير الحاله بنجاح");
        // dispatch(mainSliceActions.toggleRefreshApi());
        queryClient.invalidateQueries({ queryKey: [REACT_QUERY_PRODUCTS] });
        setLoadingNewStatus(!loadingNewStatus);
      } else {
        toast.error(response?.data?.message, {
          toastId: "changeStatus",
        });
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    } finally {
      setLoadingNewStatus(false);
      handleClose();
    }
  };

  return (
    <>
      <Box
        sx={{
          cursor: props.statusOptions && props.statusOptions.length > 0 ? "pointer" : "default",
          position: "relative",
          "& svg": {
            display: props.statusOptions && props.statusOptions.length > 0 ? "block" : "none",
            position: "absolute",
            right: "19px",
            top: "50%",
            transform: "translateY(-50%)",
            color:
              props.status?.class === "new"
                ? "primary.main"
                : props.status.class === "active"
                ? "#9B55F4"
                : props.status.class === "done"
                ? "#1c61da"
                : "rgb(217 0 9 / 99%)",
          },
        }}
        onClick={(e) => {
          if (!props.statusOptions) {
            return;
          } else {
            handleClick(e);
          }
        }}
      >
        <Tooltip title={props.status.name} placement="top" arrow>
          <Chip
            disabled={loadingNewStatus}
            label={props.status.name}
            sx={{
              backgroundColor:
                props.status?.class === "new"
                  ? "#def7e5"
                  : props.status.class === "active"
                  ? "rgb(155 85 244 / 19%)"
                  : props.status.class === "done"
                  ? "#1c61da45"
                  : "rgb(217 0 9 / 29%)",
              color:
                props.status?.class === "new"
                  ? "primary.main"
                  : props.status.class === "active"
                  ? "#9B55F4"
                  : props.status.class === "done"
                  ? "#1c61da"
                  : "rgb(217 0 9 / 99%)",
              ...mainStyles,
            }}
          />
        </Tooltip>

        {props.modelName !== "show" && <MdOutlineExpandMore />}
      </Box>

      {props.modelName !== "show" && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: "140px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255)",
              padding: "0.5rem 5px",
              borderRadius: "0.5rem",
              border: "1px solid",
              borderColor: "primary.main",
            },
          }}
        >
          {loadingNewStatus ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress size={35} />
            </Box>
          ) : (
            <Stack spacing={2}>
              {props.statusOptions?.map((item: any) => {
                if (item.id == props.status.id) {
                  return null;
                } else {
                  return (
                    <Tooltip title={item.name} placement="left-end" arrow key={item.id}>
                      <Chip
                        label={item.name}
                        sx={{
                          backgroundColor:
                            item?.class === "new"
                              ? "#def7e5"
                              : item.class === "active"
                              ? "rgb(155 85 244 / 19%)"
                              : item.class === "done"
                              ? "#1c61da45"
                              : "rgb(217 0 9 / 29%)",
                          color:
                            item?.class === "new"
                              ? "primary.main"
                              : item.class === "active"
                              ? "#9B55F4"
                              : item.class === "done"
                              ? "#1c61da"
                              : "rgb(217 0 9 / 99%)",
                          width: "100px",
                          fontSize: "1rem",
                          fontWeight: "700",
                          flexDirection: "row-reverse",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (props.modelName === "auction") {
                            handleChangeAuctionStatus(item.id);
                          } else if (props.modelName === "product") {
                            handleChangeProduct(item.id);
                          } else if (props.modelName === "show") {
                            return;
                          } else {
                            handleChangeStatus(item.id);
                          }
                        }}
                      />
                    </Tooltip>
                  );
                }
              })}
            </Stack>
          )}
        </Menu>
      )}
    </>
  );
};

export default React.memo(MainChip);
