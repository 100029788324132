import { Box, Pagination, Typography } from "@mui/material";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  totalPages: number;
};

const CustomPagination = ({ totalPages }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const page = queryString.parse(location.search).page;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "2rem",
        width: "100%",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <Pagination
        //   boundaryCount={5}
        sx={{
          "& .MuiPaginationItem-root": {
            fontSize: "20px",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.04)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
            borderRadius: "3px",
            "&.Mui-selected": {
              backgroundColor: "#34B44A33",
            },
          },
        }}
        count={totalPages}
        page={Number(page) || 1}
        onChange={(e, value) => {
          //check if there are any query params
          console.log("location.search", location);

          if (location.search) {
            if (location.search.includes("page")) {
              //if there is a page query param
              const newLocation = location.search.replace(
                /page=\d+/,
                `page=${value}`
              );
              navigate(`${location.pathname}${newLocation}`);
            } else {
              navigate(`${location.pathname}${location.search}&page=${value}`);
            }
          } else {
            navigate(`${location.pathname}?page=${value}`);
          }
        }}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );
};

export default CustomPagination;
