import { IconButton, Stack, Typography } from "@mui/material";
import { AiOutlineSetting, AiFillDelete } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import StyledIconButton from "../Buttons/StyledIconButton";
import { useDispatch } from "react-redux";
import { addressActions } from "../../store/address-slice";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { postAxios } from "../../Services/axios-services";
import { toast } from "react-toastify";
import { mainSliceActions } from "../../store/main-slice";
import ConfirmActionDialog from "../ConfirmActionDialog/ConfirmActionDialog";

type Props = {
  address: string;
  lat: number;
  lng: number;
  id: number;
  city_id: number;
  address_id: number;
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  setMapAddress: React.Dispatch<React.SetStateAction<string>>;
};

const AddressDetails = (props: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const constructAddress = (lat: number, lang: number) => {
    return `https://maps.google.com/maps?q=${lat},${lang}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
  };
  //delete address logic
  //deleting City logic
  const [deleting, setDeleting] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);
  const handleOpenDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteAddress = async (id: number) => {
    try {
      setDeleting(true);
      const data = await postAxios("address/delete", {
        address_id: id,
      });
      if (data.data.status !== 1) {
        toast.error(data.data.message);
      } else {
        toast.success(data.data.message);
        handleCloseDialog();
        dispatch(mainSliceActions.toggleRefreshApi());
      }
    } catch (error) {
      toast.error("error, while deleting address");
    } finally {
      setDeleting(false);
    }
  };
  //end of deleting city logics
  return (
    <Stack
      onClick={() => {
        props.setSelected(props.id);
        props.setMapAddress(constructAddress(props.lat, props.lng));
      }}
      sx={{
        cursor: "pointer",
        padding: "0px 1.25rem 0 2.5rem",
        border:
          props.selected === props.id
            ? "1.5px solid #34B44A"
            : "1px solid rgba(0, 0, 0, 0.08)",
        borderRadius: "10px !important",
        backgroundColor: props.id % 2 === 0 ? "#F8F8F8" : "#FFFFFF",
        height: "60px",
        h5: {
          fontWeight: props.selected === props.id ? "700" : "300",
          fontSize: "1.125rem",
          color:
            props.selected === props.id ? "#34B44A" : "rgba(0, 0, 0, 0.65)",
        },
        "&:hover": {
          border: "1.5px solid #34B44A",
          h5: {
            color: "#34B44A",
            fontWeight: "700",
          },
        },
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography sx={{}} variant="h5">
        {props.address}{" "}
      </Typography>
      <Stack direction="row">
        <IconButton
          sx={{
            color: "gray",
            fontSize: "1.5rem",
          }}
          onClick={() => {
            dispatch(
              addressActions.openModal({
                type: "edit",
                apiUrl: `address/update`,
                initialValues: {
                  lat: props.lat,
                  lng: props.lng,
                  address: props.address,
                  user_id: id,
                  city_id: props.city_id,
                  address_id: props.id,
                },
              })
            );
          }}
        >
          <AiOutlineSetting />
        </IconButton>
        <IconButton
          sx={{
            color: "error.main",
            fontSize: "1.5rem",
          }}
          onClick={() => {
            setDeleteAction(() => () => deleteAddress(props.id));
            handleOpenDialog();
          }}
        >
          <AiFillDelete />
        </IconButton>
      </Stack>
      <ConfirmActionDialog
        title="هل انت متأكد من حذف هذا العنوان؟"
        action={deleteAction}
        open={openDeleteDialog}
        handleClose={handleCloseDialog}
        loading={deleting}
      />
    </Stack>
  );
};

export default AddressDetails;
