import { CircularProgress, Stack, Typography } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useDispatch } from "react-redux";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import MainContainer from "../../components/Containers/MainContainer";
import Header from "../../components/Header/Header";
import TableLoader from "../../components/Loaders/TableLoader";
import SubCategoryTable from "../../components/Tables/SubCategoryTable";
import { modalActions } from "../../store/modal-slice";
import { useGetBrands } from "../../hooks";

type Props = {};

const BrandsPage = (props: Props) => {
  //   const mainState = useSelector((state: any) => state.main);
  //   const dependencies = useMemo(() => [mainState.refreshApi], [mainState.refreshApi]);
  const dispatch = useDispatch();
  //   const tagsApiUrl: string = useMemo(() => "tag/get", []);
  //   const { data, loading, error } = useGetSingleApi(tagsApiUrl, dependencies);
  const { data, isPending, hasNextPage, ref } = useGetBrands({
    enabled: true,
    filters: {},
  });

  return (
    <div>
      <Header title="البراندات" />
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            البراندات
          </Typography>
          <OutlinedButton
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  type: "add",
                  apiUrl: "brand/create",
                  initialValues: {
                    name_ar: "",
                    name_en: "",
                  },
                })
              );
            }}
            endIcon={<IoMdAdd />}
            sx={{
              width: "200px",
            }}
          >
            اضافة
          </OutlinedButton>
        </Stack>
        {isPending ? <TableLoader /> : <SubCategoryTable data={data?.pages.map((page) => page).flat()} type="brand" />}
        {hasNextPage && (
          <Stack direction="row" justifyContent="center" sx={{ marginTop: "20px", width: "100%" }} ref={ref}>
            <CircularProgress color="primary" size={16} />
          </Stack>
        )}
      </MainContainer>
    </div>
  );
};

export default BrandsPage;
