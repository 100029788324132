import { Autocomplete, CircularProgress, Stack, TextField } from "@mui/material";

import { IProduct } from "../../types";
import { getProductForCombobox } from "../../apis";
import { useDebounce } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

type ComboboxProps = {};
export const Combobox = (props: ComboboxProps) => {
  const [searchText, setSearchText] = useState("");
  const value = useDebounce(searchText, 500);
  const { data, isPending } = useQuery({
    queryKey: ["payRequestProduct", value],
    queryFn: getProductForCombobox,
    enabled: !!value,
  });
  const navigate = useNavigate();
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      clearOnBlur
      clearOnEscape
      options={data || []}
      getOptionLabel={(option: IProduct) => option.name}
      filterOptions={(x) => x}
      // disable auto complete filter

      sx={{ width: 300 }}
      onChange={(_e, newValue) => {
        navigate(
          `/pay-requests/${newValue?.id}?product_name=${newValue?.name}&product_type=${
            newValue?.type === 1 ? "rawakd" : "khorda"
          }`
        );
      }}
      loading={isPending}
      loadingText={
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={24} />
        </Stack>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="طلبات دفع منتج"
          variant="standard"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      )}
    />
  );
};
