import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

type Props = {
  queryKey: string[];
  queryFn: any;
  handleSuccess?: (data: any) => void;
};

export const useSingleQuery = ({ queryKey, queryFn, handleSuccess }: Props) => {
  const query = useQuery({
    queryKey,
    queryFn,
  });

  useEffect(() => {
    if (query.isSuccess && query.data && handleSuccess) {
      handleSuccess(query.data);
    }
  }, []);

  return { query };
};

// useEffect(() => {
//   if (isSuccess && data) {
//     let tags = data?.tags.map((tag: any) => tag.id);
//     setImages(data?.images);
//     console.log(data.name);
//     setProductInitialValues({
//       product_id: id || "",
//       name: data.name || "",
//       description: data.description || "",
//       logo: data.logo || "",
//       pdf: data.pdf || "",
//       quantity: data.quantity || "",
//       unit_id: data.unit?.id || "",
//       type: data.type || "",
//       price_type: data.price_type || "",
//       start_price: data.start_price || "",
//       price: data.price || "",
//       real_price: data.real_price || "",
//       mazad_type: data.mazad_type || "",
//       // 2024-03-16 15:00:00
//       end_date: data.end_date || "",
//       // end_date: "2024-03-16 15:00:00" || "",
//       cat_id: data.category?.id || "",
//       address_id: data.address?.id || "",
//       masafa_price: data.masafa_price,
//       less_quantity: data.less_quantity || "",
//       status_id: data.status?.id || "",
//       user_id: data.user?.id || "",
//       user: data.user || "",
//       images: [],
//       tags: tags || [],
//       show_company: data.show_company || 1,
//       step_price: Number(data.step_price) || "",
//       adds: (data.adds.length > 0 && data.adds) || [
//         {
//           key: "",
//           value: "",
//         },
//       ],
//     });
//   }
// }, [isSuccess, data, id]);
