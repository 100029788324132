import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ConfirmActionDialog from "../../components/ConfirmActionDialog/ConfirmActionDialog";
import CustomSelectSmall from "../../components/CustomSelect/CustomSelectSmall";
import CustomTextFieldSmall from "../../components/CustomTextFieldSmall/CustomTextFieldSmall";
import FiltersContainer from "../../components/Containers/FiltersContainer";
import Header from "../../components/Header/Header";
import { IoMdAdd } from "react-icons/io";
import MainContainer from "../../components/Containers/MainContainer";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import RawakdTable from "../../components/Tables/RawakdTable";
import { RiDeleteBin6Line } from "react-icons/ri";
import TableLoader from "../../components/Loaders/TableLoader";
import { mainSliceActions } from "../../store/main-slice";
import { postAxios } from "../../Services/axios-services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useProducts } from "../../hooks/useProducts";

type Props = {
  new?: boolean;
};

const CompoundProductsPage = (props: Props) => {
  const dispatch = useDispatch();

  //filter logic
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  const handleChange = () => {
    setFilterExpanded((prev) => !prev);
  };

  const navigate = useNavigate();

  //delete multiple logic
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteAction, setDeleteAction] = useState<any>(null);

  const handleDelete = async (values: any) => {
    try {
      let deleteApiUrl = `products/delete?product_id=`;
      let deleteIds = "";
      const newUrl = values.checked.map((value: any, index: number) => {
        if (index === 0) {
          deleteIds = `${value}`;
        } else {
          deleteIds = `${deleteIds},${value}`;
        }
      });
      deleteApiUrl = deleteApiUrl + deleteIds;

      setDeleting(true);

      await postAxios(deleteApiUrl).then((data) => {
        if (data.data.status !== 1) {
          toast.error(data.data.message);
        } else {
          toast.success(data.data.message);
          dispatch(mainSliceActions.toggleRefreshApi());
        }
      });
    } catch (err) {
    } finally {
      setDeleting(false);
      setDeleteMultiple(false);
      setDeleteAction(null);
    }
  };
  //get user status
  const { parent_id } = useParams<{ parent_id: string }>();

  //get filters
  const filterStatusApi = useMemo(() => `helper/get_status?model_name=product`, []);
  const { data: filterData } = useGetSingleApi(filterStatusApi, []);
  const [filters, setFilters] = useState({
    status_id: "",
    price_type: null,
    search_text: "",
    parent_id: parent_id,
  });

  //react-query

  const { data, isPending, hasNextPage, ref } = useProducts(filters);
  const [searchParams] = useSearchParams();

  const tableType = searchParams.get("type");

  return (
    <div>
      <Formik
        initialValues={{ checked: [] }}
        onSubmit={(values) => {
          setDeleteAction(() => () => handleDelete(values));
          setDeleteMultiple(true);
        }}
      >
        {({ values, handleSubmit }) => (
          <Form>
            <Header title="المنتجات" />
            <MainContainer>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="h6"
                  sx={{
                    color: "primary.main",
                    fontWeight: "700",
                    fontSize: "1.375rem",
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: "8px",
                      left: "0",
                      width: "90px",
                      height: "2px",
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  المنتجات
                </Typography>
                <Stack direction="row" spacing={1}>
                  <OutlinedButton
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant="outlined"
                    endIcon={<RiDeleteBin6Line />}
                    disabled={values.checked.length === 0}
                  >
                    حذف
                  </OutlinedButton>
                  <OutlinedButton
                    endIcon={<IoMdAdd />}
                    onClick={() => {
                      let type = 1;
                      if (tableType === "khorda") {
                        type = 2;
                      } else {
                        type = 1;
                      }
                      navigate(`/products/add/${parent_id}/${type}`);
                    }}
                  >
                    إضافة منتجات
                  </OutlinedButton>
                  <Button
                    sx={{
                      color: "#F7F7FC",
                      borderRadius: "8px",
                      width: "190px",
                      height: "50px",
                      fontSize: "1.125rem",
                      fontWeight: "800",
                    }}
                    onClick={handleChange}
                    variant="contained"
                    color="primary"
                    endIcon={filterExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                  >
                    تصفية المنتجات
                  </Button>
                </Stack>
              </Stack>
              <FiltersContainer showButton={false} filterExpanded={filterExpanded}>
                <CustomTextFieldSmall setFilters={setFilters} filters={filters} />
                {!props.new && filterData && (
                  <CustomSelectSmall
                    placeholder="حاله المنتج"
                    optionsProp={filterData?.data?.map((item: any) => {
                      return {
                        label: item.name_ar,
                        value: item.id,
                      };
                    })}
                    setFilters={setFilters}
                    type="status_id"
                    multi
                  />
                )}

                {/* <CustomSelectSmall
                  type="price_type"
                  placeholder="نوع التسعير"
                  optionsProp={[
                    { value: "1", label: "سعر ثابت" },
                    { value: "2", label: "اعلي سعر" },
                  ]}
                  setFilters={setFilters}
                /> */}
              </FiltersContainer>
              {isPending ? (
                <TableLoader />
              ) : (
                data && (
                  <>
                    <RawakdTable
                      values={values}
                      type={data?.pages[0][0]?.type === 1 ? "rawakd" : "khorda"}
                      rows={data?.pages.map((page) => page).flat()}
                      statusOptions={filterData?.data?.map((item: any) => {
                        return {
                          name: item.name_ar,
                          id: item.id,
                          class: item.class,
                        };
                      })}
                    />
                    {hasNextPage && (
                      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <CircularProgress size={24} ref={ref} />
                      </Box>
                    )}
                  </>
                )
              )}
            </MainContainer>
          </Form>
        )}
      </Formik>
      <ConfirmActionDialog
        title={"هل أنت متأكد من حذف المنتجات ؟"}
        action={deleteAction}
        open={deleteMultiple}
        handleClose={() => {
          setDeleteMultiple(false);
        }}
        loading={deleting}
      />
    </div>
  );
};

export default CompoundProductsPage;
