import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import { AdminsTable } from "./adminsTable";
import { IoMdAdd } from "react-icons/io";
import MainContainer from "../../../components/Containers/MainContainer";
import OutlinedButton from "../../../components/Buttons/OutlinedButton";
import TableLoader from "../../../components/Loaders/TableLoader";
import { useGetAdmins } from "../hooks";
import { useNavigate } from "react-router-dom";

export const AdminsPage = () => {
  const { data, isPending, ref, hasNextPage, isRefetching } = useGetAdmins();
  const navigate = useNavigate();
  // const cookies = new Cookies();
  // const adminId = cookies.get("admin-id");
  return (
    <Box
      sx={{
        mt: "1rem",
      }}
    >
      <MainContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.main",
              fontWeight: "700",
              fontSize: "1.375rem",
              position: "relative",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "8px",
                left: "0",
                width: "90px",
                height: "2px",
                backgroundColor: "primary.main",
              },
            }}
          >
            المستخدمين
          </Typography>
          <OutlinedButton
            endIcon={<IoMdAdd />}
            onClick={() => {
              navigate("/admins/create");
            }}
          >
            إضافة مستخدم
          </OutlinedButton>
        </Stack>

        {isPending || isRefetching ? (
          <TableLoader />
        ) : (
          data && (
            <>
              <AdminsTable rows={data?.pages.map((page) => page).flat()} />

              {hasNextPage && (
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={24} ref={ref} />
                </Box>
              )}
            </>
          )
        )}
      </MainContainer>
    </Box>
  );
};
