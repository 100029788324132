import { styled } from "@mui/material";

const SecondaryTitle = styled("h4")({
  color: "#1bbc39",
  fontWeight: "700",
  fontSize: "1.375rem",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-2px",
    left: "0",
    width: "90px",
    height: "2px",
    backgroundColor: "#1bbc39",
  },
});

export default SecondaryTitle;
