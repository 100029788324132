import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";
import useGetSingleApi from "../../hooks/useGetSingleApi";
import { useFormikContext } from "formik";

const ITEM_HEIGHT = 36;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: "300px",
    },
  },
};

interface Props {
  tags?: {
    id: string;
    name: string;
  }[];
}

export default function MultiSelectTags({ tags }: Props) {
  const { data } = useGetSingleApi("tag/get", []);
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const { setFieldValue } = useFormikContext<any>();

  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);

    setSelectedItems(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    setFieldValue("tags", value);
  };

  React.useEffect(() => {
    console.log("tags", tags);
    if (tags) {
      setSelectedItems(tags.map((tag) => tag.id));
    }
  }, [tags]);

  return (
    <FormControl sx={{ position: "relative", zIndex: "0", width: "100%" }}>
      <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
      <Select
        fullWidth
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        sx={{
          borderRadius: "8px",
        }}
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={data?.data?.data?.filter((item: { id: string; name: string }) => item?.id === value)[0]?.name}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {data?.data?.data?.map((tag: { id: string; name: string }) => (
          <MenuItem key={tag.id} value={tag.id}>
            {tag.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
