import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

const boxStyles = {
  height: "50px",
  width: "150px",
  background: "#EAF9EE",
  border: "1px solid #DEF7E5",
  boxShadow: " 0px 4px 10px rgba(200, 200, 200, 0.25)",
  borderRadius: " 8px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface ProductTabsProps {
  description: any;
  adds: any;
}

export default function ProductTabs({ description, adds }: ProductTabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "5px 0",
        border: "1px solid rgba(0, 0, 0, 0.08)",
        boxShadow: "0px 4px 10px rgba(181, 181, 181, 0.25)",
        borderRadius: "8px !important",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            padding: "0 2.5rem",
            "& .MuiTabs-flexContainer": {
              justifyContent: "space-around",
            },
          }}
        >
          <Tab
            sx={{
              fontSize: "1.3rem",
            }}
            label="وصف المنتج"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              fontSize: "1.3rem",
            }}
            label="بيانات اضافيه"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      {/* tabs */}
      <Box
        sx={{
          padding: "1.5rem 2.5rem",
        }}
      >
        {value === 0 && <div dangerouslySetInnerHTML={{ __html: description }} />}
        {value === 1 && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
            }}
          >
            {adds.length > 0 ? (
              adds.map((add: any, index: number) => {
                console.log(add);

                return <AddItem key={add.id} title={add.key} id={add.id} value={add.value} />;
              })
            ) : (
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "#BDBDBD",
                }}
              >
                لا يوجد بيانات للعرض
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
interface addItemProps {
  title: string;
  value: string;
  id: any;
}

const AddItem = ({ title, value, id }: addItemProps) => {
  return (
    <Stack
      key={id}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      sx={{
        marginBottom: "1.25rem",
        padding: "0 1.5rem",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: "1.25rem",
        }}
      >
        {title}
      </Typography>
      <Box sx={boxStyles}>
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: "700",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Stack>
  );
};
