import * as React from "react";

import { Avatar } from "@mui/material";
import LogoutMenuItem from "./LogoutMenuItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import me from "../../assets/images/me.jpg";
import { useNavigate } from "react-router-dom";

// import Cookies from "universal-cookie";

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const cookie = new Cookies();
  // const adminImage = cookie.get("admin-image");
  const navigate = useNavigate();

  return (
    <div>
      <div
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <Avatar
          sx={{
            // width: 47,
            height: 47,
            marginLeft: "1.5rem",
            cursor: "pointer",
          }}
          src={me}
          alt="admin-image"
        />
      </div>
      <Menu
        disableScrollLock={true}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            handleClose();
          }}
        >
          الملف الشخصي
        </MenuItem>
        {/* <MenuItem onClick={handleClose}></MenuItem> */}
        <LogoutMenuItem />
      </Menu>
    </div>
  );
}
