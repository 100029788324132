import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  sliderModal: boolean;
  newSliceValues: {
    image: any;
    title_ar: string;
    title_en: string;
    desc_ar: string;
    desc_en: string;
    type: string;
    slider_id?: string;
    button_link: string;
    button_ar: string;
    button_en: string;
    sort: string;
    page: number;
  };
}

const initialState: initialStateType = {
  sliderModal: false,
  newSliceValues: {
    image: "",
    title_ar: "",
    title_en: "",
    desc_ar: "",
    desc_en: "",
    type: "1",
    button_link: "",
    button_ar: "",
    button_en: "",
    sort: "",
    page: 0,
  },
};

const sliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {
    openModal(state) {
      state.sliderModal = true;
    },
    closeModal(state) {
      state.sliderModal = false;
      state.newSliceValues = {
        sort: "",
        image: "",
        title_ar: "",
        title_en: "",
        desc_ar: "",
        desc_en: "",
        type: "1",
        button_link: "",
        button_ar: "",
        button_en: "",
        page: 0,
      };
    },
    edit(state, action) {
      state.newSliceValues = action.payload;
    },
    addNew(state, action) {
      state.newSliceValues = {
        sort: "",
        image: "",
        title_ar: "",
        title_en: "",
        desc_ar: "",
        desc_en: "",
        type: "1",
        button_link: "",
        button_ar: "",
        button_en: "",
        page: 0,
      };
    },
  },
});

export const sliderActions = sliderSlice.actions;
export default sliderSlice;
